






































import box from '@/modules/common/components/box.vue'
import CodeList from '@/modules/common/domain/codeList'
import Component from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Stock from '@/modules/stock/domain/stock'
import Vue from 'vue'
import { StockItemFilter } from '@/modules/stock/item/domain/stockItemFilter'
import FilterData from '@/modules/common/mixins/filterData'

@Component({
  components: { box, dateTimeField, loading, selectField, textField }
})
export default class StockItemsFilterBox extends Vue {
  filter = new StockItemFilter()

  @Prop({ type: Object, default: () => new FilterData() }) defaultFilter!: FilterData
  @Prop({ type: String, default: 'stockItems' }) pageDataId!: string
  @Prop({ type: Boolean, default: false }) enableMultipleStock!: boolean

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]
  @Getter('validItems', { namespace: 'region' }) regions?: Array<CodeList>

  @Watch('filter', { deep: true })
  onValueChange () {
    this.$emit('filter-changed', this.filter)
  }

  async created () {
    await this.$store.dispatch('stock/getAll')
    this.filter.query = this.defaultFilter.query || null
    if (!this.enableMultipleStock) {
      this.filter.stockId = this.defaultFilter.stockId ? this.defaultFilter.stockId : this.stocks!.length > 0 ? this.stocks![0].id! : null
    } else if (this.enableMultipleStock && this.defaultFilter.stockIds && this.defaultFilter.stockIds.length) {
      this.filter.stockIds = [...this.defaultFilter.stockIds]
    }
  }
}
