import { Entity } from '@/modules/common/domain/entity'
import WorkerActivity from '@/modules/worker/domain/workerActrivity'
import User from '@/modules/user/domain/user'

export default class Worker extends Entity {
  firstname?: string | null
  surname?: string | null
  contract?: string | null
  user?: User | null
  activities: WorkerActivity[]
  active?: boolean | null

  constructor (data?: any) {
    super(data, 'id', 'fullName', ['fullName', 'contract'])
    this.activities = data.activities ? data.activities.map((workerActivity: any) => new WorkerActivity(workerActivity)) : []
    this.user = data.user ? new User(data.user) : null

    this.relations('activities')
  }

  get fullName (): string {
    return this.surname + ' ' + this.firstname
  }
}
