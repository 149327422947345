import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import CashMoneyBoxResource from '@/modules/money/box/services/cashMoneyBoxResource'
import CashMoneyBox from '@/modules/money/box/domain/cashMoneyBox'

const entityService = new EntityService<CashMoneyBox>(CashMoneyBoxResource, CashMoneyBox)
const moneyBoxModule: Module<EntityState<CashMoneyBox>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'cashMoneyBox')
}

export default moneyBoxModule
