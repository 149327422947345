



















































import _ from 'lodash'
import BankMoneyMovement from '@/modules/money/movement/domain/bankMoneyMovement'
import bankMovementCreateModal from '@/modules/money/movement/components/bankMovementCreateModal.vue'
import bankMovementSplitModal from './bankMovementSplitModal.vue'
import cashMovementCreateModal from '@/modules/money/movement/components/cashMovementCreateModal.vue'
import CashMoneyMovement from '@/modules/money/movement/domain/cashMoneyMovement'
import Component, { mixins } from 'vue-class-component'
import currencies from '@/modules/common/values/currencies'
import { FORMAT_CURRENCY } from '@/utils'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import moneyMovementDescription from '@/modules/money/movement/components/moneyMovementDescription.vue'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import SortData from '@/modules/common/mixins/sortData'
import the from '@/modules/common/components/the.vue'
import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'

@Component({
  components: { bankMovementCreateModal, bankMovementSplitModal, cashMovementCreateModal, moneyMovementDescription, the, loading, noRecords }
})
export default class MoneyMovementsTable extends mixins(auditableTooltipMixin) {
  showBankMovementCreateModal = false
  showBankMovementSplitModal = false
  showCashMovementCreateModal = false
  selectedBankMoneyMovement: BankMoneyMovement | null = null
  selectedCashMoneyMovement: CashMoneyMovement | null = null

  @Prop({ type: Array, required: true }) movements!: Array<MoneyMovement>
  @Prop({ type: SortData, required: true }) sortData!: SortData
  @State('items', { namespace: 'moneyMovementCategory' }) moneyMovementCategories!: Array<MoneyMovementCategory>

  sort (sortData: SortData) {
    this.sortData.by = sortData.by
    this.sortData.asc = sortData.asc
  }

  editBankMoneyMovement (moneyMovement: BankMoneyMovement) {
    this.selectedBankMoneyMovement = _.cloneDeep(moneyMovement)
    this.showBankMovementCreateModal = true
  }

  splitBankMoneyMovement (moneyMovement: BankMoneyMovement) {
    this.selectedBankMoneyMovement = _.cloneDeep(moneyMovement)
    this.showBankMovementSplitModal = true
  }

  editCashMoneyMovement (moneyMovement: CashMoneyMovement) {
    this.selectedCashMoneyMovement = _.cloneDeep(moneyMovement)
    this.showCashMovementCreateModal = true
  }

  hideModals () {
    this.showBankMovementCreateModal = false
    this.showBankMovementSplitModal = false
    this.showCashMovementCreateModal = false
  }

  isCashMovement (moneyMovement: MoneyMovement) {
    return moneyMovement instanceof CashMoneyMovement
  }

  isSplittable (moneyMovement: MoneyMovement) {
    return moneyMovement instanceof BankMoneyMovement &&
      moneyMovement.active &&
      !moneyMovement.invoice
  }

  amountTooltip (moneyMovement: MoneyMovement) {
    if (moneyMovement instanceof CashMoneyMovement) {
      const title = i18n.message('money-movement.amount.tooltip',
        [FORMAT_CURRENCY(moneyMovement.amountCash!, moneyMovement.moneyBox!.currency!, currencies),
          FORMAT_CURRENCY(moneyMovement.amountCheque!, moneyMovement.moneyBox!.currency!, currencies)])

      return { title: title, html: true, customClass: 'text-nowrap', interactive: true }
    }
    return null
  }
}
