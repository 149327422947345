













































import _ from 'lodash'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component from 'vue-class-component'
import { Entity } from '../../common/domain/entity'
import EntityFetchParams from '../../common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import Stock from '@/modules/stock/domain/stock'
import stockForExpeditionModal from '@/modules/setting/components/stockForExpeditionModal.vue'
import Vue from 'vue'

@Component({
  components: { stockForExpeditionModal, loading, noRecords }
})
export default class StocksForExpeditionTable extends Vue {
  showCreateModal: boolean = false
  selectedItem?: Entity | null = null

  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: CompanyBranch[]
  @State('items', { namespace: 'stock' }) stocks?: Stock[]

  edit (companyBranch: CompanyBranch) {
    this.selectedItem = _.cloneDeep(companyBranch)
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  expeditionStore (companyBranch: CompanyBranch) {
    const stock = _.find(this.stocks, stock => stock.companyBranch!.id === companyBranch.id && stock.defaultForDispatch) as Stock
    return stock ? stock.name : null
  }

  async beforeMount () {
    await this.$store.dispatch('companyBranch/clearAll')
  }

  async created () {
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('stock/getAll')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }
}
