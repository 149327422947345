






























import Component from 'vue-class-component'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import incomeStockMovementTypes from '@/modules/stock/movement/type/incomeStockMovementTypes'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementTypes from '@/modules/stock/movement/type/outcomeStockMovementTypes'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class StockMovementDescriptionColumn extends Vue {
  incomeStockMovementTypes = incomeStockMovementTypes
  outcomeStockMovementTypes = outcomeStockMovementTypes

  @Prop({ type: Object, required: true }) movement!: IncomeStockMovement | OutcomeStockMovement
}
