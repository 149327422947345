import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'
import workingActivityResource from '@/modules/workingactivity/services/workingActivityResource'

const contactModule: Module<EntityState<WorkingActivity>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(new EntityService(workingActivityResource, WorkingActivity), 'working-activity')
}

export default contactModule
