
























































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { State } from 'vuex-class'
import Takings from '@/modules/sale/domain/takings'
import Vue from 'vue'

@Component({
  components: { box, loading, noRecords }
})
export default class TakingsDailyTable extends Vue {
  @State('items', { namespace: 'takings' }) takings!: Array<Takings>
  @State('owned', { namespace: 'company' }) company? : Company

  get currency () {
    return this.company ? this.company.defaultCurrency : null
  }

  sumProp (prop: string) {
    return _(this.takings).map(prop).sum()
  }

  async created () {
    await this.$store.dispatch('company/getOwned')
  }
}
