



































































































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import Currency from '@/modules/currency/domain/currency'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import Item from '@/modules/item/domain/Item'
import notificationService from '@/modules/common/services/notificationService'
import Order from '@/modules/order/domain/order'
import OrderItem from '@/modules/order/domain/orderItem'
import orderItemForm from '@/modules/order/components/orderItemForm.vue'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import Loading from '@/modules/common/components/loading.vue'

@Component({
  components: { Loading, box, checkboxField, orderItemForm, selectField, staticField, textField, vForm }
})
export default class OrderForm extends mixins(validationMixin, confirmMixin) {
  initialized = false

  @Prop({ type: Order, required: true }) order!: Order
  @Prop({ type: Boolean, default: false }) deliveryMode!: boolean

  @Getter('activeForPurchase', { namespace: 'item' }) items!: Item[]
  @Getter('validItems', { namespace: 'currency' }) currencyList!: Array<Currency>

  @Watch('order.currency')
  onCurrencyChange () {
    this.order.items = [new OrderItem({})]
  }

  addItem () {
    const newItems = [...this.order.items]
    newItems.push(new OrderItem({ ordered: this.deliveryMode ? 0 : null }))
    this.order.items = newItems
  }

  deleteItem (item: OrderItem) {
    this.order.items = _.without(this.order.items, item)
  }

  onSubmit () {
    if (_.some(this.order.items, orderItem => _.isNil(orderItem.itemPrice))) {
      notificationService.error('error.order.item.price.required')
      this.unprotect()
      return
    }

    const operation = 'order/' + (this.order.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.order, false)).then((result: Order) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.goBack()
      }
    }).finally(this.unprotect)
  }

  deleteOrder () {
    this.confirm('order.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('order/delete', new EntityDeleteParams(this.order.id!, false)).then((result) => {
          if (result) {
            notificationService.success('order.delete.success')
            this.goBack()
          }
        }).finally(this.unprotect)
      }
    })
  }

  goBack () {
    this.$router.push({ name: this.deliveryMode ? 'deliveries' : 'orders' })
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('priceList/getAll', new EntityFetchParams(true, {
      'company-id': this.order.subscriber!.id,
      'include-global': true
    }))
    this.initialized = true // need to be here because of right recomputing price for order item filled from order item template
  }
}
