import EntityService from '@/modules/common/services/entityService'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import moneyMovementResource from '@/modules/money/movement/services/moneyMovementResource'
import CashMoneyMovement from '@/modules/money/movement/domain/cashMoneyMovement'
import BankMoneyMovement from '@/modules/money/movement/domain/bankMoneyMovement'

class MoneyMovementService extends EntityService<MoneyMovement> {
  constructor () {
    super(moneyMovementResource, MoneyMovement)
  }

  createItem (data: any) {
    switch (data['@type']) {
      case 'cash':
        return new CashMoneyMovement(data)
      case 'bank':
        return new BankMoneyMovement(data)
      default: {
        throw new Error('Unresolved money movement type.')
      }
    }
  }
}

export default new MoneyMovementService()
