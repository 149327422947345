


















































import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import userService from '@/modules/user/services/userService'
import PasswordChange from '@/modules/user/domain/passwordChange'
import notificationService from '@/modules/common/services/notificationService'

@Component({
  components: { formModal, textField }
})
export default class PasswordChangeModal extends mixins(submitProtectionMixin) {
  credentials?: PasswordChange | null = new PasswordChange()
  show: boolean = false

  @Prop({ type: Boolean, default: false }) showModal!: boolean

  async onSubmit () {
    try {
      await userService.myPasswordChange(this.credentials!)
      notificationService.success('user.password.change.success')
      this.$emit('close')
    } finally {
      await this.unprotect()
    }
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal

    if (this.show) {
      this.credentials = new PasswordChange()
    }
  }
}
