



















import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import moment from 'moment'
import takingsFilterBox from '@/modules/sale/components/takingsFilterBox.vue'
import takingsDailyTable from '@/modules/sale/components/takingsDailyTable.vue'
import takingsTable from '@/modules/sale/components/takingsTable.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import { Filter } from '@/modules/sale/domain/filter'

@Component({
  components: { page, takingsDailyTable, takingsFilterBox, takingsTable }
})
export default class TakingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'common.sale' }, { textKey: 'takings.daily.label' }])

  date: moment.Moment | null = null
  companyBranch: CompanyBranch | null = null

  async onFilterChanged (filter: Filter) {
    this.date = filter.date
    this.companyBranch = filter.companyBranch
  }
}

