import workerSettingPage from '@/modules/setting/components/workerSettingPage.vue'
import workerEditPage from '@/modules/worker/components/workerEditPage.vue'
import workerNewPage from '@/modules/worker/components/workerNewPage.vue'

export default [
  {
    path: '/settings/worker',
    component: workerSettingPage,
    name: 'workers',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/worker/new',
    component: workerNewPage,
    name: 'workerNew',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/worker/edit/:workerId',
    component: workerEditPage,
    name: 'workerEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  }
]
