















































import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import textField from '@/modules/common/components/form/textField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import createModal from '@/modules/common/components/createModal'
import addressForm from '@/modules/common/components/addressForm.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { selectField, textField, checkboxField, formModal, addressForm }
})
export default class CompanyBranchCreateModal extends mixins(createModal) {
  moduleName = 'companyBranch'

  @Prop({ type: Boolean, default: true }) showForOrder!: boolean

  afterSuccessSave () {
    this.$store.dispatch('company/refresh')
  }
}
