












import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import paymentTypesTable from '@/modules/paymenttype/components/paymentTypesTable.vue'
import stocksForExpeditionTable from '@/modules/setting/components/stocksForExpeditionTable.vue'
import Vue from 'vue'

@Component({
  components: { stocksForExpeditionTable, paymentTypesTable, page }
})
export default class RegularSaleSettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'regular-sale.label' }])
}
