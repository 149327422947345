import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import Worker from '@/modules/worker/domain/worker'

export default class Timesheet extends Entity {
  worker?: Worker | null
  dateFrom?: moment.Moment | null
  dateTo?: moment.Moment | null
  locked?: boolean | null

  constructor (data?: any) {
    super(data, 'id')

    this.dateFrom = this.convertDate(data.dateFrom)
    this.dateTo = this.convertDate(data.dateTo)

    this.worker = data.worker ? new Worker(data.worker) : null
  }
}
