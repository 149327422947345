import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import recipeResource from '@/modules/recipe/services/recipeResource'
import Recipe from '@/modules/recipe/domain/recipe'

const entityService = new EntityService<Recipe>(recipeResource, Recipe)
const recipeModule: Module<EntityState<Recipe>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'recipe')
}

export default recipeModule
