







































































import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'
import box from '@/modules/common/components/box.vue'
import cashBoxCreateModal from '@/modules/sale/components/cashBoxCreateModal.vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import Takings from '@/modules/sale/domain/takings'
import takingsCreateModal from '@/modules/sale/components/takingsCreateModal.vue'
import takingsTransferModal from '@/modules/sale/components/takingsTransferModal.vue'
import Company from '@/modules/company/domain/company'
import _ from 'lodash'

@Component({
  components: { box, cashBoxCreateModal, loading, noRecords, takingsCreateModal, takingsTransferModal }
})
export default class TakingsTable extends mixins(entityTableMixin, auditableTooltipMixin) {
  entityModuleName = 'takings'
  activeSupport = false
  showTransferModal = false

  @Prop({ type: Object, required: true }) date!: moment.Moment
  @Prop({ type: CompanyBranch, required: true }) companyBranch!: CompanyBranch

  @State('owned', { namespace: 'company' }) company? : Company
  @State('parameters', { namespace: 'takings' }) parameters: any
  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean

  async fetch () {
    // do not fetch - data are fetched by filter component
  }

  transferShow (item: Takings) {
    this.selectedItem = _.cloneDeep(item)
    this.showTransferModal = true
  }

  transferHide () {
    this.showTransferModal = false
  }

  async created () {
    await this.$store.dispatch('takings/clearAll')
    await this.$store.dispatch('company/getOwned')
    this.newItemTemplateDefault = () => new Takings({ date: this.date, currency: this.company!.defaultCurrency })
  }
}
