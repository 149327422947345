
































import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import itemCreateModal from '@/modules/item/components/itemCreateModal.vue'
import itemStockCreateModal from '@/modules/stock/item/components/itemStockCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import StockItem from '@/modules/stock/item/domain/stockItem'
import Vue from 'vue'

@Component({
  components: { box, itemCreateModal, itemStockCreateModal, loading }
})
export default class StockItemStatusBox extends Vue {
  showUpdateModal: boolean = false
  selectedStockItem?: StockItem | null = null

  @State('selected', { namespace: 'stockItem' }) stockItem!: StockItem
  @Prop({ type: Boolean, default: true }) showUpdateButton!: boolean

  updateShow () {
    this.showUpdateModal = true
    this.selectedStockItem = this.stockItem
  }

  updateHide () {
    this.showUpdateModal = false
  }
}
