









































































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import DeliveryRoute from '@/modules/delivery/route/domain/deliveryRoute'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { GOOGLE_MAP_DIRECTION_URL, GOOGLE_MAP_SEARCH_URL } from '@/config'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import FilterData from '@/modules/common/mixins/filterData'
import { Getter } from 'vuex-class'
import CodeList from '@/modules/common/domain/codeList'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { box, loading, noRecords, selectField }
})
export default class DeliveryRoutesTable extends mixins(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'deliveryRoute'

  @Getter('validItems', { namespace: 'region' }) regions?: Array<CodeList>

  filter: FilterData = {
    regionId: null
  }

  @Watch('filter.regionId')
  onFilterRegionChange () {
    this.fetch()
  }

  showAddress (deliveryRoute: DeliveryRoute) {
    window.open(GOOGLE_MAP_SEARCH_URL + this.encodeDeliveryRouteAddress(deliveryRoute), '_blank')
  }

  showDirection () {
    const addresses = _(this.items)
      .map(this.encodeDeliveryRouteAddress)
      .join('/')
    window.open(GOOGLE_MAP_DIRECTION_URL + addresses, '_blank')
  }

  private encodeDeliveryRouteAddress (deliveryRoute: DeliveryRoute) {
    const parts = deliveryRoute.address!.format(true, false).replace(/[,]/g, '').split(/\s+/)
    return _(parts).map(encodeURIComponent).join('+')
  }

  async fetch () {
    if (this.filter.regionId) {
      await this.$store.dispatch(this.entityModuleName + '/' + this.itemsFetchAction, new EntityFetchParams(true, { 'region-id': this.filter.regionId }))
    }
  }

  initData () {
    this.$store.dispatch('deliveryRoute/clearAll')
    this.$store.dispatch('region/getAll')
  }
}
