import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import getters from '@/modules/common/store/entityGetters'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import mutations from '@/modules/common/store/entityMutations'
import OrderItemTemplate from '@/modules/order/domain/orderItemTemplate'
import orderItemTemplateResource from '@/modules/order/services/orderItemTemplateResource'
import RootState from '@/store/rootState'

const entityService = new EntityService<OrderItemTemplate>(orderItemTemplateResource, OrderItemTemplate)
const orderItemTemplateModule: Module<EntityState<OrderItemTemplate>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'order-item-template')
}

export default orderItemTemplateModule
