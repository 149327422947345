






import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import moment from 'moment'
import Order from '@/modules/order/domain/order'
import orderForm from '@/modules/order/components/orderForm.vue'
import OrderItem from '@/modules/order/domain/orderItem'
import OrderItemTemplate from '@/modules/order/domain/orderItemTemplate'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { orderForm, page }
})
export default class OrderEditPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, { textKey: 'regular-sale.orders.label', routeName: 'orders' }, { textKey: 'order.new.label' }])
  order: Order | null = null

  @Prop({ type: String, required: true }) supplierBranchId!: string
  @Prop({ type: String, required: true }) subscriberId!: string
  @Prop({ type: String }) subscriberBranchId?: string
  @Prop({ type: String, required: true }) date!: string

  @Getter('activePartners', { namespace: 'company' }) companies?: Array<Company>
  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('all', { namespace: 'orderItemTemplate' }) orderItemTemplates!: OrderItemTemplate[]
  @Getter('all', { namespace: 'item' }) items!: Item[]

  async created () {
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('orderItemTemplate/getAll', new EntityFetchParams(true, { 'company-id': this.subscriberId }))

    const supplierBranch = _.find(this.company!.branches, { id: parseInt(this.supplierBranchId) })
    if (!supplierBranch) return

    const subscriber = _.find(this.companies, { id: parseInt(this.subscriberId) })
    if (!subscriber) return

    const order = new Order({ state: 'NEW', supplierBranch, subscriber })
    if (this.subscriberBranchId) {
      order.subscriberBranch = _.find(subscriber!.branches, { id: parseInt(this.subscriberBranchId) })
    }
    order.date = moment(this.date)
    order.currency = this.company!.defaultCurrency
    if (this.orderItemTemplates.length === 0) {
      order.items.push(new OrderItem({}))
    } else {
      order.items.push(..._.map(this.orderItemTemplates, orderItemTemplate => new OrderItem({ item: _.find(this.items, { id: orderItemTemplate.item!.id }) })))
    }
    this.order = order
  }
}
