import BankAccount from '@/modules/bank/account/domain/bankAccount'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import moment from 'moment'

class BankMoneyBox extends MoneyBox {
  bankAccount?: any
  initialAmount?: number
  initialDate?: moment.Moment | null

  constructor (data: any) {
    super(data)

    this.bankAccount = data.bankAccount == null ? null : new BankAccount(data.bankAccount)
    this.initialDate = this.convertDate(data.initialDate)
    this._updateRelations = ['bankAccount']

    this['@type'] = 'bank'
    this._icon = 'fas fa-university'
  }
}

export default BankMoneyBox
