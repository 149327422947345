

















































































import addressForm from '@/modules/common/components/addressForm.vue'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import BankAccount from '@/modules/bank/account/domain/bankAccount'

@Component({
  components: { selectField, textField, checkboxField, formModal, addressForm }
})
export default class BankAccountCreateModal extends mixins<createModal<BankAccount>>(createModal) {
  moduleName = 'bankAccount'

  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>

  onIbanPaste (event: ClipboardEvent) {
    this.item!.iban = (event.clipboardData!.getData('text') || '').replace(/\s/g, '')
    event.preventDefault()
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
  }
}
