import EntityService from '@/modules/common/services/entityService'
import stockResource from '@/modules/stock/services/stockResource'
import Stock from '@/modules/stock/domain/stock'

class StockService extends EntityService<Stock> {
  constructor () {
    super(stockResource, Stock)
  }
}

export default new StockService()
