





























































import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import Contact from '@/modules/contact/domain/contact'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import notificationService from '@/modules/common/services/notificationService'
import selectField from '@/modules/common/components/form/selectField.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import recordTypes from '../type/recordTypes'
import RadioField from '@/modules/common/components/form/radioField.vue'
import subjectRecordMixin from '@/modules/record/components/subjectRecordMixin'
import SubjectRecord from '@/modules/record/domain/subjectRecord'
import { RecordType } from '@/modules/record/type/RecordType'

@Component({
  components: { RadioField, dateTimeField, formModal, checkboxField, selectField, textAreaField, textField }
})
export default class SubjectRecordCreateModal extends mixins<createModal<SubjectRecord>>(createModal, confirmMixin, subjectRecordMixin) {
  moduleName = 'subjectRecord'
  recordTypes = recordTypes
  @Getter('active', { namespace: 'contact' }) contacts!: Array<Contact>

  beforeSave () {
    // remove contact if comment type has been selected
    if (this.item!.type === RecordType.COMMENT) {
      this.item!.contact = null
    }
  }

  deleteRecord () {
    this.confirm('record.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('subjectRecord/delete', new EntityDeleteParams(this.item!.id!)).then((result) => {
          if (result) {
            this.$emit('close')
            notificationService.success('record.delete.success')
          }
        }).finally(this.unprotect)
      }
    })
  }
}
