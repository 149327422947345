




































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import cashBoxCreateModal from '@/modules/sale/components/cashBoxCreateModal.vue'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'
import CashBox from '@/modules/sale/domain/cashbox'
import i18n from '@/i18n/'

@Component({
  components: { cashBoxCreateModal, loading, noRecords, box }
})
export default class CashBoxesTable extends mixins(entityTableMixin) {
  entityModuleName = 'cashBox'

  paymentMethods (cashBox: CashBox) {
    const types = []
    if (cashBox.acceptCash) {
      types.push('common.cash')
    }
    if (cashBox.acceptCheque) {
      types.push('common.cheque')
    }
    if (cashBox.acceptCard) {
      types.push('common.payment.card')
    }
    return _(types).map(type => i18n.message(type)).join(', ')
  }

  async created () {
    this.newItemTemplateDefault = () => new InvoiceSeries({})
  }
}
