





















































































import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import box from '@/modules/common/components/box.vue'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import { BooleanType } from '@/modules/common/values/booleanType'
import CodeList from '@/modules/common/domain/codeList'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import itemCreateModal from '@/modules/item/components/itemCreateModal.vue'
import { ItemFilter } from '@/modules/item/domain/itemFilter'
import itemTypes from '@/modules/item/type/itemTypes'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import PriceList from '@/modules/pricelist/domain/priceList'
import SortData from '@/modules/common/mixins/sortData'
import StockItem from '@/modules/stock/item/domain/stockItem'
import the from '@/modules/common/components/the.vue'
import _ from 'lodash'
import ItemFilterModal from '@/modules/setting/components/itemFilterModal.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { ItemFilterModal, booleanIcon, box, itemCreateModal, loading, noRecords, the }
})
export default class ItemsTable extends mixins<entityTableMixin<Item>>(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'item'
  activeSupport = false
  itemTypes = itemTypes
  sortData: SortData = new SortData('name', true)
  filter = new ItemFilter()
  filterModel: ItemFilter | null = null
  showFilterModal = false
  itemInStock = {} as any
  itemWithPriceList = {} as any
  initialized = false

  @Prop({ type: String, required: true }) pageDataId!: string
  @State('items', { namespace: 'stockItem' }) stockItems! : StockItem[]
  @State('items', { namespace: 'priceList' }) priceLists! : PriceList[]

  @Getter('validItems', { namespace: 'itemCategory' }) itemCategories!: Array<CodeList>

  detail (id: bigint) {
    this.$router.push({ name: 'itemDetail', params: { itemId: id + '' } }).catch(() => {
    })
  }

  doShowFilterModal () {
    this.filterModel = _.cloneDeep(this.filter)
    this.showFilterModal = true
  }

  hideFilterModal () {
    this.showFilterModal = false
  }

  cancelFilter () {
    this.filter.reset()
    this.hideFilterModal()
    this.fetch()
  }

  createFilter () {
    return _.omitBy({
      type: this.filter.typeEnabled ? this.filter.type : null,
      determination: this.filter.determinationTypeEnabled ? this.filter.determinationType : null,
      origin: this.filter.originTypeEnabled ? this.filter.originType : null,
      categories: this.filter.categoriesEnabled ? this.filter.categories : null,
      'batch-evidence': this.filter.batchEvidenceEnabled ? this.filter.batchEvidence === BooleanType.YES : null,
      'active-only': !this.filter.inactiveEnabled
    }, _.isNil)
  }

  async beforeFetch () {
    await this.$store.dispatch('item/clearAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }

  doFilter (filter: ItemFilter) {
    _.assign(this.filter, filter)
    this.hideFilterModal()
    this.fetch()
  }

  async created () {
    await this.$store.dispatch('itemCategory/getAll')
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { active: true }))
    await this.$store.dispatch('priceList/getAll', new EntityFetchParams(true, { 'include-global': true }))

    // make map of ids: true for all items are activly involved in some stock
    this.stockItems.forEach(value => {
      this.itemInStock[value.item!.id!] = true
    })

    // make map of ids: true for all items are activly involved in some stock
    this.priceLists.forEach(value => {
      if (value.validInDate()) {
        this.itemWithPriceList[value.item!.id!] = true
      }
    })

    this.newItemTemplateDefault = () => new Item({ origin: 'PURCHASE' })

    this.initialized = true
  }
}
