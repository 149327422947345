









import Component from 'vue-class-component'
import orderStatementHeaderPrint from '@/modules/order/statement/components/print/orderStatementHeaderPrint.vue'
import orderStatementOrdersSummaryBoxPrint from '@/modules/order/statement/components/print/orderStatementOrdersSummaryBoxPrint.vue'
import orderStatementItemsSummaryBoxPrint from '@/modules/order/statement/components/print/orderStatementItemsSummaryBoxPrint.vue'
import Vue from 'vue'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'

@Component({
  components: { orderStatementHeaderPrint, orderStatementItemsSummaryBoxPrint, orderStatementOrdersSummaryBoxPrint }
})
export default class OrderStatementPrint extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement
  @State('user', { namespace: 'auth' }) user!: User
}
