import deliveryRoutesPage from '@/modules/delivery/route/components/deliveryRoutesPage.vue'
import ordersPage from '@/modules/order/components/ordersPage.vue'
import ordersDeliveryPage from '@/modules/order/components/ordersDeliveryPage.vue'
import orderDeliveryEditPage from '@/modules/order/components/orderDeliveryEditPage.vue'
import orderCreatePage from '@/modules/order/components/orderCreatePage.vue'
import orderEditPage from '@/modules/order/components/orderEditPage.vue'
import ordersOverviewPage from '@/modules/order/components/ordersOverviewPage.vue'

export default [
  {
    path: '/orders',
    name: 'orders',
    component: ordersPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/orders/new/:date/:supplierBranchId/:subscriberId/:subscriberBranchId?',
    component: orderCreatePage,
    name: 'orderNew',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/orders/edit/:orderId',
    component: orderEditPage,
    name: 'orderEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/orders-overview',
    name: 'ordersOverview',
    component: ordersOverviewPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/deliveries',
    name: 'deliveries',
    component: ordersDeliveryPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/deliveries/print',
    name: 'deliveriesPrint',
    component: ordersDeliveryPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/deliveries/delivery-routes',
    name: 'deliveryRoutes',
    component: deliveryRoutesPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/deliveries/edit/:orderId',
    component: orderDeliveryEditPage,
    name: 'deliveryNoteEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  }
]
