











import _ from 'lodash'
import barChart from '@/modules/common/components/chart/barChart.vue'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import { extendMoment } from 'moment-range'
import groupTypes from '@/modules/common/values/groupTypes'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { State } from 'vuex-class'
import Takings from '@/modules/sale/domain/takings'
import { TakingsOverviewFilter } from '@/modules/sale/domain/takingsOverviewFilter'
import Vue from 'vue'
import TakingsOverviewTable from '@/modules/sale/components/takingsOverviewTable.vue'
import TakingsOverviewChart from '@/modules/sale/components/takingsOverviewChart.vue'

export class SumItem {
  dateLabel: string | null = null
  amountTotalFiscal: number = 0
  amountCash: number = 0
  amountCheque: number = 0
  amountCard: number = 0
  amountTotalNonFiscal: number = 0
  amountCashNonFiscal: number = 0
  amountChequeNonFiscal: number = 0
  amountTotal: number = 0
}

export interface SumItems {
  [key: string]: SumItem
}

@Component({
  components: { TakingsOverviewChart, TakingsOverviewTable, barChart, box, noRecords, loading, radioField, dateRangeField, selectField }
})
export default class TakingsOverviewSummaryTable extends Vue {
  sumItems: SumItems = {}
  dates: Array<string> = []

  @State('items', { namespace: 'takings' }) takings!: Takings[]
  @Prop({ type: TakingsOverviewFilter, required: true }) filter!: TakingsOverviewFilter

  @Watch('takings')
  onTakingsChanged () {
    this.recalculateData()
  }

  private recalculateData () {
    // extend moment with moment-range
    const momentExt = extendMoment(moment as any)
    // calculate headers
    const range = momentExt.range(this.filter!.range!.from!, this.filter!.range!.to!)
    const groupType = _.find(groupTypes, { value: this.filter.groupType! })!
    const rangeDates = Array.from(range.by(this.filter.groupType as any))
    this.dates = _.map(rangeDates, date => groupType!.dateFormat(date))

    // calculate data
    this.sumItems = _(this.takings)
      .groupBy(takings => groupType.dateFormat(takings.date)) // group by date format of selected group type
      .map((takingsPerDate, dateLabel) => {
        return {
          dateLabel,
          amountTotalFiscal: _(takingsPerDate).sumBy('amountTotalFiscal') || 0,
          amountCash: _(takingsPerDate).sumBy('amountCash') || 0,
          amountCheque: _(takingsPerDate).sumBy('amountCheque') || 0,
          amountCard: _(takingsPerDate).sumBy('amountCard') || 0,
          amountTotalNonFiscal: _(takingsPerDate).sumBy('amountTotalNonFiscal') || 0,
          amountCashNonFiscal: _(takingsPerDate).sumBy('amountCashNonFiscal') || 0,
          amountChequeNonFiscal: _(takingsPerDate).sumBy('amountChequeNonFiscal') || 0,
          amountTotal: _(takingsPerDate).sumBy('amountTotal') || 0
        } as SumItem
      })
      .keyBy('dateLabel')
      .value()

    this.dates.forEach(date => {
      this.sumItems[date] = this.sumItems[date] || new SumItem()
    })
  }
}
