import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import InventoryItem from '@/modules/inventory/domain/inventoryItem'
import moment from 'moment'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import stockMovementService from '@/modules/stock/movement/services/stockMovementService'
import User from '@/modules/user/domain/user'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'
import { compareFunction } from '@/utils'

class Inventory extends Entity {
  date?: moment.Moment | null
  number?: number
  stock?: Stock | null
  note?: string
  items: InventoryItem[]
  movements: StockMovement[]
  incomeMovement?: IncomeStockMovement
  outcomeMovement?: OutcomeStockMovement
  createdBy?: User | null
  updatedBy?: User | null
  editable?: boolean

  constructor (data: any) {
    super(data, 'id', 'formattedNumber', ['formattedNumber', 'note'])
    this.date = this.convertDate(data.date)
    this.stock = data.stock == null ? null : new Stock(data.stock)
    this.items = data.items ? data.items.map((item: any) => new InventoryItem(item)) : []
    this.movements = data.movements ? data.movements.map((item: any) => stockMovementService.createItem(item)) : []
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)

    this.incomeMovement = _.find(this.movements, movement => movement instanceof IncomeStockMovement)! as IncomeStockMovement
    this.outcomeMovement = _.find(this.movements, movement => movement instanceof OutcomeStockMovement)! as OutcomeStockMovement
    this.calcDifferences()
    this.relations('items')
  }

  get formattedNumber () {
    return this.number ? 'SI-' + this.number : null
  }

  updateItems (stockItems: StockItem[], itemBatches: ItemBatch[]) {
    const currentItems = [...this.items]
    this.items = []
    stockItems.filter(stockItem => stockItem.price! > 0).forEach(stockItem => {
      const currentInventoryItem = _.find(currentItems, inventoryItem => inventoryItem.item!.id === stockItem.item!.id)
      this.items.push(currentInventoryItem || new InventoryItem({ item: stockItem.item }))
    })

    if (itemBatches.length > 0) {
      this.items.forEach(inventoryItem => {
        const batches = _(itemBatches)
          .filter(itemBatch => itemBatch.item!.id === inventoryItem.item!.id)
          .map('name')
          .compact()
          .value()
        inventoryItem.itemBatches = batches.length > 0 ? batches : []
      })
    }
    this.items = _(this.items).sort(compareFunction('item.name')).sort(compareFunction('item.category.label')).value()
  }

  /**
   * Calculate differences to prevent recalculating each time
   */
  private calcDifferences () {
    this.items.forEach(inventoryItem => {
      if (this.incomeMovement) {
        const movementItem = _(this.incomeMovement.items).find(movementItem => movementItem.item!.id === inventoryItem.item!.id)
        if (movementItem) {
          inventoryItem.difference = movementItem.amount!
          return
        }
      }
      if (this.outcomeMovement) {
        const movementItem = _(this.outcomeMovement.items).find(movementItem => movementItem.item!.id === inventoryItem.item!.id)
        if (movementItem) {
          inventoryItem.difference = movementItem.amount! * -1
          return
        }
      }
      inventoryItem.difference = 0
    })
  }
}

export default Inventory
