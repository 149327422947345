
























import Component from 'vue-class-component'
import passwordChangeModal from '@/modules/user/components/passwordChangeModal.vue'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'
import Vue from 'vue'

@Component({
  components: { passwordChangeModal }
})
export default class Navbar extends Vue {
  showModal: boolean = false

  @State('user', { namespace: 'auth' }) user!: User

  showPasswordChange () {
    this.showModal = true
  }

  hidePasswordChange () {
    this.showModal = false
  }

  async logout () {
    await this.$router.push({ name: 'logout' })
  }

  mounted () {
    // need to init main push menu manually - do not work automatically after login
    const element: any = $(this.$refs.pushmenu)
    element.PushMenu()
  }
}
