




























import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import deliveriesTable from '@/modules/order/components/deliveriesTable.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import EntityMap from '@/modules/common/domain/entityMap'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import moment from 'moment'
import Order from '@/modules/order/domain/order'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import ordersOverviewFilterBox from '@/modules/order/components/ordersOverviewFilterBox.vue'
import ordersOverviewSummaryTable from '@/modules/order/components/ordersOverviewSummaryTable.vue'
import ordersOverviewPaymentTypeTable from '@/modules/order/components/ordersOverviewPaymentTypeTable.vue'
import ordersOverviewItemTable from '@/modules/order/components/ordersOverviewItemTable.vue'
import page from '@/modules/common/components/page.vue'
import tabsMixin from '@/modules/common/mixins/tabsMixin'
import Range from '@/modules/common/components/form/range'

@Component({
  components: { deliveriesTable, ordersOverviewItemTable, ordersOverviewPaymentTypeTable, ordersOverviewFilterBox, ordersOverviewSummaryTable, page }
})
export default class OrdersOverviewPage extends mixins(tabsMixin) {
  pageDataId = 'ordersOverview'
  orders: Order[] | null = []

  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>
  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @State('items', { namespace: 'order' }) allOrders!: Order[]

  breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, { textKey: 'common.overview' }])
  filter = new OrderFilter()

  private async fetch () {
    if (this.filter.readyForFetch) {
      this.orders = []
      await this.$store.dispatch('order/getAll', new EntityFetchParams(true, this.createFilter(this.filter)))
      await this.$store.dispatch('order/enrichAll', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
      this.orders = this.allOrders
    }
  }

  async defaultFilter () {
    this.filter.companyBranchId = this.companyBranches!.length > 0 ? this.companyBranches![0].id! : null
    this.filter.currency = this.company!.defaultCurrency || null
    this.filter.range = new Range(moment().startOf('month'), moment())
  }

  async initData () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('order/clearAll')
    await this.$store.dispatch('company/getAllPartners')
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('companyBranch/clearAll')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }

  private createFilter (filter: OrderFilter) {
    return _.pickBy({
      'supplier-branch-id': filter.companyBranchId,
      'date-from': FORMAT_SYSTEM_DATE(filter.range!.from),
      'date-to': FORMAT_SYSTEM_DATE(filter.range!.to),
      currency: filter.currency
    }, _.identity)
  }

  destroyed () {
    this.$store.dispatch('order/clearAll')
  }
}
