




import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class BooleanIcon extends Vue {
  @Prop({ type: Boolean, default: false }) model!: boolean
}
