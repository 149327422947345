







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import EntityMap from '@/modules/common/domain/entityMap'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import Loading from '@/modules/common/components/loading.vue'
import Order from '@/modules/order/domain/order'
import OrderForm from '@/modules/order/components/orderForm.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import Company from '@/modules/company/domain/company'

@Component({
  components: { Loading, OrderForm, page }
})
export default class OrderEditPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  order?: Order | null = null

  @Prop({ type: String, required: true }) orderId!: string

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('selected', { namespace: 'order' }) orderSelected?: Order
  @State('owned', { namespace: 'company' }) company?: Company

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('order/select', this.orderId)
    await this.$store.dispatch('order/enrich', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
    this.order = this.orderSelected

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' },
      { textKey: 'regular-sale.orders.label', routeName: 'orders' },
      { text: this.order!.formattedNumber! }])
  }
}
