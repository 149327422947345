


















import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Filter } from '@/modules/sale/domain/filter'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import selectField from '@/modules/common/components/form/selectField.vue'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { box, dateTimeField, loading, selectField }
})
export default class TakingsFilterBox extends Vue {
  filter: Filter = {
    date: moment(),
    companyBranch: null
  }

  @Getter('active', { namespace: 'companyBranch' }) companyBranches? : Array<CompanyBranch>

  @Watch('filter', { deep: true })
  onValueChange () {
    if (this.filter.date && this.filter.companyBranch) {
      this.$store.dispatch('takings/getAll', new EntityFetchParams(true, this.createFilter()))
      this.$emit('filter-changed', this.filter)
    }
  }

  createFilter () {
    return _.pickBy({
      date: FORMAT_SYSTEM_DATE(this.filter.date),
      'company-branch-id': this.filter!.companyBranch!.id
    }, _.identity)
  }

  async created () {
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('companyBranch/clearAll')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
    if (this.companyBranches!.length > 0) {
      this.filter.companyBranch = this.companyBranches![0]
    }
  }
}
