export const AUTH_USER_UPDATE = 'AUTH_USER_UPDATE'
export const AUTH_LOG_IN = 'AUTH_LOG_IN'
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT'

export const APP_SUBMIT_PROTECTION_ENABLE = 'APP_SUBMIT_PROTECTION_ENABLE'
export const APP_SUBMIT_PROTECTION_DISABLE = 'APP_SUBMIT_PROTECTION_DISABLE'
export const APP_LOADING_DATA_ENABLE = 'APP_LOADING_DATA_ENABLE'
export const APP_LOADING_DATA_DISABLE = 'APP_LOADING_DATA_DISABLE'
export const APP_PAGE_DATA_SAVE = 'APP_PAGE_DATA_SAVE'
export const APP_UPDATE_PAGE_GROUP = 'APP_UPDATE_PAGE_GROUP'
