import Component from 'vue-class-component'
import { RecordType } from '@/modules/record/type/RecordType'
import Vue from 'vue'

@Component
export default class ConfirmMixin extends Vue {
  optionClass (recordType: RecordType) {
    switch (recordType) {
      case RecordType.EMAIL_INCOME:
        return 'bg-primary'
      case RecordType.EMAIL_OUTCOME:
        return 'bg-info'
      case RecordType.PHONE_COMMUNICATION:
        return 'bg-green'
      case RecordType.PERSONAL_MEETING:
        return 'bg-purple'
      case RecordType.COMMENT:
        return 'bg-yellow'
    }
  }

  optionIcon (recordType: RecordType) {
    switch (recordType) {
      case RecordType.EMAIL_INCOME:
        return 'fas fa-envelope-open-text'
      case RecordType.EMAIL_OUTCOME:
        return 'fas fa-envelope'
      case RecordType.PHONE_COMMUNICATION:
        return 'fas fa-phone'
      case RecordType.PERSONAL_MEETING:
        return 'fas fa-user-friends'
      case RecordType.COMMENT:
        return 'fas fa-sticky-note'
    }
  }
}
