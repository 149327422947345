

































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import Worker from '@/modules/worker/domain/worker'

@Component({
  components: { box, loading, noRecords }
})
export default class WorkersTable extends mixins(entityTableMixin) {
  entityModuleName = 'worker'

  activeNumber (worker: Worker) {
    return _.filter(worker.activities, 'active').length
  }
}
