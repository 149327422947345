import _ from 'lodash'
import BankAccount from '@/modules/bank/account/domain/bankAccount'
import { FORMAT_CURRENCY, FORMAT_DATE } from '@/utils'
import Company from '@/modules/company/domain/company'
import currencies from '@/modules/common/values/currencies'
import { Entity } from '@/modules/common/domain/entity'
import i18n from '@/i18n'
import InvoiceItem from '@/modules/invoice/domain/invoiceItem'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import moment from 'moment'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { PaymentMethod } from '@/modules/invoice/type/PaymentMethod'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import moneyBoxService from '@/modules/money/box/services/moneyBoxService'

class Invoice extends Entity {
  invoiceSeries?: InvoiceSeries
  number?: string
  type?: InvoiceType
  constantSymbol?: string
  variableSymbol?: string | null
  specificSymbol?: string
  subscriber?: Company | null
  supplier?: Company | null
  totalAmount?: number
  totalVat?: number
  unpaidAmount?: number
  currency?: string
  paymentMethod?: PaymentMethod
  dateOfIssue?: moment.Moment | null
  dueDate?: moment.Moment | null
  taxableDate?: moment.Moment | null
  reference?: string
  transferredVat?: boolean
  bankAccount?: BankAccount | null
  items: Array<InvoiceItem>
  movements: any[] = []
  orderStatement?: OrderStatement | null

  constructor (data: any) {
    super(data, 'id', 'number', ['label', 'supplier.name'])
    this.subscriber = data.subscriber == null ? null : new Company(data.subscriber)
    this.supplier = data.supplier == null ? null : new Company(data.supplier)
    this.bankAccount = data.bankAccount ? new BankAccount(data.bankAccount) : null
    this.orderStatement = data.orderStatement ? new OrderStatement(data.orderStatement) : null
    this.items = data.items ? data.items.map((item: InvoiceItem) => new InvoiceItem(item)) : []
    this.movements = data.movements ? data.movements.map((item: any) => ({ // cyclomatic dependency - need to transform movement manually
      '@type': item['@type'],
      date: this.convertDate(item.date),
      note: item.note,
      category: item.category ? new MoneyMovementCategory(item.category) : null,
      moneyBox: moneyBoxService.createItem(item.moneyBox),
      totalAmount: item.totalAmount
    })) : []

    this.dateOfIssue = this.convertDate(data.dateOfIssue)
    this.dueDate = this.convertDate(data.dueDate)
    this.taxableDate = this.convertDate(data.taxableDate)

    this.relations('items')

    this.labelDescription = i18n.message('invoice.label.description',
      [
        _.isNil(this.totalAmount) || _.isNil(this.currency) ? '' : FORMAT_CURRENCY(this.totalAmount, this.currency, currencies),
        _.isNil(this.dueDate) ? '' : FORMAT_DATE(this.dueDate)
      ])

    this.extendSearchString()
  }

  get summaries () {
    return _(this.items)
      .filter(item => item.vatRate != null)
      .groupBy('vatRate')
      .values()
      .map(items => ({
        vatRate: items[0].vatRate,
        totalPrice: _.sumBy(items, 'totalPrice'),
        totalVat: _.sumBy(items, 'totalVat')
      }))
      .value()
  }

  get summary () {
    return ({
      totalPrice: _.round(_.sumBy(this.summaries, 'totalPrice'), 2),
      totalVat: _.round(_.sumBy(this.summaries, 'totalVat'), 2),
      totalPriceWithVat: _.round(_.sumBy(this.summaries, 'totalPrice') + _.sumBy(this.summaries, 'totalVat'), 2)
    })
  }

  get totalWithoutVat () {
    return (this.totalAmount || 0) - (this.totalVat || 0)
  }

  get overdue () {
    return !_.isNil(this.unpaidAmount) && !_.isNil(this.dueDate) && (this.unpaidAmount > 0 && this.dueDate.endOf('day').isBefore(moment().startOf('day')))
  }

  get overdueDays () {
    return this.overdue ? moment().endOf('day').diff(this.dueDate!.startOf('day'), 'days') : 0
  }

  get paid () {
    return this.unpaidAmount === 0
  }

  get paidDays () {
    if (this.paid) {
      const maxPayDate = _(this.movements).map('date').max()!
      return Math.abs(this.dateOfIssue!.startOf('day').diff(maxPayDate.endOf('day'), 'days'))
    } else {
      return null
    }
  }

  get partiallyPaid () {
    return !_.isNil(this.unpaidAmount) && !_.isNil(this.totalAmount) && (this.unpaidAmount > 0) && (this.unpaidAmount < this.totalAmount)
  }

  get notPaid () {
    return this.unpaidAmount === this.totalAmount
  }

  private extendSearchString () {
    this.searchString = this.number || ''
    if (this.subscriber) {
      this.searchString += this.subscriber.name
    }
    if (this.supplier) {
      this.searchString += this.supplier.name
    }
  }
}

export default Invoice
