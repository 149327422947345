import _ from 'lodash'
import EntityService from '@/modules/common/services/entityService'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryResource from '@/modules/inventory/services/inventoryResource'
import moment from 'moment'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

class InventoryService extends EntityService<Inventory> {
  constructor () {
    super(inventoryResource, Inventory)
  }

  newInventory (stock: Stock, stockItems: StockItem[], itemBatches: ItemBatch[]) {
    const inventory = new Inventory({
      stock,
      date: moment()
    })
    inventory.updateItems(stockItems, itemBatches)
    return inventory
  }
}

export default new InventoryService()
