import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import Recipe from '@/modules/recipe/domain/recipe'

export default class OrderItemTemplate extends Entity {
  item?: Item | null
  recipe?: Recipe | null

  constructor (data: any) {
    super(data)
    this.item = data.item ? new Item(data.item) : null
    this.recipe = data.recipe ? new Recipe(data.recipe) : null
  }
}
