



























import Component, { mixins } from 'vue-class-component'
import Inventory from '@/modules/inventory/domain/inventory'
import printMixin from '@/modules/common/mixins/printMixin'
import { Prop } from 'vue-property-decorator'

@Component
export default class InventoryDetailBox extends mixins(printMixin) {
  @Prop({ type: Inventory, required: true }) inventory!: Inventory

  edit () {
    this.$router.push({ name: 'inventoryEdit', params: { inventoryId: this.inventory.id + '' } })
  }
}
