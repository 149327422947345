







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import workersTable from '@/modules/worker/components/workersTable.vue'

@Component({
  components: { page, workersTable }
})
export default class WorkerSettingPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'workers.label' }])
}
