







































































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import dateMonthField from '@/modules/common/components/dateMonthField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE, localeCompare, normalize } from '@/utils'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import Worker from '@/modules/worker/domain/worker'
import WorkRecord from '@/modules/work/record/domain/workRecord'

@Component({
  components: { box, dateMonthField, loading, noRecords }
})
export default class WorkRecordsActivitySummaryTable extends mixins(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'workRecord'
  activeSupport = false
  filter: FilterData = {
    date: null
  }

  @State('owned', { namespace: 'company' }) company? : Company
  @State('items', { namespace: 'worker' }) workers? : Array<Worker>

  @Watch('filter.date')
  filterChanged (newDate: moment.Moment, oldDate: moment.Moment) {
    // need to check type of value, because watcher from bookmarkableComponent has impact here
    if (oldDate && moment.isMoment(newDate)) {
      this.fetch()
    }
  }

  get summaryItems () {
    const regexp = new RegExp(normalize(this.query || '*'), 'g')
    const defaultItems = _(this.workers)
      .filter(item => !!normalize(item.searchString).match(regexp))
      .map(worker => ({
        workerId: worker.id,
        fullName: worker.fullName,
        contract: worker.contract,
        duration: moment.duration(0),
        price: 0
      }))
      .value()
    return _(this.items as Array<WorkRecord>)
      .groupBy((workRecord: WorkRecord) => workRecord.workerActivity!.worker!.id)
      .values()
      .map((workRecordsPerWorker: Array<WorkRecord>) =>
        ({
          workerId: workRecordsPerWorker[0]!.workerActivity!.worker!.id,
          fullName: workRecordsPerWorker[0]!.workerActivity!.worker!.fullName,
          contract: workRecordsPerWorker[0]!.workerActivity!.worker!.contract,
          duration: moment.duration(_.sumBy(workRecordsPerWorker, 'hours'), 'hour'),
          price: _.sumBy(workRecordsPerWorker, 'price')
        }))
      .unionBy(defaultItems, 'workerId')
      .sort(localeCompare('fullName'))
      .value()
  }

  get totalDuration () {
    return _(this.summaryItems)
      .map('duration')
      .reduce((result: moment.Duration, item: moment.Duration) => {
        return result.add(item)
      }, moment.duration(0))
  }

  get totalPrice () {
    return _(this.summaryItems)
      .sumBy('price')
  }

  defaultFilter () {
    this.filter.date = moment().startOf('month')
  }

  createFilter () {
    return _.pickBy({
      dateFrom: FORMAT_SYSTEM_DATE(this.filter.date.startOf('month')),
      dateTo: FORMAT_SYSTEM_DATE(this.filter.date.endOf('month'))
    }, _.identity)
  }

  detail (id: number) {
    this.$router.push({ name: 'workerRecordsWorker', params: { workerId: id + '' } })
  }

  async beforeFetch () {
    await this.$store.dispatch('workRecord/clearAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }

  async created () {
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('worker/getAll')
  }
}
