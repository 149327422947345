

















































import _ from 'lodash'
import Component from 'vue-class-component'
import loading from '../../../common/components/loading.vue'
import noRecords from '../../../common/components/noRecords.vue'
import Order from '@/modules/order/domain/order'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { Prop } from 'vue-property-decorator'
import staticField from '@/modules/common/components/form/staticField.vue'
import Vue from 'vue'

@Component({
  components: { staticField, noRecords, loading }
})
export default class PartnerMovementsCategoryTable extends Vue {
  @Prop({ type: Array, required: true }) orders!: Order[]
  @Prop({ type: Array, required: true }) orderStatements!: OrderStatement[]

  get currency () {
    const first = _.first(this.orders)
    return first ? first.currency : null
  }

  get totalNotBilled () {
    return _(this.orders).filter(order => order.delivered && !order.billed).sumBy('totalWithoutVat') || 0
  }

  detail (id: number) {
    this.$router.push({ name: 'orderStatementDetail', params: { orderStatementId: id + '' } })
  }
}
