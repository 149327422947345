































































import _ from 'lodash'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import itemCreateModal from '@/modules/item/components/itemCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import priceListCreateModal from '@/modules/pricelist/components/priceListCreateModal.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import PriceList from '@/modules/pricelist/domain/priceList'
import priceListService from '@/modules/pricelist/service/priceListService'
import { Getter } from 'vuex-class'
import Currency from '@/modules/currency/domain/currency'
import ValueOrderData from '@/modules/common/mixins/valueOrderData'

@Component({
  components: { booleanIcon, itemCreateModal, loading, noRecords, priceListCreateModal, selectField }
})
export default class PriceListTable extends mixins<entityTableMixin<PriceList>>(entityTableMixin) {
  entityModuleName = 'priceList'
  activeSupport = false
  selectedPriceList: PriceList | null = null
  itemsGetter = 'global'

  @Prop({ type: Number, required: true }) itemId!: number

  @Getter('validItems', { namespace: 'currency' }) currencyList!: Array<Currency>
  @Getter('valueOrder', { namespace: 'currency' }) currencyValueOrder!: ValueOrderData

  @Watch('items', { deep: true, immediate: true })
  onItemsChanged (items?: Array<PriceList>) {
    // select price list if only one exists
    if (items && items.length) {
      this.selectedPriceList = items[0] as PriceList
    } else {
      // ensure to be selected null if last is deleted
      this.selectedPriceList = null
    }
  }

  // order by currency order
  get priceListItems () {
    if (this.currencyValueOrder && this.selectedPriceList) {
      return _.sortBy(this.selectedPriceList.items, priceListItem => this.currencyValueOrder![priceListItem.currency!]) || Number.MAX_VALUE
    } else {
      return []
    }
  }

  async fetch () {
    // don't do fetch
  }

  async mounted () {
    this.newItemTemplateDefault = () => priceListService.newPriceList(this.currencyList, this.itemId)
  }
}
