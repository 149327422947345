
































































import bankAccountCreateModal from '@/modules/bank/account/components/bankAccountCreateModal.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Prop } from 'vue-property-decorator'
import BankAccount from '@/modules/bank/account/domain/bankAccount'
import Subject from '@/modules/subject/domain/subject'
import { State } from 'vuex-class'
import Currency from '@/modules/currency/domain/currency'

@Component({
  components: { bankAccountCreateModal, loading, noRecords }
})
export default class BankAccountsTable extends mixins(entityTableMixin) {
  entityModuleName = 'bankAccount'

  @Prop({ type: Object, required: true }) subject!: Subject
  @State('items', { namespace: 'currency' }) currencies? : Currency[]

  async created () {
    const parameters: any = { subjectId: this.subject.id }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    this.newItemTemplateDefault = () => new BankAccount({ subject: this.subject })
  }

  async beforeFetch () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('bankAccount/clearAll')
  }
}
