











































































import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import Invoice from '../domain/invoice'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import BooleanIcon from '@/modules/common/components/icon/booleanIcon.vue'

@Component({
  components: { BooleanIcon, checkboxField, box }
})
export default class InvoiceSummaryBox extends Vue {
  @Prop({ type: Invoice, required: true }) invoice!: Invoice
  @Prop({ type: Boolean, default: false }) editMode!: boolean

  get outcome () {
    return this.invoice.type === InvoiceType.OUTCOME
  }

  get income () {
    return this.invoice.type === InvoiceType.INCOME
  }

  get hasFooter (): boolean {
    return !!this.$slots.footer
  }
}
