













































import Component from 'vue-class-component'
import Company from '../../company/domain/company'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { companyCreateModal, loading }
})
export default class PartnerAddressesBox extends Vue {
  showCreateModal: boolean = false
  selectedPartner?: Company | null = null
  @State('selected', { namespace: 'company' }) partner!: Company

  updateShow () {
    this.selectedPartner = this.partner
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
    this.selectedPartner = null // need to be here to again set fresh copy of model in createModal
  }
}
