































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import moment from 'moment'
import page from '@/modules/common/components/page.vue'
import timeSheetBox from '@/modules/work/record/components/timeSheetBox.vue'
import timeSheetPriceBox from '@/modules/work/record/components/timeSheetPriceBox.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import workerInfoBox from '@/modules/worker/components/workerInfoBox.vue'
import workRecordLastItemsBox from '@/modules/work/record/components/workRecordLastItemsBox.vue'

@Component({
  components: { page, timeSheetBox, timeSheetPriceBox, workerInfoBox, workRecordLastItemsBox }
})
export default class WorkRecordsPersonalPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'work-record.label' }, { textKey: 'work-record.summary.worker.label', routeName: 'workRecordsWorkerSummary' }, { textKey: 'work-record.overview.label' }], 'workRecordsWorkerSummary')
  worker?: Worker | null = null
  latestCurrentMonthFrom = moment().startOf('month')
  latestPrevMonthFrom = moment().subtract(1, 'month').startOf('month')
  latestPrevMonthTo = moment().startOf('month')
  @Prop({ type: String, required: true }) workerId!: string

  async mounted () {
    this.worker = await this.$store.dispatch('worker/select', this.workerId)
    const latestDateFrom = FORMAT_SYSTEM_DATE(moment().subtract(2, 'month').startOf('month'))
    await this.$store.dispatch('workRecord/getLatest', new EntityFetchParams(true, { dateFrom: latestDateFrom, workerId: this.workerId }))
  }
}
