
























import companyBoxPrint from '@/modules/invoice/components/print/companyBoxPrint.vue'
import Component from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import documentField from '@/modules/common/components/form/documentField.vue'
import { Getter } from 'vuex-class'
import Invoice from '@/modules/invoice/domain/invoice'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { companyBoxPrint, documentField }
})
export default class InvoiceSummaryPrint extends Vue {
  @Prop({ type: Invoice, required: true }) invoice!: Invoice
  @Getter('first', { namespace: 'configuration' }) configuration?: Configuration
}
