import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from './priceListGetters'
import mutations from '@/modules/common/store/entityMutations'
import PriceList from '@/modules/pricelist/domain/priceList'
import priceListService from '@/modules/pricelist/service/priceListService'
import RootState from '@/store/rootState'

const contactModule: Module<EntityState<PriceList>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(priceListService, 'price-list')
}

export default contactModule
