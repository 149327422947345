





















































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import companyBox from '@/modules/company/components/companyBox.vue'
import page from '@/modules/common/components/page.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import Invoice from '@/modules/invoice/domain/invoice'
import invoiceSummaryBox from '@/modules/invoice/components/invoiceSummaryBox.vue'
import invoiceDetailInfoBox from '@/modules/invoice/components/InvoiceDetailInfoBox.vue'
import invoicesInfoBox from '@/modules/invoice/components/invoicesInfoBox.vue'
import invoiceItemsInfoBox from '@/modules/invoice/components/invoiceItemsInfoBox.vue'
import invoiceMovementsBox from '@/modules/invoice/components/InvoiceMovementsBox.vue'
import invoicePrint from '@/modules/invoice/components/print/invoicePrint.vue'
import invoiceSymbolsInfoBox from '@/modules/invoice/components/invoiceSymbolsInfoBox.vue'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import partnerContactsBox from '@/modules/company/components/partnerContactsBox.vue'
import { State } from 'vuex-class'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { companyBox, invoiceDetailInfoBox, invoicesInfoBox, invoiceItemsInfoBox, invoiceMovementsBox, invoicePrint, invoiceSymbolsInfoBox, invoiceSummaryBox, page, partnerContactsBox }
})
export default class InvoiceDetailPage extends Vue {
  show = true
  breadCrumbData: BreadCrumbData | null = null

  @Prop(String) id!: string
  @State('selected', { namespace: 'invoice' }) invoice!: Invoice

  get outcome () {
    return this.invoice.type === InvoiceType.OUTCOME
  }

  public metaInfo () {
    return {
      title: this.invoice ? this.$t('invoice.label.with.number', [this.invoice.number]) : null
    }
  }

  async created () {
    await this.$store.dispatch('invoice/select', this.id)
    const subjectId = this.outcome ? this.invoice.subscriber!.id : this.invoice.supplier!.id
    await this.$store.dispatch('contact/getAll', new EntityFetchParams(true, { 'subject-id': subjectId }))

    if (this.invoice.type === InvoiceType.INCOME) {
      this.breadCrumbData = new BreadCrumbData([{ textKey: 'common.purchase' },
        { textKey: 'invoices.income.label', routeName: 'invoicesIncome' },
        { text: this.invoice.number }], 'invoicesIncome')
    } else {
      this.breadCrumbData = new BreadCrumbData([{ textKey: 'common.sale' },
        { textKey: 'invoices.outcome.label', routeName: 'invoicesOutcome' },
        { text: this.invoice.number }], 'invoicesOutcome')
    }
  }
}
