import workingActivitiesSettingPage from '@/modules/setting/components/workingActivitiesSettingPage.vue'
import workingActivityNewPage from '@/modules/workingactivity/components/workingActivityNewPage.vue'
import workingActivityEditPage from '@/modules/workingactivity/components/workingActivityEditPage.vue'

export default [
  {
    path: '/settings/working-activity',
    component: workingActivitiesSettingPage,
    name: 'workingActivities',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/working-activity/new',
    component: workingActivityNewPage,
    name: 'workingActivityNew',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/working-activity/edit/:workingActivityId',
    component: workingActivityEditPage,
    name: 'workingActivityEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_WORKER',
      pageGroup: 'setting'
    }
  }
]
