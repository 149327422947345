






























import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import _ from 'lodash'
import { Getter } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import OrderItemTemplate from '@/modules/order/domain/orderItemTemplate'

@Component({
  components: { formModal, checkboxField, numberField, selectField, textField }
})
export default class CompanySaleSettingsCreateModal extends mixins(createModal) {
  moduleName = 'orderItemTemplate'

  @Getter('activeForPurchase', { namespace: 'item' }) items!: Item[]
  // correct orderItemTemplates are fetched
  @Getter('all', { namespace: 'orderItemTemplate' }) orderItemTemplates!: OrderItemTemplate[]

  get availableItems () {
    const usedItemIds = _.map(this.orderItemTemplates, 'item.id')
    return _.filter(this.items, item => (usedItemIds.indexOf(item.id) < 0))
  }

  async afterSuccessDelete () {
    await this.$store.dispatch('orderItemTemplate/refresh')
  }

  async afterSuccessSave (item: any) {
    await this.$store.dispatch('orderItemTemplate/refresh')
  }

  async created () {
    await this.$store.dispatch('item/getAll')
  }
}
