










import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import cashBoxesTable from '@/modules/sale/components/cashBoxesTable.vue'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { cashBoxesTable, page }
})
export default class SaleSettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'common.sale' }])
}
