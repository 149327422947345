
























































import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'

@Component({
  components: { formModal, checkboxField, radioField, selectField, textField }
})
export default class StockCreateModal extends mixins(createModal) {
  moduleName = 'stock'

  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>

  async created () {
    await this.$store.dispatch('currency/getAll')
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }
}
