




































































import CodeList from '@/modules/common/domain/codeList'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import { Getter } from 'vuex-class'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryItemForm from '@/modules/inventory/components/InventoryItemForm.vue'
import loading from '@/modules/common/components/loading.vue'
import NoRecords from '@/modules/common/components/noRecords.vue'
import notificationService from '@/modules/common/services/notificationService'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import StockItem from '@/modules/stock/item/domain/stockItem'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

@Component({
  components: { NoRecords, dateTimeField, inventoryItemForm, loading, selectField, staticField, textField, vForm }
})
export default class InventoryForm extends mixins(validationMixin, confirmMixin) {
  categoriesFilter = []
  initialized = false

  @Prop({ type: Inventory, required: true }) inventory!: Inventory

  @Getter('validItems', { namespace: 'itemCategory' }) itemCategories!: Array<CodeList>
  @Getter('active', { namespace: 'stockItem' }) stockItems!: Array<StockItem>
  @Getter('active', { namespace: 'itemBatch' }) itemBatches!: ItemBatch[]

  onSubmit () {
    if (!this.inventory.items.length) {
      notificationService.error('error.inventory.items.emtpy')
      return this.unprotect()
    }
    const operation = 'inventory/' + (this.inventory!.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.inventory!, false)).then((result: Inventory) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'inventory', params: { inventoryId: result.id + '' } })
      }
    }).finally(this.unprotect)
  }

  deleteInventory () {
    this.confirm('inventory.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('inventory/delete', new EntityDeleteParams(this.inventory.id!, false)).then((result) => {
          if (result) {
            notificationService.success('inventory.delete.success')
            this.$router.push({ name: 'stockMovements' })
          }
        }).finally(this.unprotect)
      }
    })
  }

  @Watch('categoriesFilter')
  onCategoriesFilterChanged (categories: number[]) {
    if (categories.length > 0) {
      this.inventory.updateItems(this.stockItems.filter(stockItem => categories.indexOf(stockItem.item!.category!.id!) >= 0), this.itemBatches)
    } else {
      this.inventory.updateItems(this.stockItems, this.itemBatches)
    }
  }

  async created () {
    await this.$store.dispatch('itemCategory/getAll')
    await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.inventory!.stock!.id, 'active-only': true }))
    this.initialized = true
  }
}
