



































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import codeListTable from '@/modules/common/components/codeListTable.vue'
import page from '@/modules/common/components/page.vue'
import stocksTable from '@/modules/stock/components/stocksTable.vue'
import Vue from 'vue'

@Component({
  components: { stocksTable, codeListTable, page }
})
export default class StockSettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'setting.stock.label' }])
}
