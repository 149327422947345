import { CLEAR_TAX_BASE_SUMMARIES, CLEAR_VAT_SUMMARIES, SUMMARY_TAX_BASE_LIST, SUMMARY_VAT_LIST } from './mutationTypes'
import { MutationTree } from 'vuex'
import FinanceState from '@/modules/finance/store/financeState'

const mutations: MutationTree<FinanceState> = {
  [SUMMARY_VAT_LIST] (state, action) {
    state.vatSummaryItems = action.items
  },
  [SUMMARY_TAX_BASE_LIST] (state, action) {
    state.taxBaseSummaryItems = action.items
  },
  [CLEAR_VAT_SUMMARIES] (state) {
    state.vatSummaryItems = []
  },
  [CLEAR_TAX_BASE_SUMMARIES] (state) {
    state.taxBaseSummaryItems = []
  }
}

export default mutations
