import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'

export default class ItemDispatch extends Entity {
  item?: Item | null
  subItem?: Item | null
  amount?: number | null

  constructor (data?: any) {
    super(data, 'id', 'name')

    this.item = data.item ? new Item(data.item) : null
    this.subItem = data.subItem ? new Item(data.subItem) : null
  }
}
