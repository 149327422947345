import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryService from '@/modules/inventory/services/inventoryService'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'

const inventoryModule: Module<EntityState<Inventory>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(inventoryService, 'inventory')
}

export default inventoryModule
