































import box from '@/modules/common/components/box.vue'
import Company from '../domain/company'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { box }
})
export default class CompanyBox extends Vue {
  @Prop({ type: Company, required: true }) company!: Company
  @Prop({ type: Boolean, default: false }) collapsible!: boolean
  @Prop({ type: Boolean, default: false }) collapsed!: boolean
}
