




































import companyBox from '@/modules/company/components/companyBox.vue'
import Component from 'vue-class-component'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import incomeStockMovementDetailBox from '@/modules/stock/movement/components/incomeStockMovementDetailBox.vue'
import incomeStockMovementTransferBox from '@/modules/stock/movement/components/incomeStockMovementTransferBox.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '../../../common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import stockMovementItemsBox from '@/modules/stock/movement/components/stockMovementItemsBox.vue'
import stockMovementSummaryBox from '@/modules/stock/movement/components/stockMovementSummaryBox.vue'
import Vue from 'vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'

@Component({
  components: { companyBox, incomeStockMovementDetailBox, incomeStockMovementTransferBox, loading, page, stockMovementItemsBox, stockMovementSummaryBox }
})
export default class IncomeStockMovementCreatePage extends Vue {
  breadCrumbData?: BreadCrumbData | null = null

  @Prop({ type: String, required: true }) movementId!: string

  @State('selected', { namespace: 'incomeStockMovement' }) movement!: IncomeStockMovement

  async created () {
    await this.$store.dispatch('incomeStockMovement/select', this.movementId)

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' },
      { textKey: 'stock-movements.label.2', routeName: 'stockMovements' },
      { text: this.movement.formattedNumber! }], 'stockMovements')
  }
}
