





































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import Recipe from '@/modules/recipe/domain/recipe'
import RecipeAmount from '@/modules/stock/movement/domain/recipeAmount'
import selectField from '@/modules/common/components/form/selectField.vue'
import StockItem from '@/modules/stock/item/domain/stockItem'
import { Prop, Watch } from 'vue-property-decorator'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'

@Component({
  components: { formModal, numberField, selectField }
})
export default class OutcomeStockMovementRecipeModal extends mixins(submitProtectionMixin) {
  model: RecipeAmount = new RecipeAmount()
  show: boolean = false

  @Prop({ type: Boolean, default: false }) showModal!: boolean

  @Getter('all', { namespace: 'recipe' }) recipes!: Recipe[]
  @Getter('all', { namespace: 'item' }) items?: Item[]
  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]

  async onSubmit () {
    // validate if all recipe items are available on target stock
    const recipeItemIds = _.map(this.model.recipe!.items, 'item.id')
    const availableItemIds = _.map(this.stockItems, 'item.id')
    const firstInvalidItemId = _.find(recipeItemIds, recipeItemId => !availableItemIds.includes(recipeItemId))
    if (firstInvalidItemId) {
      const invalidItem = _.find(this.items, { id: firstInvalidItemId })
      notificationService.error('error.stock.movement.recipe.not.valid.item', [invalidItem!.name])
    } else {
      await this.$emit('add-recipe-amount', this.model)
      await this.$emit('close')
    }
    await this.unprotect()
  }

  onClose () {
    this.$emit('close')
  }

  validationObject () {
    const validationObj = { required: true, max_value: 9999999999, min_value: 0 } as any
    const item = this.model.recipe ? this.model.recipe!.item : null
    if (item) {
      if (item.scale === 0) {
        validationObj.numeric = true
        validationObj.min_value = 1
      } else if (item.scale! > 0) {
        validationObj.decimal = item.scale
        validationObj.min_value = _.round(1 / (10 ** item.scale!))
      }
    }
    return validationObj
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal

    if (this.show) {
      this.model = new RecipeAmount()
    }
  }

  async created () {
    await this.$store.dispatch('recipe/getAll')
  }
}
