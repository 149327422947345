import { Module } from 'vuex'
import actions from '@/modules/finance/store/financeSummaryActions'
import mutations from '@/modules/finance/store/financeSummaryMutations'
import RootState from '@/store/rootState'
import FinanceState from '@/modules/finance/store/financeState'

const financeModule: Module<FinanceState, RootState> = {
  namespaced: true,
  state: new FinanceState(),
  mutations,
  actions
}

export default financeModule
