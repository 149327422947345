import itemDetailPage from '@/modules/item/components/itemDetailPage.vue'

export default [
  {
    name: 'itemDetail',
    path: '/settings/item/:itemId',
    component: itemDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_ITEM',
      pageGroup: 'setting'
    }
  }
]
