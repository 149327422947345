































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import notificationService from '@/modules/common/services/notificationService'
import Recipe from '@/modules/recipe/domain/recipe'

@Component({
  components: { formModal, numberField }
})
export default class RecipeCreateModal extends mixins<createModal<Recipe>>(createModal) {
  moduleName = 'recipe'

  deleteItemCustom () {
    if (this.item!.items.length > 0) {
      notificationService.error('error.recipe.delete.items.not.empty')
    } else {
      this.deleteItem()
    }
  }

  validationObject () {
    const validationObj = { required: true, max_value: 9999999999, min_value: 0 } as any
    const item = this.item!.item!
    if (item.scale === 0) {
      validationObj.numeric = true
      validationObj.min_value = 1
    } else if (item.scale! > 0) {
      validationObj.decimal = item.scale
      validationObj.min_value = _.round(1 / (10 ** item.scale!))
    }
    return validationObj
  }
}
