
























import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementDetailBox from '@/modules/stock/movement/components/outcomeStockMovementDetailBox.vue'
import outcomeStockMovementTransferBox from '@/modules/stock/movement/components/outcomeStockMovementTransferBox.vue'
import page from '../../../common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import stockMovementItemsBox from '@/modules/stock/movement/components/stockMovementItemsBox.vue'
import stockMovementSummaryBox from '@/modules/stock/movement/components/stockMovementSummaryBox.vue'
import Vue from 'vue'

@Component({
  components: { loading, outcomeStockMovementDetailBox, outcomeStockMovementTransferBox, page, stockMovementItemsBox, stockMovementSummaryBox }
})
export default class OutcomeStockMovementCreatePage extends Vue {
  breadCrumbData: BreadCrumbData | null = null

  @Prop({ type: String, required: true }) movementId!: string

  @State('selected', { namespace: 'outcomeStockMovement' }) movement!: OutcomeStockMovement

  async created () {
    await this.$store.dispatch('outcomeStockMovement/select', this.movementId)

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' },
      { textKey: 'stock-movements.label.2', routeName: 'stockMovements' },
      { text: this.movement.formattedNumber! }], 'stockMovements')
  }
}
