











import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import { Getter, State } from 'vuex-class'
import Invoice from '@/modules/invoice/domain/invoice'
import invoiceCreateForm from './invoiceCreateForm.vue'
import invoiceService from '@/modules/invoice/services/invoiceService'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { invoiceCreateForm, loading, page }
})
export default class InvoicesPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  invoice?: Invoice | null = null
  error?: string | null = null

  @Prop({ type: String }) id?: string

  @Getter('activePartners', { namespace: 'company' }) supplierOptions?: Array<Company>
  @Getter('first', { namespace: 'configuration' }) configuration?: Configuration
  @State('owned', { namespace: 'company' }) ownedCompany?: Company

  async mounted () {
    await this.$store.dispatch('company/getAllPartners', true)
    await this.$store.dispatch('company/getOwned')

    if (!this.ownedCompany) {
      this.error = 'error.company.owned.missing'
    } else if (this.id) {
      this.invoice = _.cloneDeep(await this.$store.dispatch('invoice/select', this.id))
    } else {
      this.invoice = invoiceService.newIncomeInvoice(
        this.configuration ? this.configuration.invoice : {}, this.ownedCompany)
    }

    const lastCrumb = this.id ? { text: this.invoice!.number } : { textKey: 'invoice.create.label' }
    this.breadCrumbData = new BreadCrumbData([{ textKey: 'common.purchase' }, {
      textKey: 'invoices.income.label',
      routeName: 'invoicesIncome'
    }, lastCrumb])
  }
}
