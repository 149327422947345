











































import CodeList from '@/modules/common/domain/codeList'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { formModal, checkboxField, numberField, selectField, textField }
})
export default class CompanySaleSettingsCreateModal extends mixins(createModal) {
  moduleName = 'company'

  @Getter('validItems', { namespace: 'paymentType' }) paymentTypes!: Array<CodeList>

  async created () {
    await this.$store.dispatch('paymentType/getAll')
  }
}
