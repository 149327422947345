import userSettingPage from '@/modules/setting/components/userSettingPage.vue'
import usersPermissions from '../components/userPermissionSettingPage.vue'

export default [
  {
    path: '/settings/users',
    component: userSettingPage,
    name: 'users',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_USER',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/users/permissions/:userId',
    component: usersPermissions,
    name: 'userPermissionsSetting',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_USER',
      pageGroup: 'setting'
    }
  }
]
