import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'
import StockItem from '@/modules/stock/item/domain/stockItem'
import stockItemResource from '@/modules/stock/item/services/stockItemResource'

const entityService = new EntityService<StockItem>(stockItemResource, StockItem)
const stockModule: Module<EntityState<StockItem>, RootState> = {
  namespaced: true,
  state: new EntityState<StockItem>(),
  mutations,
  getters,
  actions: actions(entityService, 'stock-item')
}

export default stockModule
