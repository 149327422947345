






























import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component, { mixins } from 'vue-class-component'
import companyBox from '@/modules/company/components/companyBox.vue'
import deliveryNotesPrint from '@/modules/order/components/print/deliveryNotesPrint.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import invoicePrint from '@/modules/invoice/components/print/invoicePrint.vue'
import loading from '@/modules/common/components/loading.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import orderStatementInfoBox from '@/modules/order/statement/components/orderStatementInfoBox.vue'
import orderStatementInvoicesBox from '@/modules/order/statement/components/orderStatementInvoicesBox.vue'
import orderStatementItemsSummaryBox from '@/modules/order/statement/components/orderStatementItemsSummaryBox.vue'
import orderStatementOrdersSummaryBox from '@/modules/order/statement/components/orderStatementOrdersSummaryBox.vue'
import orderStatementPrint from '@/modules/order/statement/components/print/orderStatementPrint.vue'
import orderStatementReturnedSummaryBox from '@/modules/order/statement/components/orderStatementReturnedSummaryBox.vue'
import page from '@/modules/common/components/page.vue'
import partnerContactsBox from '@/modules/company/components/partnerContactsBox.vue'
import printMixin from '@/modules/common/mixins/printMixin'
import { Prop } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import { OrderStatementPrintFilter } from '@/modules/order/statement/domain/orderStatementPrintFilter'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'
import Company from '@/modules/company/domain/company'

@Component({
  components: { deliveryNotesPrint, orderStatementPrint, invoicePrint, companyBox, loading, page, orderStatementInfoBox, orderStatementInvoicesBox, orderStatementItemsSummaryBox, orderStatementOrdersSummaryBox, orderStatementReturnedSummaryBox, partnerContactsBox }
})
export default class OrderStatementDetailPage extends mixins(printMixin) {
  breadCrumbData: BreadCrumbData | null = null
  orderStatement: OrderStatement | null = null

  printFilter = new OrderStatementPrintFilter(true, false, false)

  @Prop({ type: String, required: true }) orderStatementId!: string

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  public metaInfo () {
    return {
      title: this.orderStatement ? this.$t('order-statement.label.with.number', [this.orderStatement.formattedNumber]) : null
    }
  }

  async doPrint (filter: OrderStatementPrintFilter) {
    _.assign(this.printFilter, filter)
    // using v-show instead of v-if because of problems with image rendering in invoice-print component
    this.print()
  }

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('orderStatement/select', this.orderStatementId)
    this.orderStatement = await this.$store.dispatch('orderStatement/enrich', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
    await this.$store.dispatch('contact/getAll', new EntityFetchParams(true, { 'subject-id': this.orderStatement!.subscriber!.id }))

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, {
      textKey: 'order-statements.label.short',
      routeName: 'orderStatements'
    }, { text: this.orderStatement!.formattedNumber! }], 'orderStatements')
  }
}
