







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'
import workingActivityForm from '@/modules/workingactivity/components/workingActivityForm.vue'

@Component({
  components: { page, workingActivityForm }
})
export default class WorkingActivityNewPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'setting.working-activity.label', routeName: 'workingActivities' }, { textKey: 'working-activity.new.label' }])

  activity?: WorkingActivity | null = null

  async mounted () {
    await this.$store.dispatch('worker/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('company/getOwned')
    this.activity = new WorkingActivity({ active: true })
  }
}
