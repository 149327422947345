
































import Company from '@/modules/company/domain/company'
import companySubmenu from '@/modules/app/components/sidebar/companySubmenu.vue'
import Component from 'vue-class-component'
import financeSubmenu from '@/modules/app/components/sidebar/financeSubmenu.vue'
import { State } from 'vuex-class'
import purchaseSubmenu from '@/modules/app/components/sidebar/purchaseSubmenu.vue'
import regularSaleSubmenu from '@/modules/app/components/sidebar/regularSaleSubmenu.vue'
import saleSubmenu from '@/modules/app/components/sidebar/saleSubmenu.vue'
import settingsSubmenu from './settingsSubmenu.vue'
import stockSubmenu from './stockSubmenu.vue'
import workRecordSubmenu from '@/modules/app/components/sidebar/workRecordSubmenu.vue'
import Vue from 'vue'

@Component({
  components: { companySubmenu, financeSubmenu, purchaseSubmenu, regularSaleSubmenu, saleSubmenu, settingsSubmenu, stockSubmenu, workRecordSubmenu }
})
export default class Sidebar extends Vue {
  @State('owned', { namespace: 'company' }) company? : Company

  mounted () {
    this.$store.dispatch('company/getOwned')
    // need to init main side bar manually - do not work automatically after login
    const element: any = $(this.$refs.sidebar)
    // eslint-disable-next-line no-unused-vars
    const treeview = element.Treeview('init')
  }
}
