












































import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { formModal, checkboxField, textField }
})
export default class PaymentTypeCreateModal extends mixins(createModal) {
  moduleName = 'paymentType'
}
