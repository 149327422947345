



































import _ from 'lodash'
import CodeList from '@/modules/common/domain/codeList'
import Component from 'vue-class-component'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import { localeCompare } from '@/utils'
import noRecords from '@/modules/common/components/noRecords.vue'
import Order from '@/modules/order/domain/order'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

class SumItem {
  paymentType: CodeList | null = null
  totalWithoutVat: number = 0
  totalWithVat: number = 0
  currency: string | null = null
}

@Component({
  components: { loading, noRecords }
})
export default class OrdersOverviewPaymentTypeTable extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Order[]

  sumItems: SumItem[] = []
  notDefinedPaymentType = new CodeList({ id: 0, label: i18n.message('common.not-defined') })

  @Watch('orders')
  onOrdersChanged () {
    this.calculateSumItems()
  }

  get currency () {
    return this.sumItems.length ? this.sumItems[0].currency : null
  }

  get sumWithoutVat () {
    return _.sumBy(this.sumItems, 'totalWithoutVat') || 0
  }

  get sumWithVat () {
    return _.sumBy(this.sumItems, 'totalWithVat') || 0
  }

  private calculateSumItems () {
    if (this.orders.length > 0) {
      this.sumItems = _(this.orders)
        .groupBy(order => order.subscriber!.saleSettings && order.subscriber!.saleSettings.paymentType ? order.subscriber!.saleSettings.paymentType.id : this.notDefinedPaymentType.id)
        .map((ordersPerSubscriber) => {
          const firstOrder = ordersPerSubscriber[0]
          const subscriber = firstOrder.subscriber!
          const paymentType = subscriber.saleSettings && subscriber.saleSettings.paymentType ? subscriber.saleSettings.paymentType : this.notDefinedPaymentType
          return {
            paymentType: paymentType,
            totalWithoutVat: _(ordersPerSubscriber).sumBy('totalWithoutVat'),
            totalWithVat: _(ordersPerSubscriber).sumBy('totalWithVat'),
            currency: firstOrder.currency
          } as SumItem
        })
        .value()
        .sort(localeCompare(item => item.paymentType.label))
    } else {
      this.sumItems = []
    }
  }

  created () {
    this.calculateSumItems()
  }
}
