import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import CashBox from '@/modules/sale/domain/cashbox'
import User from '@/modules/user/domain/user'
import _ from 'lodash'
import AuditableEntity from '@/modules/common/domain/auditableEntity'

class Takings extends Entity implements AuditableEntity {
  date?: moment.Moment | null
  currency?: string
  cashBox: CashBox | null
  amountCash?: number
  amountCheque?: number
  amountCard?: number
  totalVatAmount?: number | null = null
  totalCashAmount?: number
  totalChequeAmount?: number
  transferred?: boolean
  createdBy?: User | null
  updatedBy?: User | null

  constructor (data: any) {
    super(data)

    this.date = this.convertDate(data.date)

    this.totalVatAmount = data.totalVatAmount

    this.cashBox = data.cashBox == null ? null : new CashBox(data.cashBox)
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)
  }

  get amountTotalWithoutCard () {
    return (this.totalCashAmount || 0) + (this.totalChequeAmount || 0)
  }

  get amountCashNonFiscal () {
    return (this.totalCashAmount || 0) - (this.amountCash || 0)
  }

  get amountChequeNonFiscal () {
    return (this.totalChequeAmount || 0) - (this.amountCheque || 0)
  }

  get amountTotalFiscal () {
    return (this.amountCash || 0) + (this.amountCheque || 0) + (this.amountCard || 0)
  }

  get amountTotalFiscalWithoutCard () {
    return (this.amountCash || 0) + (this.amountCheque || 0)
  }

  get amountTotalNonFiscal () {
    return (this.totalCashAmount || 0) + (this.totalChequeAmount || 0) - this.amountTotalFiscalWithoutCard
  }

  get amountTotal () {
    return this.amountTotalFiscal + this.amountTotalNonFiscal
  }

  get amountsNotDefined () {
    return _.isNil(this.amountCash) && _.isNil(this.amountCheque) && _.isNil(this.amountCard)
  }

  calculateVat (vatRate: number) {
    this.totalVatAmount = this.amountTotalFiscal && vatRate ? _.round((this.amountTotalFiscal * (vatRate / (100 + vatRate))), 2) : 0
  }
}

export default Takings
