
































import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import Invoice from '@/modules/invoice/domain/invoice'
import Vue from 'vue'
import { State } from 'vuex-class'

@Component({
  components: { box }
})
export default class InvoiceItemsInfoBox extends Vue {
  show = true

  @State('selected', { namespace: 'invoice' }) invoice!: Invoice
}
