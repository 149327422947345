






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import CashMoneyBox from '@/modules/money/box/domain/cashMoneyBox'

@Component
export default class MoneyBoxBalanceBox extends Vue {
  @Prop({ type: MoneyBox, required: true }) moneyBox!: MoneyBox

  get cashType () {
    return this.moneyBox instanceof CashMoneyBox
  }
}
