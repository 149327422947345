































import Component from 'vue-class-component'
import currencies from '@/modules/common/values/currencies'
import Invoice from '@/modules/invoice/domain/invoice'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class InvoiceVatSummaryPrint extends Vue {
  currencies = currencies

  @Prop({ type: Invoice, required: true }) invoice!: Invoice
}
