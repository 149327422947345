











































































import _ from 'lodash'
import barChart from '@/modules/common/components/chart/barChart.vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { SumItems } from '@/modules/sale/components/takingsOverviewSummaryBox.vue'
import Vue from 'vue'

@Component({
  components: { barChart }
})
export default class TakingsOverviewSummaryTable extends Vue {
  @Prop({ type: Object, required: true }) sumItems!: SumItems
  @Prop({ type: Array, required: true }) dates!: string[]
  @Prop({ type: String, required: true }) currency!: string

  itemByDate (date: string) {
    return this.sumItems[date]
  }

  sumByProperty (property: string) {
    return _(this.sumItems).values().map(property).sum() || 0
  }

  isEmpty (someData: any) {
    return _.isEmpty(someData)
  }
}
