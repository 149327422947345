
























































































import BankAccount from '@/modules/bank/account/domain/bankAccount'
import Component, { mixins } from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import Document from '@/modules/document/domain/document'
import DocumentField from '@/modules/common/components/form/documentField.vue'
import DocumentUpload from '@/modules/document/components/documentUpload.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import NumberField from '@/modules/common/components/form/numberField.vue'
import paymentMethods from '@/modules/common/values/paymentMethods'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { IMAGE_FILE_MAX_SIZE, IMAGE_FILE_SUPPORTED_EXTENSIONS } from '@/config'
import { vatCalculationTypes } from '@/modules/configuration/type/VatCalculationType'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { DocumentUpload, DocumentField, NumberField, selectField, textField, checkboxField, formModal }
})
export default class InvoiceConfigCreateModal extends mixins<createModal<Configuration>>(createModal) {
  moduleName = 'configuration'
  paymentMethods = paymentMethods
  imageFileTypes = IMAGE_FILE_SUPPORTED_EXTENSIONS
  imageMaxSize = IMAGE_FILE_MAX_SIZE
  vatCalculationTypes = vatCalculationTypes

  @Getter('active', { namespace: 'bankAccount' }) bankAccounts?: Array<BankAccount>
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>

  @Watch('item.invoice.defaultBankAccount', { deep: true, immediate: false })
  onBankAccountChange (bankAccount: BankAccount) {
    if (this.modelInitialized && bankAccount) {
      this.item!.invoice.defaultCurrency = bankAccount.currency
    }
  }

  logoDocumentUploaded (document: Document) {
    this.item!.invoice.logoDocument = document
  }

  stampDocumentUploaded (document: Document) {
    this.item!.invoice.stampDocument = document
  }

  removeLogoDocument () {
    this.item!.invoice.logoDocument = null
  }

  removeStampDocument () {
    this.item!.invoice.stampDocument = null
  }

  created () {
    this.$store.dispatch('currency/getAll')
    this.$store.dispatch('bankAccount/getAll', new EntityFetchParams(true, { ownershipped: true }))
  }
}
