


















































import bankAccountsTable from '@/modules/bank/account/components/bankAccountsTable.vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import companyOrderItemTemplateTable from '@/modules/company/components/companyOrderItemTemplateTable.vue'
import companyPriceListTable from '@/modules/company/components/companyPriceListTable.vue'
import companySaleSettingsBox from '@/modules/company/components/companySaleSettingsBox.vue'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import page from '@/modules/common/components/page.vue'
import partnerInfoBox from './partnerInfoBox.vue'
import partnerAddressesBox from './partnerAdressesBox.vue'
import partnerContactsBox from './partnerContactsBox.vue'
import partnerMoneyMovementPanel from '@/modules/company/components/movement/partnerMoneyMovementPanel.vue'
import partnerRegularSalePanel from '@/modules/company/components/regularsale/partnerRegularSalePanel.vue'
import { Prop } from 'vue-property-decorator'
import subjectRecordsTimeline from '@/modules/record/components/subjectRecordsTimeline.vue'
import Vue from 'vue'
import partnerInvoicesPanel from '@/modules/company/components/invoice/partnerInvoicesPanel.vue'

@Component({
  components: {
    companyOrderItemTemplateTable,
    bankAccountsTable,
    companyPriceListTable,
    companySaleSettingsBox,
    page,
    partnerAddressesBox,
    partnerContactsBox,
    partnerInfoBox,
    partnerInvoicesPanel,
    partnerMoneyMovementPanel,
    partnerRegularSalePanel,
    subjectRecordsTimeline
  }
})
export default class PartnerDetailPage extends Vue {
  @Prop({ type: String, required: true }) partnerId!: string
  @State('selected', { namespace: 'company' }) partner!: Company
  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean

  // need to cast to BigInt
  get partnerIdNumber () {
    return parseInt(this.partnerId)
  }

  get breadCrumbData () {
    return this.partner ? new BreadCrumbData([{ textKey: 'contacts.label' }, {
      textKey: 'partners.label',
      routeName: 'partners'
    }, { text: this.partner.name }], 'partners') : null
  }

  async created () {
    await this.$store.dispatch('company/select', this.partnerId)
    await this.$store.dispatch('contact/getAll', new EntityFetchParams(true, { 'subject-id': this.partnerId }))
  }
}
