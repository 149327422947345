export enum PermissionType {
  COMPANY_PARTNER = 'COMPANY_PARTNER',
  FINANCE_OVERVIEW = 'FINANCE_OVERVIEW',
  FINANCE_MONEY_BOX = 'FINANCE_MONEY_BOX',
  FINANCE_MOVEMENT_IMPORT = 'FINANCE_MOVEMENT_IMPORT',
  INVOICE = 'INVOICE',
  REGULAR_SALE = 'REGULAR_SALE',
  SETTING_COMPANY = 'SETTING_COMPANY',
  SETTING_FINANCE = 'SETTING_FINANCE',
  SETTING_INVOICE = 'SETTING_INVOICE',
  SETTING_ITEM = 'SETTING_ITEM',
  SETTING_SALE = 'SETTING_SALE',
  SETTING_REGULAR_SALE = 'SETTING_REGULAR_SALE',
  SETTING_WORKER = 'SETTING_WORKER',
  SETTING_USER = 'SETTING_USER',
  SETTING_STOCK = 'SETTING_STOCK',
  STOCK = 'STOCK',
  SYSTEM_CHECK = 'SYSTEM_CHECK',
  TAKINGS = 'TAKINGS',
  TAKINGS_OVERVIEW = 'TAKINGS_OVERVIEW',
  TAKINGS_TRANSFER = 'TAKINGS_TRANSFER',
  WORK_RECORD_PERSONAL = 'WORK_RECORD_PERSONAL',
  WORK_RECORD_MANAGEMENT = 'WORK_RECORD_MANAGEMENT'
}
