












































































































































import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import moneyMovementTypes from '@/modules/money/movement/type/moneyMovementTypes'
import numberField from '@/modules/common/components/form/numberField.vue'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import moneyMovementCreateModalMixin from '@/modules/money/movement/components/moneyMovementCreateModalMixin.vue'
import { Watch } from 'vue-property-decorator'
import Invoice from '@/modules/invoice/domain/invoice'

@Component({
  components: { dateTimeField, checkboxField, formModal, numberField, selectField, staticField, radioField, textField }
})
export default class BankMovementCreateModal extends mixins(moneyMovementCreateModalMixin) {
  moduleName = 'bankMoneyMovement'
  moneyMovementTypes = moneyMovementTypes

  @Watch('item.invoice')
  onAmountChange (invoice: Invoice) {
    if (invoice && this.modelInitialized) {
      this.item!.totalVat = invoice.totalVat
      this.item!.taxableDate = invoice.taxableDate
    }
  }
}
