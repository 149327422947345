








































































































import _ from 'lodash'
import formModal from '@/modules/common/components/formModal.vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import notificationService from '@/modules/common/services/notificationService'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import TakingsTransfer from '@/modules/sale/domain/TakingsTransfer'
import Takings from '@/modules/sale/domain/takings'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'

@Component({
  components: { dateTimeField, checkboxField, formModal, numberField, selectField, staticField }
})
export default class TakingsTransferModal extends mixins(submitProtectionMixin) {
  transfer = new TakingsTransfer()
  show: boolean = false

  @Prop({ type: CompanyBranch, required: true }) companyBranch!: CompanyBranch
  @Prop({ type: Boolean, default: false }) showModal!: boolean
  @Prop(Object) model!: Takings

  @Getter('active', { namespace: 'moneyBox' }) moneyBoxesAll!: Array<MoneyBox>

  get moneyBoxes (): Array<MoneyBox> {
    return _.filter(this.moneyBoxesAll, moneyBox => (moneyBox['@type'] === 'cash') && (moneyBox.currency === this.transfer.takings!.currency))
  }

  get currency () {
    return this.transfer.takings ? this.transfer.takings.currency : null
  }

  get categories () {
    return _.filter(this.$store.getters['moneyMovementCategory/validItems'], { type: MoneyMovementType.INCOME })
  }

  onSubmit () {
    this.$store.dispatch('takings/transfer', this.transfer).then((result) => {
      if (result) {
        notificationService.success('takings.transfer.success', [this.transfer.moneyBox!.name])
        this.$emit('close')
      }
    }).finally(this.unprotect)
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  @Watch('model')
  onModelChange (model: Takings) {
    this.transfer.takings = _.cloneDeep(model)
  }

  async created () {
    await this.$store.dispatch('moneyBox/getAll')
    await this.$store.dispatch('moneyMovementCategory/getAll')
  }
}
