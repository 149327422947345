
















































import booleanIcon from '../../common/components/icon/booleanIcon.vue'
import CodeListTable from '../../common/components/codeListTable.vue'
import Component from 'vue-class-component'
import PaymentType from '@/modules/paymenttype/domain/paymentType'
import paymentTypeCreateModal from './paymentTypeCreateModal.vue'
import Vue from 'vue'
@Component({
  components: { booleanIcon, paymentTypeCreateModal, CodeListTable }
})
export default class PaymentTypesTable extends Vue {
  newPaymentType () {
    return () => new PaymentType({})
  }
}
