
















































import Component from 'vue-class-component'
import { State } from 'vuex-class'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import Vue from 'vue'
import Invoice from '@/modules/invoice/domain/invoice'
import noRecords from '@/modules/common/components/noRecords.vue'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'

@Component({
  components: { noRecords }
})
export default class OrderStatementInvoicesBox extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement

  invoiceDetail (invoice: Invoice) {
    this.$router.push({ name: invoice.type === InvoiceType.INCOME ? 'invoiceDetailIncome' : 'invoiceDetailOutcome', params: { id: invoice.id + '' } })
  }

  createInvoice () {
    this.$router.push({ name: 'invoiceForOrderStatement', params: { orderStatementId: this.statement.id! + '' } })
  }
}
