






























































import Vue from 'vue'
import Component from 'vue-class-component'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop } from 'vue-property-decorator'
import Address from '@/modules/common/domain/address'
import countries from '@/modules/common/values/countries'
import { State } from 'vuex-class'
import CodeList from '@/modules/common/domain/codeList'

@Component({
  components: { selectField, textField }
})
export default class AddressForm extends Vue {
  countries = countries
  @Prop({ type: Address, required: true }) address!: Address
  @Prop({ type: Boolean, default: true }) showRegion!: boolean
  @State('items', { namespace: 'region' }) regions!: Array<CodeList>

  created () {
    this.$store.dispatch('region/getAll')
  }
}
