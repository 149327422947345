import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import Stock from '@/modules/stock/domain/stock'

export default class ItemBatch extends Entity {
  item?: Item | null
  stock?: Stock | null
  name?: string | null

  constructor (data?: any) {
    // id prop need to be set to 'name', because this value is set to final associations
    super(data, 'name', 'name')

    this.item = data.item ? new Item(data.item) : null
    this.stock = data.stock ? new Stock(data.stock) : null
  }
}
