import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import SubjectRecordResource from '@/modules/record/services/subjectRecordResource'
import SubjectRecord from '@/modules/record/domain/subjectRecord'

const entityService = new EntityService<SubjectRecord>(SubjectRecordResource, SubjectRecord)
const recordModule: Module<EntityState<SubjectRecord>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'subject-record')
}

export default recordModule
