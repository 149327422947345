import { ActionTree } from 'vuex'
import BankMoneyMovement from '@/modules/money/movement/domain/bankMoneyMovement'
import bankMoneyMovementService from '@/modules/money/movement/services/bankMoneyMovementService'
import EntityState from '@/modules/common/store/entityState'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import RootState from '@/store/rootState'
import MoneyMovementSplit from '@/modules/money/movement/domain/moneyMovementSplit'

const actions: ActionTree<EntityState<BankMoneyMovement>, RootState> = {
  async update ({ dispatch, commit }, entityCreateParams: EntityCreateParams<BankMoneyMovement>) {
    const updatedEntity = await bankMoneyMovementService.update(entityCreateParams.entity as BankMoneyMovement)
    if (updatedEntity) {
      if (entityCreateParams.doFetchAfter) {
        await dispatch('moneyMovement/refresh', null, { root: true })
        await dispatch('invoice/refresh', null, { root: true })
      }
      return updatedEntity
    } else {
      return false
    }
  },
  async split ({ state, dispatch, commit }, moneyMovementSplit: MoneyMovementSplit) {
    const updatedMovement = await bankMoneyMovementService.split(moneyMovementSplit)
    if (updatedMovement) {
      await dispatch('moneyMovement/refresh', null, { root: true })
      dispatch('invoice/refresh', null, { root: true })
      return true
    } else {
      return false
    }
  }
}

export default actions
