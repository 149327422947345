import financeOverviewPage from '@/modules/finance/components/financeOverviewPage.vue'

export default [
  {
    path: '/finance/overview/:currency',
    component: financeOverviewPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'FINANCE_OVERVIEW',
      pageGroup: 'finance'
    }
  }
]
