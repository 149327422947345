
















































































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import invoiceSeriesCreateModal from '@/modules/invoice/components/invoiceSeriesCreateModal.vue'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'

@Component({
  components: { invoiceSeriesCreateModal, loading, noRecords, box }
})
export default class InvoiceSeriesTable extends mixins(entityTableMixin) {
  entityModuleName = 'invoiceSeries'

  async created () {
    this.newItemTemplateDefault = () => new InvoiceSeries({})
  }
}
