import { VatCalculationType } from '@/modules/configuration/type/VatCalculationType'
import _ from 'lodash'
import BigNumber from 'bignumber.js'

function calculateItemTotalWithVat (price: number, vatRateNum: BigNumber, amount: number) {
  return new BigNumber(price)
    .multipliedBy(vatRateNum)
    .dp(2, BigNumber.ROUND_HALF_UP)
    .multipliedBy(amount)
    .dp(2, BigNumber.ROUND_HALF_UP)
}

function calcVatRateNum (vatRate: number) {
  return new BigNumber(vatRate).div(100).plus(1)
}

export const calculateVat = (vatCalculationType: VatCalculationType, price?: number| null, amount?: number | null, vatRate?: number | null) => {
  if (_.isNil(price) || _.isNil(amount) || _.isNil(vatRate)) {
    return null
  }
  if (vatCalculationType === VatCalculationType.SUM) {
    return new BigNumber(amount)
      .multipliedBy(price)
      .multipliedBy(vatRate)
      .div(100)
      .dp(2, BigNumber.ROUND_HALF_UP)
      .toNumber()
  } else {
    const vatRateNum = calcVatRateNum(vatRate)
    const totalWithVat = calculateItemTotalWithVat(price, vatRateNum, amount)
    const totalWithoutVat = new BigNumber(totalWithVat).div(vatRateNum).dp(2, BigNumber.ROUND_HALF_UP)
    return totalWithVat.minus(totalWithoutVat).toNumber()
  }
}

export const calculateTotalWithoutVat = (vatCalculationType: VatCalculationType, price?: number| null, amount?: number | null, vatRate?: number | null) => {
  if (_.isNil(price) || _.isNil(amount) || _.isNil(vatRate)) {
    return null
  }
  if (vatCalculationType === VatCalculationType.SUM) {
    return new BigNumber(amount)
      .multipliedBy(price)
      .dp(2, BigNumber.ROUND_HALF_UP)
      .toNumber()
  } else {
    const vatRateNum = calcVatRateNum(vatRate)
    const totalWithVat = calculateItemTotalWithVat(price, vatRateNum, amount)
    return totalWithVat.div(vatRateNum).dp(2, BigNumber.ROUND_HALF_UP).toNumber()
  }
}

export const calculateTotalWithVat = (vatCalculationType: VatCalculationType, price?: number| null, amount?: number | null, vatRate?: number | null) => {
  if (_.isNil(price) || _.isNil(amount) || _.isNil(vatRate)) {
    return null
  }
  const vatRateNum = calcVatRateNum(vatRate)
  if (vatCalculationType === VatCalculationType.SUM) {
    return new BigNumber(amount)
      .multipliedBy(price)
      .multipliedBy(vatRateNum)
      .dp(2, BigNumber.ROUND_HALF_UP)
      .toNumber()
  } else {
    return calculateItemTotalWithVat(price, vatRateNum, amount).toNumber()
  }
}
