import entityActions from '@/modules/common/store/entityActions'
import moneyBoxService from '@/modules/money/box/services/moneyBoxService'
import { MONEY_BOX_BALANCE_LIST } from '@/modules/money/box/store/moneyBoxMutationTypes'

const actions = entityActions(moneyBoxService, 'money-box')

actions.balances = async function ({ state, dispatch, commit }, filter) {
  const balances = await moneyBoxService.balances(filter)
  await commit(MONEY_BOX_BALANCE_LIST, {
    items: balances
  })
}

export default actions
