
























import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RecordType } from '@/modules/record/type/RecordType'
import recordTypes from '@/modules/record/type/recordTypes'
import SubjectRecord from '@/modules/record/domain/subjectRecord'
import subjectRecordCreateModal from './subjectRecordCreateModal.vue'
import subjectRecordMixin from '@/modules/record/components/subjectRecordMixin'

@Component({
  components: { subjectRecordCreateModal }
})
export default class SubjectRecordWidget extends mixins(subjectRecordMixin) {
  recordTypes = recordTypes
  selectedItem?: SubjectRecord | null = null
  showCreateModal: boolean = false
  @Prop({ type: SubjectRecord, required: true }) record!: SubjectRecord

  editRecord () {
    this.selectedItem = _.cloneDeep(this.record)
    this.showCreateModal = true
  }

  editHide () {
    this.showCreateModal = false
  }

  recordClass (recordType: RecordType) {
    return `${this.optionClass(recordType)} ${this.optionIcon(recordType)}`
  }
}
