import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'
import Stock from '@/modules/stock/domain/stock'
import stockService from '@/modules/stock/services/stockService'

const stockModule: Module<EntityState<Stock>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(stockService, 'stock')
}

export default stockModule
