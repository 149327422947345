











































import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import textField from '@/modules/common/components/form/textField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import createModal from '@/modules/common/components/createModal'
import { Prop } from 'vue-property-decorator'
import VSwatches from 'vue-swatches'

@Component({
  components: { textField, checkboxField, formModal, VSwatches }
})
export default class CodeListCreateModalGeneric extends mixins(createModal) {
  @Prop(String) moduleName!: string
  @Prop({ type: Boolean, default: false }) colorable?: boolean
  @Prop({ type: Number, default: 2 }) labelMinLength!: boolean
}
