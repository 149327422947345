











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class InfoBox extends Vue {
  @Prop(String) title?: string
  @Prop(String) value?: string
  @Prop(String) iconClass?: string
  @Prop(String) titleClass?: string
  @Prop({ type: String, default: 'bg-teal' }) bgClass?: string
  @Prop(String) valueClass?: string
  @Prop({ type: Boolean, default: false }) fill?: boolean
  @Prop({ type: Boolean, default: false }) medium?: boolean
}
