import { Module } from 'vuex'
import actions from '@/modules/money/box/store/moneyBoxActions'
import mutations from '@/modules/money/box/store/moneyBoxMutations'
import getters from '@/modules/money/box/store/moneyBoxGetters'
import RootState from '@/store/rootState'
import MoneyBoxState from '@/modules/money/box/store/MoneyBoxState'

const moneyBoxModule: Module<MoneyBoxState, RootState> = {
  namespaced: true,
  state: new MoneyBoxState(),
  mutations,
  getters,
  actions
}

export default moneyBoxModule
