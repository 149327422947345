



























import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { box, loading, noRecords }
})
export default class InvoicesInfoBox extends mixins(entityTableMixin) {
  entityModuleName = 'invoice'
  activeSupport = false

  @Prop(Number) partnerId!: number
  @Prop({ type: Number, default: 5 }) limit!: number
  @Prop({ type: Number }) excludeId!: number

  async beforeFetch () {
    await this.$store.dispatch('invoice/clearAll')
  }

  async created () {
    const parameters: any = { 'subscriber-id': this.partnerId, limit: this.limit, type: InvoiceType.OUTCOME }
    if (this.excludeId) {
      parameters.excludeId = this.excludeId
    }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
  }
}
