






















import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import deliveriesTable from '@/modules/order/components/deliveriesTable.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import ordersFilterBox from '@/modules/order/components/ordersFilterBox.vue'
import ordersOverviewItemTable from '@/modules/order/components/ordersOverviewItemTable.vue'
import ordersOverviewPaymentTypeTable from '@/modules/order/components/ordersOverviewPaymentTypeTable.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import Order from '@/modules/order/domain/order'
import { Getter, State } from 'vuex-class'
import Loading from '@/modules/common/components/loading.vue'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'
import Company from '@/modules/company/domain/company'

@Component({
  components: { Loading, ordersFilterBox, ordersOverviewItemTable, ordersOverviewPaymentTypeTable, deliveriesTable, page }
})
export default class OrdersDeliveryPage extends Vue {
  pageDataId = 'deliveries'
  orders: Order[] | null = []
  breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, { textKey: 'orders.delivered.label' }])
  filter: OrderFilter | null = null
  showSummary = false

  @State('items', { namespace: 'order' }) allOrders!: Order[]
  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  async onFilterChanged (filter: OrderFilter) {
    const fetchData = this.filter === null || this.filter.query === filter.query // do not fetch if only query has been changed
    this.filter = filter
    if (fetchData) {
      await this.fetch()
    }
  }

  async fetch () {
    if (this.requiredFilterFilled) {
      this.orders = []
      await this.$store.dispatch('order/getAll', new EntityFetchParams(true, this.createFilter()))
      await this.$store.dispatch('order/enrichAll', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
      this.orders = this.allOrders
    } else {
      await this.$store.dispatch('order/clearAll')
    }
  }

  createFilter () {
    const filter = _.pickBy({
      'supplier-branch-id': this.filter!.companyBranchId,
      date: FORMAT_SYSTEM_DATE(this.filter!.date),
      currency: this.showSummary ? this.filter!.currency : null
    }, _.identity)
    // do not apply region filter for summary
    if (!this.showSummary && this.filter!.regionId) {
      filter['region-id'] = this.filter!.regionId
    }
    return filter
  }

  async activateOrders () {
    this.showSummary = false
    await this.fetch()
  }

  async activateSummary () {
    this.showSummary = true
    await this.fetch()
  }

  get requiredFilterFilled () {
    return this.filter && this.filter.date && this.filter.companyBranchId
  }

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getAllPartners')
  }

  destroyed () {
    this.$store.dispatch('order/clearAll')
  }
}

