











































import authService from '@/modules/auth/services/authService'
import Component, { mixins } from 'vue-class-component'
import { Getter, State } from 'vuex-class'
import textField from '@/modules/common/components/form/textField.vue'
import User from '@/modules/user/domain/user'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { vForm, textField }
})
export default class Login extends mixins(validationMixin) {
  credentials = { username: '', password: '' }

  @Getter('loggedIn', { namespace: 'auth' }) loggedIn!: boolean
  @State('user', { namespace: 'auth' }) user!: User

  onSubmit () {
    return this.$store.dispatch('auth/logIn', this.credentials).then((loggedIn: boolean) => {
      if (loggedIn) {
        this.$router.push(authService.mainPath(this.$router.currentRoute, this.user))
      }
    })
  }
}
