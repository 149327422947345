
























































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import userCreateModal from '@/modules/user/components/userCreateModal.vue'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { loading, noRecords, userCreateModal }
})
export default class ItemBatchesTable extends mixins<entityTableMixin<ItemBatch>>(entityTableMixin) {
  entityModuleName = 'itemBatch'

  @Prop({ type: Number, required: true }) itemId!: number
  @Prop({ type: Number }) stockId?: number

  stockMovements (itemBatch: ItemBatch) {
    this.$router.push({
      name: 'stockMovements',
      query: {
        fn_stockId: itemBatch.stock!.id!.toString(),
        fn_itemId: itemBatch.item!.id!.toString(),
        fb_itemEnabled: 'true',
        fs_itemBatch: itemBatch.name
      }
    })
  }

  get showStock () {
    return _.isNil(this.stockId)
  }

  async created () {
    const parameters = { 'item-id': this.itemId } as any
    if (this.stockId) {
      parameters['stock-id'] = this.stockId
    }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
  }
}
