import EntityService from '@/modules/common/services/entityService'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import incomeStockMovementResource from '@/modules/stock/movement/services/incomeStockMovementResource'
import moment from 'moment'
import Stock from '@/modules/stock/domain/stock'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'

class IncomeStockMovementService extends EntityService<IncomeStockMovement> {
  constructor () {
    super(incomeStockMovementResource, IncomeStockMovement)
  }

  newIncomeStockMovement (stock: Stock) {
    return new IncomeStockMovement({
      stock,
      date: moment(),
      items: [new StockMovementItem({})]
    })
  }
}

export default new IncomeStockMovementService()
