import _ from 'lodash'
import i18n from '@/i18n'
import { OutcomeStockMovementType } from '@/modules/stock/movement/type/outcomeStockMovementType'
import outcomeStockMovementTypes from '@/modules/stock/movement/type/outcomeStockMovementTypes'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'

class OutcomeStockMovement extends StockMovement {
  type?: OutcomeStockMovementType | null = null
  incomeMovement?: IncomeStockMovement | null

  constructor (data: any) {
    super(data)

    this.incomeMovement = data.incomeMovement == null ? null : new IncomeStockMovement(data.incomeMovement)
    this.type = data.type

    if (!this['@type']) {
      this['@type'] = 'outcome'
    }

    this.extendSearchString()
  }

  get formattedNumber () {
    return this.number ? 'SV-' + this.number : null
  }

  get unpaired () {
    return _.isNil(this.incomeMovement)
  }

  private extendSearchString () {
    this.searchString += ' ' + this.formattedNumber

    if (this.type) {
      this.searchString += i18n.message(_.find(outcomeStockMovementTypes, { value: this.type })!.label)
    }

    // concat string without whitespaces
    this.searchString += this.searchString!.replace(/\s/g, '')
  }
}

export default OutcomeStockMovement
