



















































































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import moneyBoxCreateModal from '@/modules/money/box/components/moneyBoxCreateModal.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import CashMoneyBox from '@/modules/money/box/domain/cashMoneyBox'

@Component({
  components: { moneyBoxCreateModal, loading, noRecords, box }
})
export default class MoneyBoxesTable extends mixins(entityTableMixin) {
  entityModuleName = 'moneyBox'

  created () {
    this.newItemTemplateDefault = () => new CashMoneyBox({})
  }
}
