



















































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import DateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { compareFunction, FORMAT_SYSTEM_DATE } from '@/utils'
import Range from '@/modules/common/components/form/range'
import SelectField from '@/modules/common/components/form/selectField.vue'
import SortData from '@/modules/common/mixins/sortData'
import Stock from '@/modules/stock/domain/stock'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import FilterData from '@/modules/common/mixins/filterData'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'

@Component({
  components: { DateRangeField, SelectField, loading, noRecords, box }
})
export default class StockMovementsDeltaTable extends Vue {
  entityModuleName = 'stockMovement'
  fetched = false
  activeSupport = false
  sortData: SortData = new SortData('item.name', true)
  filter = new StockMovementFilter()

  @Prop({ type: Object, default: new FilterData() }) defaultFilter!: FilterData
  @Prop({ type: String, default: 'stockItemsDelta' }) pageDataId!: string
  @Getter('active', { namespace: 'stock' }) allStocks!: Stock[]
  @Getter('all', { namespace: 'stockMovement' }) stockMovements!: StockMovement[]

  @Watch('filter', { deep: true })
  onFilterChange () {
    this.$emit('filter-changed', this.filter)
  }

  get items () {
    const items: any = {}
    _.forEach(this.stockMovements, stockMovement => {
      _.forEach(stockMovement.items, stockMovementItem => {
        if (!items[stockMovementItem.item!.id! + '']) {
          items[stockMovementItem.item!.id! + ''] = { item: stockMovementItem.item, stock: stockMovement.stock }
        }
        const itemSum = items[stockMovementItem.item!.id! + '']
        if (stockMovement.income) {
          itemSum.amountSumIncome = _.round((itemSum.amountSumIncome || 0) + stockMovementItem.amount, stockMovementItem.item!.scale)
          itemSum.totalPriceSumIncome = _.round((itemSum.totalPriceSumIncome || 0) + stockMovementItem.totalPrice, stockMovementItem.item!.scale)
        } else {
          itemSum.amountSumOutcome = _.round((itemSum.amountSumOutcome || 0) + stockMovementItem.amount, stockMovementItem.item!.scale)
          itemSum.totalPriceSumOutcome = _.round((itemSum.totalPriceSumOutcome || 0) + stockMovementItem.totalPrice, stockMovementItem.item!.scale)
        }
        itemSum.amountSumTotal = _.round((itemSum.amountSumIncome || 0) - (itemSum.amountSumOutcome || 0), stockMovementItem.item!.scale)
        itemSum.totalPriceSumTotal = _.round((itemSum.totalPriceSumIncome || 0) - (itemSum.totalPriceSumOutcome || 0), stockMovementItem.item!.scale)
      })
    })

    return _.values(items).sort(compareFunction('item.name')).sort(compareFunction('item.category.label'))
  }

  createFilter () {
    return _.pickBy({
      'stock-id': this.filter!.stockId,
      'date-from': this.filter.range && this.filter.range.from ? FORMAT_SYSTEM_DATE(this.filter.range!.from) : null,
      'date-to': this.filter.range && this.filter.range.to ? FORMAT_SYSTEM_DATE(this.filter.range!.to) : null
    }, _.identity)
  }

  async fetch () {
    await this.$store.dispatch('stockMovement/clearAll')
    this.fetched = false
    const filter = this.createFilter()
    await this.$store.dispatch('stockMovement/getAll', new EntityFetchParams(true, filter))
    this.fetched = true
  }

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('stock/getAll')
    this.filter.stockId = this.defaultFilter.stockId ? this.defaultFilter.stockId : (this.allStocks.length > 0 ? this.allStocks[0].id! : null)
    this.filter.range = this.defaultFilter.range ? this.defaultFilter.range : (new Range(moment().startOf('day').subtract(30, 'day')))
    await this.fetch()
  }
}
