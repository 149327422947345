



































import _ from 'lodash'
import Component from 'vue-class-component'
import Item from '@/modules/item/domain/Item'
import noRecords from '@/modules/common/components/noRecords.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { State } from 'vuex-class'
import Vue from 'vue'

class SummaryItem {
  itemId: number
  item: Item
  price: number
  delivered: number

  constructor (itemId: number, item: Item, price: number, delivered: number) {
    this.itemId = itemId
    this.item = item
    this.price = price
    this.delivered = delivered
  }

  get totalPrice () {
    return _.round(this.price * this.delivered, 2)
  }
}

@Component({
  components: { noRecords }
})
export default class OrderStatementOrdersSummaryBox extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement

  get items () {
    const summaryItems: Array<SummaryItem> = []
    _(this.statement.orders).flatMap('items').forEach(orderItem => {
      const summaryItem = _.find(summaryItems, { itemId: orderItem.item.id, price: orderItem.itemPrice })
      if (summaryItem) {
        summaryItem.delivered += orderItem.delivered
      } else {
        summaryItems.push(new SummaryItem(orderItem.item.id, orderItem.item, orderItem.itemPrice, orderItem.delivered))
      }
    })

    return summaryItems
  }
}
