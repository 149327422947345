
































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import moment from 'moment'
import page from '@/modules/common/components/page.vue'
import { State } from 'vuex-class'
import timeSheetBox from '@/modules/work/record/components/timeSheetBox.vue'
import User from '@/modules/user/domain/user'
import Vue from 'vue'
import workRecordLastItemsBox from '@/modules/work/record/components/workRecordLastItemsBox.vue'
import workerInfoBox from '@/modules/worker/components/workerInfoBox.vue'
import Worker from '@/modules/worker/domain/worker'

@Component({
  components: { page, timeSheetBox, workerInfoBox, workRecordLastItemsBox }
})
export default class WorkRecordsPersonalPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'work-record.label' }, { textKey: 'work-record.personal.label.short' }])

  worker?: Worker | null = null
  initialized = false
  latestCurrentMonthFrom = moment().startOf('month')
  latestPrevMonthFrom = moment().subtract(1, 'month').startOf('month')
  latestPrevMonthTo = moment().startOf('month')
  @State('user', { namespace: 'auth' }) user!: User

  async mounted () {
    if (this.user.worker) {
      this.worker = await this.$store.dispatch('worker/select', this.user.worker.id)

      const latestDateFrom = FORMAT_SYSTEM_DATE(moment().subtract(2, 'month').startOf('month'))
      await this.$store.dispatch('workRecord/getLatest', new EntityFetchParams(true, { dateFrom: latestDateFrom, workerId: this.worker!.id }))
    }
    this.initialized = true
  }
}
