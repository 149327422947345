import _ from 'lodash'
import { AccountingType } from '@/modules/money/movement/type/accountingType'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import Range from '@/modules/common/components/form/range'

export class MoneyMovementFilter {
  range?: Range | null = null
  moneyBoxId?: number | null = null
  query?: string | null = null
  typesEnabled = false
  categoriesEnabled = false
  accountingTypesEnabled = false
  types?: MoneyMovementType[] | null = []
  categories?: number[] = []
  accountingTypes?: AccountingType[] | null = []

  get advancedEnabled () {
    return this.typesEnabled || this.categoriesEnabled || this.accountingTypesEnabled
  }

  get typeIncome (): boolean {
    return this.getType(MoneyMovementType.INCOME)
  }

  get typeOutcome (): boolean {
    return this.getType(MoneyMovementType.OUTCOME)
  }

  get accountingTypeFiscal (): boolean {
    return this.getAccountingType(AccountingType.FISCAL)
  }

  get accountingTypeNonFiscal (): boolean {
    return this.getAccountingType(AccountingType.NON_FISCAL)
  }

  get accountingTypeInternal (): boolean {
    return this.getAccountingType(AccountingType.INTERNAL)
  }

  set typeIncome (setFlag: boolean) {
    this.setType(setFlag, MoneyMovementType.INCOME)
  }

  set typeOutcome (setFlag: boolean) {
    this.setType(setFlag, MoneyMovementType.OUTCOME)
  }

  set accountingTypeFiscal (setFlag: boolean) {
    this.setAccountingType(setFlag, AccountingType.FISCAL)
  }

  set accountingTypeNonFiscal (setFlag: boolean) {
    this.setAccountingType(setFlag, AccountingType.NON_FISCAL)
  }

  set accountingTypeInternal (setFlag: boolean) {
    this.setAccountingType(setFlag, AccountingType.INTERNAL)
  }

  private getType (type: MoneyMovementType) {
    return !!this.types && this.types.indexOf(type) >= 0
  }

  private setType (setFlag: boolean, type: MoneyMovementType) {
    if (setFlag) {
      if (!this.types) {
        this.types = [type]
      } else {
        this.types.push(type)
      }
    } else if (!setFlag && this.types && (this.types.indexOf(type) >= 0)) {
      this.types = _.without(this.types, type)
    }
  }

  private getAccountingType (type: AccountingType) {
    return !!this.accountingTypes && this.accountingTypes.indexOf(type) >= 0
  }

  private setAccountingType (setFlag: boolean, type: AccountingType) {
    if (setFlag) {
      if (!this.accountingTypes) {
        this.accountingTypes = [type]
      } else {
        this.accountingTypes.push(type)
      }
    } else if (!setFlag && this.accountingTypes && (this.accountingTypes.indexOf(type) >= 0)) {
      this.accountingTypes = _.without(this.accountingTypes, type)
    }
  }
}
