import { Entity } from '@/modules/common/domain/entity'
import RecipeItem from '@/modules/inventory/domain/inventoryItem'
import Item from '@/modules/item/domain/Item'

class Recipe extends Entity {
  batchSize?: number
  item?: Item | null
  items: RecipeItem[]

  constructor (data: any) {
    super(data, 'id')
    this.item = data.item == null ? null : new Item(data.item)
    this.items = data.items ? data.items.map((item: any) => new RecipeItem(item)) : []
    this.label = data.item ? data.item.name : null
  }
}

export default Recipe
