






















































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Prop } from 'vue-property-decorator'
import RecipeItem from '@/modules/recipe/domain/recipeItem'
import Recipe from '@/modules/recipe/domain/recipe'
import RecipeItemCreateModal from '@/modules/recipe/components/recipeItemCreateModal.vue'

@Component({
  components: { RecipeItemCreateModal, loading, noRecords, box }
})
export default class RecipeItemTable extends mixins(entityTableMixin) {
  entityModuleName = 'recipeItem'
  activeSupport = false

  @Prop({ type: Recipe, required: true }) recipe!: Recipe

  async created () {
    const parameters: any = { 'recipe-id': this.recipe.id }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    this.newItemTemplateDefault = () => new RecipeItem({ recipe: this.recipe })
  }
}
