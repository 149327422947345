


















import Component from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter } from 'vuex-class'
import i18nService from '@/i18n'
import Loading from '@/modules/common/components/loading.vue'
import navbar from './navbar.vue'
import sidebar from './sidebar/sidebar.vue'
import { SMARTBRAINS_URL } from '@/config'
import Vue from 'vue'

@Component({
  components: { Loading, navbar, sidebar }
})
export default class App extends Vue {
  configurationFetchFailed = false // need to check case, when configuration fetch failed. Logout page need to be accessible
  smartbrainsUrl = SMARTBRAINS_URL

  @Getter('loggedIn', { namespace: 'auth' }) loggedIn!: boolean
  @Getter('first', { namespace: 'configuration' }) configuration?: Configuration

  async beforeCreate () {
    try {
      await this.$store.dispatch('configuration/getAll', new EntityFetchParams(true))
      i18nService.changeLocale(this.configuration!.application.localization!.toLowerCase())
    } catch (e) {
      this.configurationFetchFailed = true
    }
  }
}
