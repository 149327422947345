import { BooleanType } from '@/modules/common/values/booleanType'
import { ItemDeterminationType } from '@/modules/item/type/itemDeterminationType'
import { ItemOriginType } from '@/modules/item/type/itemOriginType'
import { ItemType } from '@/modules/item/type/itemType'

export class ItemFilter {
  typeEnabled = false
  type: ItemType | null = null
  categoriesEnabled = false
  categories: number[] = []
  determinationTypeEnabled = false
  determinationType: ItemDeterminationType | null = null
  originTypeEnabled = false
  originType: ItemOriginType | null = null
  batchEvidenceEnabled = false
  batchEvidence: BooleanType | null = null
  inactiveEnabled = false

  get advancedEnabled () {
    return this.typeEnabled || this.categoriesEnabled || this.determinationTypeEnabled || this.originTypeEnabled || this.batchEvidenceEnabled || this.inactiveEnabled
  }

  reset () {
    this.typeEnabled = false
    this.type = null
    this.categoriesEnabled = false
    this.categories = []
    this.determinationTypeEnabled = false
    this.determinationType = null
    this.originTypeEnabled = false
    this.originType = null
    this.batchEvidenceEnabled = false
    this.batchEvidence = null
    this.inactiveEnabled = false
  }
}
