










































































import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import Order from '@/modules/order/domain/order'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'
import Vue from 'vue'

@Component
export default class DeliveryNotesPrint extends Vue {
  @Prop({ type: Object, required: true }) order!: Order
  @State('owned', { namespace: 'company' }) company?: Company
  @State('user', { namespace: 'auth' }) user!: User

  async created () {
    await this.$store.dispatch('company/getOwned')
  }
}
