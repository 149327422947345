import invoicesIncomePage from '../components/invoicesIncomePage.vue'
import invoicesOutcomePage from '../components/invoicesOutcomePage.vue'
import invoiceOutcomeCreatePage from '../components/invoiceOutcomeCreatePage.vue'
import invoiceIncomeCreatePage from '../components/invoiceIncomeCreatePage.vue'
import invoiceDetailPage from '../components/invoiceDetailPage.vue'

export default [
  {
    path: '/invoices/INCOME',
    component: invoicesIncomePage,
    props: true,
    name: 'invoicesIncome',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'purchase'
    }
  },
  {
    path: '/invoices/OUTCOME',
    component: invoicesOutcomePage,
    props: true,
    name: 'invoicesOutcome',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'sale'
    }
  },
  {
    path: '/invoices/INCOME/new',
    name: 'invoiceIncomeCreate',
    component: invoiceIncomeCreatePage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'purchase'
    }
  },
  {
    path: '/invoices/OUTCOME/new',
    name: 'invoiceOutcomeCreate',
    component: invoiceOutcomeCreatePage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'sale'
    }
  },
  {
    path: '/invoices/INCOME/:id',
    name: 'invoiceDetailIncome',
    component: invoiceDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'purchase'
    }
  },
  {
    path: '/invoices/OUTCOME/:id',
    name: 'invoiceDetailOutcome',
    component: invoiceDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'sale'
    }
  },
  {
    path: '/invoice-new/OUTCOME/order-statement/:orderStatementId',
    name: 'invoiceForOrderStatement',
    component: invoiceOutcomeCreatePage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'sale'
    }
  },
  {
    path: '/invoice-edit/OUTCOME/:id',
    name: 'invoiceOutcomeEdit',
    component: invoiceOutcomeCreatePage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'sale'
    }
  },
  {
    path: '/invoice-edit/INCOME/:id',
    name: 'invoiceIncomeEdit',
    component: invoiceIncomeCreatePage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'INVOICE',
      pageGroup: 'purchase'
    }
  }
]
