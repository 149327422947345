import { createMutations } from '@/modules/common/store/entityMutations'
import { MutationTree } from 'vuex'
import { MONEY_BOX_BALANCE_LIST } from '@/modules/money/box/store/moneyBoxMutationTypes'
import MoneyBoxState from '@/modules/money/box/store/MoneyBoxState'

const mutations: MutationTree<MoneyBoxState> = createMutations()

mutations[MONEY_BOX_BALANCE_LIST] = (state, action) => {
  state.balances = action.items
}

export default mutations
