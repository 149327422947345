























































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import cashBoxCreateModal from '@/modules/sale/components/cashBoxCreateModal.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import numberFilter from '@/modules/common/filters/numberFilter'
import { Prop, Watch } from 'vue-property-decorator'
import { StockItemFilter } from '@/modules/stock/item/domain/stockItemFilter'
import StockItem from '@/modules/stock/item/domain/stockItem'
import { compareFunction } from '@/utils'

@Component({
  components: { cashBoxCreateModal, loading, noRecords }
})
export default class StockItemsTable extends mixins<entityTableMixin<StockItem>>(entityTableMixin) {
  entityModuleName = 'stockItem'
  number = numberFilter

  @Prop({ type: StockItemFilter, required: true }) filter!: StockItemFilter

  @Watch('filter.stockId')
  onFilterStockIdChange () {
    this.fetchActionParams.parameters = this.createFilter()
    this.fetch()
  }

  @Watch('filter.query')
  onFilterQueryChange (query: string) {
    this.query = query
  }

  detail (id: number) {
    this.$router.push({ name: 'stockItem', params: { itemId: id + '' } })
  }

  sortEntities (entities: StockItem[]) {
    return _(entities).sort(compareFunction('item.name')).sort(compareFunction('item.category.label')).value()
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }

  createFilter () {
    return _.pickBy({
      'stock-id': this.filter!.stockId
    }, _.identity)
  }
}
