
























































import _ from 'lodash'
import Component from 'vue-class-component'
import Item from '@/modules/item/domain/Item'
import itemCreateModal from '@/modules/item/components/itemCreateModal.vue'
import itemTypes from '@/modules/item/type/itemTypes'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { loading, itemCreateModal }
})
export default class ItemInfoBox extends Vue {
  showUpdateModal: boolean = false
  selectedItem?: Item | null = null
  itemTypes = itemTypes

  @Prop({ type: Item, required: true }) item!: Item
  @Prop({ type: Boolean, default: true }) showUpdateButton!: boolean

  updateShow () {
    this.showUpdateModal = true
    this.selectedItem = _.cloneDeep(this.item)
  }

  updateHide () {
    this.showUpdateModal = false
  }
}
