import _ from 'lodash'
import BankMoneyMovement from '../domain/bankMoneyMovement'
import bankMoneyMovementResource from '@/modules/money/movement/services/bankMoneyMovementResource'
import EntityService from '@/modules/common/services/entityService'
import MoneyMovementSplit from '@/modules/money/movement/domain/moneyMovementSplit'

class BankMoneyMovementService extends EntityService<BankMoneyMovement> {
  newMovement (originalMovement: BankMoneyMovement): BankMoneyMovement {
    return new BankMoneyMovement({
      type: originalMovement.type,
      moneyBox: originalMovement.moneyBox,
      date: originalMovement.date,
      totalAmount: 0,
      accountingType: originalMovement.accountingType,
      vatAmount: 0,
      '@type': originalMovement['@type']
    })
  }

  async split (split: MoneyMovementSplit) {
    const response = await bankMoneyMovementResource.split({}, _.pickBy(split))
    if (response.ok) {
      return new BankMoneyMovement(response.data)
    } else {
      return null
    }
  }
}

export default new BankMoneyMovementService(bankMoneyMovementResource, BankMoneyMovement)
