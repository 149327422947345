import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import Recipe from '@/modules/recipe/domain/recipe'

class InventoryItem extends Entity {
  item?: Item | null
  amount?: number | null
  difference?: number = 0
  recipe: Recipe | null
  itemBatches: string[] = []

  constructor (data: any) {
    super(data)
    this.item = data.item ? new Item(data.item) : null
    this.recipe = data.recipe ? new Recipe(data.recipe) : null
    this.itemBatches = data.itemBatches ? [...data.itemBatches] : []
  }
}

export default InventoryItem
