














































import Vue from 'vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import noRecords from '@/modules/common/components/noRecords.vue'
import Box from '@/modules/common/components/box.vue'

@Component({
  components: { Box, noRecords }
})
export default class OrderStatementItemsSummaryBoxPrint extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement
}
