import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import OrderStatementService from '../services/orderStatementService'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'

const orderModule: Module<EntityState<OrderStatement>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(OrderStatementService, 'order-statement')
}

export default orderModule
