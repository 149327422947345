











































































import CashBox from '@/modules/sale/domain/cashbox'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import radioField from '@/modules/common/components/form/radioField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { Getter } from 'vuex-class'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import notificationService from '@/modules/common/services/notificationService'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class CashBoxCreateModal extends mixins<createModal<CashBox>>(createModal) {
  moduleName = 'cashBox'

  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>

  validate () {
    if (!this.item!.acceptCash && !this.item!.acceptCheque && !this.item!.acceptCard) {
      notificationService.error('error.cash-box.accept.empty')
      return false
    }
    return true
  }

  async created () {
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }
}
