import Range from '@/modules/common/components/form/range'

export class StockMovementFilter {
  stockId: number | null = null
  range: Range | null = null
  query: string | null = null
  itemEnabled = false
  itemId: number | null = null
  itemBatch: string | null = null

  get advancedEnabled () {
    return this.itemEnabled
  }
}
