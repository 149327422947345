






























import Component from 'vue-class-component'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import companyBranchCreateModal from '@/modules/company/components/companyBranchCreateModal.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { companyBranchCreateModal }
})
export default class PartnerAddressesBox extends Vue {
  selectedItem: CompanyBranch | null = null
  showCreateModal: boolean = false

  @Prop({ type: String }) label?: string
  @Prop({ type: CompanyBranch, required: true }) companyBranch!: CompanyBranch

  updateShow () {
    this.selectedItem = this.companyBranch
    this.showCreateModal = true
  }

  updatedHide () {
    this.showCreateModal = false
    this.selectedItem = null // need to be here to again set fresh copy of model in createModal
  }
}
