import companySettingsPage from '../components/companySettingsPage.vue'
import financeSettingsPage from '../components/financeSettingsPage.vue'
import invoiceSettingsPage from '../components/invoiceSettingsPage.vue'
import itemSettingPage from '@/modules/setting/components/itemSettingPage.vue'
import stockSettingsPage from '../components/stockSettingsPage.vue'
import saleSettingsPage from '@/modules/setting/components/saleSettingsPage.vue'
import regularSaleSettingsPage from '@/modules/setting/components/regularSaleSettingsPage.vue'

export default [
  {
    path: '/settings/company',
    component: companySettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_COMPANY',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/finance',
    component: financeSettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_FINANCE',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/sale',
    component: saleSettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_SALE',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/regular-sale',
    component: regularSaleSettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_REGULAR_SALE',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/invoice',
    component: invoiceSettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_INVOICE',
      pageGroup: 'setting'
    }
  },
  {
    path: '/settings/stock',
    component: stockSettingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_STOCK',
      pageGroup: 'setting'
    }
  },
  {
    name: 'itemSettings',
    path: '/settings/item',
    component: itemSettingPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'SETTING_ITEM',
      pageGroup: 'setting'
    }
  }
]
