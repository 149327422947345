



















































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { State } from 'vuex-class'
import OrderStatementPrintModal from '@/modules/order/statement/components/print/orderStatementPrintModal.vue'
import { OrderStatementPrintFilter } from '@/modules/order/statement/domain/orderStatementPrintFilter'
import Vue from 'vue'

@Component({
  components: { OrderStatementPrintModal, box, loading }
})
export default class OrderStatementInfoBox extends Vue {
  showPrintModal = false
  printFilterModel: OrderStatementPrintFilter | null = null
  printFilter = new OrderStatementPrintFilter(true, false, false)

  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement

  doShowPrintModal () {
    this.printFilterModel = _.cloneDeep(this.printFilter)
    this.showPrintModal = true
  }

  hideModals () {
    this.showPrintModal = false
  }

  doPrint (filter: OrderStatementPrintFilter) {
    _.assign(this.printFilter, filter)
    this.hideModals()
    // print need to be postponed to apply rendering
    this.$emit('print', filter)
  }

  edit () {
    this.$router.push({ name: 'orderStatementEdit', params: { orderStatementId: this.statement.id + '' } })
  }
}
