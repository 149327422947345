import { Entity } from '@/modules/common/domain/entity'
import { Moment } from 'moment'
import { PermissionType } from '@/modules/user/type/permissionType'
import Worker from '@/modules/worker/domain/worker'

export default class User extends Entity {
  username?: string | null
  password?: string | null
  firstname?: string | null
  surname?: string | null
  email?: string | null
  phoneNumber?: string | null
  permissions: string[] = []
  lastLoggedIn?: Moment | null
  worker?: Worker | null

  constructor (data?: any) {
    super(data, 'id', 'fullName', ['fullName', 'email'])

    if (data.permissions) {
      this.permissions = data.permissions.map((permission: any) => (<any>PermissionType)[permission])
    }
    this.worker = data.worker ? new Worker(data.worker) : null
    this.lastLoggedIn = data.lastLoggedIn ? this.convertDate(data.lastLoggedIn) : null
  }

  get fullName (): string {
    return this.surname + ' ' + this.firstname
  }
}
