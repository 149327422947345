import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/codeListGetters'
import EntityState from '@/modules/common/store/entityState'
import RootState from '@/store/rootState'
import EntityService from '@/modules/common/services/entityService'
import resource from '@/modules/unit/services/unitResource'
import CodeList from '@/modules/common/domain/codeList'

const unitModule: Module<EntityState<CodeList>, RootState> = {
  namespaced: true,
  state: new EntityState<CodeList>(),
  mutations,
  getters,
  actions: actions(new EntityService<CodeList>(resource, CodeList), 'unit')
}

export default unitModule
