









































































import _ from 'lodash'
import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import { FORMAT_SYSTEM_DATE, normalize } from '@/utils'
import { Getter, State } from 'vuex-class'
import CheckboxField from '@/modules/common/components/form/checkboxField.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import Order from '@/modules/order/domain/order'
import OrderDetailBox from '@/modules/order/components/orderDetailBox.vue'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import pageDataMixin from '@/modules/common/mixins/pageDataMixin'
import PageDataSet from '@/modules/app/store/pageDataSet'
import printMixin from '@/modules/common/mixins/printMixin'
import { Prop, Watch } from 'vue-property-decorator'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import PageData from '@/modules/app/store/pageData'

@Component({
  components: { OrderDetailBox, CheckboxField, booleanIcon, loading, noRecords }
})
export default class OrdersTable extends mixins(auditableTooltipMixin, confirmMixin, submitProtectionMixin, printMixin, pageDataMixin) {
  pageDataId = 'orders'
  initialized = false
  printDeliveryNotes = true

  @Prop({ type: OrderFilter, required: true }) filter!: OrderFilter
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Order[]

  @Getter('activePartners', { namespace: 'company' }) companies?: Company[]
  @State('owned', { namespace: 'company' }) company?: Company
  @State('pageDataSet', { namespace: 'app' }) pageDataSet!: PageDataSet

  get items () {
    if (!this.requiredFilterFilled) {
      return []
    }
    // map to object where key is '1' or '1-1' where first number is subscriber id and second is optional is subscriber branch id
    const orderMap = _.keyBy(this.filteredOrders, order => order.subscriber!.id + (order.subscriberBranch ? ('-' + order.subscriberBranch.id) : ''))
    const items: Array<Order> = []
    this.companies!.forEach(company => {
      // add order for subscriber
      if (this.matchByFilter(company)) {
        const orderForCompany = orderMap[company.id + '']
        if (orderForCompany) {
          items.push(orderForCompany)
        } else if (company.active && company.forOrder) { // create new order template only for active company and forOrder flag enabled
          items.push(this.newOrder(company))
        }
      }
      _(company.branches).filter(this.matchByFilter).forEach(branch => {
        // add order for subscriber and specific branch
        const orderForBranch = orderMap[company.id + '-' + branch.id]
        if (orderForBranch) {
          items.push(orderForBranch)
        } else if (branch.active && branch.forOrder) { // create new order template only for active company branch and forOrder flag enabled
          items.push(this.newOrder(company, branch))
        }
      })
    })
    return items
  }

  // filter orders by query - other parameters for filtering were used in BE
  get filteredOrders () {
    let filteredOrders
    if (this.filter.query) {
      const regexp = new RegExp(normalize(this.filter.query), 'g')
      filteredOrders = this.orders.filter(item => !!normalize(item.searchString).match(regexp))
    } else {
      filteredOrders = this.orders
    }
    return filteredOrders
  }

  // need to check orders and also available companies/companyBranches for no-record warning
  get noRecord () {
    return this.initialized &&
      this.orders.length === 0 &&
      !_.some(this.companies, company => company.active && company.forOrder) &&
      !_(this.companies).flatMap('branches').some(companyBranch => companyBranch.active && companyBranch.forOrder)
  }

  // filter company or company branch by region and by query
  matchByFilter (objWithAddress: any) {
    const matchByRegion = !this.filter.regionId || (objWithAddress.address && objWithAddress.address.region && objWithAddress.address.region.id === this.filter.regionId)
    let matchByQuery = true
    if (this.filter.query) {
      const regexp = new RegExp(normalize(this.filter.query), 'g')
      matchByQuery = !!normalize(objWithAddress.searchString).match(regexp)
    }
    return matchByRegion && matchByQuery
  }

  newOrder (subscriber: Company, subscriberBranch?: CompanyBranch) {
    return new Order({
      state: 'NEW',
      date: this.filter.date,
      supplierBranch: _.find(this.company!.branches, { id: this.filter.companyBranchId }),
      subscriber,
      subscriberBranch
    })
  }

  get requiredFilterFilled () {
    return this.filter.date && this.filter.companyBranchId
  }

  async editItem (order: Order) {
    await this.$store.dispatch('order/clearAll')
    if (order.id) {
      await this.$router.push({
        name: 'orderEdit',
        params: { orderId: order.id + '' }
      })
    } else {
      const params = {
        subscriberId: order.subscriber!.id + '',
        supplierBranchId: order.supplierBranch!.id + '',
        date: FORMAT_SYSTEM_DATE(order.date)
      } as any
      if (order.subscriberBranch) {
        params.subscriberBranchId = order.subscriberBranch.id + ''
      }
      await this.$router.push({ name: 'orderNew', params })
    }
  }

  @Watch('filter.showDetails')
  onFilterRegionChange (value: boolean) {
    if (this.pageData) {
      const filterData = _.cloneDeep(this.pageData.filterData)
      filterData.showDetails = value
      this.$store.dispatch('app/savePageData', { id: this.pageDataId, data: new PageData(filterData, this.pageData.sortData) })
    }
  }

  async beforeMount () {
    await this.$store.dispatch('order/clearAll')
  }

  async created () {
    this.filter.showDetails = this.pageData ? this.pageData.filterData.showDetails : false
    this.initialized = true
  }
}
