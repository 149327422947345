import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'

class RecipeItem extends Entity {
  item?: Item | null
  amount?: number | null
  recipe: any

  constructor (data: any) {
    super(data)
    this.item = data.item ? new Item(data.item) : null
  }
}

export default RecipeItem
