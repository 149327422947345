

































































































import _ from 'lodash'
import { AccountingType } from '@/modules/money/movement/type/accountingType'
import barChart from '@/modules/common/components/chart/barChart.vue'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import currencyFilter from '@/modules/common/filters/currencyFilter'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { extendMoment } from 'moment-range'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { Getter } from 'vuex-class'
import groupTypes from '@/modules/common/values/groupTypes'
import { CHART_COLORS } from '@/config'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import noRecords from '@/modules/common/components/noRecords.vue'
import notificationService from '@/modules/common/services/notificationService'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import Range from '@/modules/common/components/form/range'
import SortData from '@/modules/common/mixins/sortData'

class Filter {
  groupType: string | null = null
  range: Range | null = null
  dataSource: string | null = null

  get hasValidRange () {
    return this.range && this.range.from && this.range.from.isValid() && this.range.to && this.range.to.isValid()
  }
}

class SumItem {
  withoutVatIncome: number = 0
  withoutVatOutcome: number = 0
  totalAmountIncome: number = 0
  totalAmountOutcome: number = 0
  totalVatIncome: number = 0
  totalVatOutcome: number = 0
  totalAmount: number = 0
  totalVat: number = 0
}

interface SumItems {
  [key: string]: SumItem
}

@Component({
  components: { barChart, noRecords, loading, radioField, dateRangeField }
})
export default class VatDetailBox extends mixins(bookmarkableComponent) {
  filter = new Filter()
  groupTypes = groupTypes
  maxDate = moment()
  sumItems: SumItems = {}
  cashFlowSum: number | null = null
  dates: Array<string> = []

  @Getter('byFilter', { namespace: 'moneyMovement' }) movementsByFilter!: (filterData: FilterData, sortData: SortData) => Array<MoneyMovement>
  @Prop({ type: String, required: true }) currency!: string

  get movements () {
    return this.movementsByFilter(this.filter, this.sortData)
  }

  get chartData () {
    return {
      labels: this.dates,
      datasets: [
        {
          backgroundColor: CHART_COLORS.green,
          borderColor: CHART_COLORS.green,
          data: _.map(this.dates, label => this.itemByDate(label).totalVat),
          label: i18n.message('common.vat.total'),
          fill: false,
          type: 'line'
        },
        {
          backgroundColor: CHART_COLORS.red,
          borderColor: CHART_COLORS.red,
          data: _.map(this.dates, label => this.itemByDate(label).totalAmount),
          label: i18n.message('common.total'),
          fill: false,
          type: 'line'
        },
        {
          backgroundColor: CHART_COLORS.blue,
          data: _.map(this.dates, label => this.itemByDate(label).withoutVatIncome),
          label: i18n.message('finance.income.without.vat'),
          stack: 'stack-income'
        },
        {
          backgroundColor: CHART_COLORS.blueLight,
          data: _.map(this.dates, label => this.itemByDate(label).totalVatIncome),
          label: i18n.message('finance.income.vat'),
          stack: 'stack-income'
        },
        {
          backgroundColor: CHART_COLORS.purple,
          data: _.map(this.dates, label => Math.abs(this.itemByDate(label).withoutVatOutcome)),
          label: i18n.message('finance.outcome.without.vat'),
          stack: 'stack-outcome'
        },
        {
          backgroundColor: CHART_COLORS.purpleLight,
          data: _.map(this.dates, label => Math.abs(this.itemByDate(label).totalVatOutcome)),
          label: i18n.message('finance.outcome.vat'),
          stack: 'stack-outcome'
        }
      ]
    }
  }

  get chartLabelCallback () {
    return (tooltipItem: any, data: any) => currencyFilter(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], this.currency) || ''
  }

  get chartBeforeLabelCallback () {
    return (tooltipItem: any, data: any) => data.datasets[tooltipItem.datasetIndex].label || ''
  }

  get chartAfterLabelCallback () {
    return (tooltipItem: any, data: any) => (tooltipItem.datasetIndex + 1) < data.datasets.length ? ' ' : null
  }

  defaultFilter () {
    this.filter.groupType = 'month'
    this.filter.range = new Range(moment().subtract(5, 'month'), moment())
    this.filter.dataSource = 'CATEGORY'
  }

  itemByDate (date: string) {
    return this.sumItems[date] || 0
  }

  sumByProperty (property: string) {
    return _(this.sumItems).values().map(property).sum() || 0
  }

  async fetch () {
    if (this.filter.hasValidRange && this.filter.range!.to!.diff(this.filter.range!.from, 'days') > 365) {
      return notificationService.error('error.range.too.big')
    }
    await this.clear()
    const filter = this.createFilter()
    await this.$store.dispatch('moneyMovement/getAll', new EntityFetchParams(true, filter))
    this.recalculateData()
  }

  createFilter () {
    const taxableDateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const taxableDateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null

    return _.pickBy({
      taxableDateFrom,
      taxableDateTo,
      active: true,
      currency: this.currency,
      accountingTypes: [AccountingType.FISCAL]
    }, _.identity)
  }

  recalculateData () {
    // extend moment with moment-range
    const momentExt = extendMoment(moment as any)
    // calculate headers
    const range = momentExt.range(this.filter!.range!.from!, this.filter!.range!.to!)
    const groupType = _.find(this.groupTypes, { value: this.filter.groupType! })
    const rangeDates = Array.from(range.by(this.filter.groupType as any))
    this.dates = _.map(rangeDates, date => groupType!.dateFormat(date))

    // calculate data
    this.sumItems = _(this.movements)
      .groupBy(movement => _.find(this.groupTypes, { value: this.filter!.groupType! })!.dateFormat(movement.date)) // group by date format of selected group type
      .map((movementsPerDate, dateLabel) => {
        const result = {
          dateLabel,
          totalAmountIncome: _(movementsPerDate).filter({ type: MoneyMovementType.INCOME }).map('totalAmount').sum() || 0,
          totalVatIncome: _(movementsPerDate).filter({ type: MoneyMovementType.INCOME }).map('totalVat').sum() || 0,
          withoutVatIncome: _(movementsPerDate).filter({ type: MoneyMovementType.INCOME }).map('totalWithoutVat').sum() || 0,
          totalAmountOutcome: _(movementsPerDate).filter({ type: MoneyMovementType.OUTCOME }).map('totalAmount').sum() || 0,
          totalVatOutcome: _(movementsPerDate).filter({ type: MoneyMovementType.OUTCOME }).map('totalVat').sum() || 0,
          withoutVatOutcome: _(movementsPerDate).filter({ type: MoneyMovementType.OUTCOME }).map('totalWithoutVat').sum() || 0,
          totalAmount: _(movementsPerDate).map('totalAmount').sum() || 0,
          totalVat: _(movementsPerDate).map('totalVat').sum() || 0
        }
        return result
      })
      .keyBy('dateLabel')
      .value()

    this.dates.forEach(date => {
      this.sumItems[date] = this.sumItems[date] || new SumItem()
    })
  }

  sumClassObj (amount: number) {
    return {
      'text-danger': amount < 0,
      'text-success': amount >= 0
    }
  }

  async clear () {
    this.sumItems = {}
    this.cashFlowSum = null
    this.dates = []
    await this.$store.dispatch('moneyMovement/clearAll')
  }

  isEmpty (someData: any) {
    return _.isEmpty(someData)
  }

  // need to clear cashed data based on currency change, because different currency
  // pages are same route only with different value of currency parameter and destroy method
  // is not called
  @Watch('currency')
  async onCurrencyChange () {
    await this.clear()
  }
}
