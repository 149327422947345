





























import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component from 'vue-class-component'
import Item from '@/modules/item/domain/Item'
import itemOriginTypes from '@/modules/item/type/itemOriginTypes'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { booleanIcon }
})
export default class ItemStockInfoBox extends Vue {
  itemOriginTypes = itemOriginTypes

  @State('selected', { namespace: 'item' }) item!: Item
}
