







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import orderStatementForm from '@/modules/order/statement/components/orderStatementForm.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { Getter, State } from 'vuex-class'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'
import Company from '@/modules/company/domain/company'

@Component({
  components: { loading, orderStatementForm, page }
})
export default class OrderStatementCreatePage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  statement: OrderStatement | null = null

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  @Prop({ type: String, required: false }) orderStatementId!: string

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('orderStatement/select', this.orderStatementId)
    this.statement = await this.$store.dispatch('orderStatement/enrich', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, {
      textKey: 'order-statements.label.short',
      routeName: 'orderStatements'
    }, { text: this.statement!.formattedNumber! }])
  }
}
