




































import _ from 'lodash'
import CodeList from '@/modules/common/domain/codeList'
import Component from 'vue-class-component'
import currencyFilter from '@/modules/common/filters/currencyFilter'
import loading from '@/modules/common/components/loading.vue'
import { FORMAT_SYSTEM_DATE, localeCompare } from '@/utils'
import noRecords from '@/modules/common/components/noRecords.vue'
import numberFilter from '@/modules/common/filters/numberFilter'
import Order from '@/modules/order/domain/order'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

class SumItem {
  subscriberId: number | null = null
  subscriberName: string | null = null
  paymentType: CodeList | null = null
  totalOrders = 0
  totalWithoutVat: number = 0
  totalWithVat: number = 0
  undelivered = 0
  totalNotBilled = 0
  currency: string | null = null
}

@Component({
  components: { loading, noRecords }
})
export default class OrdersOverviewSummaryTable extends Vue {
  @Prop({ type: OrderFilter, required: true }) filter!: OrderFilter
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Order[]

  sumItems: SumItem[] = []

  @Watch('orders')
  onOrdersChanged () {
    this.calculateSumItems()
  }

  get dateFrom () {
    return FORMAT_SYSTEM_DATE(this.filter?.range?.from)
  }

  get dateTo () {
    return FORMAT_SYSTEM_DATE(this.filter?.range?.to)
  }

  private notBilled (sumItem: SumItem) {
    return currencyFilter(sumItem.totalNotBilled, sumItem.currency!)
  }

  private calculateSumItems () {
    if (this.orders.length > 0) {
      this.sumItems = _(this.orders)
        .groupBy('subscriber.id')
        .map((ordersPerSubscriber) => {
          const firstOrder = ordersPerSubscriber[0]
          const subscriber = firstOrder.subscriber!
          return {
            subscriberId: subscriber.id!,
            orderStatementEnabled: subscriber.orderStatementEnabled!,
            subscriberName: subscriber.name!,
            paymentType: subscriber.saleSettings ? subscriber.saleSettings.paymentType! : null,
            totalOrders: ordersPerSubscriber.length,
            totalWithoutVat: _(ordersPerSubscriber).sumBy('totalWithoutVat'),
            totalWithVat: _(ordersPerSubscriber).sumBy('totalWithVat'),
            undelivered: _(ordersPerSubscriber).filter(order => !order.delivered).size(),
            totalNotBilled: _(ordersPerSubscriber).filter(order => order.delivered && !order.billed).sumBy('totalWithoutVat'),
            currency: firstOrder.currency
          } as SumItem
        })
        .value()
        .sort(localeCompare('subscriberName'))
    } else {
      this.sumItems = []
    }
  }

  numberFormat (value: number) {
    return numberFilter(value)
  }

  created () {
    this.calculateSumItems()
  }
}
