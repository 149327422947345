import * as types from './mutationTypes'
import { ActionTree } from 'vuex'
import FinanceState from './financeState'
import financeSummaryService from '../services/financeSummaryService'
import RootState from '../../../store/rootState'

const actions: ActionTree<FinanceState, RootState> = {
  async getVatSummaries ({ state, dispatch, commit }, currency) {
    dispatch('app/loadingDataEnable', 'financeVatSummaries', { root: true })
    const vatSummaries = await financeSummaryService.getVatSummaries(currency)
    await commit(types.SUMMARY_VAT_LIST, {
      items: vatSummaries
    })
    dispatch('app/loadingDataDisable', 'financeVatSummaries', { root: true })
  },
  async getTaxBaseSummaries ({ state, dispatch, commit }, currency) {
    dispatch('app/loadingDataEnable', 'financeTaxBaseSummaries', { root: true })
    const taxBaseSummaries = await financeSummaryService.getTaxBaseSummaries(currency)
    await commit(types.SUMMARY_TAX_BASE_LIST, {
      items: taxBaseSummaries
    })
    dispatch('app/loadingDataDisable', 'financeTaxBaseSummaries', { root: true })
  },
  async clearVatSummaries ({ commit }) {
    await commit(types.CLEAR_VAT_SUMMARIES)
  },
  async clearTaxBaseSummaries ({ commit }) {
    await commit(types.CLEAR_TAX_BASE_SUMMARIES)
  }
}

export default actions
