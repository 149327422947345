






















































































































































import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import CodeList from '@/modules/common/domain/codeList'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import Item from '@/modules/item/domain/Item'
import itemOriginTypes from '@/modules/item/type/itemOriginTypes'
import { ItemType } from '@/modules/item/type/itemType'
import itemTypes from '@/modules/item/type/itemTypes'
import radioField from '@/modules/common/components/form/radioField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import scaleTypes from '@/modules/item/type/scaleTypes'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import { Watch } from 'vue-property-decorator'
import { ItemOriginType } from '@/modules/item/type/itemOriginType'

@Component({
  components: { checkboxField, formModal, radioField, selectField, staticField, textField }
})
export default class ItemCreateModal extends mixins<createModal<Item>>(createModal) {
  itemOriginTypes = itemOriginTypes
  moduleName = 'item'
  itemTypes = itemTypes
  scaleTypes = scaleTypes

  @Getter('validItems', { namespace: 'unit' }) units!: Array<CodeList>
  @Getter('validItems', { namespace: 'itemCategory' }) itemCategories!: Array<CodeList>

  @Watch('item.type')
  onTypeChanged (newType?: ItemType, oldType?: ItemType) {
    if (newType && oldType && newType !== oldType && newType === 'SERVICE') {
      this.item!.forStock = false
      this.item!.batchEvidence = false
      this.item!.origin = ItemOriginType.PURCHASE
    }
  }

  afterSuccessSave (item: Item) {
    if (!this.isEdit()) {
      this.$router.push({ name: 'itemDetail', params: { itemId: item.id + '' } })
    }
  }

  async created () {
    await this.$store.dispatch('unit/getAll')
    await this.$store.dispatch('itemCategory/getAll')
  }
}
