
















































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import Item from '@/modules/item/domain/Item'
import numberField from '@/modules/common/components/form/numberField.vue'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementItemForm from '@/modules/stock/movement/components/outcomeStockMovementItemForm.vue'
import { OutcomeStockMovementType } from '@/modules/stock/movement/type/outcomeStockMovementType'
import outcomeStockMovementTypes from '@/modules/stock/movement/type/outcomeStockMovementTypes'
import { Prop } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import textField from '@/modules/common/components/form/textField.vue'
import outcomeStockMovementRecipeModal from '@/modules/stock/movement/components/outcomeStockMovementRecipeModal.vue'
import RecipeAmount from '@/modules/stock/movement/domain/recipeAmount'
import bigDecimal from 'js-big-decimal'
import StockItem from '@/modules/stock/item/domain/stockItem'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { outcomeStockMovementRecipeModal, dateTimeField, loading, outcomeStockMovementItemForm, numberField, selectField, staticField, textField, vForm }
})
export default class OutcomeStockMovementForm extends mixins(validationMixin, confirmMixin) {
  outcomeStockMovementTypes = _.filter(outcomeStockMovementTypes, type => type.value !== OutcomeStockMovementType.INVENTORY_SHORTAGE)
  initialized = false
  showAddByRecipeModal = false

  @Prop({ type: OutcomeStockMovement, required: true }) movement!: OutcomeStockMovement

  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]
  @Getter('all', { namespace: 'item' }) items?: Item[]

  addItem () {
    this.movement!.items = [...this.movement!.items, new StockMovementItem({})]
  }

  addRecipeAmount (recipeAmount: RecipeAmount) {
    recipeAmount.recipe!.items.forEach(recipeItem => {
      const existingMovementItem = _.find(this.movement.items, movementItem => (movementItem.item && movementItem.item!.id) === recipeItem.item!.id)
      const item = _.find(this.items, { id: recipeItem.item!.id })
      const amount = Number(bigDecimal.round((recipeItem.amount! / recipeAmount.recipe!.batchSize!) ! * recipeAmount.amount!, item!.scale, bigDecimal.RoundingModes.UP))
      // if item is already added to stock movement, add correct amount
      if (existingMovementItem) {
        existingMovementItem.amount! += amount
      } else {
        const stockItem = _.find(this.stockItems, stockItem => stockItem.item!.id === item!.id)
        this.movement.items.push(new StockMovementItem({
          item,
          amount,
          price: stockItem!.price
        }))
      }
    })

    // clear empty items
    if (this.movement.items.length > 1) {
      this.movement.items = _.filter(this.movement.items, movementItem => !_.isNil(movementItem.item) && !_.isNil(movementItem.amount))
    }
  }

  doShowAddByRecipeModal () {
    this.showAddByRecipeModal = true
  }

  hideAddByRecipeModal () {
    this.showAddByRecipeModal = false
  }

  onSubmit () {
    const operation = 'outcomeStockMovement/' + (this.movement!.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.movement!, false)).then((result: OutcomeStockMovement) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'outcomeStockMovement', params: { movementId: result.id + '' } })
      }
    }).finally(this.unprotect)
  }

  deleteMovement () {
    this.confirm('stock-movement.outcome.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('outcomeStockMovement/delete', new EntityDeleteParams(this.movement.id!, false)).then((result) => {
          if (result) {
            notificationService.success('stock-movement.outcome.delete.success')
            this.$router.push({ name: 'stockMovements' })
          }
        }).finally(this.unprotect)
      }
    })
  }

  async created () {
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.movement.stock!.id }))
    await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.movement.stock!.id, 'active-only': true }))
    await this.$store.dispatch('item/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('recipe/getAll', new EntityFetchParams(true))
    this.initialized = true
  }
}
