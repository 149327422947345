

































































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import Range from '@/modules/common/components/form/range'
import recordTypes from '@/modules/record/type/recordTypes'
import SubjectRecord from '@/modules/record/domain/subjectRecord'
import { Watch } from 'vue-property-decorator'
import subjectRecordMixin from '@/modules/record/components/subjectRecordMixin'
import Truncate from '@/modules/common/components/truncate.vue'

class Filter {
  range?: Range | null = null
  query?: string | null = null
}

@Component({
  components: { Truncate, dateRangeField, loading, noRecords }
})
export default class SubjectRecordsTable extends mixins<entityTableMixin<SubjectRecord>>(bookmarkableComponent, entityTableMixin, subjectRecordMixin) {
  recordTypes = recordTypes
  entityModuleName = 'subjectRecord'
  activeSupport = false
  filter = new Filter()

  @Watch('filter.range')
  onRangeChanged (rangeNew?: Range, rangeOld?: Range) {
    if (!rangeNew && rangeOld) {
      this.fetch()
    }
  }

  defaultFilter () {
    this.filter.range = new Range(moment().subtract(7, 'day'))
  }

  async detail (record: SubjectRecord) {
    await this.$router.push({ name: 'partnerDetail', params: { partnerId: record.subject!.id + '' } })
  }

  createFilter () {
    const dateFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null

    return _.pickBy({
      'date-from': dateFrom,
      'date-to': dateTo
    }, _.identity)
  }

  async beforeFetch () {
    await this.$store.dispatch('subjectRecord/clearAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }
}
