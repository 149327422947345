import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import i18n from '@/i18n'
import { IncomeStockMovementType } from '@/modules/stock/movement/type/incomeStockMovementType'
import incomeStockMovementTypes from '@/modules/stock/movement/type/incomeStockMovementTypes'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'

class IncomeStockMovement extends StockMovement {
  type?: IncomeStockMovementType | null = null
  supplier?: Company | null = null
  deliveryNote?: string | null
  outcomeMovement?: OutcomeStockMovement | null

  constructor (data: any) {
    super(data)

    this.type = data.type
    this.deliveryNote = data.deliveryNote
    this.supplier = data.supplier == null ? null : new Company(data.supplier)
    this.outcomeMovement = data.outcomeMovement == null ? null : new OutcomeStockMovement(data.outcomeMovement)

    if (!this['@type']) {
      this['@type'] = 'income'
    }

    this.extendSearchString()
  }

  get formattedNumber () {
    return this.number ? 'SP-' + this.number : null
  }

  private extendSearchString () {
    this.searchString += ' ' + this.formattedNumber

    if (this.type) {
      this.searchString += i18n.message(_.find(incomeStockMovementTypes, { value: this.type })!.label)
    }

    // concat string without whitespaces
    this.searchString += this.searchString!.replace(/\s/g, '')
  }
}

export default IncomeStockMovement
