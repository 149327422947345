






import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '../../../common/components/page.vue'
import Vue from 'vue'
import OutcomeStockMovementForm from '@/modules/stock/movement/components/outcomeStockMovementForm.vue'
import { Prop } from 'vue-property-decorator'
import _ from 'lodash'
import { Getter, State } from 'vuex-class'
import Stock from '@/modules/stock/domain/stock'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementService from '@/modules/stock/movement/services/outcomeStockMovementService'
import Order from '@/modules/order/domain/order'
import StockItem from '@/modules/stock/item/domain/stockItem'
import notificationService from '@/modules/common/services/notificationService'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'

@Component({
  components: { OutcomeStockMovementForm, page }
})
export default class OutcomeStockMovementCreatePage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'stock-movements.label.2', routeName: 'stockMovements' }, { textKey: 'stock-movement.outcome.new.label' }])

  movement?: OutcomeStockMovement | null = null

  @Prop({ type: String, required: true }) stockId!: string

  @Getter('active', { namespace: 'stock' }) stocks!: Stock[]
  @State('selectedItems', { namespace: 'order' }) selectedDeliveries!: Order[]
  @Getter('active', { namespace: 'stockItem' }) stockItems!: StockItem[]
  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>

  async created () {
    await this.$store.dispatch('stock/getAll')
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.stockId }))
    const stock = _.find(this.stocks, { id: Number(this.stockId) })!
    if (this.selectedDeliveries.length > 0) {
      try {
        this.movement = await outcomeStockMovementService.newOutcomeForDeliveries(stock, this.stockItems, this.selectedDeliveries, this.itemsMap)
      } catch (e) {
        notificationService.error(e.message, [e.item.name])
        this.movement = outcomeStockMovementService.newOutcomeStockMovement(stock)
      } finally {
        await this.$store.dispatch('order/clearSelectedItems')
      }
    } else {
      this.movement = outcomeStockMovementService.newOutcomeStockMovement(stock)
    }
  }
}
