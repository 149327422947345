








































import _ from 'lodash'
import Component from 'vue-class-component'
import moment from 'moment'
import moneyMovementDescription from '@/modules/money/movement/components/moneyMovementDescription.vue'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  components: { moneyMovementDescription }
})
export default class MoneyBoxesSummaryBox extends Vue {
  @Prop({ type: String, required: true }) currency!: string
  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean

  get moneyBoxes () {
    return this.$store.getters['moneyBox/activeByCurrency'](this.currency)
  }

  get cashFlow () {
    return _(this.moneyBoxes).map('amount').sum()
  }

  get totalCash () {
    return _(this.moneyBoxes).map('amountCash').sum()
  }

  get totalCheque () {
    return _(this.moneyBoxes).map('amountCheque').sum()
  }

  detail (moneyBoxId: bigint) {
    if (this.hasPermission('FINANCE_MONEY_BOX')) {
      this.$router.push({
        name: 'moneyBox',
        query: {
          fn_moneyBoxId: moneyBoxId.toString(),
          frf_range: FORMAT_SYSTEM_DATE(moment().subtract(30, 'day')),
          frt_range: FORMAT_SYSTEM_DATE(moment())
        }
      })
    }
  }
}
