








import _ from 'lodash'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import workerForm from '@/modules/worker/components/workerForm.vue'
import workingActivityForm from '@/modules/workingactivity/components/workingActivityForm.vue'
import Vue from 'vue'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'

@Component({
  components: { loading, page, workerForm, workingActivityForm }
})
export default class WorkingActivityEditPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  activity?: WorkingActivity | null = null

  @Prop({ type: String, required: true }) workingActivityId!: string

  async mounted () {
    await this.$store.dispatch('worker/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('company/getOwned')
    this.activity = _.cloneDeep(await this.$store.dispatch('workingActivity/select', this.workingActivityId))

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'setting.working-activity.label', routeName: 'workingActivities' },
      { text: this.activity!.label }])
  }
}
