


































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import Inventory from '@/modules/inventory/domain/inventory'
import InventoryItem from '@/modules/inventory/domain/inventoryItem'
import Item from '@/modules/item/domain/Item'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Getter, State } from 'vuex-class'
import staticField from '@/modules/common/components/form/staticField.vue'
import StockItem from '@/modules/stock/item/domain/stockItem'
import validationMixin from '@/modules/common/components/form/validationMixin'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

@Component({
  components: { selectField, numberField, staticField }
})
export default class InventoryItemForm extends mixins(validationMixin, confirmMixin) {
  @Prop({ type: Inventory, required: true }) inventory!: Inventory
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: InventoryItem, required: true }) inventoryItem!: InventoryItem

  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]
  @Getter('active', { namespace: 'itemBatch' }) itemBatches?: ItemBatch[]
  supplyCurrent?: number | null = null

  /**
   * Validation object need to be created dynamic, based on scale settings of "Item" object.
   * @param item selected for OrderItem
   */
  validationObject (item: Item) {
    const validationObj = { required: true, max_value: 9999999999, min_value: 0 } as any
    if (item.scale === 0) {
      validationObj.numeric = true
    } else {
      validationObj.decimal = item.scale
    }
    return validationObj
  }

  get batchesEnabled () {
    return this.inventoryItem.item && this.inventoryItem.item.batchEvidence
  }

  @Watch('inventoryItem', { deep: true })
  onItemChange (inventoryItem: InventoryItem) {
    inventoryItem.difference = _.isNil(inventoryItem.amount) ? 0 : (inventoryItem.amount! - this.supplyCurrent!)
  }

  availableBatches (itemId: number) {
    return _.filter(this.itemBatches, itemBatch => itemBatch.item!.id === itemId)
  }

  created () {
    // for edit mode, supply need to be calculated from existing inventory movements
    if (this.inventory.id) {
      if (this.inventory.incomeMovement) {
        const movementItem = _(this.inventory.incomeMovement!.items).find(movementItem => movementItem.item!.id! === this.inventoryItem!.item!.id)
        if (movementItem) {
          this.supplyCurrent = (this.inventoryItem.amount || 0) - movementItem.amount!
          return
        }
      }
      if (this.inventory.outcomeMovement) {
        const movementItem = _(this.inventory.outcomeMovement!.items).find(movementItem => movementItem.item!.id! === this.inventoryItem!.item!.id)
        if (movementItem) {
          this.supplyCurrent = (this.inventoryItem.amount || 0) + movementItem.amount!
          return
        }
      }
      this.supplyCurrent = this.inventoryItem.amount
    } else {
      this.supplyCurrent = _.find(this.stockItems, stockItem => stockItem.item!.id! === this.inventoryItem!.item!.id)!.amount || 0
    }
  }
}
