







import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import OutcomeStockMovementForm from '@/modules/stock/movement/components/outcomeStockMovementForm.vue'
import page from '../../../common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { loading, OutcomeStockMovementForm, page }
})
export default class OutcomeStockMovementEditPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null

  movement?: OutcomeStockMovement | null = null

  @Prop({ type: String, required: true }) movementId!: string

  async created () {
    this.movement = _.cloneDeep(await this.$store.dispatch('outcomeStockMovement/select', Number(this.movementId)))

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' },
      { textKey: 'stock-movements.label.2', routeName: 'stockMovements' },
      { text: this.movement!.formattedNumber! }])
  }
}
