import takingsService from '@/modules/sale/services/takingsService'
import TakingsTransfer from '@/modules/sale/domain/TakingsTransfer'
import entityActions from '@/modules/common/store/entityActions'

const actions = entityActions(takingsService, 'takings')

actions.transfer = async function ({ state, dispatch, commit }, takingsTransfer: TakingsTransfer) {
  const updatedTakings = await takingsService.transfer(takingsTransfer)
  if (updatedTakings) {
    await dispatch('takings/refresh', null, { root: true })
    return true
  } else {
    return false
  }
}

export default actions
