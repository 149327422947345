

























































































































import addressForm from '@/modules/common/components/addressForm.vue'
import CodeList from '@/modules/common/domain/codeList'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { selectField, textField, checkboxField, formModal, addressForm }
})
export default class CompanyCreateModal extends mixins<createModal<Company>>(createModal) {
  moduleName = 'company'
  vatPayer = false
  @Prop({ type: Boolean, default: true }) showInactivate!: boolean
  @Prop({ type: Boolean, default: true }) showName!: boolean
  @Prop({ type: Boolean, default: true }) showCompanyId!: boolean
  @Prop({ type: Boolean, default: true }) showVatId!: boolean
  @Prop({ type: Boolean, default: true }) showWeb!: boolean
  @Prop({ type: Boolean, default: true }) showDefaultCurrency!: boolean
  @Prop({ type: Boolean, default: true }) showRegion!: boolean
  @Prop({ type: Boolean, default: true }) showCategories!: boolean
  @Prop({ type: Boolean, default: true }) showVatLocal!: boolean
  @Prop({ type: Boolean, default: true }) showAddress!: boolean
  @Prop({ type: Boolean, default: true }) showForOrder!: boolean
  @Prop({ type: Boolean, default: true }) showSeparator!: boolean

  @State('items', { namespace: 'subjectCategory' }) categories!: Array<CodeList>
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>

  beforeSave () {
    if (!this.vatPayer) {
      this.item!.vatId = null
    }
  }

  afterSuccessSave (item: Company) {
    if (!this.isEdit()) {
      this.$router.push({ name: 'partnerDetail', params: { partnerId: item.id + '' } })
    }
  }

  afterModelSet () {
    if (this.item) {
      this.vatPayer = !!this.item.vatId
    }
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('subjectCategory/getAll')
  }
}
