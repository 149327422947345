






import _ from 'lodash'
import barChart from '@/modules/common/components/chart/barChart.vue'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import currencyFilter from '@/modules/common/filters/currencyFilter'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import { CHART_COLORS } from '@/config'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { SumItems } from '@/modules/sale/components/takingsOverviewSummaryBox.vue'
import Vue from 'vue'

@Component({
  components: { barChart, box, noRecords, loading, radioField, dateRangeField, selectField }
})
export default class TakingsOverviewSummaryTable extends Vue {
  @Prop({ type: Object, required: true }) sumItems!: SumItems
  @Prop({ type: Array, required: true }) dates!: string[]
  @Prop({ type: String, required: true }) currency!: string

  get chartData () {
    return {
      labels: this.dates,
      datasets: [
        {
          backgroundColor: CHART_COLORS.green,
          borderColor: CHART_COLORS.green,
          data: _.map(this.dates, label => this.itemByDate(label).amountTotal),
          label: i18n.message('common.total'),
          fill: false,
          type: 'line'
        },
        {
          backgroundColor: CHART_COLORS.blue,
          data: _.map(this.dates, label => this.itemByDate(label).amountCard),
          label: i18n.message('finance.amount.card'),
          stack: 'stack-fiscal'
        },
        {
          backgroundColor: CHART_COLORS.blueLight,
          data: _.map(this.dates, label => this.itemByDate(label).amountCheque),
          label: i18n.message('finance.amount.cheque'),
          stack: 'stack-fiscal'
        },
        {
          backgroundColor: CHART_COLORS.purple,
          data: _.map(this.dates, label => this.itemByDate(label).amountCash),
          label: i18n.message('finance.amount.cash'),
          stack: 'stack-fiscal'
        },
        {
          backgroundColor: CHART_COLORS.purpleLight,
          data: _.map(this.dates, label => this.itemByDate(label).amountChequeNonFiscal),
          label: i18n.message('finance.amount.cheque.non.fiscal'),
          stack: 'stack-non-fiscal'
        },
        {
          backgroundColor: CHART_COLORS.redLight,
          data: _.map(this.dates, label => this.itemByDate(label).amountCashNonFiscal),
          label: i18n.message('finance.amount.cash.non.fiscal'),
          stack: 'stack-non-fiscal'
        }
      ]
    }
  }

  get chartLabelCallback () {
    return (tooltipItem: any, data: any) => currencyFilter(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], this.currency) || ''
  }

  get chartBeforeLabelCallback () {
    return (tooltipItem: any, data: any) => data.datasets[tooltipItem.datasetIndex].label || ''
  }

  get chartAfterLabelCallback () {
    return (tooltipItem: any, data: any) => (tooltipItem.datasetIndex + 1) < data.datasets.length ? ' ' : null
  }

  itemByDate (date: string) {
    return this.sumItems[date] || 0
  }
}
