










































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import numberField from '@/modules/common/components/form/numberField.vue'
import Item from '@/modules/item/domain/Item'
import ItemDispatch from '@/modules/item/domain/ItemDispatch'
import selectField from '@/modules/common/components/form/selectField.vue'

@Component({
  components: { formModal, numberField, selectField }
})
export default class ItemDispatchCreateModal extends mixins<createModal<ItemDispatch>>(createModal) {
  moduleName = 'itemDispatch'

  @Getter('activeForStock', { namespace: 'item' }) items!: Item[]
  @Getter('all', { namespace: 'itemDispatch' }) itemDispatches!: ItemDispatch[] // item dispatches are fetched in table

  get availableItems () {
    const usedItemIds = _(this.itemDispatches!).map('subItem.id').value()
    usedItemIds.push(this.item!.item!.id)
    return _.filter(this.items, item => (usedItemIds.indexOf(item.id) < 0))
  }

  /**
   * Validation object need to be created dynamic, based on scale settings of "Item" object.
   * @param item selected for OrderItem
   */
  validationObject (item?: Item) {
    const validationObj = { required: true, max_value: 9999999999 } as any
    if (!item) {
      return validationObj
    } else if (item.scale === 0) {
      validationObj.numeric = true
      validationObj.min_value = 1
    } else if (item.scale! > 0) {
      validationObj.decimal = item.scale
      validationObj.min_value = _.round(1 / (10 ** item.scale!), item.scale)
    }
    return validationObj
  }

  async created () {
    await this.$store.dispatch('item/getAll')
  }
}
