import actions from '@/modules/common/store/entityActions'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import getters from '@/modules/common/store/entityGetters'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'
import itemBatchResource from '@/modules/item/batch/service/itemBatchResource'
import RootState from '@/store/rootState'

const contactModule: Module<EntityState<ItemBatch>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(new EntityService(itemBatchResource, ItemBatch), 'item-batch')
}

export default contactModule
