export const LIST = 'LIST'
export const LIST_LATEST = 'LIST_LATEST'
export const SELECT = 'SELECT'
export const SELECT_ITEMS = 'SELECT_ITEMS'
export const CLEAR_ALL = 'CLEAR_ALL'
export const CLEAR_SELECTED = 'CLEAR_SELECTED'
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS'
export const CLEAR_LATEST = 'CLEAR_LATEST'
export const ENRICH = 'ENRICH'
export const ENRICH_ALL = 'ENRICH_ALL'
