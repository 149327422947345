























































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import box from '@/modules/common/components/box.vue'
import CodeList from '@/modules/common/domain/codeList'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import groupTypes from '@/modules/common/values/groupTypes'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import radioField from '@/modules/common/components/form/radioField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import Currency from '@/modules/currency/domain/currency'
import Company from '@/modules/company/domain/company'
import Range from '@/modules/common/components/form/range'
import { TakingsOverviewFilter } from '@/modules/sale/domain/takingsOverviewFilter'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { box, dateRangeField, loading, radioField, selectField, textField }
})
export default class TakingsOverviewFilterBox extends mixins(bookmarkableComponent) {
  groupTypes = groupTypes
  filter = new TakingsOverviewFilter()
  @Prop({ type: String, required: true }) pageDataId!: string
  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>
  @Getter('validItems', { namespace: 'region' }) regions?: Array<CodeList>
  @State('items', { namespace: 'currency' }) currencies?: Currency[]
  @State('owned', { namespace: 'company' }) company?: Company

  get fetchDisabled () {
    return !this.filter.hasValidRange || !this.filter.currency
  }

  fetch () {
    this.$emit('filter-changed', this.filter)
  }

  async defaultFilter () {
    this.filter.groupType = _.first(groupTypes)!.value
    this.filter.companyBranchIds = []
    this.filter.currency = this.company!.defaultCurrency || null
    this.filter.range = new Range(moment().subtract(5, 'months').startOf('month'), moment())
  }

  async initData () {
    await this.$store.dispatch('currency/getAll')
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('companyBranch/clearAll')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }

  afterSetup () {
    if (!this.fetchDisabled) {
      this.fetch()
    }
  }
}
