


























import Component from 'vue-class-component'
import Order from '@/modules/order/domain/order'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class OrdersDeliveryPrintDetailTable extends Vue {
  @Prop({ type: Array, required: true }) orders!: Order[]
  @Prop({ type: Number, required: true }) total!: number
}
