import takingsPage from '@/modules/sale/components/takingsPage.vue'
import takingsOverviewPage from '@/modules/sale/components/takingsOverviewPage.vue'

export default [
  {
    path: '/takings',
    name: 'takings',
    component: takingsPage,
    meta: {
      requiresLoggedIn: true,
      requiresAnyPermission: ['TAKINGS', 'TAKINGS_TRANSFER'],
      pageGroup: 'sale'
    }
  },
  {
    path: '/takings-overview',
    name: 'takingsOverview  ',
    component: takingsOverviewPage,
    meta: {
      requiresLoggedIn: true,
      requiresAnyPermission: ['TAKINGS_OVERVIEW'],
      pageGroup: 'sale'
    }
  }
]
