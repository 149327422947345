import priceListResource from '@/modules/pricelist/service/priceListResource'
import EntityService from '@/modules/common/services/entityService'
import moment from 'moment'
import PriceList from '@/modules/pricelist/domain/priceList'
import Company from '@/modules/company/domain/company'
import PriceListItem from '@/modules/pricelist/domain/priceListItem'
import Currency from '@/modules/currency/domain/currency'
import Item from '@/modules/item/domain/Item'

class PriceListService extends EntityService<PriceList> {
  newPriceList (currencies: Array<Currency>, itemId?: number | null, companyId?: number): PriceList {
    const priceList = new PriceList({
      validFrom: moment(),
      item: itemId ? new Item({ id: itemId }) : null,
      company: companyId ? new Company({ id: companyId }) : null
    })
    currencies.forEach((currency: Currency) => {
      priceList.items.push(new PriceListItem({ currency: currency.code }))
    })
    if (!currencies.length) {
      priceList.items.push(new PriceListItem({}))
    }
    return priceList
  }
}

export default new PriceListService(priceListResource, PriceList)
