import _ from 'lodash'
import { createGetters } from '@/modules/common/store/entityGetters'
import EntityState from '@/modules/common/store/entityState'
import { GetterTree } from 'vuex'
import Item from '@/modules/item/domain/Item'
import RootState from '@/store/rootState'

const getters: GetterTree<EntityState<Item>, RootState> = createGetters()

getters.activeForPurchase = (state, getters) => {
  return _.filter(getters.active || [], { forPurchase: true })
}
getters.activeForStock = (state, getters) => {
  return _.filter(getters.active || [], { forStock: true })
}

export default getters
