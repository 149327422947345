







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import workRecordsTable from '@/modules/work/record/components/workRecordsTable.vue'

@Component({
  components: { page, workRecordsTable }
})
export default class WorkRecordsPersonalPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'work-record.label' }, { textKey: 'work-record.overview.records.label' }])
}
