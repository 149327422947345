




















import Component, { mixins } from 'vue-class-component'
import Document from '@/modules/document/domain/document'
import documentMixin from '@/modules/common/components/form/documentMixin'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import documentService from '@/modules/document/services/documentService'

@Component({
  components: { ValidationProvider }
})
export default class DocumentField extends mixins(documentMixin) {
  localBlob : Blob | null = null
  imageUrl: string | null = null

  @Prop(Document) document?: Document
  @Prop({ type: Boolean, default: true }) editable!: boolean
  @Prop({ type: String, required: true }) fieldId?: string
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: false }) image!: boolean
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: String, default: '100px' }) previewWidth!: string
  @Prop({ type: String, default: 'auto' }) previewHeight!: string
  @Prop({ type: Boolean, default: false }) disableDownload!: boolean

  get validation () {
    return this.required ? 'required' : ''
  }

  @Watch('document', { immediate: true })
  onDocumentChange () {
    this.preparePreview()
  }

  private async preparePreview () {
    if (this.preview && this.document && this.document.id) {
      this.localBlob = await documentService.downloadBlob('api/document/' + this.document.id)
      const urlCreator = window.URL
      this.imageUrl = urlCreator.createObjectURL(this.localBlob)
    }
  }
}
