




























































































































import BankMoneyBox from '@/modules/money/box/domain/bankMoneyBox'
import BankAccount from '@/modules/bank/account/domain/bankAccount'
import CashMoneyBox from '@/modules/money/box/domain/cashMoneyBox'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import moneyBoxTypes from '@/modules/money/box/type/moneyBoxTypes'
import { MoneyBoxType } from '@/modules/money/box/type/moneyBoxType'
import radioField from '@/modules/common/components/form/radioField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class MoneyBoxCreateModal extends mixins<createModal<CashMoneyBox | BankMoneyBox>>(createModal) {
  moneyBoxType?: MoneyBoxType | null = null
  moduleName = 'cashMoneyBox'
  doFetchAfterDefault = false
  moneyBoxTypes = moneyBoxTypes

  @State('owned', { namespace: 'company' }) company? : Company
  @Getter('active', { namespace: 'companyBranch' }) companyBranches? : Array<CompanyBranch>
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>

  get bankType () {
    return !!(this.item! as any).bankAccount
  }

  @Watch('moneyBoxType')
  onMoneyBoxTypeChanged (moneyBoxType: MoneyBoxType) {
    if (!this.isEdit()) {
      const data: any = {
        name: this.item!.name,
        currency: this.item!.currency,
        companyBranch: this.item!.companyBranch
      }
      if (moneyBoxType === MoneyBoxType.CASH) {
        this.item = new CashMoneyBox(data)
      } else {
        data.bankAccount = new BankAccount({})
        this.item = new BankMoneyBox(data)
      }
    }
  }

  @Watch('company')
  onCompanyChanged (company: Company) {
    if (company) {
      this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
    }
  }

  @Watch('item')
  onItemChanged () {
    if (this.bankType) {
      this.moneyBoxType = MoneyBoxType.BANK
    } else {
      this.moneyBoxType = MoneyBoxType.CASH
    }
  }

  onIbanPaste (event: any) {
    (this.item as BankMoneyBox)!.bankAccount!.iban = (event.clipboardData.getData('text') || '').replace(/\s/g, '')
    event.preventDefault()
  }

  beforeSave () {
    // set currency in backAccount same as in money box
    if (this.bankType) {
      (this.item as BankMoneyBox)!.bankAccount!.currency = this.item!.currency
      this.moduleName = 'bankMoneyBox'
    } else {
      this.moduleName = 'cashMoneyBox'
    }
  }

  afterSuccessSave () {
    this.$store.dispatch('moneyBox/getAll', new EntityFetchParams(true))
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('company/getOwned')
  }
}
