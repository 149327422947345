




































import _ from 'lodash'
import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import currencies from '@/modules/common/values/currencies'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import Currency from '@/modules/currency/domain/currency'

@Component({
  components: { formModal, checkboxField, selectField, textField }
})
export default class CodeListCreateModalGeneric extends mixins<createModal<Currency>>(createModal) {
  currencies = currencies
  moduleName = 'currency'

  beforeSave () {
    this.item!.label = _.find(currencies, { value: this.item!.code })!.description!
  }
}
