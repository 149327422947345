import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import { RecordType } from '@/modules/record/type/RecordType'
import Contact from '@/modules/contact/domain/contact'
import User from '@/modules/user/domain/user'

abstract class Record extends Entity {
  date?: moment.Moment | null
  type?: RecordType
  contact: Contact | null
  note: string | null = null
  createdBy: User | null
  '@type': string

  protected constructor (data: any) {
    super(data)
    this.date = this.convertDate(data.date) || moment()
    this.contact = data.contact ? new Contact(data.contact) : null
    this.createdBy = data.createdBy ? new User(data.createdBy) : null
    this.note = data.note || null
  }

  extendSearchString () {
    this.searchString = this.note || ''
    if (this.contact) {
      this.searchString += ' ' + this.contact.searchString
    }
    if (this.createdBy) {
      this.searchString += ' ' + this.createdBy.searchString
    }
  }
}

export default Record
