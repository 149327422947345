

























































import Address from '@/modules/common/domain/address'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import { CompanyRole } from '@/modules/company/type/companyRole'
import Component from 'vue-class-component'
import Currency from '@/modules/currency/domain/currency'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { page, box, companyCreateModal, loading }
})
export default class CompanyOwnedDetail extends Vue {
  selectedItem?: Company | null = null
  showCreateModal: boolean = false
  @State('owned', { namespace: 'company' }) company? : Company
  @State('items', { namespace: 'currency' }) currencies? : Currency[]

  createShow () {
    this.selectedItem = new Company({ role: CompanyRole.OWNED, address: new Address(), active: true })
    this.showCreateModal = true
  }

  updateShow () {
    this.selectedItem = this.company
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('company/getOwned')
  }
}
