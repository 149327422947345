







import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import workersTable from '@/modules/worker/components/workersTable.vue'
import workerForm from '@/modules/worker/components/workerForm.vue'
import Worker from '@/modules/worker/domain/worker'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'

@Component({
  components: { workerForm, page, workersTable }
})
export default class WorkerEditPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'setting.worker.label', routeName: 'workers' }, { textKey: 'worker.new.label' }])

  worker?: Worker | null = null

  async mounted () {
    await this.$store.dispatch('workingActivity/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('company/getOwned')
    this.worker = new Worker({ active: true })
  }
}
