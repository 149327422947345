












import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import Vue from 'vue'

@Component
export default class StockMovementSummaryBox extends Vue {
  @Prop({ type: StockMovement, required: true }) movement!: StockMovement
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String, required: true }) totalPriceLabel!: string
}
