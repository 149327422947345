import workRecordsPersonalPage from '@/modules/work/record/components/workRecordsPersonalPage.vue'
import workRecordsActivitySummaryPage from '@/modules/work/record/components/workRecordsActivitySummaryPage.vue'
import workRecordsWorkerSummaryPage from '@/modules/work/record/components/workRecordsWorkerSummaryPage.vue'
import workRecordsPage from '@/modules/work/record/components/workRecordsPage.vue'
import workRecordsWorkerPage from '@/modules/work/record/components/workRecordsWorkerPage.vue'

export default [
  {
    path: '/work-record/personal',
    component: workRecordsPersonalPage,
    name: 'workRecordsPersonal',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'WORK_RECORD_PERSONAL',
      pageGroup: 'workRecord'
    }
  },
  {
    path: '/work-record/summary/activity',
    component: workRecordsActivitySummaryPage,
    name: 'workRecordsActivitySummary',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'WORK_RECORD_MANAGEMENT',
      pageGroup: 'workRecord'
    }
  },
  {
    path: '/work-record/summary/worker',
    component: workRecordsWorkerSummaryPage,
    name: 'workRecordsWorkerSummary',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'WORK_RECORD_MANAGEMENT',
      pageGroup: 'workRecord'
    }
  },
  {
    path: '/work-record/summary/all',
    component: workRecordsPage,
    name: 'workRecords',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'WORK_RECORD_MANAGEMENT',
      pageGroup: 'workRecord'
    }
  },
  {
    path: '/work-record/summary/worker/:workerId',
    component: workRecordsWorkerPage,
    name: 'workerRecordsWorker',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'WORK_RECORD_MANAGEMENT',
      pageGroup: 'workRecord'
    }
  }
]
