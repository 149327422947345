









































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter, State } from 'vuex-class'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import Item from '@/modules/item/domain/Item'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import staticField from '@/modules/common/components/form/staticField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

@Component({
  components: { dateTimeField, numberField, selectField, staticField, textField, vForm }
})
export default class IncomeStockMovementItemForm extends mixins(validationMixin, confirmMixin) {
  @Prop({ type: IncomeStockMovement, required: true }) movement!: IncomeStockMovement
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: StockMovementItem, required: true }) movementItem!: StockMovementItem
  @Prop({ type: Boolean, required: true, default: false }) readonly!: boolean

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]
  @Getter('active', { namespace: 'itemBatch' }) itemBatches?: ItemBatch[]
  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]
  @Getter('activeForStock', { namespace: 'item' }) items!: Item[]

  @Watch('movementItem.item')
  onItemChanged (newItem?: Item, oldItem?: Item) {
    if ((oldItem && !newItem) || (oldItem && newItem && oldItem.id !== newItem.id)) {
      this.movementItem.itemBatches = []
    }
  }

  get availableItems () {
    const usedItemIds = _.map(this.movement!.items, 'item.id')
    const availableItemIds = _(this.stockItems!).filter('active').map('item.id').value()
    return _.filter(this.items, item => (usedItemIds.indexOf(item.id) < 0) && (availableItemIds.indexOf(item.id) >= 0))
  }

  availableBatches (itemId: number) {
    return _.filter(this.itemBatches, itemBatch => itemBatch.item!.id === itemId)
  }

  deleteItem (item: StockMovementItem) {
    const items = this.movement!.items
    this.movement!.items = []
    // workaround - watcher for movementItem and clearing itemBatches array runs also in case,
    // when movementItem with lower index is deleted
    this.$nextTick(() => {
      this.movement!.items = _.without(items, item)
    })
  }

  /**
   * Validation object need to be created dynamic, based on scale settings of "Item" object.
   * @param item selected for OrderItem
   */
  validationObject (item?: Item) {
    const validationObj = { required: true, max_value: 9999999999 } as any
    if (!item) {
      return validationObj
    } else {
      if (item.scale === 0) {
        validationObj.numeric = true
        validationObj.min_value = 1
      } else {
        validationObj.decimal = item.scale
        validationObj.min_value = _.round(1 / (10 ** item.scale!), item.scale)
      }
    }
    return validationObj
  }
}
