







import _ from 'lodash'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter } from 'vuex-class'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryForm from '@/modules/inventory/components/inventoryForm.vue'
import inventoryService from '@/modules/inventory/services/inventoryService'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Stock from '@/modules/stock/domain/stock'
import Vue from 'vue'
import StockItem from '@/modules/stock/item/domain/stockItem'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

@Component({
  components: { inventoryForm, loading, page }
})
export default class InventoryCreatePage extends Vue {
  inventory?: Inventory | null = null

  @Prop({ type: String, required: true }) stockId!: string

  @Getter('active', { namespace: 'stock' }) stocks!: Stock[]
  @Getter('active', { namespace: 'stockItem' }) stockItems!: Array<StockItem>
  @Getter('active', { namespace: 'itemBatch' }) itemBatches?: ItemBatch[]

  async created () {
    await this.$store.dispatch('stock/getAll')
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.stockId }))
    await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.stockId, 'active-only': true }))
    const stock = _.find(this.stocks, { id: Number(this.stockId) })!
    this.inventory = inventoryService.newInventory(stock, this.stockItems, this.itemBatches!)
  }
}
