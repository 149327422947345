



















import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component, { mixins } from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import stockItemsFilterBox from '@/modules/stock/item/components/stockItemsFilterBox.vue'
import stockItemsTable from '@/modules/stock/item/components/stockItemsTable.vue'
import stockItemsUnderLimitTable from '@/modules/stock/item/components/stockItemsUnderLimitTable.vue'
import StockMovementsDeltaTable from '@/modules/stock/movement/components/stockMovementsDeltaTable.vue'
import tabsMixin from '@/modules/common/mixins/tabsMixin'
import FilterData from '@/modules/common/mixins/filterData'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'
import { StockItemFilter } from '@/modules/stock/item/domain/stockItemFilter'

@Component({
  components: { StockMovementsDeltaTable, page, stockItemsFilterBox, stockItemsTable, stockItemsUnderLimitTable }
})
export default class StockItemsOverviewPage extends mixins(tabsMixin) {
  pageDataId = 'stockItemsOverview'
  breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'common.overview' }])
  clearFilterOnTabChange = true
  filterReady = false
  // need to define all filter properties to null -> vue specific issue
  filter = {
    ...(new StockMovementFilter()),
    ...(new StockItemFilter())
  }

  afterSetup () {
    this.filterReady = true
  }

  async onFilterChanged (filter: FilterData) {
    _.assign(this.filter, filter)
  }
}
