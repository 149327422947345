import _ from 'lodash'
import ColorableCodeList from '@/modules/common/domain/colorableCodeList'
import { Entity } from '@/modules/common/domain/entity'
import { ItemType } from '@/modules/item/type/itemType'
import CodeList from '@/modules/common/domain/codeList'
import { ItemOriginType } from '@/modules/item/type/itemOriginType'
import i18n from '@/i18n'
import itemTypes from '@/modules/item/type/itemTypes'

export default class Item extends Entity {
  type?: ItemType
  name?: string | null
  description?: string | null
  code?: string | null
  unit?: CodeList
  category?: ColorableCodeList
  scale?: number
  origin?: ItemOriginType
  active?: boolean | null
  forStock?: boolean | null
  forPurchase?: boolean | null
  batchEvidence?: boolean | null

  constructor (data?: any) {
    super(data, 'id', 'name')

    this.extendSearchString()
  }

  get recipeEnabled () {
    return this.forStock && this.origin === ItemOriginType.PRODUCTION
  }

  private extendSearchString () {
    this.searchString = this.name || ''

    if (this.type) {
      this.searchString += i18n.message(_.find(itemTypes, { value: this.type })!.label)
    }

    if (this.category) {
      this.searchString += ' ' + this.category.label
    }
    if (this.code) {
      this.searchString += ' ' + this.code
    }

    // concat string without whitespaces
    this.searchString += this.searchString.replace(/\s/g, '')
  }
}
