import _ from 'lodash'
import { normalize } from '@/utils'

import { createGetters } from '@/modules/common/store/entityGetters'
import EntityState from '@/modules/common/store/entityState'
import FilterData from '@/modules/common/mixins/filterData'
import { GetterTree } from 'vuex'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import RootState from '@/store/rootState'
import SortData from '@/modules/common/mixins/sortData'

const getters: GetterTree<EntityState<MoneyMovement>, RootState> = createGetters()

getters.byFilter = state => (filter: FilterData, sortData: SortData) => {
  let allMoneyMovement = state.items
  if (filter && filter.query) {
    const regexp = new RegExp(normalize(filter.query), 'g')
    allMoneyMovement = allMoneyMovement.filter(moneyMovement => {
      return !!normalize(moneyMovement.searchString).match(regexp)
    })
  }
  // for date ordering consider transactionId as second order parameter
  const sortBy = sortData.by === 'date' ? [sortData.by, 'bankTransaction.transactionId'] : [sortData.by]
  const sorted = _.sortBy(allMoneyMovement, sortBy)
  return sortData.asc ? sorted : sorted.reverse()
}

export default getters
