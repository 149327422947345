







































































































import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop } from 'vue-property-decorator'
import PriceList from '@/modules/pricelist/domain/priceList'
import PriceListItem from '@/modules/pricelist/domain/priceListItem'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'

@Component({
  components: { dateTimeField, formModal, numberField, selectField, staticField }
})
export default class PriceListCreateModal extends mixins<createModal<PriceList>>(createModal) {
  moduleName = 'priceList'

  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('activePartners', { namespace: 'company' }) partners?: Company[]
  @Getter('validItems', { namespace: 'currency' }) currencies!: Currency[]
  @Getter('activeForPurchase', { namespace: 'item' }) itemList!: Item[]

  @Prop({ type: String, required: false, default: '' }) editCurrency?: string
  @Prop({ type: Boolean, default: false }) companyEditable!: boolean
  @Prop({ type: Boolean, default: true }) itemEditable!: boolean

  get vatRates () {
    const list = this.item!.validFrom
      ? this.$store.getters['vatRate/validItems'](this.item!.validFrom, this.company!.address!.country)
      : []
    return _.unionBy(list, 'rate')
  }

  validate () {
    const usedCurrencyCodes = _(this.item!.items).map('currency').uniq().value()
    if (usedCurrencyCodes.length < this.item!.items!.length) {
      notificationService.error('error.priceList.currency.duplicate')
      return false
    }
    return true
  }

  deletePriceListItem (item: PriceListItem) {
    this.item!.items = _.without(this.item!.items, item)
  }

  addItem () {
    this.item!.items = [...this.item!.items, new PriceListItem({})]
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('vatRate/getAll')
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('company/getAllPartners')
  }
}
