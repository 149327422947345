import Subject from '@/modules/subject/domain/subject'
import Record from '@/modules/record/domain/record'
import subjectService from '@/modules/subject/services/subjectService'

class SubjectRecord extends Record {
  subject?: Subject | null

  constructor (data: any) {
    super(data)
    this.subject = this.subject ? subjectService.getSubject(this.subject) : null

    this.extendSearchString2nd()
  }

  extendSearchString2nd () {
    super.extendSearchString()

    if (this.subject) {
      this.searchString += ' ' + this.subject.searchString
    }
  }
}

export default SubjectRecord
