import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { InvoiceSeriesFormat } from '@/modules/invoice/type/InvoiceSeriesFormat'

class InvoiceSeries extends Entity {
  name?: string
  format?: InvoiceSeriesFormat
  prefix?: string
  year?: bigint
  index?: bigint
  active?: boolean
  defaultFlag?: boolean

  constructor (data: any) {
    super(data)

    this.value = this.id
    this.label = this.formatName()
  }

  formatName () {
    if (this.format === InvoiceSeriesFormat.PREFIX_NNNN_YYYY) {
      return `${this.prefix}XXXX${this.year}`
    } else if (this.format === InvoiceSeriesFormat.PREFIX_YYYY_NNNN) {
      return `${this.prefix}${this.year}XXXX`
    } else {
      return ''
    }
  }

  nextVariableSymbol () {
    if (_.isNil(this.prefix) || _.isNil(this.year) || _.isNil(this.index) || _.isNil(this.format)) {
      return null
    }
    let prefixNumbers = ''
    const result = this.prefix.match(/\d+/g)
    if (result && result.length) {
      prefixNumbers = result[0].slice(-2)
    }
    const indexNumbers = _.padStart(this.index + '', 4, '0').slice(-4)
    const yearNumbers = _.padStart(this.year + '', 4, '0').slice(-4)

    if (this.format === InvoiceSeriesFormat.PREFIX_NNNN_YYYY) {
      return prefixNumbers + indexNumbers + yearNumbers
    } else if (this.format === InvoiceSeriesFormat.PREFIX_YYYY_NNNN) {
      return prefixNumbers + yearNumbers + indexNumbers
    } else {
      throw Error('invalid.invoice-series.format')
    }
  }

  nextInvoiceNumber () {
    if (_.isNil(this.prefix) || _.isNil(this.year) || _.isNil(this.index) || _.isNil(this.format)) {
      return null
    }
    const indexNumbers = _.padStart(this.index + '', 4, '0').slice(-4)
    const yearNumbers = _.padStart(this.year + '', 4, '0').slice(-4)

    if (this.format === InvoiceSeriesFormat.PREFIX_NNNN_YYYY) {
      return (this.prefix || '') + indexNumbers + yearNumbers
    } else if (this.format === InvoiceSeriesFormat.PREFIX_YYYY_NNNN) {
      return (this.prefix || '') + yearNumbers + indexNumbers
    } else {
      throw Error('invalid.invoice-series.format')
    }
  }
}

export default InvoiceSeries
