import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import Takings from '@/modules/sale/domain/takings'
import takingsAction from './takingsActions'

const takingsModule: Module<EntityState<Takings>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: takingsAction
}

export default takingsModule
