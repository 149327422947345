import { Entity } from '@/modules/common/domain/entity'
import CompanyBranch from '@/modules/company/domain/companyBranch'

class Stock extends Entity {
  name?: string
  currency?: string
  companyBranch: CompanyBranch | null
  active?: boolean
  defaultForDispatch?: boolean

  constructor (data: any) {
    super(data, 'id', 'name')

    this.companyBranch = data.companyBranch == null ? null : new CompanyBranch(data.companyBranch)
  }
}

export default Stock
