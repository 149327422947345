import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import _ from 'lodash'

class StockMovementItem extends Entity {
  item?: Item | null
  amount?: number | null
  price?: number | null
  itemBatches: string[] = []

  constructor (data: any) {
    super(data)
    this.item = data.item ? new Item(data.item) : null
    this.itemBatches = data.itemBatches ? [...data.itemBatches] : []
  }

  get totalPrice () {
    return _.round((this.price || 0) * (this.amount || 0), 2)
  }
}

export default StockMovementItem
