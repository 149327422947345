import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from '@/modules/common/store/entityGetters'
import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import stockMovementService from '@/modules/stock/movement/services/stockMovementService'

const stockModule: Module<EntityState<StockMovement>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(stockMovementService, 'stock-movement')
}

export default stockModule
