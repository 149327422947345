import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'money-box'

const customActions = {
  balances: { method: 'GET', url: API_ROOT + contextPath + '/balances' },
  updatePosition: { method: 'PUT', url: API_ROOT + contextPath + '/{id}/position/{position}' }
}

export default (<any>Vue).resource(API_ROOT + contextPath + '{/id}', {}, customActions)
