import { Moment } from 'moment'
import { createGetters } from '@/modules/common/store/entityGetters'
import { GetterTree } from 'vuex'
import EntityState from '@/modules/common/store/entityState'
import RootState from '@/store/rootState'
import VatRate from '@/modules/vatRate/domain/vatRate'

const getters: GetterTree<EntityState<VatRate>, RootState> = createGetters()

getters.validItems = (state) => (date: Moment, country: string) => {
  const validItems = state.items.filter(item => item.validInDate(date))
  return country ? validItems.filter(item => item.country === country) : validItems
}

export default getters
