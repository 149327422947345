





































































import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import Item from '@/modules/item/domain/Item'
import itemStockCreateModal from '@/modules/stock/item/components/itemStockCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import StockItem from '@/modules/stock/item/domain/stockItem'

@Component({
  components: { booleanIcon, itemStockCreateModal, loading, noRecords }
})
export default class ItemStockItemsTable extends mixins<entityTableMixin<StockItem>>(entityTableMixin) {
  entityModuleName = 'stockItem'

  @Prop({ type: Number, required: true }) itemId!: number

  @State('selected', { namespace: 'item' }) item!: Item

  createHide () {
    this.showCreateModal = false
    this.$emit('data-changed')
  }

  async beforeFetch () {
    await this.$store.dispatch('stockItem/clearAll')
  }

  async created () {
    this.fetchActionParams = new EntityFetchParams(true, { 'item-id': this.itemId })
    this.newItemTemplateDefault = () => new StockItem({ item: this.item, minAmount: 0 })
  }
}
