












































import box from '@/modules/common/components/box.vue'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import Currency from '@/modules/currency/domain/currency'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { box, dateRangeField, dateTimeField, loading, selectField, textField }
})
export default class OrdersOverviewFilterBox extends Vue {
  pageDataId = 'ordersOverview'
  @Prop({ type: OrderFilter, required: true }) filter!: OrderFilter
  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>
  @State('items', { namespace: 'currency' }) currencies?: Currency[]

  fetch () {
    this.$emit('fetch')
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
  }
}
