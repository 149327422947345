




















































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import Invoice from '@/modules/invoice/domain/invoice'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import loading from '@/modules/common/components/loading.vue'
import { paymentMethods } from '@/modules/common/values'
import { State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import printMixin from '@/modules/common/mixins/printMixin'

@Component({
  components: { loading, box }
})
export default class InvoiceDetailInfoBox extends mixins(submitProtectionMixin, printMixin) {
  paymentMethods = paymentMethods

  @State('selected', { namespace: 'invoice' }) invoice!: Invoice

  get outcome () {
    return this.invoice.type === InvoiceType.OUTCOME
  }

  edit () {
    this.$router.push({ name: this.invoice.type === InvoiceType.OUTCOME ? 'invoiceOutcomeEdit' : 'invoiceIncomeEdit', params: { id: this.invoice.id + '' } })
  }
}
