


































































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import Worker from '@/modules/worker/domain/worker'
import { Prop, Watch } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'
import WorkerActivity from '@/modules/worker/domain/workerActrivity'
import Company from '@/modules/company/domain/company'

@Component({
  components: { checkboxField, numberField, textField, vForm }
})
export default class WorkerForm extends mixins(validationMixin) {
  workerActivities: number[] = []

  @Prop({ type: Worker, required: true }) worker!: Worker
  @Getter('active', { namespace: 'workingActivity' }) workingActivities!: WorkingActivity[]
  @State('owned', { namespace: 'company' }) company? : Company

  onSubmit () {
    const operation = 'worker/' + (this.worker.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.worker)).then((result: Worker) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'workers' })
      }
    }).finally(this.unprotect)
  }

  @Watch('worker')
  onWorkerChanged () {
    this.extractWorkerActivities()
  }

  private extractWorkerActivities () {
    if (this.worker) {
      this.workerActivities = _(this.worker.activities).filter('active').map(workerActivity => workerActivity.activity!.id!).value()
    }
  }

  async toggleActivity (activity: WorkingActivity) {
    const workerActivity = _.find(this.worker.activities, workerActivity => workerActivity.activity!.id === activity.id)
    if (workerActivity && workerActivity.id) {
      workerActivity.active = !workerActivity.active
    } else if (workerActivity) {
      this.worker.activities = _.filter(this.worker.activities, workerActivity => workerActivity.activity!.id !== activity.id)
    } else {
      this.worker.activities.push(new WorkerActivity({ worker: { id: this.worker.id }, activity: { id: activity.id }, price: 0, active: true }))
    }
    this.extractWorkerActivities()
  }

  workerActivity (activity: WorkingActivity) {
    return _.find(this.worker.activities, workerActivity => workerActivity.activity!.id === activity.id)
  }

  hasActivity (activity: WorkingActivity) {
    return this.workerActivities.lastIndexOf(activity.id!) >= 0
  }

  mounted () {
    this.extractWorkerActivities()
  }
}
