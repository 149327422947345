import moneyBoxPage from '@/modules/money/box/components/moneyBoxPage.vue'

export default [
  {
    name: 'moneyBox',
    path: '/money-box',
    component: moneyBoxPage,
    meta: {
      requiresLoggedIn: true,
      pageGroup: 'finance'
    }
  }]
