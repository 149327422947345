




















import Vue from 'vue'
import truncate from '@/modules/common/components/truncate.vue'
import Component from 'vue-class-component'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { truncate }
})
export default class MoneyMovementDescription extends Vue {
  @Prop({ type: MoneyMovement, required: false }) movement?: MoneyMovement
  @Prop({ type: Boolean, required: false, default: true }) showIcon?: boolean
}
