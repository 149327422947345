
























import Component from 'vue-class-component'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class OutcomeStockMovementTransferBox extends Vue {
  @Prop({ type: OutcomeStockMovement, required: true }) movement!: OutcomeStockMovement
}
