










































import _ from 'lodash'
import Component from 'vue-class-component'
import CodeList from '@/modules/common/domain/codeList'
import i18n from '@/i18n'
import { Prop, Watch } from 'vue-property-decorator'
import Order from '@/modules/order/domain/order'
import { State } from 'vuex-class'
import ordersDeliveryPrintDetailCompactTable from '@/modules/order/components/print/ordersDeliveryPrintDetailCompactTable.vue'
import ordersDeliveryPrintDetailTable from '@/modules/order/components/print/ordersDeliveryPrintDetailTable.vue'
import ordersDeliveryPrintItemSummaryTable from '@/modules/order/components/print/ordersDeliveryPrintItemSummaryTable.vue'
import ordersDeliveryPrintPaymentSummaryTable from '@/modules/order/components/print/ordersDeliveryPrintPaymentSummaryTable.vue'
import Vue from 'vue'
import DeliveryRoute from '@/modules/delivery/route/domain/deliveryRoute'

@Component({
  components: { ordersDeliveryPrintDetailCompactTable, ordersDeliveryPrintDetailTable, ordersDeliveryPrintItemSummaryTable, ordersDeliveryPrintPaymentSummaryTable }
})
export default class OrdersDeliveryPrintTable extends Vue {
  @Prop({ type: Boolean, default: true }) compact!: Boolean
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Array<Order>
  @State('items', { namespace: 'region' }) regions!: Array<CodeList>
  @State('items', { namespace: 'deliveryRoute' }) deliveryRoutes!: Array<DeliveryRoute>

  readonly MISSING_REGION_ID = '0'
  ordersByRegion: any | null = null
  routesByCompany: any | null = null
  routesByCompanyBranch: any | null = null
  initialized = false

  @Watch('orders')
  onOrdersChanged () {
    this.collectOrders()
  }

  get filteredRegions () {
    if (this.ordersByRegion) {
      const usedRegions = _.filter(this.regions, region => this.ordersByRegion[region.id!])
      if (this.ordersByRegion[this.MISSING_REGION_ID]) {
        usedRegions.push(new CodeList({ id: this.MISSING_REGION_ID, label: i18n.message('region.unknown') }))
      }
      return usedRegions
    } else {
      return []
    }
  }

  get date () {
    return this.orders.length ? this.orders[0].date : null
  }

  get companyBranch () {
    return this.orders.length ? this.orders[0].supplierBranch : null
  }

  collectOrders () {
    if (!this.initialized) return
    this.ordersByRegion = _(this.orders)
      // order by delivery route - can be done globally because one order is for all delivery router records
      .sortBy((order) => {
        if (order.subscriberBranch) {
          return this.routesByCompanyBranch[order.subscriberBranch.id!] || Number.MAX_VALUE
        } else {
          return this.routesByCompany[order.subscriber!.id!] || Number.MAX_VALUE
        }
      })
      .groupBy(order => order.subscriberAddress!.region ? order.subscriberAddress!.region.id : this.MISSING_REGION_ID)
      .value()
  }

  async mounted () {
    await this.$store.dispatch('region/getAll')
    await this.$store.dispatch('deliveryRoute/getAll')

    this.routesByCompany = _.chain(this.deliveryRoutes)
      .filter(deliveryRoute => _.isNil(deliveryRoute.companyBranch))
      .map(deliveryRoute => ({ [deliveryRoute.company!.id!]: deliveryRoute.order }))
      .reduce(_.extend)
      .value() || {}
    this.routesByCompanyBranch = _.chain(this.deliveryRoutes)
      .filter(deliveryRoute => !_.isNil(deliveryRoute.companyBranch))
      .map(deliveryRoute => ({ [deliveryRoute.companyBranch!.id!]: deliveryRoute.order }))
      .reduce(_.extend)
      .value() || {}

    this.initialized = true
    this.collectOrders()
  }
}
