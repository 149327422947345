import MoneyMovement from './moneyMovement'
import _ from 'lodash'

class CashMoneyMovement extends MoneyMovement {
  amountCash?: number | null = null
  amountCheque?: number | null = null

  constructor (data: any) {
    super(data)

    this.amountCash = _.isNil(data.amountCash) ? null : data.amountCash
    this.amountCheque = _.isNil(data.amountCheque) ? null : data.amountCheque

    if (!this['@type']) {
      this['@type'] = 'cash'
    }
  }

  calculateVat (vatRate: number) {
    const amount = (this.amountCash || 0) + (this.amountCheque || 0)
    this.totalVat = amount && vatRate ? _.round((amount * (vatRate / (100 + vatRate))), 2) : 0
  }

  setTotalAmount (totalAmount: number) {
    this.totalAmount = totalAmount
    this.amountCash = totalAmount
    this.amountCheque = null
  }
}

export default CashMoneyMovement
