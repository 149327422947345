import _ from 'lodash'
import Item from '@/modules/item/domain/Item'
import vatCalculator from '@/modules/finance/services/vatCalculator'

class SummaryItem {
  itemId: number
  item: Item
  price: number
  vatRate: number
  delivered: number

  constructor (itemId: number, item: Item, price: number, vatRate: number, delivered: number) {
    this.itemId = itemId
    this.item = item
    this.price = price
    this.vatRate = vatRate
    this.delivered = delivered
  }

  get totalPrice () {
    return vatCalculator.calculateTotalWithVat(this.price, this.delivered, this.vatRate)
  }

  get totalWithoutVat () {
    return vatCalculator.calculateTotalWithoutVat(this.price, this.delivered, this.vatRate)
  }

  get totalVat () {
    return vatCalculator.calculateVat(this.price, this.delivered, this.vatRate)
  }
}

export default SummaryItem
