import CodeList from '@/modules/common/domain/codeList'

class VatRate extends CodeList {
  rate?: number
  country?: string

  constructor (data: any) {
    super(data)
    this.value = data.rate
  }
}

export default VatRate
