import _ from 'lodash'
import accountingTypes from '@/modules/money/movement/type/accountingTypes'
import { Entity } from '@/modules/common/domain/entity'
import i18n from '@/i18n'
import Invoice from '@/modules/invoice/domain/invoice'
import { Moment } from 'moment'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import subjectService from '@/modules/subject/services/subjectService'
import Subject from '@/modules/subject/domain/subject'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import { AccountingType } from '@/modules/money/movement/type/accountingType'
import AuditableEntity from '@/modules/common/domain/auditableEntity'
import User from '@/modules/user/domain/user'

class MoneyMovement extends Entity implements AuditableEntity {
  moneyBox?: MoneyBox | null
  category?: MoneyMovementCategory | null
  invoice?: Invoice | null
  subject?: Subject | null
  type?: MoneyMovementType
  accountingType?: AccountingType
  date?: Moment | null
  taxableDate?: Moment | null
  totalAmount?: number | null
  totalVat?: number | null
  totalWithoutVat?: number
  note?: number
  active?: boolean
  createdBy?: User | null
  updatedBy?: User | null
  '@type': string

  constructor (data: any) {
    super(data, 'id', 'id', ['note'])

    this.totalVat = _.isNil(data.totalVat) ? null : data.totalVat

    this.moneyBox = data.moneyBox == null ? null : new MoneyBox(data.moneyBox)
    this.category = data.category == null ? null : new MoneyMovementCategory(data.category)
    this.invoice = data.invoice == null ? null : new Invoice(data.invoice)
    this.subject = this.subject ? subjectService.getSubject(this.subject) : null
    this.date = this.convertDate(data.date)
    this.taxableDate = data.taxableDate == null ? null : this.convertDate(data.taxableDate)
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)

    this.extendSearchString()
  }

  get fiscal () {
    return this.accountingType === AccountingType.FISCAL
  }

  setTotalAmount (totalAmount: number) {
    this.totalAmount = totalAmount
  }

  calculateVat (vatRate: number) {
    this.totalVat = this.totalAmount && vatRate ? _.round((this.totalAmount * (vatRate / (100 + vatRate))), 2) : 0
  }

  private extendSearchString () {
    this.searchString += this.accountingType ? i18n.message(_.find(accountingTypes, { value: this.accountingType })!.label) : ''

    if (this.subject) {
      this.searchString = this.searchString + this.subject.searchString!
    }
    if (this.category) {
      this.searchString = this.searchString + this.category.label
    }
  }
}

export default MoneyMovement
