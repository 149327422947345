



















































import Company from '@/modules/company/domain/company'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { companyCreateModal, loading }
})
export default class PartnerInfo extends Vue {
  showCreateModal: boolean = false
  selectedPartner?: Company | null = null

  @State('selected', { namespace: 'company' }) partner!: Company

  updateShow () {
    this.selectedPartner = this.partner
    this.showCreateModal = true
  }

  createHide () {
    this.selectedPartner = null
    this.showCreateModal = false
  }
}
