




















































































import codeListModal from '@/modules/common/components/codeListCreateModalGeneric.vue'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '../mixins/entityTableMixin'
import loading from './loading.vue'
import noRecords from './noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { loading, noRecords, codeListModal }
})
export default class CodeListTable extends mixins(entityTableMixin) {
  itemsActiveGetter = 'validItems'
  fetchActionParams = new EntityFetchParams(true)
  entityModuleName?: string

  @Prop({ type: String, required: true }) moduleName!: string
  @Prop({ type: String, required: true }) loadingId?: string
  @Prop({ type: Boolean, default: false }) colorable?: boolean
  @Prop({ type: Number, default: 2 }) labelMinLength!: boolean

  @Watch('moduleName', { immediate: true })
  async onModuleNameChanged (moduleName: string) {
    this.entityModuleName = moduleName
    await this.$store.dispatch(this.entityModuleName + '/clearAll')
  }
}
