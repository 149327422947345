import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from './moneyMovementGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import cashMoneyMovementResource from '@/modules/money/movement/services/cashMoneyMovementResource'
import CashMoneyMovement from '@/modules/money/movement/domain/cashMoneyMovement'

const entityService = new EntityService<CashMoneyMovement>(cashMoneyMovementResource, CashMoneyMovement)
const moneyBoxModule: Module<EntityState<CashMoneyMovement>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'cash-money-movement')
}

export default moneyBoxModule
