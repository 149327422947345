































import box from '@/modules/common/components/box.vue'
import CodeList from '@/modules/common/domain/codeList'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Watch } from 'vue-property-decorator'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import DateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import { OrderStatementFilter } from '@/modules/order/statement/domain/orderStatementFilter'
import Range from '@/modules/common/components/form/range'

@Component({
  components: { DateRangeField, box, dateTimeField, loading, selectField, textField }
})
export default class OrderStatementsFilterBox extends mixins(bookmarkableComponent) {
  filter = new OrderStatementFilter()
  @Getter('validItems', { namespace: 'paymentType' }) paymentTypes!: Array<CodeList>

  @Watch('filter', { deep: true })
  onValueChange () {
    this.$emit('filter-changed', this.filter)
  }

  async defaultFilter () {
    this.filter.range = new Range(moment().startOf('month'))
    this.filter.paymentTypeId = null
    this.filter.query = null
  }

  async initData () {
    await this.$store.dispatch('paymentType/getAll')
  }
}
