















































































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import companyBranchCreateModal from '@/modules/company/components/companyBranchCreateModal.vue'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Address from '@/modules/common/domain/address'
import { Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: { companyBranchCreateModal, loading, noRecords, box }
})
export default class CompanyOwnedBranches extends mixins(entityTableMixin) {
  entityModuleName = 'companyBranch'
  doFetch = false
  addDisabled = true

  @State('owned', { namespace: 'company' }) company? : Company

  async fetch () {
    // do nothing, fetch is doing in watcher
  }

  @Watch('company', { immediate: true })
  onCompanyChanged (company: Company) {
    if (company) {
      this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
      this.addDisabled = false
    }
  }

  created () {
    this.newItemTemplateDefault = () => new CompanyBranch({ company: this.company, address: new Address(), active: true })
  }
}
