







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import invoicesTable from '@/modules/invoice/components/invoicesTable.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { invoicesTable, page }
})
export default class InvoicesPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'common.purchase' }, { textKey: 'invoices.income.label' }])
}
