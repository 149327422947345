







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import invoiceConfigInfoBox from '@/modules/configuration/components/invoiceConfigInfoBox.vue'
import invoiceSeriesTable from '@/modules/invoice/components/invoiceSeriesTable.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { invoiceConfigInfoBox, invoiceSeriesTable, page }
})
export default class InvoiceSettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'invoicing.label' }])
}
