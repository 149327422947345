






































































import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import Currency from '@/modules/currency/domain/currency'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import ValueOrderData from '@/modules/common/mixins/valueOrderData'
import PriceList from '@/modules/pricelist/domain/priceList'

@Component({
  components: { dateTimeField, formModal, numberField, selectField, staticField }
})
export default class PriceListCreateModal extends mixins<createModal<PriceList>>(createModal) {
  moduleName = 'priceList'

  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>
  @Getter('valueOrder', { namespace: 'currency' }) currencyValueOrder!: ValueOrderData

  get vatRates () {
    const list = this.item!.validFrom
      ? this.$store.getters['vatRate/validItems'](this.item!.validFrom, this.company!.address!.country)
      : []
    return _.unionBy(list, 'rate')
  }

  // order by currency order
  get priceListItems () {
    if (this.currencyValueOrder && this.item!.items) {
      return _.sortBy(this.item!.items, priceListItem => this.currencyValueOrder![priceListItem.currency!]) || Number.MAX_VALUE
    } else {
      return []
    }
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('vatRate/getAll')
    await this.$store.dispatch('company/getOwned')
  }
}
