import MoneyBox from '@/modules/money/box/domain/moneyBox'

class CashMoneyBox extends MoneyBox {
  amountCash?: number
  amountCheque?: number

  constructor (data: any) {
    super(data)

    this['@type'] = 'cash'
    this._icon = 'fas fa-money-bill-alt'
  }
}

export default CashMoneyBox
