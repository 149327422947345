






































































import _ from 'lodash'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component, { mixins } from 'vue-class-component'
import companyPriceListCreateModal from '@/modules/company/components/companyPriceListCreateModal.vue'
import Currency from '@/modules/currency/domain/currency'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop } from 'vue-property-decorator'
import PriceList from '@/modules/pricelist/domain/priceList'
import priceListService from '@/modules/pricelist/service/priceListService'
import { Getter } from 'vuex-class'
import PriceListItem from '@/modules/pricelist/domain/priceListItem'
import { localeCompare } from '@/utils'

@Component({
  components: { booleanIcon, companyPriceListCreateModal, loading, noRecords, selectField }
})
export default class CompaniesPriceListTable extends mixins<entityTableMixin<PriceList>>(entityTableMixin) {
  entityModuleName = 'priceList'
  itemsGetter = 'individual'
  activeSupport = false
  showAll = false

  @Prop({ type: Number, required: true }) itemId!: number

  @Getter('validItems', { namespace: 'currency' }) currencyList!: Array<Currency>

  get priceListItems (): Array<any> {
    let priceLists: Array<PriceList>
    if (this.showAll) {
      priceLists = _(this.items).sort(localeCompare('company.name')).value()
    } else {
      priceLists = _(this.items)
        .groupBy('company.id')
        .values()
        .map(priceLists => _.find(priceLists, priceList => priceList.validInDate(moment()))) // sorted by date from BE, so just take valid
        .sort(localeCompare('company.name'))
        .value() as Array<PriceList>
    }
    return _.flatMap(priceLists, (priceList: PriceList) =>
      _.map(priceList.items as Array<PriceListItem>, (priceListItem: PriceListItem) => ({ priceList, priceListItem })))
  }

  async fetch () {
    // don't do fetch
  }

  created () {
    this.newItemTemplateDefault = () => priceListService.newPriceList([], this.itemId)
  }
}
