import _ from 'lodash'
import EntityService from '@/modules/common/services/entityService'
import i18n from '@/i18n'
import moment from 'moment'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementResource from '@/modules/stock/movement/services/outcomeStockMovementResource'
import Stock from '@/modules/stock/domain/stock'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import Order from '@/modules/order/domain/order'
import itemDispatchService from '@/modules/item/service/itemDispatchService'
import StockItem from '@/modules/stock/item/domain/stockItem'
import Item from '@/modules/item/domain/Item'
import ItemDispatch from '@/modules/item/domain/ItemDispatch'
import { FORMAT_DATE } from '@/utils'
import { OutcomeStockMovementType } from '@/modules/stock/movement/type/outcomeStockMovementType'
import EntityMap from '@/modules/common/domain/entityMap'

class OutcomeStockMovementService extends EntityService<OutcomeStockMovement> {
  constructor () {
    super(outcomeStockMovementResource, OutcomeStockMovement)
  }

  newOutcomeStockMovement (stock: Stock) {
    return new OutcomeStockMovement({
      stock,
      date: moment(),
      items: [new StockMovementItem({})]
    })
  }

  async newOutcomeForDeliveries (stock: Stock, stockItems: StockItem[], orders: Order[], itemsMap: EntityMap<Item>) {
    const movement = this.newOutcomeStockMovement(stock)
    movement.type = OutcomeStockMovementType.SALE
    movement.items = []
    const firstOrder = _.first(orders)
    const date = firstOrder ? firstOrder.date! : movement.date!
    movement.note = i18n.message('stock-movement.outcome.note.delivery', [FORMAT_DATE(date)])
    const itemDispatches = await itemDispatchService.findAll({}) as ItemDispatch[]
    orders.forEach(order => {
      order.items.forEach(orderItem => {
        const item = itemsMap[orderItem.item!.id!]
        const amount = order.delivered ? orderItem.delivered! : orderItem.ordered!
        if (item.forStock) {
          this.addItemToMovement(movement, item, amount, stockItems)
        } else {
          itemDispatches.forEach(itemDispatch => {
            if (itemDispatch.item!.id === item.id) {
              this.addItemToMovement(movement, itemDispatch.subItem!, itemDispatch.amount! * amount, stockItems)
            }
          })
        }
      })
    })
    return movement
  }

  /** add amount to existing movement item, or push new movement item if is necessary. **/
  private addItemToMovement (movement: OutcomeStockMovement, item: Item, amount: number, stockItems: StockItem[]) {
    const existingItem = _.find(movement.items, movementItem => movementItem.item!.id === item.id)
    if (existingItem) {
      existingItem.amount = _.round(existingItem.amount! + amount, 3)
    } else {
      const stockItem = _.find(stockItems, stockItem => stockItem.item!.id === item.id)
      if (!stockItem) {
        const error = Error('error.stock.movement.item.not.in.stock') as any
        error.item = item
        throw error
      } else if (_.isNil(stockItem.price) || stockItem.price === 0) {
        const error = Error('error.stock.movement.item.never.income') as any
        error.item = item
        throw error
      } else {
        movement.items.push(new StockMovementItem({
          item,
          amount: _.round(amount, 3),
          price: stockItem.price
        }))
      }
    }
  }
}

export default new OutcomeStockMovementService()
