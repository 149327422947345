import { Entity } from '@/modules/common/domain/entity'
import WorkerActivity from '@/modules/worker/domain/workerActrivity'

export default class WorkingActivity extends Entity {
  assignments: WorkerActivity[]
  active?: boolean | null

  constructor (data?: any) {
    super(data)
    this.assignments = data.assignments ? data.assignments.map((workerActivity: any) => new WorkerActivity(workerActivity)) : []

    this.relations('assignments')
  }
}
