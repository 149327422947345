






import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import { Getter } from 'vuex-class'
import incomeStockMovementForm from '@/modules/stock/movement/components/incomeStockMovementForm.vue'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import incomeStockMovementService from '@/modules/stock/movement/services/incomeStockMovementService'
import page from '../../../common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Stock from '@/modules/stock/domain/stock'
import Vue from 'vue'

@Component({
  components: { incomeStockMovementForm, page }
})
export default class IncomeStockMovementCreatePage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'stock-movements.label.2', routeName: 'stockMovements' }, { textKey: 'stock-movement.income.new.label' }])

  movement?: IncomeStockMovement | null = null

  @Prop({ type: String, required: true }) stockId!: string

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]

  async created () {
    await this.$store.dispatch('stock/getAll')
    const stock = _.find(this.stocks, { id: Number(this.stockId) })!
    this.movement = incomeStockMovementService.newIncomeStockMovement(stock)
  }
}
