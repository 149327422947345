































































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import incomeStockMovementTypes from '@/modules/stock/movement/type/incomeStockMovementTypes'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import outcomeStockMovementTypes from '@/modules/stock/movement/type/outcomeStockMovementTypes'
import Range from '@/modules/common/components/form/range'
import { State } from 'vuex-class'
import StockItem from '@/modules/stock/item/domain/stockItem'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import { StockItemMovementsFilter } from '@/modules/stock/item/domain/stockItemMovementsFilter'
import StockMovementDescriptionColumn from '@/modules/stock/movement/components/stockMovementDescriptionColumn.vue'

@Component({
  components: { StockMovementDescriptionColumn, dateRangeField, loading, noRecords }
})
export default class StockItemMovementsTable extends mixins(bookmarkableComponent) {
  incomeStockMovementTypes = incomeStockMovementTypes
  outcomeStockMovementTypes = outcomeStockMovementTypes
  filter = new StockItemMovementsFilter()

  @State('selected', { namespace: 'stockItem' }) stockItem!: StockItem
  @State('items', { namespace: 'stockMovement' }) movements!: StockMovement[]

  defaultFilter () {
    this.filter.range = new Range(moment().startOf('month').subtract(2, 'month'))
  }

  async initData () {
    await this.$store.dispatch('stockMovement/clearAll')
  }

  async fetch () {
    await this.$store.dispatch('stockMovement/getAll', new EntityFetchParams(true, this.createFilter()))
  }

  amount (movement: StockMovement) {
    const amount = _.find(movement.items, movementItem => movementItem.item!.id === this.stockItem.item!.id)!.amount as number
    return movement.income ? amount : (amount * -1)
  }

  totalPrice (movement: StockMovement) {
    const totalPrice = _.find(movement.items, movementItem => movementItem.item!.id === this.stockItem.item!.id)!.totalPrice as number
    return movement.income ? totalPrice : (totalPrice * -1)
  }

  get incomeAmountSum () {
    return _(this.movements).filter('income').map(this.amount).sum()
  }

  get outcomeAmountSum () {
    return Math.abs(_(this.movements).filter('outcome').map(this.amount).sum())
  }

  get incomeTotalPriceSum () {
    return _(this.movements).filter('income').map(this.totalPrice).sum()
  }

  get outcomeTotalPriceSum () {
    return Math.abs(_(this.movements).filter('outcome').map(this.totalPrice).sum())
  }

  createFilter () {
    return _.pickBy({
      'item-id': this.stockItem.item!.id,
      'stock-id': this.stockItem.stock!.id,
      'date-from': this.filter.range && this.filter.range.from ? FORMAT_SYSTEM_DATE(this.filter.range!.from) : null,
      'date-to': this.filter.range && this.filter.range.to ? FORMAT_SYSTEM_DATE(this.filter.range!.to) : null
    }, _.identity)
  }

  async afterSetup () {
    await this.fetch()
  }
}
