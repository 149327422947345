








import _ from 'lodash'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import Worker from '@/modules/worker/domain/worker'
import workerForm from '@/modules/worker/components/workerForm.vue'
import workersTable from '@/modules/worker/components/workersTable.vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'

@Component({
  components: { loading, page, workerForm, workersTable }
})
export default class WorkerEditPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  worker?: Worker | null = null

  @Prop({ type: String, required: true }) workerId!: string

  async mounted () {
    await this.$store.dispatch('workingActivity/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('company/getOwned')
    this.worker = _.cloneDeep(await this.$store.dispatch('worker/select', this.workerId))

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'setting.worker.label', routeName: 'workers' }, { text: this.worker!.fullName }])
  }
}
