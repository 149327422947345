






















import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class CompanyBoxPrint extends Vue {
  @Prop({ type: Company, required: true }) company!: Company
  @Prop({ type: String, required: true }) title!: String
}
