
































































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateMonthField from '@/modules/common/components/dateMonthField.vue'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Worker from '@/modules/worker/domain/worker'
import workRecordCreateModal from '@/modules/work/record/components/workRecordCreateModal.vue'
import WorkRecord from '@/modules/work/record/domain/workRecord'
import { State } from 'vuex-class'
import Company from '@/modules/company/domain/company'
import Timesheet from '@/modules/timesheet/domain/timesheet'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import notificationService from '@/modules/common/services/notificationService'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'

@Component({
  components: { dateMonthField, dateRangeField, checkboxField, loading, noRecords, workRecordCreateModal }
})
export default class TimeSheetBox extends mixins(entityTableMixin, bookmarkableComponent, submitProtectionMixin) {
  entityModuleName = 'workRecord'
  activeSupport = false
  filter: FilterData = {
    date: null
  }

  blockLockedUpdate = true

  @Prop({ type: Worker }) worker?: Worker
  @State('owned', { namespace: 'company' }) company?: Company
  @State('items', { namespace: 'timesheet' }) timesheets!: Array<Timesheet>

  @Watch('filter.date')
  filterChanged (newDate: moment.Moment, oldDate: moment.Moment) {
    // need to check type of value, because watcher from bookmarkableComponent has impact here
    if (oldDate && moment.isMoment(newDate)) {
      this.fetch()
    }
  }

  get summaryItems () {
    return _(this.items as Array<WorkRecord>)
      .groupBy((workRecord: WorkRecord) => workRecord.workerActivity!.id)
      .values()
      .map((workRecordsPerWorkerActivity: Array<WorkRecord>) =>
        ({
          activity: workRecordsPerWorkerActivity[0]!.workerActivity!.activity!.label,
          price: workRecordsPerWorkerActivity[0]!.workerActivity!.price,
          duration: moment.duration(_.sumBy(workRecordsPerWorkerActivity, 'hours'), 'hour'),
          totalPrice: _.sumBy(workRecordsPerWorkerActivity, 'price')
        }))
      .value()
  }

  get totalDuration () {
    return _(this.summaryItems)
      .map('duration')
      .reduce((result: moment.Duration, item: moment.Duration) => {
        return result.add(item)
      }, moment.duration(0))
  }

  get totalPrice () {
    return _(this.summaryItems)
      .sumBy('totalPrice')
  }

  get timesheet () {
    return _.head(this.timesheets)
  }

  get locked () {
    const timesheet = _.head(this.timesheets)
    return timesheet ? timesheet.locked! : false
  }

  set locked (value: boolean) {
  }

  lockTimesheet () {
    this.protect()
    const value = !this.locked
    let timesheet: Timesheet, operation
    if (this.timesheets.length) {
      timesheet = _.cloneDeep(_.head(this.timesheets)!)
      operation = 'update'
    } else {
      timesheet = new Timesheet({ worker: this.worker, dateFrom: this.filter.date })
      operation = 'create'
    }
    timesheet.locked = value
    this.$store.dispatch('timesheet/' + operation, new EntityCreateParams(timesheet, true)).then(() => {
      notificationService.success('entity.edit.success')
    }).finally(() => {
      this.unprotect()
    })
  }

  defaultFilter () {
    this.filter.date = moment().startOf('month')
  }

  createFilter () {
    return _.pickBy({
      dateFrom: FORMAT_SYSTEM_DATE(this.filter.date.startOf('month')),
      dateTo: FORMAT_SYSTEM_DATE(this.filter.date.endOf('month')),
      workerId: this.worker!.id
    }, _.identity)
  }

  async beforeFetch () {
    await this.$store.dispatch('workRecord/clearAll')
    const parameters = this.createFilter()
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    await this.$store.dispatch('timesheet/getAll', new EntityFetchParams(true, {
      date: parameters.dateFrom,
      workerId: this.worker!.id
    }))
  }

  async created () {
    await this.$store.dispatch('company/getOwned')
    this.newItemTemplateDefault = () => new WorkRecord({ dateFrom: moment().startOf('day') })
  }
}
