











import Component from 'vue-class-component'
import infoBox from '@/modules/common/components/infoBox.vue'
import loading from '@/modules/common/components/loading.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { State } from 'vuex-class'

@Component({
  components: { infoBox, loading }
})
export default class TaxBaseSummaryBox extends Vue {
  @Prop({ type: String, required: true }) currency!: string
  @State('taxBaseSummaryItems', { namespace: 'finance' }) taxBaseSummaryItems?: Array<string>

  async fetch () {
    await this.$store.dispatch('finance/clearTaxBaseSummaries', this.currency)
    this.$store.dispatch('finance/getTaxBaseSummaries', this.currency)
  }

  @Watch('currency')
  async onCurrencyChange () {
    this.fetch()
  }

  mounted () {
    this.fetch()
  }
}
