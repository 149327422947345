




































import Component from 'vue-class-component'
import Vue from 'vue'
import { State } from 'vuex-class'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import Company from '@/modules/company/domain/company'

@Component
export default class OrderStatementHeaderPrint extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement
  @State('owned', { namespace: 'company' }) company?: Company
}
