






import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import usersTable from '@/modules/user/components/usersTable.vue'
import Vue from 'vue'

@Component({
  components: { page, usersTable }
})
export default class UserSettingPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'users.label' }])
}
