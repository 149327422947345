


































































































































import _ from 'lodash'
import { AccountingType } from '@/modules/money/movement/type/accountingType'
import barChart from '@/modules/common/components/chart/barChart.vue'
import box from '@/modules/common/components/box.vue'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import currencyFilter from '@/modules/common/filters/currencyFilter'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { extendMoment } from 'moment-range'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE, localeCompare } from '@/utils'
import { Getter, State } from 'vuex-class'
import groupTypes from '@/modules/common/values/groupTypes'
import { CHART_COLORS } from '@/config'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import MoneyBoxBalance from '@/modules/money/box/domain/moneyBoxBalance'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import Range from '@/modules/common/components/form/range'
import selectField from '@/modules/common/components/form/selectField.vue'
import SortData from '@/modules/common/mixins/sortData'

interface Filter {
  groupType: string | null,
  range: Range | null,
  dataSource: string | null
}

class SumItem {
  totalAmountIncome: number = 0
  totalAmountOutcome: number = 0
  totalAmount: number = 0
  cashFlow: number = 0
}

class DataSource {
  value: string
  label: string
  groupProperty: string
  codelist: boolean

  constructor (value: string, label: string, groupProperty: string, codelist: boolean = false) {
    this.value = value
    this.label = label
    this.groupProperty = groupProperty
    this.codelist = codelist
  }
}

interface SumItems {
  [key: string]: SumItem
}

interface SumDetailItem {
  [key: string]: {
    id: string
    value: number
  }
}

interface SumDetailItems {
  [key: string]: {
    dateLabel: string
    values: SumDetailItem
  }
}

@Component({
  components: { barChart, box, noRecords, loading, radioField, dateRangeField, selectField }
})
export default class CashFlowSummaryBox extends mixins(bookmarkableComponent) {
  filter: Filter = {
    groupType: null,
    range: null,
    dataSource: null
  }

  groupTypes = groupTypes

  dataSources = [
    new DataSource('CATEGORY', i18n.message('money-movement.categories.label'), 'category'),
    new DataSource('SUBJECT', i18n.message('partners.label'), 'subject'),
    new DataSource('MONEY_BOX', i18n.message('money-boxes.label.short'), 'moneyBox'),
    new DataSource('accounting-type', i18n.message('finance.accounting-type.label'), 'accountingType', true) // value of DataSource must match with sk.ts localization of enum
  ]

  notDefined = {
    id: -1,
    label: i18n.message('common.not-defined')
  }

  maxDate = moment()
  sumItems: SumItems = {}
  cashFlowSum: number | null = null
  sumDetailItems: SumDetailItems = {}
  dates: Array<string> = []
  detailDates: Array<string> = []
  dataSourceValues: Array<any> = []

  @Getter('byFilter', { namespace: 'moneyMovement' }) movementsByFilter!: (filterData: FilterData, sortData: SortData) => Array<MoneyMovement>
  @State('balances', { namespace: 'moneyBox' }) balances!: Array<MoneyBoxBalance>
  @Prop({ type: String, required: true }) currency!: string

  get movements () {
    return _.filter(this.movementsByFilter(this.filter, this.sortData), (movement: MoneyMovement) => movement.accountingType !== AccountingType.INTERNAL)
  }

  get chartData () {
    return {
      labels: this.dates,
      datasets: [
        {
          backgroundColor: CHART_COLORS.green,
          borderColor: CHART_COLORS.green,
          data: _.map(this.dates, label => this.itemByDate(label).cashFlow),
          label: i18n.message('finance.cash-flow.label'),
          fill: false,
          type: 'line'
        },
        {
          backgroundColor: CHART_COLORS.red,
          borderColor: CHART_COLORS.red,
          data: _.map(this.dates, label => this.itemByDate(label).totalAmount),
          label: i18n.message('common.total'),
          fill: false,
          type: 'line'
        },
        {
          backgroundColor: CHART_COLORS.turquoise,
          data: _.map(this.dates, label => this.itemByDate(label).totalAmountIncome),
          label: i18n.message('finance.incomes.label')
        },
        {
          backgroundColor: CHART_COLORS.blue,
          data: _.map(this.dates, label => Math.abs(this.itemByDate(label).totalAmountOutcome)),
          label: i18n.message('finance.outcomes.label')
        }
      ]
    }
  }

  get chartLabelCallback () {
    return (tooltipItem: any, data: any) => currencyFilter(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], this.currency) || ''
  }

  get chartBeforeLabelCallback () {
    return (tooltipItem: any, data: any) => data.datasets[tooltipItem.datasetIndex].label || ''
  }

  get chartAfterLabelCallback () {
    return (tooltipItem: any, data: any) => (tooltipItem.datasetIndex + 1) < data.datasets.length ? ' ' : null
  }

  defaultFilter () {
    this.filter.groupType = 'month'
    this.filter.range = new Range(moment().subtract(5, 'month'), moment())
    this.filter.dataSource = 'CATEGORY'
  }

  itemByDate (date: string) {
    return this.sumItems[date] || 0
  }

  sumByProperty (property: string) {
    return _(this.sumItems).values().map(property).sum() | 0
  }

  async fetch () {
    await this.clear()
    const filter = this.createFilter()
    await this.$store.dispatch('moneyMovement/getAll', new EntityFetchParams(true, filter))
    await this.$store.dispatch('moneyBox/balances', { dateTo: FORMAT_SYSTEM_DATE(this.filter.range!.from!.subtract(1, 'd')), currency: filter.currency })
    this.recalculateData()
  }

  createFilter () {
    const dateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null
    return _.pickBy({
      dateFrom,
      dateTo,
      active: true,
      currency: this.currency
    }, _.identity)
  }

  recalculateData () {
    // extend moment with moment-range
    const momentExt = extendMoment(moment as any)
    // calculate headers
    const range = momentExt.range(this.filter!.range!.from!, this.filter!.range!.to!)
    const groupType = _.find(this.groupTypes, { value: this.filter.groupType! })
    const rangeDates = Array.from(range.by(this.filter.groupType as any))
    this.dates = _.map(rangeDates, date => groupType!.dateFormat(date))

    // sum total CashFlow to dateFrom
    this.cashFlowSum = _(this.balances).map('totalAmount').sum()
    let cashFlowSum = this.cashFlowSum
    // calculate data
    this.sumItems = _(this.movements)
      .groupBy(movement => _.find(this.groupTypes, { value: this.filter!.groupType! })!.dateFormat(movement.date)) // group by date format of selected group type
      .map((movementsPerDate, dateLabel) => {
        const result = {
          dateLabel,
          totalAmountIncome: _(movementsPerDate).filter({ type: MoneyMovementType.INCOME }).map('totalAmount').sum() || 0,
          totalAmountOutcome: _(movementsPerDate).filter({ type: MoneyMovementType.OUTCOME }).map('totalAmount').sum() || 0,
          totalAmount: _(movementsPerDate).map('totalAmount').sum() || 0,
          cashFlow: cashFlowSum + (_(movementsPerDate).map('totalAmount').sum() || 0)
        }
        cashFlowSum = result.cashFlow
        return result
      })
      .keyBy('dateLabel')
      .value()

    // fill all missing dates because of cashFlow attribute
    cashFlowSum = this.cashFlowSum
    this.dates.forEach(date => {
      if (!this.sumItems[date]) {
        this.sumItems[date] = new SumItem()
        this.sumItems[date].cashFlow = cashFlowSum
      } else {
        cashFlowSum = cashFlowSum + this.sumItems[date].totalAmount
      }
    })
  }

  sumClassObj (amount: number) {
    return {
      'text-danger': amount < 0,
      'text-success': amount >= 0
    }
  }

  recalculateDetailData () {
    this.detailDates = [...this.dates]

    const datasource = _.find(this.dataSources, { value: this.filter!.dataSource! })!

    // extract uniq dataSource values and order by label
    const dsSet = _(this.movements)
      .map((movement: any) => movement[datasource.groupProperty] ? movement[datasource.groupProperty] : this.notDefined)

    // For codelist datasources, mapping of single string value to object with id and label property need to be done
    if (datasource.codelist) {
      this.dataSourceValues = dsSet
        .uniq()
        .map(accountingType => ({ id: accountingType, label: i18n.message(`value.${datasource.value}.label.${accountingType}`) }))
        .sort(localeCompare('label'))
        .value()
    } else {
      this.dataSourceValues = dsSet
        .uniqBy('id')
        .sort(localeCompare('label'))
        .value()
    }

    // group data for detail table. Response data object will have dates as keys and object with id of grouped
    // property as keys a value as real value
    this.sumDetailItems = _(this.movements)
      .groupBy(movement => _.find(this.groupTypes, { value: this.filter!.groupType! })!.dateFormat(movement.date)) // group by date format of selected group type
      .map((movementsPerDate: Array<MoneyMovement>, dateLabel: string) => {
        // group by dataSource.id, result will be
        // [{
        //    id: 4,
        //    value : XX
        //  },
        //  ...]
        //
        const itemForDate = _(movementsPerDate)
          .groupBy((movement: any) => movement[datasource.groupProperty] ? (datasource.codelist ? movement[datasource.groupProperty] : movement[datasource.groupProperty].id) : this.notDefined.id)
          .map((movementsPerGroupProp, propId) => ({
            id: propId,
            value: _(movementsPerGroupProp).map('totalAmount').sum() || 0
          }))
          .keyBy('id')
          .value()
        return {
          dateLabel,
          values: itemForDate
        }
      })
      .keyBy('dateLabel')
      .value()
  }

  detailItemByDate (date: string, dataSourceId: string) {
    return _.isNil(this.sumDetailItems[date]) || _.isNil(this.sumDetailItems[date].values[dataSourceId])
      ? 0 : this.sumDetailItems[date].values[dataSourceId].value
  }

  // generate class object for icon showing value change direction
  detailItemChangeClassObj (date: any, dataSourceId: string) {
    // skip first column
    if (!this.detailDates.indexOf(date)) {
      return null
    } else {
      const prevValue = this.detailItemByDate(this.detailDates[this.detailDates.indexOf(date) - 1], dataSourceId)
      const currentValue = this.detailItemByDate(date, dataSourceId)
      return {
        'fa-caret-up text-success': currentValue > prevValue,
        'fa-caret-down text-danger': currentValue < prevValue
      }
    }
  }

  // sum of values per specific dataSource
  detailItemSum (dataSourceId: string) {
    return _(this.sumDetailItems)
      .values()
      .map('values')
      .filter(item => !_.isNil(item[dataSourceId]))
      .map(dataSourceId)
      .map('value')
      .sum()
  }

  async clear () {
    this.sumItems = {}
    this.cashFlowSum = null
    this.sumDetailItems = {}
    this.dates = []
    this.detailDates = []
    this.dataSourceValues = []
    await this.$store.dispatch('moneyMovement/clearAll')
  }

  isEmpty (someData: any) {
    return _.isEmpty(someData)
  }

  // need to clear cashed data based on currency change, because different currency
  // pages are same route only with different value of currency parameter and destroy method
  // is not called
  @Watch('currency')
  async onCurrencyChange () {
    await this.clear()
  }
}
