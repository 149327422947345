












import _ from 'lodash'
import Component from 'vue-class-component'
import infoBox from '@/modules/common/components/infoBox.vue'
import loading from '@/modules/common/components/loading.vue'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import Vue from 'vue'
import { Getter } from 'vuex-class'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { infoBox, loading }
})
export default class CashFlowBox extends Vue {
  @Getter('active', { namespace: 'moneyBox' }) moneyBoxes!: Array<MoneyBox>
  @Prop({ type: String, required: true }) currency!: string

  get cashFlow () {
    return this.moneyBoxes.length === 0 ? null : _(this.moneyBoxes).filter({ currency: this.currency }).map('amount').sum()
  }
}

