















































































































import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import incomeStockMovementItemForm from '@/modules/stock/movement/components/incomeStockMovementItemForm.vue'
import { IncomeStockMovementType } from '@/modules/stock/movement/type/incomeStockMovementType'
import incomeStockMovementTypes from '@/modules/stock/movement/type/incomeStockMovementTypes'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import { OutcomeStockMovementType } from '@/modules/stock/movement/type/outcomeStockMovementType'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import Stock from '@/modules/stock/domain/stock'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import StockItem from '@/modules/stock/item/domain/stockItem'

@Component({
  components: { dateTimeField, loading, incomeStockMovementItemForm, numberField, selectField, staticField, textField, vForm }
})
export default class IncomeStockMovementForm extends mixins(validationMixin, confirmMixin) {
  incomeStockMovementTypes = _.filter(incomeStockMovementTypes, type => type.value !== IncomeStockMovementType.INVENTORY_SURPLUS)
  targetStock: Stock | null = null
  initialized = false

  @Prop({ type: IncomeStockMovement, required: true }) movement!: IncomeStockMovement

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]
  @Getter('activePartners', { namespace: 'company' }) companies?: Array<Company>
  @State('items', { namespace: 'outcomeStockMovement' }) outcomeMovements?: OutcomeStockMovement[]
  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]

  get targetStocks () {
    return _.filter(this.stocks, stock => (stock.id !== this.movement!.stock!.id) && (stock.currency === this.movement.stock!.currency))
  }

  addItem () {
    this.movement!.items = [...this.movement!.items, new StockMovementItem({})]
  }

  onSubmit () {
    // clear selected supplier and deliveryNote if type is not PURCHASE
    if (this.movement.type !== IncomeStockMovementType.PURCHASE) {
      this.movement.supplier = null
      this.movement.deliveryNote = null
    }
    // clear selected outcomeMovement if type is not TRANSFER
    if (this.movement.type !== IncomeStockMovementType.TRANSFER) {
      this.targetStock = null
      this.movement.outcomeMovement = null
    }

    const operation = 'incomeStockMovement/' + (this.movement!.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.movement!, false)).then((result: IncomeStockMovement) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'incomeStockMovement', params: { movementId: result.id + '' } })
      }
    }).finally(this.unprotect)
  }

  @Watch('targetStock')
  onSupplierChanged (targetStock: Stock) {
    if (targetStock) {
      this.$store.dispatch('outcomeStockMovement/getAll', new EntityFetchParams(true, { 'stock-id': targetStock.id, type: OutcomeStockMovementType.TRANSFER, unpaired: true }))
    } else {
      this.$store.dispatch('outcomeStockMovement/clearAll')
    }
    this.movement.outcomeMovement = null
  }

  @Watch('movement.outcomeMovement')
  onOutcomeMovementChanged (outcomeMovement: OutcomeStockMovement) {
    this.movement.items = []
    if (outcomeMovement) {
      const availableItemIds = _.map(this.stockItems, 'item.id')
      const firstUnsupported = _.find(outcomeMovement.items, movementItem => availableItemIds.indexOf(movementItem.item!.id) < 0)

      if (firstUnsupported) {
        notificationService.error('error.stock.movement.transfer.not.valid.item', [firstUnsupported.item!.name])
      } else {
        outcomeMovement.items.forEach(stockMovementItem => {
          this.movement.items.push(new StockMovementItem({
            item: stockMovementItem.item,
            price: stockMovementItem.price,
            amount: Math.abs(stockMovementItem.amount!),
            itemBatches: [...stockMovementItem.itemBatches]
          }))
        })
      }
    }
  }

  @Watch('movement.type')
  onTypeChanged (type: OutcomeStockMovementType) {
    if (type === OutcomeStockMovementType.TRANSFER) {
      this.movement.items = []
    }
  }

  deleteMovement () {
    this.confirm('stock-movement.income.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('incomeStockMovement/delete', new EntityDeleteParams(this.movement.id!, false)).then((result) => {
          if (result) {
            notificationService.success('stock-movement.income.delete.success')
            this.$router.push({ name: 'stockMovements' })
          }
        }).finally(this.unprotect)
      }
    })
  }

  async created () {
    await this.$store.dispatch('stock/getAll')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.movement.stock!.id, 'active-only': true }))
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.movement.stock!.id }))
    await this.$store.dispatch('item/getAll', new EntityFetchParams(true))

    // select target stock for edit mode
    if (this.movement.id && this.movement.outcomeMovement) {
      this.targetStock = _.find(this.stocks, { id: this.movement.outcomeMovement.stock!.id }) || null
    }
    this.initialized = true
  }
}
