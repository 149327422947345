









































































import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import Stock from '@/modules/stock/domain/stock'
import stockCreateModal from '@/modules/stock/components/stockCreateModal.vue'

@Component({
  components: { loading, noRecords, stockCreateModal }
})
export default class StocksTable extends mixins(entityTableMixin) {
  entityModuleName = 'stock'

  async created () {
    this.newItemTemplateDefault = () => new Stock({})
  }
}
