import Takings from '@/modules/sale/domain/takings'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'

class TakingsTransfer {
  takings: Takings | null = null
  moneyBox: MoneyBox | null = null
  category: MoneyMovementCategory | null = null
}

export default TakingsTransfer
