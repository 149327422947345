
















































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'

@Component({
  components: { formModal, checkboxField, numberField, selectField }
})
export default class ItemStockCreateModal extends mixins<createModal<StockItem>>(createModal) {
  moduleName = 'stockItem'

  @Getter('active', { namespace: 'stock' }) stocks!: Array<Stock>
  @State('items', { namespace: 'stockItem' }) stockItems!: Array<StockItem>

  @Prop({ type: Boolean, default: true }) showStock!: boolean

  // do not offer used stock in select
  get availableStocks () {
    const usedStockIds = _.map(this.stockItems, 'stock.id')
    return _.filter(this.stocks, stock => usedStockIds.indexOf(stock.id) < 0)
  }

  validationObject () {
    const validationObj = { required: true, max_value: 9999999999, min_value: 0 } as any
    if (this.item!.item!.scale === 0) {
      validationObj.numeric = true
    } else if (this.item!.item!.scale! > 0) {
      validationObj.decimal = this.item!.item!.scale
    }
    return validationObj
  }

  inactivable () {
    return this.isEdit() && !this.item!.amount
  }

  async created () {
    await this.$store.dispatch('stock/getAll')
  }
}
