import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import RecipeItem from '@/modules/recipe/domain/recipeItem'
import recipeItemResource from '@/modules/recipe/services/recipeItemResource'

const entityService = new EntityService<RecipeItem>(recipeItemResource, RecipeItem)
const recipeModule: Module<EntityState<RecipeItem>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'recipe-item')
}

export default recipeModule
