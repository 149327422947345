













































































import _ from 'lodash'
import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import dateMonthField from '@/modules/common/components/dateMonthField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import workRecordCreateModal from '@/modules/work/record/components/workRecordCreateModal.vue'
import WorkRecord from '@/modules/work/record/domain/workRecord'

@Component({
  components: { box, dateMonthField, loading, noRecords, workRecordCreateModal }
})
export default class WorkRecordsActivitySummaryTable extends mixins(auditableTooltipMixin, entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'workRecord'
  activeSupport = false
  filter: FilterData = {
    date: null
  }

  @State('owned', { namespace: 'company' }) company?: Company

  @Watch('filter.date')
  filterChanged (newDate: moment.Moment, oldDate: moment.Moment) {
    // need to check type of value, because watcher from bookmarkableComponent has impact here
    if (oldDate && moment.isMoment(newDate)) {
      this.fetch()
    }
  }

  defaultFilter () {
    this.filter.date = moment().startOf('month')
  }

  createFilter () {
    return _.pickBy({
      dateFrom: FORMAT_SYSTEM_DATE(this.filter.date.startOf('month')),
      dateTo: FORMAT_SYSTEM_DATE(this.filter.date.endOf('month'))
    }, _.identity)
  }

  async beforeFetch () {
    await this.$store.dispatch('workRecord/clearAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }

  async mounted () {
    await this.$store.dispatch('company/getOwned')
  }

  async created () {
    this.newItemTemplateDefault = () => new WorkRecord({ dateFrom: moment().startOf('day') })
  }
}
