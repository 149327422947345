














import Vue from 'vue'
import Component from 'vue-class-component'
import { ValidationProvider } from 'vee-validate'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { ValidationProvider }
})
export default class TextAreaField extends Vue {
  innerValue?: string | null = null

  @Prop({ type: String, required: false }) fieldId?: string
  @Prop({ type: String }) label?: string
  @Prop({ type: Number, required: false, default: Number.MAX_VALUE }) maxlength?: number
  @Prop({ type: String, required: false }) cssClass?: string
  @Prop({ type: String }) validation!: string
  @Prop({ type: Object, default: () => {} }) validationObj!: any
  @Prop({ type: String, default: '' }) value!: string

  get requiredVal () {
    return ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required
  }

  @Watch('innerValue')
  onInnerValueChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value')
  onValueChange (value: string) {
    this.innerValue = value
  }

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
