import { calculateTotalWithoutVat, calculateTotalWithVat, calculateVat } from '@/modules/finance/services/financeUtils'
import { Store } from 'vuex'
import RootState from '@/store/rootState'

class VatCalculator {
  store?: Store<RootState>

  calculateVat (price?: number | null, amount?: number | null, vatRate?: number | null) {
    if (!this.store) throw new Error('not.initialized')
    const vatCalculationType = this.store!.getters['configuration/first'].invoice.vatCalculationType
    return calculateVat(vatCalculationType, price, amount, vatRate)
  }

  calculateTotalWithoutVat (price?: number | null, amount?: number | null, vatRate?: number | null) {
    if (!this.store) throw new Error('not.initialized')
    const vatCalculationType = this.store!.getters['configuration/first'].invoice.vatCalculationType
    return calculateTotalWithoutVat(vatCalculationType, price, amount, vatRate)
  }

  calculateTotalWithVat (price?: number | null, amount?: number | null, vatRate?: number | null) {
    if (!this.store) throw new Error('not.initialized')
    const vatCalculationType = this.store!.getters['configuration/first'].invoice.vatCalculationType
    return calculateTotalWithVat(vatCalculationType, price, amount, vatRate)
  }

  init (store: Store<RootState>) {
    this.store = store
  }
}

const calculator = new VatCalculator()

export default calculator
