import EntityService from '@/modules/common/services/entityService'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import stockMovementResource from '@/modules/stock/movement/services/stockMovementResource'

class StockMovementService extends EntityService<StockMovement> {
  constructor () {
    super(stockMovementResource, StockMovement)
  }

  createItem (data: any) {
    switch (data['@type']) {
      case 'income':
        return new IncomeStockMovement(data)
      case 'outcome':
        return new OutcomeStockMovement(data)
      default: {
        throw new Error('Unresolved stock movement type.')
      }
    }
  }
}

export default new StockMovementService()
