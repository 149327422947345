import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import InvoiceSeriesResource from '@/modules/invoice/services/invoiceSeriesResource'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'

const entityService = new EntityService<InvoiceSeries>(InvoiceSeriesResource, InvoiceSeries)
const invoiceSeriesModule: Module<EntityState<InvoiceSeries>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'invoice-series')
}

export default invoiceSeriesModule
