



























































import box from '@/modules/common/components/box.vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component, { mixins } from 'vue-class-component'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import notificationService from '@/modules/common/services/notificationService'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import permissionsEditBox from '@/modules/user/components/permissionsEditBox.vue'
import { PermissionType } from '@/modules/user/type/permissionType'
import { State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import User from '@/modules/user/domain/user'
import userInfoBox from '@/modules/user/components/userInfoBox.vue'

@Component({
  components: { box, page, permissionsEditBox, userInfoBox }
})
export default class UserPermissionSettingPage extends mixins(submitProtectionMixin) {
  breadCrumbData: BreadCrumbData | null = null
  PermissionType = PermissionType

  @Prop({ type: String, required: true }) userId!: string
  @State('selected', { namespace: 'user' }) user!: User

  onSubmit () {
    this.protect()
    this.$store.dispatch('user/update', new EntityCreateParams(this.user)).then((result: User) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'users' })
      }
    }).finally(this.unprotect)
  }

  async created () {
    await this.$store.dispatch('user/select', this.userId)

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'users.label', routeName: 'users' }, { text: this.user.fullName! }])
  }
}
