



































import _ from 'lodash'
import Component from 'vue-class-component'
import Item from '@/modules/item/domain/Item'
import noRecords from '@/modules/common/components/noRecords.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { Getter, State } from 'vuex-class'
import Vue from 'vue'
import EntityMap from '@/modules/common/domain/entityMap'

class SummaryItem {
  itemId: number
  item: Item
  price: number
  returned: number

  constructor (itemId: number, item: Item, price: number, returned: number) {
    this.itemId = itemId
    this.item = item
    this.price = price
    this.returned = returned
  }

  get totalPrice () {
    return _.round(this.price * this.returned, 2)
  }
}

@Component({
  components: { noRecords }
})
export default class OrderStatementReturnedSummaryBox extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement
  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>

  get items () {
    const summaryItems: Array<SummaryItem> = []
    _(this.statement.orders).flatMap('items').forEach(orderItem => {
      const summaryItem = _.find(summaryItems, { itemId: orderItem.item.id, price: orderItem.itemPrice })
      if (summaryItem) {
        summaryItem.returned += orderItem.returned
      } else {
        summaryItems.push(new SummaryItem(orderItem.item.id, this.itemsMap[orderItem.item.id], orderItem.itemPrice, orderItem.returned))
      }
    })

    return _.reject(summaryItems, { returned: 0 })
  }
}
