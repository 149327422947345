



































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'
import numberField from '@/modules/common/components/form/numberField.vue'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import { Prop, Watch } from 'vue-property-decorator'
import staticField from '@/modules/common/components/form/staticField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { dateTimeField, numberField, selectField, staticField, textField, vForm }
})
export default class OutcomeStockMovementItemForm extends mixins(validationMixin, confirmMixin) {
  @Prop({ type: OutcomeStockMovement, required: true }) movement!: OutcomeStockMovement
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: StockMovementItem, required: true }) movementItem!: StockMovementItem

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]
  @State('items', { namespace: 'stockItem' }) stockItems?: StockItem[]
  @Getter('active', { namespace: 'itemBatch' }) itemBatches?: ItemBatch[]
  @Getter('activeForStock', { namespace: 'item' }) items!: Item[]
  @State('selected', { namespace: 'outcomeStockMovement' }) originMovement?: OutcomeStockMovement

  get availableItems () {
    const usedItemIds = _.map(this.movement!.items, 'item.id')
    const availableItemIds = _(this.stockItems!).filter(stockItem => stockItem.amount! > 0).map('item.id').value()
    return _.filter(this.items, item => (usedItemIds.indexOf(item.id) < 0) && (availableItemIds.indexOf(item.id) >= 0))
  }

  availableBatches (itemId: number) {
    return _.filter(this.itemBatches, itemBatch => itemBatch.item!.id === itemId)
  }

  deleteItem (item: StockMovementItem) {
    this.movement!.items = _.without(this.movement!.items, item)
  }

  /**
   * Validation object need to be created dynamic, based on scale settings of "Item" object.
   * @param item selected for OrderItem
   */
  validationObject (item?: Item) {
    const validationObj = { required: true, max_value: 9999999999 } as any
    if (!item) {
      return validationObj
    } else {
      validationObj.max_value = this.availableAmount(item) // set max value to available amount
      // for edit mode, max value need to be set to available amount + already used amount
      if (this.movement.id) {
        const originMovementItem = _.find(this.originMovement!.items, movementItem => movementItem.item!.id === item.id)
        if (originMovementItem) {
          validationObj.max_value = validationObj.max_value + originMovementItem.amount!
        }
      }
      if (item.scale === 0) {
        validationObj.numeric = true
        validationObj.min_value = 1
      } else {
        validationObj.decimal = item.scale
        validationObj.min_value = _.round(1 / (10 ** item.scale!), item.scale)
      }
    }
    return validationObj
  }

  availableAmount (item: Item) {
    const stockItem = _.find(this.stockItems, stockItem => stockItem.item!.id === item.id)
    if (stockItem) {
      return stockItem.amount
    }
    return 0
  }

  itemPrice (item: Item) {
    const stockItem = _.find(this.stockItems, stockItem => stockItem.item!.id === item.id)
    if (stockItem) {
      return stockItem.price
    }
    return 0
  }

  @Watch('movementItem.item', { deep: true })
  onItemChange (newItem?: Item, oldItem?: Item) {
    if (newItem && (!oldItem || newItem.id !== oldItem.id)) {
      this.movementItem.price = this.itemPrice(newItem)
    } else if (!newItem) {
      this.movementItem.price = 0
    }
  }
}
