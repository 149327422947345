import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from './moneyMovementGetters'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import moneyMovementService from '@/modules/money/movement/services/moneyMovementService'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'

const moneyBoxModule: Module<EntityState<MoneyMovement>, RootState> = {
  namespaced: true,
  state: new EntityState<MoneyMovement>(),
  mutations,
  getters,
  actions: actions(moneyMovementService, 'money-movement')
}

export default moneyBoxModule
