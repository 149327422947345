import userResource from './userResource'
import User from '../domain/user'
import EntityService from '@/modules/common/services/entityService'
import PasswordChange from '@/modules/user/domain/passwordChange'

class UserService extends EntityService<User> {
  constructor () {
    super(userResource, User)
  }

  async me () {
    const response = await userResource.me()
    if (response.ok) {
      return new User(response.data)
    } else {
      return null
    }
  }

  async myPasswordChange (passwordChange: PasswordChange) {
    const response = await userResource.myPasswordChange({}, passwordChange)
    return response.ok
  }
}

export default new UserService()
