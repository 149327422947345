























































import companyBoxPrint from '@/modules/invoice/components/print/companyBoxPrint.vue'
import Component from 'vue-class-component'
import documentField from '@/modules/common/components/form/documentField.vue'
import Invoice from '@/modules/invoice/domain/invoice'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import paymentMethods from '@/modules/common/values/paymentMethods'

@Component({
  components: { companyBoxPrint, documentField }
})
export default class InvoicePrint extends Vue {
  paymentMethods = paymentMethods

  @Prop({ type: Invoice, required: true }) invoice!: Invoice
}
