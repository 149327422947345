import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import BankMoneyBoxResource from '@/modules/money/box/services/bankMoneyBoxResource'
import BankMoneyBox from '@/modules/money/box/domain/bankMoneyBox'

const entityService = new EntityService<BankMoneyBox>(BankMoneyBoxResource, BankMoneyBox)
const bankMoneyBoxModule: Module<EntityState<BankMoneyBox>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(entityService, 'bank-money-box')
}

export default bankMoneyBoxModule
