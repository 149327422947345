import subjectRecordsPage from '@/modules/record/components/subjectRecordsPage.vue'

export default [
  {
    path: '/subject-records',
    component: subjectRecordsPage,
    name: 'subjectRecords',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'COMPANY_PARTNER',
      pageGroup: 'company'
    }
  }
]
