









































































import Component, { mixins } from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import { State } from 'vuex-class'
import CodeList from '@/modules/common/domain/codeList'
import Company from '@/modules/company/domain/company'
import { CompanyRole } from '@/modules/company/type/companyRole'
import Address from '@/modules/common/domain/address'

@Component({
  components: { box, loading, noRecords, companyCreateModal }
})
export default class PartnersTable extends mixins(entityTableMixin) {
  entityModuleName = 'company'
  itemsGetter = 'allPartners'
  itemsActiveGetter = 'activePartners'
  itemsFetchAction = 'getAllPartners'
  @State('items', { namespace: 'region' }) regions!: Array<CodeList>

  createShow () {
    this.selectedItem = new Company({ role: CompanyRole.PARTNER, address: new Address(), active: true })
    this.showCreateModal = true
  }

  detail (id: bigint) {
    this.$router.push({ name: 'partnerDetail', params: { partnerId: id + '' } })
  }

  createHide () {
    this.showCreateModal = false
  }

  created () {
    this.$store.dispatch('region/getAll')
  }
}
