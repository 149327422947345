var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"title":"setting.permission.label","bread-crumb-data":_vm.breadCrumbData}},[_c('user-info-box'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('permissions-edit-box',{attrs:{"title":"finance.label","permissions":[_vm.PermissionType.FINANCE_OVERVIEW,
                            _vm.PermissionType.FINANCE_MONEY_BOX,
                            _vm.PermissionType.FINANCE_MOVEMENT_IMPORT]}}),_c('permissions-edit-box',{attrs:{"title":"invoicing.label","permissions":[_vm.PermissionType.INVOICE]}}),_c('permissions-edit-box',{attrs:{"title":"work-record.label","permissions":[_vm.PermissionType.WORK_RECORD_PERSONAL,
                            _vm.PermissionType.WORK_RECORD_MANAGEMENT]}}),_c('permissions-edit-box',{attrs:{"title":"takings.daily.label.short","permissions":[_vm.PermissionType.TAKINGS_OVERVIEW,
                            _vm.PermissionType.TAKINGS,
                            _vm.PermissionType.TAKINGS_TRANSFER]}}),_c('permissions-edit-box',{attrs:{"title":"regular-sale.label","permissions":[_vm.PermissionType.REGULAR_SALE]}})],1),_c('div',{staticClass:"col-lg-6"},[_c('permissions-edit-box',{attrs:{"title":"contacts.label","permissions":[_vm.PermissionType.COMPANY_PARTNER]}}),_c('permissions-edit-box',{attrs:{"title":"stocks.label","permissions":[_vm.PermissionType.STOCK]}}),_c('permissions-edit-box',{attrs:{"title":"setting.label","permissions":[_vm.PermissionType.SETTING_COMPANY,
                            _vm.PermissionType.SETTING_FINANCE,
                            _vm.PermissionType.SETTING_SALE,
                            _vm.PermissionType.SETTING_INVOICE,
                            _vm.PermissionType.SETTING_STOCK,
                            _vm.PermissionType.SETTING_ITEM,
                            _vm.PermissionType.SETTING_REGULAR_SALE,
                            _vm.PermissionType.SETTING_WORKER,
                            _vm.PermissionType.SETTING_USER]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('box',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('router-link',{staticClass:"btn btn-default pull-left",attrs:{"tag":"button","type":"button","to":"/settings/users"}},[_vm._v(_vm._s(_vm._f("translate")('common.cancel')))]),_c('button',{staticClass:"btn btn-success float-right",attrs:{"disabled":_vm.protected()},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_vm._v(_vm._s(_vm._f("translate")('common.save')))])]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }