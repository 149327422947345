




































import _ from 'lodash'
import Box from '@/modules/common/components/box.vue'
import { compareFunction } from '@/utils'
import Component from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { Box, noRecords }
})
export default class OrderStatementOrdersSummaryBox extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement

  get orderGroups () {
    return _(this.statement.orders).groupBy(order => order.subscriberName + order.subscriberAddress!.format(true))
      .values()
      .map(ordersOnAddress => {
        const order = ordersOnAddress[0]!
        return {
          label: `${order.subscriberName}, ${order.subscriberAddress!.format(true)}`, // for sorting purpose
          subscriberName: order.subscriberName,
          subscriberAddress: order.subscriberAddress,
          orders: ordersOnAddress
        }
      })
      .sort(compareFunction('label'))
  }
}
