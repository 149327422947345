import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import workerResource from '@/modules/worker/services/workerResource'
import Worker from '@/modules/worker/domain/worker'

const contactModule: Module<EntityState<Worker>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(new EntityService(workerResource, Worker), 'worker')
}

export default contactModule
