










































































































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import { itemDeterminationTypes } from '@/modules/item/type/itemDeterminationType'
import itemOriginTypes from '@/modules/item/type/itemOriginTypes'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import StockItem from '@/modules/stock/item/domain/stockItem'
import { ItemFilter } from '@/modules/item/domain/itemFilter'
import CodeList from '@/modules/common/domain/codeList'
import itemTypes from '@/modules/item/type/itemTypes'
import { booleanTypes } from '@/modules/common/values/booleanType'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class StockMovementFilterModal extends mixins(validationMixin) {
  filterModel: ItemFilter | null = null
  show: boolean = false
  itemTypes = itemTypes
  booleanTypes = booleanTypes
  itemDeterminationTypes = itemDeterminationTypes
  itemOriginTypes = itemOriginTypes

  @Prop({ type: ItemFilter }) model!: ItemFilter
  @Prop({ type: Boolean, default: false }) showModal!: boolean

  @Getter('active', { namespace: 'stockItem' }) stockItems!: StockItem[]
  @Getter('validItems', { namespace: 'itemCategory' }) itemCategories!: Array<CodeList>

  onSubmit () {
    this.$emit('filter', this.filterModel)
    this.unprotect()
  }

  cancel () {
    this.$emit('cancel')
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('filterModel.typeEnabled')
  onTypeEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.type = null
    }
  }

  @Watch('filterModel.originTypeEnabled')
  onOriginEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.originType = null
    }
  }

  @Watch('filterModel.determinationTypeEnabled')
  onDeterminationTypeEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.determinationType = null
    }
  }

  @Watch('filterModel.categoriesEnabled')
  onCategoriesEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.categories = []
    }
  }

  @Watch('filterModel.batchEvidenceEnabled')
  onBatchEvidenceEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.batchEvidence = null
    }
  }

  @Watch('model')
  onModelChange (newValue: any) {
    this.filterModel = _.cloneDeep(newValue)
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  async created () {
    await this.$store.dispatch('itemCategory/getAll')
  }
}
