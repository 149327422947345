






















import _ from 'lodash'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'

@Component
export default class MoneyBoxDeltaBox extends Vue {
  @Prop({ type: Array, required: true }) movements!: Array<MoneyMovement>
  @Prop({ type: String, required: true }) currency!: string

  get sumIncome () {
    return _(this.movements).filter({ type: MoneyMovementType.INCOME, active: true }).map('totalAmount').sum() || 0
  }

  get sumOutcome () {
    return _(this.movements).filter({ type: MoneyMovementType.OUTCOME, active: true }).map('totalAmount').sum() || 0
  }

  get sum () {
    return _.round(this.sumIncome + this.sumOutcome, 2) // decimal fix problem with rounding
  }
}
