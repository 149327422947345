































































































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import Currency from '@/modules/currency/domain/currency'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import notificationService from '@/modules/common/services/notificationService'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import Order from '@/modules/order/domain/order'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import { OrderState } from '@/modules/order/type/orderState'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'

class Filter {
  subscriber?: Company | null = null
  currency?: string | null = null

  get complete () {
    return this.subscriber && this.currency
  }
}

@Component({
  components: { box, dateTimeField, selectField, loading, noRecords, staticField, textField, vForm }
})
export default class OrderStatementForm extends mixins(validationMixin, confirmMixin) {
  filter: Filter = new Filter()
  initialized = false
  unselectedOrders: Array<Order> = []
  subscriber?: Company | null = null // need to temporary store subscriber in edit mode - for case if all orders are unselected
  orders: Order[] = []

  @Prop({ type: OrderStatement, required: true }) statement!: OrderStatement

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('validItems', { namespace: 'currency' }) currencyList!: Array<Currency>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @Getter('activePartners', { namespace: 'company' }) activePartners?: Array<Company>
  @State('owned', { namespace: 'company' }) company? : Company

  get subscribers () {
    return _.filter(this.activePartners, 'orderStatementEnabled')
  }

  get allOrders () {
    return _(this.orders)
      .unionBy(this.statement.orders, 'id')
      .unionBy(this.unselectedOrders, 'id')
      .orderBy(['date', 'created']).value()
  }

  get isEdit () {
    return this.statement && this.statement.id
  }

  get deletable () {
    return this.isEdit && this.statement.invoices.length === 0
  }

  isSelected (order: Order) {
    return _.some(this.statement.orders, { id: order.id })
  }

  deleteStatement () {
    this.protect()
    this.confirm('order-statement.delete.confirmation', [this.statement.formattedNumber]).then((value) => {
      if (value) {
        this.$store.dispatch('orderStatement/delete', new EntityDeleteParams(this.statement.id!, false)).then((result) => {
          if (result) {
            notificationService.success('order-statement.delete.success')
            this.$router.push({ name: 'orderStatements' })
          }
        }).finally(this.unprotect)
      }
    }).finally(this.unprotect)
  }

  selectOrder (order: Order) {
    if (this.isSelected(order)) {
      // for edit mode, save removed order to be able to select it again
      if (this.isEdit && !_.some(this.orders, { id: order.id })) {
        this.unselectedOrders.push(order)
      }
      this.statement.orders = _.reject(this.statement.orders, { id: order.id })
    } else {
      // for edit mode, remove stored unselected order
      if (this.isEdit && _.some(this.unselectedOrders, { id: order.id })) {
        this.unselectedOrders = _.reject(this.unselectedOrders, { id: order.id })
      }
      this.statement.orders = [...this.statement.orders, order]
    }
  }

  selectAll () {
    // all are selected
    if (this.allOrders.length && this.allOrders.length === this.statement.orders.length) {
      this.statement.orders = []
    } else {
      this.statement.orders = [...this.allOrders]
    }
  }

  @Watch('filter.currency')
  @Watch('filter.subscriber')
  onFilterDateChange () {
    if (!this.isEdit && this.initialized) {
      this.statement.orders = []
      this.fetch()
    }
  }

  async fetch () {
    if (this.filter.complete) {
      await this.$store.dispatch('order/getAll', new EntityFetchParams(true, this.createOrderFilter()))
      this.orders = await this.$store.dispatch('order/enrichAll', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
    } else {
      await this.$store.dispatch('order/clearAll')
    }
  }

  goBack () {
    this.$router.push({ name: 'orderStatements' })
  }

  onSubmit () {
    if (!this.statement.orders.length) {
      notificationService.error('error.order-statement.orders.empty')
      return this.unprotect()
    }

    const operation = 'orderStatement/' + (this.statement.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.statement)).then((result: OrderStatement) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'orderStatementDetail', params: { orderStatementId: result.id + '' } })
      }
    }).finally(this.unprotect)
  }

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('order/clearAll')
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('company/getOwned')

    if (!this.statement.orders.length && this.company) { // set default owned company currency for new statement
      this.filter.currency = this.company.defaultCurrency
    } else if (this.statement.orders.length) { // set currency of first order for new statement
      this.filter.subscriber = this.statement.orders[0].subscriber
      this.filter.currency = this.statement.orders[0].currency
      await this.fetch()
      this.subscriber = this.statement.subscriber
    }
    this.initialized = true
  }

  private createOrderFilter () {
    return {
      'subscriber-id': this.filter.subscriber!.id,
      'no-statement': true,
      state: OrderState.DELIVERED,
      currency: this.filter.currency
    }
  }
}
