
























































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import i18n from '@/i18n'
import Invoice from '@/modules/invoice/domain/invoice'
import invoicePrint from '@/modules/invoice/components/print/invoicePrint.vue'
import { invoiceStatuses } from '@/modules/common/values/'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import Range from '@/modules/common/components/form/range'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import printMixin from '@/modules/common/mixins/printMixin'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'

class Filter {
  range?: Range | null = null
  status?: string | null = null
  query?: string | null = null
}

@Component({
  components: { invoicePrint, box, dateRangeField, loading, noRecords, selectField },
  metaInfo: {
    title: i18n.message('invoices.label')
  }
})
export default class InvoicesTable extends mixins(entityTableMixin, bookmarkableComponent, submitProtectionMixin, printMixin) {
  entityModuleName = 'invoice'
  activeSupport = false
  statuses = invoiceStatuses
  filter = new Filter()

  @Prop({ type: String, required: true }) type!: string
  @Prop({ type: String, required: true }) pageDataId!: string

  // watch: {
  // need to clear invoices based on type change, because different
  // pages are same route only with different value of type parameter and destroy method
  // is not called
  @Watch('type')
  onTypeChange (newType: string, oldType: string) {
    if (newType !== oldType) {
      this.defaultFilter()
      this.selectedItems = []
      this.fetch()
    }
  }

  @Watch('filter.status')
  onStatusChanged () {
    this.fetch()
  }

  @Watch('filter.range')
  onRangeChanged (rangeNew?: Range, rangeOld?: Range) {
    if (!rangeNew && rangeOld) {
      this.fetch()
    }
  }

  get isOutcomeType () {
    return this.type === InvoiceType.OUTCOME
  }

  get selectedAll () {
    return this.selectedItems.length === this.items.length
  }

  defaultFilter () {
    this.filter.range = new Range(moment().subtract(1, 'month'), moment())
  }

  async detail (invoice: Invoice) {
    await this.$router.push({ name: invoice.type === InvoiceType.INCOME ? 'invoiceDetailIncome' : 'invoiceDetailOutcome', params: { id: invoice.id + '' } })
  }

  createFilter () {
    const filter = this.filter
    const dateOfIssueFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateOfIssueTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null

    return _.pickBy({
      dateOfIssueFrom,
      dateOfIssueTo,
      status: filter.status,
      type: this.type
    }, _.identity)
  }

  printSelected () {
    this.print()
  }

  async beforeFetch () {
    await this.$store.dispatch('invoice/clearAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }
}
