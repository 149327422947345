import _ from 'lodash'
import AuditableEntity from '@/modules/common/domain/auditableEntity'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import { Entity } from '@/modules/common/domain/entity'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'
import moment from 'moment'
import OrderItem from '@/modules/order/domain/orderItem'
import { OrderState } from '@/modules/order/type/orderState'
import User from '@/modules/user/domain/user'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'

class Order extends Entity implements AuditableEntity {
  date?: moment.Moment | null
  state?: OrderState | null
  number?: number
  currency?: string
  supplierBranch?: CompanyBranch | null
  subscriber?: Company | null
  subscriberBranch?: CompanyBranch | null
  statement?: OrderStatement | null
  subscriberOrderNumber?: string
  note?: string
  items: Array<OrderItem>
  totalPrice?: number
  totalVat?: number
  totalDeliveredPrice?: number
  totalDeliveredVat?: number
  createdBy?: User | null
  updatedBy?: User | null

  constructor (data: any) {
    super(data)
    this.date = this.convertDate(data.date)
    this.supplierBranch = data.supplierBranch == null ? null : new CompanyBranch(data.supplierBranch)
    this.subscriber = data.subscriber == null ? null : new Company(data.subscriber)
    this.statement = data.statement == null ? null : new OrderStatement(data.statement)
    this.subscriberBranch = data.subscriberBranch == null ? null : new CompanyBranch(data.subscriberBranch)
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)

    this.items = data.items ? data.items.map((item: any) => new OrderItem(item)) : []
    this.relations('items')

    this.extendSearchString()
  }

  get totalOrderedWithoutVat () {
    return (this.totalPrice || 0) - (this.totalVat || 0)
  }

  get totalDeliveredWithoutVat () {
    return (this.totalDeliveredPrice || 0) - (this.totalDeliveredVat || 0)
  }

  get totalWithoutVat () {
    return this.state === 'NEW' ? this.totalOrderedWithoutVat : this.totalDeliveredWithoutVat
  }

  get totalWithVat () {
    return this.state === 'NEW' ? this.totalPrice : this.totalDeliveredPrice
  }

  get delivered () {
    return this.state === OrderState.DELIVERED
  }

  get billed () {
    return !_.isNil(this.statement)
  }

  get formattedNumber () {
    return this.number ? 'D-' + this.number : null
  }

  set delivered (delivered) {
    if (delivered) {
      this.items.forEach(value => {
        value.delivered = value.ordered
        value.returned = 0
      })
      this.state = OrderState.DELIVERED
    } else {
      this.items.forEach(value => {
        value.delivered = 0
        value.returned = 0
      })
      this.state = OrderState.NEW
    }
  }

  private extendSearchString () {
    this.searchString = ''
    if (this.subscriber) {
      if (this.subscriberBranch) {
        this.searchString += this.subscriber.name
        this.searchString += this.subscriberBranch.searchString
      } else {
        this.searchString = this.subscriber.searchString
      }
    }
  }

  get subscriberAddress () {
    if (this.subscriberBranch) {
      return this.subscriberBranch.address
    } else if (this.subscriber) {
      return this.subscriber.address
    } else {
      return null
    }
  }

  get subscriberName () {
    if (this.subscriberBranch) {
      return this.subscriberBranch.name
    } else if (this.subscriber) {
      return this.subscriber.name
    } else {
      return null
    }
  }

  enrich (data: any) {
    const { itemsMap, partnersMap, company }: { itemsMap: EntityMap<Item>, partnersMap: EntityMap<Company>, company: Company } = data
    if (company) {
      this.supplierBranch = company.branchesMap[this.supplierBranch!.id!]
    }
    if (itemsMap) {
      this.items.forEach(orderItem => {
        orderItem.item = itemsMap[orderItem.item!.id!] || orderItem.item
      })
    }
    if (partnersMap) {
      this.subscriber = partnersMap[this.subscriber!.id!]
      if (this.subscriberBranch) {
        this.subscriberBranch = this.subscriber!.branchesMap[this.subscriberBranch.id!]
      }
    }
    this.extendSearchString()
    super.enrich(data)
    return this
  }
}

export default Order
