import { Entity } from '@/modules/common/domain/entity'
import Worker from '@/modules/worker/domain/worker'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'

export default class WorkerActivity extends Entity {
  worker?: Worker | null
  activity?: WorkingActivity | null
  price?: number | null
  active?: boolean

  constructor (data?: any) {
    super(data, 'id', 'id')

    this.worker = data.worker ? new Worker(data.worker) : null
    this.activity = data.activity ? new WorkingActivity(data.activity) : null

    this.label = this.activity ? this.activity.label : '-'
  }
}
