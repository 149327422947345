import _ from 'lodash'
import EntityState from '@/modules/common/store/entityState'
import { Moment } from 'moment'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import { createGetters } from '@/modules/common/store/entityGetters'
import PriceList from '@/modules/pricelist/domain/priceList'

const getters: GetterTree<EntityState<PriceList>, RootState> = createGetters()

getters.validForDate = (state) => (date: Moment, itemId: number, companyId: number, currency: string) => {
  // filter valid price list by date and required currency
  const validPriceLists = state.items.filter(priceList => priceList.item!.id === itemId && priceList.validInDate(date) && priceList.validForCurrency(currency))
  // try to find newest valid priceList for company
  const validForCompany = _(validPriceLists)
    .filter(priceList => !_.isNil(priceList.company) && priceList.company.id === companyId)
    .sortBy('validFrom')
    .reverse()
    .first()

  if (validForCompany) {
    return validForCompany
  }

  // try to find global valid price list for company
  return _(validPriceLists)
    .filter(priceList => !priceList.company)
    .sortBy('validFrom')
    .reverse()
    .first() || null
}

getters.hasValidPriceList = (state) => (date: Moment, itemId: number, companyId: number, currency: string) => {
  const validPriceLists = state.items.filter(item => item.validInDate(date))
  // try to find newest valid priceList for company
  return _(validPriceLists)
    .filter(priceList => priceList.item!.id === itemId)
    .filter(priceList => !priceList.company || (priceList.company && priceList.company.id === companyId))
    .flatMap('items')
    .chain()
    .some({ currency: currency })
    .value()
}

getters.global = (state) => {
  return state.items.filter(item => !item.company)
}

getters.individual = (state) => {
  return state.items.filter(item => !!item.company)
}

export default getters
