







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import orderStatementForm from '@/modules/order/statement/components/orderStatementForm.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import orderStatementService from '@/modules/order/statement/services/orderStatementService'
import { Getter, State } from 'vuex-class'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'
import Company from '@/modules/company/domain/company'

@Component({
  components: { loading, orderStatementForm, page }
})
export default class OrderStatementCreatePage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, { textKey: 'order-statements.label.short', routeName: 'orderStatements' }, { textKey: 'order-statement.new.label' }])
  statement: OrderStatement | null = null

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  @Prop({ type: String, required: false }) orderId!: string
  @Prop({ type: String, required: false }) subscriberId?: string
  @Prop({ type: String, required: false }) currency?: string
  @Prop({ type: String, required: false }) dateFrom?: string
  @Prop({ type: String, required: false }) dateTo?: string

  async created () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('company/getAllPartners')

    const data = { date: moment() } as any
    if (this.subscriberId && this.currency) {
      this.statement = await orderStatementService.newOrderStatement(Number(this.subscriberId),
        this.currency,
        this.dateFrom ? moment(this.dateFrom) : null,
        this.dateTo ? moment(this.dateTo) : null,
        { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
    } else if (this.orderId) {
      await this.$store.dispatch('order/select', this.orderId)
      const order = await this.$store.dispatch('order/enrich', { itemsMap: this.itemsMap, partnersMap: this.partnersMap, company: this.company })
      data.orders = [order]
      this.statement = new OrderStatement(data)
    } else {
      this.statement = new OrderStatement(data)
    }
  }
}
