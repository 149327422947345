


































import Component from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import { Getter, State } from 'vuex-class'
import Vue from 'vue'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'

@Component({
  components: { noRecords }
})
export default class OrderStatementItemsSummaryBox extends Vue {
  @State('selected', { namespace: 'orderStatement' }) statement!: OrderStatement
  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
}
