





































































import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import textField from '@/modules/common/components/form/textField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import createModal from '@/modules/common/components/createModal'

@Component({
  components: { selectField, textField, checkboxField, formModal }
})
export default class ContactCreateModal extends mixins(createModal) {
    moduleName = 'contact'
}
