























































































































import _ from 'lodash'
import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import deliveryNotesPrint from '@/modules/order/components/print/deliveryNotesPrint.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import notificationService from '@/modules/common/services/notificationService'
import { normalize } from '@/utils'
import { Prop } from 'vue-property-decorator'
import Order from '@/modules/order/domain/order'
import orderDetailBox from '@/modules/order/components/orderDetailBox.vue'
import { OrderFilter } from '@/modules/order/domain/orderFilter'
import ordersDeliveryPrintTable from '@/modules/order/components/print/ordersDeliveryPrintTable.vue'
import { OrderState } from '@/modules/order/type/orderState'
import printMixin from '@/modules/common/mixins/printMixin'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import { PermissionType } from '@/modules/user/type/permissionType'
import Stock from '@/modules/stock/domain/stock'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { booleanIcon, deliveryNotesPrint, checkboxField, loading, noRecords, ordersDeliveryPrintTable, orderDetailBox, textField }
})
export default class DeliveriesTable extends mixins(auditableTooltipMixin, confirmMixin, submitProtectionMixin, printMixin) {
  printDeliveryNotes = true
  printCompact = false
  ordersToPrint: Order[] = []
  readonly MAX_VISIBLE_ITEMS = 300

  @Prop({ type: OrderFilter, required: true }) filter!: OrderFilter
  @Prop({ type: Boolean, default: false }) overview!: boolean
  @Prop({ type: String, required: true }) pageDataId!: string
  @Getter('activePartners', { namespace: 'company' }) companies?: Company[]
  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean
  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]

  @Prop({ type: Array, required: true, default: () => [] }) orders!: Order[]

  // filter orders by query - other parameters for filtering were used in BE
  get items (): Order[] {
    let filteredOrders
    if (this.filter.query) {
      const regexp = new RegExp(normalize(this.filter.query), 'g')
      filteredOrders = this.orders.filter(item => !!normalize(item.searchString).match(regexp))
    } else {
      filteredOrders = this.orders
    }
    return filteredOrders
  }

  get tooManyRecords () {
    return this.orders.length > this.MAX_VISIBLE_ITEMS && this.items.length > this.MAX_VISIBLE_ITEMS
  }

  get deliverAllAvailable () {
    return _.some(this.items, { state: OrderState.NEW })
  }

  get printDeliverNoteEnabled () {
    return _.some(this.items, order => order.subscriber!.deliverNoteEnabled)
  }

  get deliverStock () {
    return _.find(this.stocks, stock => stock.defaultForDispatch && stock.companyBranch!.id === this.filter.companyBranchId) as Stock | null
  }

  editItem (order: Order) {
    this.$store.dispatch('order/clearAll')
    this.$router.push({ name: 'deliveryNoteEdit', params: { orderId: order.id + '' } })
  }

  editStatement (order: Order) {
    this.$store.dispatch('order/clearAll')
    if (order.statement) {
      this.$router.push({ name: 'orderStatementDetail', params: { orderStatementId: order.statement.id + '' } })
    } else {
      this.$router.push({ name: 'orderStatementCreateForOrder', params: { orderId: order.id + '' } })
    }
  }

  printPage (printDeliveryNotes: boolean, printCompact: boolean, order?: Order) {
    this.printDeliveryNotes = printDeliveryNotes
    this.printCompact = printCompact
    this.ordersToPrint = order ? [order] : [...this.items]
    this.print()
  }

  goToDeliveryRoutes () {
    this.$store.dispatch('order/clearAll')
    this.$router.push({ name: 'deliveryRoutes' })
  }

  deliverAll () {
    this.confirm('order.deliver.all.confirmation').then((value) => {
      if (value) {
        const orders = _.filter(this.items, { state: OrderState.NEW })
        orders.forEach((order: Order) => {
          order.delivered = true
        })
        this.$store.dispatch('order/update', new EntityCreateParams(orders, false)).then((result) => {
          if (result) {
            this.$emit('close')
            notificationService.success('entity.edit.success')
            this.$emit('refresh')
          }
        }).finally(this.unprotect)
      }
    })
  }

  async dispatchFromStock () {
    await this.protect()
    await this.$store.dispatch('order/selectItems', this.items)
    await this.unprotect()
    await this.$router.push({ name: 'outcomeStockMovementNew', params: { stockId: this.deliverStock!.id! + '' } })
  }

  async created () {
    if (this.hasPermission(PermissionType.STOCK)) {
      await this.$store.dispatch('stock/getAll')
    }
  }
}
