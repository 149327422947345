import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import workRecordResource from '@/modules/work/record/services/workRecordResource'
import WorkRecord from '@/modules/work/record/domain/workRecord'

const contactModule: Module<EntityState<WorkRecord>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(new EntityService(workRecordResource, WorkRecord), 'work-record')
}

export default contactModule
