import _ from 'lodash'
import EntityService from '@/modules/common/services/entityService'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import moneyBoxResource from '@/modules/money/box/services/moneyBoxResource'
import CashMoneyBox from '@/modules/money/box/domain/cashMoneyBox'
import BankMoneyBox from '@/modules/money/box/domain/bankMoneyBox'
import MoneyBoxBalance from '@/modules/money/box/domain/moneyBoxBalance'

class MoneyBoxService extends EntityService<MoneyBox> {
  constructor () {
    super(moneyBoxResource, MoneyBox)
  }

  createItem (data: any) {
    switch (data['@type']) {
      case 'cash':
        return new CashMoneyBox(data)
      case 'bank':
        return new BankMoneyBox(data)
      default: {
        throw new Error('Unresolved money movement type.')
      }
    }
  }

  async balances (filter: any) {
    const response = await moneyBoxResource.balances(_.pickBy(filter))
    if (response.ok) {
      return response.data.map((moneyBoxBalanceData: any) => new MoneyBoxBalance(moneyBoxBalanceData))
    } else {
      return null
    }
  }
}

export default new MoneyBoxService()
