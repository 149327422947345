







import companiesPriceListTable from '@/modules/pricelist/components/companiesPriceListTable.vue'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import PriceListTable from '@/modules/pricelist/components/priceListTable.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
@Component({
  components: { companiesPriceListTable, PriceListTable }
})
export default class PriceListTables extends Vue {
  @Prop({ type: Number, required: true }) itemId!: number

  async beforeCreate () {
    await this.$store.dispatch('priceList/clearAll')
  }

  async mounted () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('priceList/getAll', new EntityFetchParams(true, { 'item-id': this.itemId }))
  }
}
