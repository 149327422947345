

































import Component, { mixins } from 'vue-class-component'
import incomeStockMovementTypes from '@/modules/stock/movement/type/incomeStockMovementTypes'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import page from '../../../common/components/page.vue'
import printMixin from '@/modules/common/mixins/printMixin'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { page }
})
export default class IncomeStockMovementDetailBox extends mixins(printMixin) {
  incomeStockMovementTypes = incomeStockMovementTypes

  @Prop({ type: IncomeStockMovement, required: true }) movement!: IncomeStockMovement

  edit () {
    this.$router.push({ name: 'incomeStockMovementEdit', params: { movementId: this.movement.id + '' } })
  }
}
