






























































































import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'
import { UserType } from '@/modules/user/type/userType'
import userTypes from '@/modules/user/type/userTypes'
import Worker from '@/modules/worker/domain/worker'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { textField, checkboxField, formModal, radioField, selectField }
})
export default class UserCreateModal extends mixins<createModal<User>>(createModal) {
  moduleName = 'user'
  passwordEnabled = false
  passwordEnabledEditable = true
  type: UserType | null = null
  userTypes = userTypes

  @State('items', { namespace: 'worker' }) workers!: Array<Worker>

  @Watch('item')
  onItemChanged (item: User) {
    if (item && !this.isEdit()) {
      this.passwordEnabled = true
      this.passwordEnabledEditable = false
    }
  }

  @Watch('item.worker')
  onWorkerChanged (worker: Worker) {
    if (this.modelInitialized) {
      if (worker) {
        this.item!.firstname = worker.firstname
        this.item!.surname = worker.surname
      } else {
        this.item!.firstname = null
        this.item!.surname = null
      }
    }
  }

  @Watch('type')
  onTypeChanged (type: UserType) {
    if (type === UserType.USER) {
      this.item!.worker = null
    }
  }

  afterModelSet () {
    if (this.isEdit()) {
      this.type = this.item!.worker ? UserType.WORKER : UserType.USER
    } else {
      this.type = UserType.USER
    }
  }

  beforeSave () {
    if (!this.passwordEnabled) {
      this.item!.password = undefined
    }
  }

  beforeClose () {
    this.passwordEnabled = false
    this.passwordEnabledEditable = true
  }

  mounted () {
    this.$store.dispatch('worker/getAll')
  }
}
