

































import Component, { mixins } from 'vue-class-component'
import OutcomeStockMovement from '@/modules/stock/movement/domain/outcomeStockMovement'
import outcomeStockMovementTypes from '@/modules/stock/movement/type/outcomeStockMovementTypes'
import printMixin from '@/modules/common/mixins/printMixin'
import { Prop } from 'vue-property-decorator'

@Component
export default class OutcomeStockMovementDetailBox extends mixins(printMixin) {
  outcomeStockMovementTypes = outcomeStockMovementTypes

  @Prop({ type: OutcomeStockMovement, required: true }) movement!: OutcomeStockMovement

  edit () {
    this.$router.push({ name: 'outcomeStockMovementEdit', params: { movementId: this.movement.id + '' } })
  }
}
