






































import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Company from '@/modules/company/domain/company'
import companySaleSettingsCreateModal from '@/modules/company/components/companySaleSettingsCreateModal.vue'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { booleanIcon, companySaleSettingsCreateModal, loading }
})
export default class CompanySaleSettingsBox extends Vue {
  showCreateModal: boolean = false
  selectedItem?: any | null = null

  @State('selected', { namespace: 'company' }) company!: Company

  updateShow () {
    this.selectedItem = this.company
    this.showCreateModal = true
  }

  createHide () {
    this.selectedItem = null
    this.showCreateModal = false
  }
}
