import _ from 'lodash'
import { createGetters } from '@/modules/common/store/entityGetters'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import MoneyBoxState from '@/modules/money/box/store/MoneyBoxState'

const getters: GetterTree<MoneyBoxState, RootState> = createGetters()

getters.activeByCurrency = (state, getters) => (currency: string) => {
  return _.filter(getters.active || [], { currency })
}

export default getters
