




















































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import CodeList from '@/modules/common/domain/codeList'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import Currency from '@/modules/currency/domain/currency'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import { OrderFilter } from '@/modules/order/domain/orderFilter'

@Component({
  components: { box, dateTimeField, loading, selectField, textField }
})
export default class OrdersFilterBox extends mixins(bookmarkableComponent) {
  filter = new OrderFilter()
  initialized = false

  @Prop({ type: Boolean, default: true }) showRegion!: boolean
  @Prop({ type: Boolean, default: true }) showQuery!: boolean
  @Prop({ type: Boolean, default: false }) showCurrency!: boolean
  @Prop({ type: String, required: true }) pageId!: string

  @Getter('active', { namespace: 'companyBranch' }) companyBranches?: Array<CompanyBranch>
  @Getter('validItems', { namespace: 'region' }) regions?: Array<CodeList>
  @Getter('validItems', { namespace: 'currency' }) currencies!: Array<Currency>
  @State('owned', { namespace: 'company' }) company!: Company

  @Watch('filter', { deep: true })
  onFilterValueChange () {
    if (this.initialized) {
      this.$emit('filter-changed', _.cloneDeep(this.filter))
    }
  }

  @Watch('pageId', { immediate: true })
  onPageIdChange (pageId: string) {
    this.pageDataId = pageId
  }

  async defaultFilter () {
    this.filter.date = moment()
    this.filter.regionId = null
    this.filter.query = null
    this.filter.companyBranchId = this.companyBranches!.length > 0 ? this.companyBranches![0].id! : null
    this.filter.currency = this.company.defaultCurrency!
  }

  // method to be overridden
  afterSetup () {
    this.$nextTick(() => {
      this.initialized = true
      this.onFilterValueChange()
    })
  }

  async initData () {
    const company = await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('region/getAll')
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('companyBranch/clearAll')
    await this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': company.id }))
  }
}
