






































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import box from '@/modules/common/components/box.vue'
import codeListTable from '@/modules/common/components/codeListTable.vue'
import companyOwnedBranches from '@/modules/company/components/companyOwnedBranches.vue'
import companyOwnedDetail from '@/modules/company/components/companyOwnedDetail.vue'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { companyOwnedBranches, companyOwnedDetail, page, box, codeListTable }
})
export default class CompanySettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'company.label' }])
}
