



























































import _ from 'lodash'
import Component from 'vue-class-component'
import Loading from '../../../common/components/loading.vue'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import moneyMovementTypes from '@/modules/money/movement/type/moneyMovementTypes'
import NoRecords from '../../../common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { NoRecords, Loading }
})
export default class PartnerMovementsTable extends Vue {
  moneyMovementTypes = moneyMovementTypes

  @Prop({ type: Array, required: true }) movements!: MoneyMovement[]

  get currency () {
    const first = _.first(this.movements)
    return first ? first.moneyBox!.currency : null
  }

  get totalAmountFiscal () {
    return _(this.movements).filter('fiscal').sumBy('totalAmount') || 0
  }

  get totalWithoutVatFiscal () {
    return _(this.movements).filter('fiscal').sumBy('totalWithoutVat') || 0
  }

  get totalVatFiscal () {
    return _(this.movements).filter('fiscal').sumBy('totalVat') || 0
  }

  get totalAmountNotFiscal () {
    return _(this.movements).filter(movement => !movement.fiscal).sumBy('totalAmount') || 0
  }
}
