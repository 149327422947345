


















































































































































































































































































import _ from 'lodash'
import BankAccount from '@/modules/bank/account/domain/bankAccount'
import box from '@/modules/common/components/box.vue'
import CodeList from '@/modules/common/domain/codeList'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import Currency from '@/modules/currency/domain/currency'
import currencyValues from '@/modules/common/values/currencies'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import Invoice from '../domain/invoice'
import InvoiceItem from '@/modules/invoice/domain/invoiceItem'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'
import invoiceService from '@/modules/invoice/services/invoiceService'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import invoiceSummaryBox from './invoiceSummaryBox.vue'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import moment, { Moment } from 'moment'
import numberField from '@/modules/common/components/form/numberField.vue'
import { paymentMethods } from '@/modules/common/values'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { checkboxField, vForm, box, selectField, dateTimeField, loading, numberField, textField, invoiceSummaryBox }
})
export default class InvoiceCreateForm extends mixins(validationMixin, confirmMixin) {
  paymentMethods = paymentMethods
  currencyValues = currencyValues

  @Prop({ type: Invoice, required: true }) invoice!: Invoice
  @Prop({ type: Array, required: false, default: () => [] }) subscriberOptions!: Array<Company>
  @Prop({ type: Array, required: false, default: () => [] }) supplierOptions!: Array<Company>

  @Getter('first', { namespace: 'configuration' }) configuration?: Configuration
  @Getter('validItems', { namespace: 'unit' }) units!: Array<CodeList>
  @State('items', { namespace: 'currency' }) currencies? : Currency[]

  get bankAccounts () {
    if (!this.invoice.supplier || !this.invoice.currency) {
      return []
    } else {
      return _.filter(this.invoice.supplier.bankAccounts, { currency: this.invoice.currency })
        .map((bankAccount) => new BankAccount(bankAccount))
    }
  }

  get invoiceSeries () {
    if (!this.invoice || _.isNil(this.invoice.supplier)) {
      return []
    } else {
      return _(this.$store.getters['invoiceSeries/active'])
        .value()
    }
  }

  get vatRates () {
    const list = this.invoice.supplier
      ? this.$store.getters['vatRate/validItems'](this.invoice.dateOfIssue, this.invoice.supplier.address!.country)
      : this.$store.getters['vatRate/validItems'](this.invoice.dateOfIssue)
    return _.unionBy(list, 'rate')
  }

  get outcome () {
    return this.invoice.type === InvoiceType.OUTCOME
  }

  get income () {
    return this.invoice.type === InvoiceType.INCOME
  }

  addItem () {
    this.invoice.items.push(invoiceService.newInvoiceItem(this.invoice.transferredVat ? 0 : null))
  }

  deleteItem (item: InvoiceItem) {
    this.invoice.items = _.without(this.invoice.items, item)
  }

  onSubmit () {
    const operation = this.invoice.id ? 'update' : 'create'
    const invoice = _.cloneDeep(this.invoice)
    this.$store.dispatch('invoice/' + operation, new EntityCreateParams(invoice)).then((result: Invoice) => {
      if (result) {
        notificationService.success('entity.edit.success')
        if (invoice.orderStatement && invoice.orderStatement.id) {
          this.$router.push({ name: 'orderStatementDetail', params: { orderStatementId: invoice.orderStatement.id + '' } })
        } else {
          this.$router.push({ name: invoice.type === InvoiceType.INCOME ? 'invoiceDetailIncome' : 'invoiceDetailOutcome', params: { id: result.id + '' } })
        }
      }
    }).finally(this.unprotect)
  }

  isEdit () {
    return !!(this.invoice && this.invoice.id)
  }

  @Watch('invoice.supplier')
  onInvoiceSupplierChange () {
    // if supplier has been changed, clear bank account if selected
    if (!this.invoice.supplier ||
      (this.invoice.bankAccount && this.invoice.supplier)) {
      this.invoice.bankAccount = null
    }
    // if only one bank account is available, select it
    if (this.invoice.supplier && !this.invoice.bankAccount && this.bankAccounts.length === 1) {
      this.invoice.bankAccount = this.bankAccounts[0]
    }
    // select default invoiceSeries
    if (this.invoice.supplier && !this.invoice.invoiceSeries && this.invoiceSeries) {
      this.invoice.invoiceSeries = _.find(this.invoiceSeries, 'defaultFlag')
    }
  }

  @Watch('invoice.subscriber')
  onInvoiceSubscriberChange (newSubscriber: Company, oldSubscriber: Company) {
    // if subscriber has been changed, set due date by it's settings
    if (this.outcome &&
      newSubscriber &&
      (!oldSubscriber || (newSubscriber.id !== oldSubscriber.id)) &&
      !_.isNil(newSubscriber.saleSettings!.dueDateOffset)) {
      this.invoice.dueDate = moment().add(newSubscriber.saleSettings!.dueDateOffset, 'd')
    }
  }

  @Watch('invoice.currency')
  onInvoiceCurrencyChange () {
    if (!this.invoice.currency ||
      (this.invoice.bankAccount &&
        this.invoice.currency &&
        this.invoice.bankAccount.currency !== this.invoice.currency)) {
      this.invoice.bankAccount = null
    }
    // if only one bank account is available, select it
    if (this.invoice.supplier && !this.invoice.bankAccount && this.bankAccounts.length === 1) {
      this.invoice.bankAccount = this.bankAccounts[0]
    }
  }

  @Watch('invoice.invoiceSeries')
  onInvoiceSeriesChange (series: InvoiceSeries) {
    if (this.invoice && this.outcome && !this.isEdit()) {
      this.invoice.variableSymbol = series ? series.nextVariableSymbol() : null
    }
  }

  @Watch('invoice.transferredVat')
  onTransferredVatChange (newValue: boolean, oldValue: boolean) {
    if (newValue && !oldValue) {
      this.invoice.items.forEach(invoiceItem => { invoiceItem.vatRate = 0 })
    }
  }

  @Watch('invoice.dateOfIssue')
  onInvoiceDateOfIssueChange (newValue: Moment, oldValue: Moment) {
    // check old value - for first appear of edit page, old value is undefined
    if (newValue && (oldValue || !this.isEdit())) {
      this.invoice.taxableDate = moment(newValue)
      if (this.configuration && this.configuration.invoice) {
        this.invoice.dueDate = moment(newValue).add(this.configuration.invoice.defaultDueDateOffset || 0, 'day')
      }
    }
  }

  deleteInvoice () {
    this.confirm('invoice.delete.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('invoice/delete', new EntityDeleteParams(this.invoice.id!, false)).then((result) => {
          if (result) {
            notificationService.success('invoice.delete.success')
            this.$router.push({ name: this.invoice.type === InvoiceType.INCOME ? 'invoicesIncome' : 'invoicesOutcome' })
          }
        }).finally(this.unprotect)
      }
    })
  }

  async created () {
    await this.$store.dispatch('currency/getAll')
    await this.$store.dispatch('invoiceSeries/getAll')
    await this.$store.dispatch('vatRate/getAll')
    await this.$store.dispatch('unit/getAll')
  }
}
