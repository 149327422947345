



































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import Configuration from '@/modules/configuration/domain/configuration'
import Currency from '@/modules/currency/domain/currency'
import documentField from '@/modules/common/components/form/documentField.vue'
import { Getter, State } from 'vuex-class'
import invoiceConfigCreateModal from '@/modules/configuration/components/invoiceConfigCreateModal.vue'
import InvoiceSetting from '@/modules/configuration/domain/invoiceSetting'
import paymentMethods from '@/modules/common/values/paymentMethods'
import selectField from '@/modules/common/components/form/selectField.vue'
import { vatCalculationTypes } from '@/modules/configuration/type/VatCalculationType'
import Vue from 'vue'

@Component({
  components: { box, documentField, selectField, invoiceConfigCreateModal }
})
export default class InvoiceConfigInfoBox extends Vue {
  paymentMethods = paymentMethods
  vatCalculationTypes = vatCalculationTypes
  selectedItem?: Configuration | null = null
  showCreateModal: boolean = false

  @State('items', { namespace: 'currency' }) currencies?: Currency[]
  @Getter('first', { namespace: 'configuration' }) configuration?: Configuration

  createShow () {
    this.selectedItem = _.cloneDeep(this.configuration) || new Configuration({ invoice: new InvoiceSetting({}) })
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  created () {
    this.$store.dispatch('currency/getAll')
  }
}
