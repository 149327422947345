import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import CodeList from '@/modules/common/domain/codeList'
import vatCalculator from '@/modules/finance/services/vatCalculator'

class InvoiceItem extends Entity {
  name?: string
  price?: number
  unit?: CodeList | null
  number?: number
  vatRate: number | null

  constructor (data: any) {
    super(data)
    this.unit = data.unit ? new CodeList(data.unit) : null
    this.vatRate = _.isNil(data.vatRate) ? null : data.vatRate
  }

  get totalPrice () {
    return vatCalculator.calculateTotalWithoutVat(this.price, this.number, this.vatRate)
  }

  get totalVat () {
    return vatCalculator.calculateVat(this.price, this.number, this.vatRate)
  }

  get totalWithVat () {
    return vatCalculator.calculateTotalWithVat(this.price, this.number, this.vatRate)
  }
}

export default InvoiceItem
