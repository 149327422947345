import MoneyMovement from './moneyMovement'
import BankTransaction from '@/modules/bank/account/domain/bankTransaction'
import BankAccount from '@/modules/bank/account/domain/bankAccount'

class BankMoneyMovement extends MoneyMovement {
  bankTransaction?: BankTransaction
  correspondingBankAccount?: BankAccount
  mainMovementId?: bigint

  constructor (data: any) {
    super(data)

    if (!this['@type']) {
      this['@type'] = 'bank'
    }
  }
}

export default BankMoneyMovement
