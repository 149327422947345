









import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component, { mixins } from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import { StockItemFilter } from '@/modules/stock/item/domain/stockItemFilter'
import stockItemsFilterBox from '@/modules/stock/item/components/stockItemsFilterBox.vue'
import stockItemsTable from '@/modules/stock/item/components/stockItemsTable.vue'

@Component({
  components: { page, stockItemsFilterBox, stockItemsTable }
})
export default class StockItemsPage extends mixins(bookmarkableComponent) {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'stock-items.label' }])
  pageDataId = 'stockItems'

  filter = new StockItemFilter()

  async onFilterChanged (filter: StockItemFilter) {
    this.filter = filter
  }
}
