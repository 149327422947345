

























































































































































import Component, { mixins } from 'vue-class-component'
import moneyMovementCreateModalMixin from '@/modules/money/movement/components/moneyMovementCreateModalMixin.vue'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import radioField from '@/modules/common/components/form/radioField.vue'
import moneyMovementTypes from '@/modules/money/movement/type/moneyMovementTypes'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Watch } from 'vue-property-decorator'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import _ from 'lodash'
import CashMoneyMovement from '@/modules/money/movement/domain/cashMoneyMovement'

@Component({
  components: { dateTimeField, checkboxField, formModal, numberField, selectField, staticField, radioField, textField }
})
export default class CashMovementCreateModal extends mixins(moneyMovementCreateModalMixin) {
  moduleName = 'cashMoneyMovement'
  moneyMovementTypes = moneyMovementTypes

  @Watch('item.amountCash')
  @Watch('item.amountCheque')
  onAmountChange () {
    if (this.modelInitialized) {
      // need to use round because of adding of decimal numbers in javascript
      this.movement.totalAmount = _.round((this.movement.amountCash || 0) + (this.movement.amountCheque || 0), 2)
    }
  }

  get movement (): CashMoneyMovement {
    return this.item!
  }

  afterModelSet () {
    if (this.movement.type === MoneyMovementType.OUTCOME) {
      this.movement.amountCash = _.isNil(this.movement.amountCash) ? null : Math.abs(this.movement.amountCash)
      this.movement.amountCheque = _.isNil(this.movement.amountCheque) ? null : Math.abs(this.movement.amountCheque)
      this.movement.totalAmount = _.isNil(this.movement.totalAmount) ? null : Math.abs(this.movement.totalAmount)
      this.movement.totalVat = _.isNil(this.movement.totalVat) ? null : Math.abs(this.movement.totalVat)
    }
  }
}
