import incomeStockMovementPage from '@/modules/stock/movement/components/incomeStockMovementPage.vue'
import incomeStockMovementCreatePage from '@/modules/stock/movement/components/incomeStockMovementCreatePage.vue'
import incomeStockMovementEditPage from '@/modules/stock/movement/components/incomeStockMovementEditPage.vue'
import stockMovementsPage from '@/modules/stock/movement/components/stockMovementsPage.vue'
import outcomeStockMovementCreatePage from '@/modules/stock/movement/components/outcomeStockMovementCreatePage.vue'
import outcomeStockMovementPage from '@/modules/stock/movement/components/outcomeStockMovementPage.vue'
import outcomeStockMovementEditPage from '@/modules/stock/movement/components/outcomeStockMovementEditPage.vue'

export default [
  {
    path: '/stock/movements',
    component: stockMovementsPage,
    name: 'stockMovements',
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/new/outcome/:stockId',
    component: outcomeStockMovementCreatePage,
    name: 'outcomeStockMovementNew',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/edit/outcome/:movementId',
    component: outcomeStockMovementEditPage,
    name: 'outcomeStockMovementEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/outcome/:movementId',
    component: outcomeStockMovementPage,
    name: 'outcomeStockMovement',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/new/income/:stockId',
    component: incomeStockMovementCreatePage,
    name: 'incomeStockMovementNew',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/edit/income/:movementId',
    component: incomeStockMovementEditPage,
    name: 'incomeStockMovementEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/income/:movementId',
    component: incomeStockMovementPage,
    name: 'incomeStockMovement',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  }
]
