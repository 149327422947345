





































import Component, { mixins } from 'vue-class-component'
import { Getter } from 'vuex-class'
import submenuMixin from '@/modules/app/components/sidebar/submenuMixin'

@Component
export default class SettingsSubmenu extends mixins(submenuMixin) {
  pageGroup = 'setting'

  @Getter('hasPermission', { namespace: 'auth' }) hasPermission!: (permission: string) => boolean
  @Getter('hasAnyPermission', { namespace: 'auth' }) hasAnyPermission!: (...permission: string[]) => boolean
}
