import { Entity } from '@/modules/common/domain/entity'
import WorkerActivity from '@/modules/worker/domain/workerActrivity'
import moment from 'moment'
import User from '@/modules/user/domain/user'

export default class WorkRecord extends Entity {
  workerActivity?: WorkerActivity | null
  dateFrom?: moment.Moment | null
  dateTo?: moment.Moment | null
  createdBy?: User | null
  updatedBy?: User | null

  constructor (data?: any) {
    super(data, 'id', 'fullName', ['fullName', 'contract'])

    this.dateFrom = this.convertDate(data.dateFrom)
    this.dateTo = this.convertDate(data.dateTo)

    this.workerActivity = data.workerActivity ? new WorkerActivity(data.workerActivity) : null
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)

    this.extendSearchString()
  }

  get hours () {
    if (!this.dateFrom || !this.dateTo) {
      return 0
    }
    return this.dateTo.diff(this.dateFrom, 'hours', true)
  }

  get price () {
    if (!this.dateFrom || !this.dateTo || !this.workerActivity) {
      return 0
    }
    return this.hours * this.workerActivity.price!
  }

  get duration () {
    if (!this.dateFrom || !this.dateTo) {
      return null
    }
    return moment.duration(this.dateTo.diff(this.dateFrom))
  }

  private extendSearchString () {
    if (this.workerActivity && this.workerActivity.activity) {
      this.searchString += ' ' + this.workerActivity.activity.searchString
    }
    if (this.workerActivity && this.workerActivity.worker) {
      this.searchString += ' ' + this.workerActivity.worker.searchString
    }
    // concat string without whitespaces
    this.searchString += this.searchString!.replace(/\s/g, '')
  }
}
