




















































import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import Currency from '@/modules/currency/domain/currency'
import Invoice from '@/modules/invoice/domain/invoice'
import optionalValue from '@/modules/common/components/optionalValue.vue'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { box, optionalValue }
})
export default class InvoiceSymbolsInfoBox extends Vue {
  @State('selected', { namespace: 'invoice' }) invoice!: Invoice
  @State('items', { namespace: 'currency' }) currencies? : Currency[]

  created () {
    this.$store.dispatch('currency/getAll')
  }
}
