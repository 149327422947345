



































import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Order from '@/modules/order/domain/order'
import Vue from 'vue'
import _ from 'lodash'
import CodeList from '@/modules/common/domain/codeList'
import i18n from '@/i18n'
import { State } from 'vuex-class'

@Component
export default class OrdersDeliveryPrintPaymentSummaryTable extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Array<Order>
  @State('items', { namespace: 'paymentType' }) paymentTypes!: Array<CodeList>

  readonly MISSING_PAYMENT_TYPE_ID = '0'
  ordersByPaymentType: any | null = null

  @Watch('orders')
  onOrdersChanged (orders: Array<Order>) {
    this.ordersByPaymentType = _(orders)
      .groupBy(order => order.subscriber!.saleSettings!.paymentType ? order.subscriber!.saleSettings!.paymentType!.id : this.MISSING_PAYMENT_TYPE_ID)
      .value()
  }

  get filteredPaymentTypes () {
    if (this.ordersByPaymentType) {
      const usedPaymentTypes = _.filter(this.paymentTypes, paymentType => this.ordersByPaymentType[paymentType.id!])
      if (this.ordersByPaymentType[this.MISSING_PAYMENT_TYPE_ID]) {
        usedPaymentTypes.push(new CodeList({ id: this.MISSING_PAYMENT_TYPE_ID, label: i18n.message('payment-method.unknown') }))
      }
      return usedPaymentTypes
    } else {
      return []
    }
  }

  get currency () {
    return this.orders.length ? this.orders[0].currency : null
  }

  totalOrders (paymentTypeId: number) {
    return this.ordersByPaymentType[paymentTypeId].length
  }

  totalPriceWithoutVat (paymentTypeId: number) {
    return _.sumBy(this.ordersByPaymentType[paymentTypeId], 'totalOrderedWithoutVat')
  }

  totalPrice (paymentTypeId: number) {
    return _.sumBy(this.ordersByPaymentType[paymentTypeId], 'totalPrice')
  }

  created () {
    this.$store.dispatch('paymentType/getAll')
  }
}
