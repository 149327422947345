import _ from 'lodash'
import Takings from '@/modules/sale/domain/takings'
import EntityService from '@/modules/common/services/entityService'
import takingsResource from '@/modules/sale/services/takingsResource'
import TakingsTransfer from '@/modules/sale/domain/TakingsTransfer'

class TakingsService extends EntityService<Takings> {
  async transfer (transfer: TakingsTransfer) {
    const response = await takingsResource.transfer({}, _.omitBy(transfer, _.isNil))
    if (response.ok) {
      return new Takings(response.data)
    } else {
      return null
    }
  }
}

export default new TakingsService(takingsResource, Takings)
