import { Entity } from '@/modules/common/domain/entity'
import { Moment } from 'moment'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import moneyBoxService from '@/modules/money/box/services/moneyBoxService'

class MoneyBoxBalance extends Entity {
  totalAmount?: number
  date: Moment | null
  moneyBox: MoneyBox

  constructor (data: any) {
    super(data)
    this.date = this.convertDate(data.date)
    this.moneyBox = moneyBoxService.createItem(data.moneyBox)
  }
}

export default MoneyBoxBalance
