








import _ from 'lodash'
import Component from 'vue-class-component'
import DeliveryNotePrint from '@/modules/order/components/print/deliveryNotePrint.vue'
import Order from '@/modules/order/domain/order'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { DeliveryNotePrint }
})
export default class DeliveryNotesPrint extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Array<Order>

  get deliveryNoteOrders () {
    return _.filter(this.orders, order => order.subscriber!.saleSettings!.deliveryNoteEnabled)
  }
}
