


































import _ from 'lodash'
import Vue from 'vue'
import Component from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import { State } from 'vuex-class'
import Company from '@/modules/company/domain/company'
import partnerAddressBox from './partnerAddressBox.vue'
import partnerBranchAddressBox from './partnerBranchAddressBox.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import companyBranchCreateModal from '@/modules/company/components/companyBranchCreateModal.vue'
import { Watch } from 'vue-property-decorator'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Address from '@/modules/common/domain/address'
import loading from '@/modules/common/components/loading.vue'

@Component({
  components: { box, companyBranchCreateModal, loading, partnerAddressBox, partnerBranchAddressBox }
})
export default class PartnerAddressesBox extends Vue {
  showInactivated = false
  selectedItem?: CompanyBranch | null = null
  showCreateModal: boolean = false
  @State('selected', { namespace: 'company' }) partner!: Company

  get branches () {
    if (this.showInactivated) {
      const all = this.$store.getters['companyBranch/all']
      return _.orderBy(all, 'active', 'desc')
    } else {
      return this.$store.getters['companyBranch/active']
    }
  }

  createShow () {
    this.selectedItem = new CompanyBranch({ company: this.partner, address: new Address(), active: true })
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  @Watch('partner')
  onPartnerChanged (partner: Company) {
    if (partner) {
      this.$store.dispatch('companyBranch/getAll', new EntityFetchParams(true, { 'company-id': partner.id }))
    }
  }

  async created () {
    await this.$store.dispatch('companyBranch/clearAll')
  }
}
