import orderStatementsPage from '@/modules/order/statement/components/orderStatementsPage.vue'
import orderStatementDetailPage from '@/modules/order/statement/components/orderStatementDetailPage.vue'
import orderStatementCreatePage from '@/modules/order/statement/components/orderStatementCreatePage.vue'
import orderStatementEditPage from '@/modules/order/statement/components/orderStatementEditPage.vue'

export default [
  {
    path: '/order-statements',
    name: 'orderStatements',
    component: orderStatementsPage,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/order-statements/new/:currency/:subscriberId/:dateFrom?/:dateTo?',
    component: orderStatementCreatePage,
    name: 'orderStatementCreate',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/order-statements/new/:orderId?',
    component: orderStatementCreatePage,
    name: 'orderStatementCreateForOrder',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/order-statements/edit/:orderStatementId?',
    component: orderStatementEditPage,
    name: 'orderStatementEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  },
  {
    path: '/order-statements/:orderStatementId',
    component: orderStatementDetailPage,
    name: 'orderStatementDetail',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'REGULAR_SALE',
      pageGroup: 'regularSale'
    }
  }
]
