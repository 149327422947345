import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'bank-money-movement'

const customActions = {
  split: { method: 'POST', url: API_ROOT + contextPath + '/split' }
}

export default ((<any>Vue).resource(API_ROOT + contextPath + '{/id}', {}, customActions))
