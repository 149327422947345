



















































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import { OrderStatementPrintFilter } from '@/modules/order/statement/domain/orderStatementPrintFilter'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class OrderStatementPrintModal extends mixins(validationMixin) {
  filterModel: OrderStatementPrintFilter | null = null
  show: boolean = false

  @Prop({ type: OrderStatementPrintFilter }) model!: OrderStatementPrintFilter
  @Prop({ type: Boolean, default: false }) showModal!: boolean

  onSubmit () {
    this.$emit('print', this.filterModel)
    this.unprotect()
  }

  cancel () {
    this.$emit('cancel')
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('model')
  onModelChange (newValue: any) {
    this.filterModel = _.cloneDeep(newValue)
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }
}
