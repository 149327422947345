







































































import Component, { mixins } from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import userCreateModal from '@/modules/user/components/userCreateModal.vue'
import User from '@/modules/user/domain/user'

@Component({
  components: { box, loading, noRecords, userCreateModal }
})
export default class UsersTable extends mixins(entityTableMixin) {
  entityModuleName = 'user'

  permissionsSetting (id: number) {
    this.$router.push({ name: 'userPermissionsSetting', params: { userId: id + '' } })
  }

  created () {
    this.newItemTemplateDefault = () => new User({})
  }
}
