import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'takings'

const customActions = {
  transfer: { method: 'PUT', url: API_ROOT + contextPath + '/transfer' }
}

export default ((<any>Vue).resource(API_ROOT + contextPath + '{/id}', {}, customActions))
