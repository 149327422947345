import { Module } from 'vuex'
import mutations from '@/modules/common/store/entityMutations'
import getters from './moneyMovementGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import BankMoneyMovement from '@/modules/money/movement/domain/bankMoneyMovement'
import moneyMovementActions from '@/modules/money/movement/store/bankMoneyMovementActions'

const moneyBoxModule: Module<EntityState<BankMoneyMovement>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: moneyMovementActions
}

export default moneyBoxModule
