



































































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import codeListTable from '@/modules/common/components/codeListTable.vue'
import Currency from '@/modules/currency/domain/currency'
import currencyCreateModal from '@/modules/currency/components/currencyCreateModal.vue'
import moneyBoxesTable from '@/modules/money/box/components/moneyBoxesTable.vue'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { box, codeListTable, currencyCreateModal, moneyBoxesTable, page }
})
export default class FinanceSettingsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'finance.label' }])

  filterFunction (type: string) {
    return (item: MoneyMovementCategory) => item.type === type
  }

  newItemFunction (type: string) {
    return () => new MoneyMovementCategory({ type })
  }

  newCurrencyFunction () {
    return () => new Currency({})
  }
}
