import _ from 'lodash'
import moment from 'moment'
import Range from '@/modules/common/components/form/range'

export class OrderFilter {
  date: moment.Moment | null = null
  range: Range | null = null
  companyBranchId: number | null = null
  regionId: number | null = null
  currency: string | null = null
  query: string | null = null
  showDetails = false

  get hasValidRange () {
    return this.range && this.range.from && this.range.from.isValid() && this.range.to && this.range.to.isValid()
  }

  get readyForFetch () {
    return this.hasValidRange && !_.isNil(this.companyBranchId) && !_.isNil(this.currency)
  }
}
