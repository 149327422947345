import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import EntityState from '@/modules/common/store/entityState'
import getters from './itemGetters'
import Item from '@/modules/item/domain/Item'
import itemService from '@/modules/item/service/itemService'
import mutations from '@/modules/common/store/entityMutations'
import RootState from '@/store/rootState'

const contactModule: Module<EntityState<Item>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(itemService, 'item')
}

export default contactModule
