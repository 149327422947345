


































import cashFlowBox from '@/modules/finance/components/cashFlowBox.vue'
import cashFlowSummaryBox from '@/modules/finance/components/cashFlowSummaryBox.vue'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import moneyBoxesSummaryBox from '@/modules/finance/components/moneyBoxesSummaryBox.vue'
import page from '@/modules/common/components/page.vue'
import { Prop, Watch } from 'vue-property-decorator'
import taxBaseSummaryBox from '@/modules/finance/components/taxBaseSummaryBox.vue'
import vatDetailBox from '@/modules/finance/components/vatDetailBox.vue'
import vatSummaryBox from '@/modules/finance/components/vatSummaryBox.vue'
import Vue from 'vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'

@Component({
  components: { cashFlowBox, cashFlowSummaryBox, moneyBoxesSummaryBox, page, taxBaseSummaryBox, vatDetailBox, vatSummaryBox }
})
export default class FinanceOverviewPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'finance.label' }, { textKey: 'common.overview' }])

  @Prop({ type: String, required: true }) currency!: string

  // need to clear movements based on currency change, because different currency
  // pages are same route only with different value of currency parameter and destroy method
  // is not called
  @Watch('currency')
  async onCurrencyChange (newCurrency: string, oldCurrency: string) {
    if (newCurrency && oldCurrency && newCurrency !== oldCurrency) {
      await this.$store.dispatch('moneyMovement/clear')
    }
  }

  async created () {
    await this.$store.dispatch('moneyBox/clearAll')
    await this.$store.dispatch('moneyBox/getAll', new EntityFetchParams(true))
  }

  destroyed () {
    this.$store.dispatch('moneyBox/clearAll')
    this.$store.dispatch('moneyMovement/clearAll')
  }
}
