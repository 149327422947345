

import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import createModal from '@/modules/common/components/createModal'
import accountingTypes from '@/modules/money/movement/type/accountingTypes'
import { Getter, State } from 'vuex-class'
import Invoice from '@/modules/invoice/domain/invoice'
import moment from 'moment'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import { Watch } from 'vue-property-decorator'
import { AccountingType } from '@/modules/money/movement/type/accountingType'

@Component
export default class MoneyMovementCreateModalMixin extends mixins<createModal<MoneyMovement>>(createModal) {
  accountingTypes = accountingTypes
  modeInitialized = false
  doFetchAfterDefault = false
  doFetchAfterDelete = false

  @State('items', { namespace: 'invoice' }) invoiceItems!: Array<Invoice>
  @Getter('activePartners', { namespace: 'company' }) subjects?: Array<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  get invoices () {
    const currency = this.item!.moneyBox!.currency
    return _.filter(this.invoiceItems, (invoice: Invoice) => {
      const invoiceType = invoice.type !== this.item!.type // OUTCOME invoice type for INCOME movement and vice versa
      const currencyMatch = invoice.currency === currency
      const subjectMatch = !this.item!.subject || (this.item!.subject.id === (this.item!.type === MoneyMovementType.INCOME ? invoice.subscriber!.id : invoice.supplier!.id))
      const minTotalAmount = (_.isNil(this.item!.totalAmount) && !invoice.paid) || (invoice.unpaidAmount! >= this.item!.totalAmount!) // when total amount was no set yet, allow all invoices to be selected
      return invoiceType && currencyMatch && subjectMatch && minTotalAmount
    })
  }

  get categories () {
    return _.filter(this.$store.getters['moneyMovementCategory/validItems'], { type: this.item!.type })
  }

  get vatRates () {
    return this.item!.date ? _.unionBy(this.$store.getters['vatRate/validItems'](this.item!.date, this.company!.address!.country), 'rate') : []
  }

  get taxableDateDisabled () {
    return !this.item!.fiscal
  }

  beforeSave () {
    if (!this.item!.fiscal) {
      this.item!.totalVat = null
    }
  }

  vatDisabled () {
    return !this.item!.fiscal
  }

  get subjectDisabled () {
    return this.item!.accountingType === AccountingType.INTERNAL
  }

  get invoiceDisabled () {
    return !this.item!.type || this.item!.accountingType === AccountingType.INTERNAL
  }

  afterModelSet () {
    // ensure positive values also for OUTCOME type
    if (this.item!.type === MoneyMovementType.OUTCOME) {
      this.item!.totalAmount = Math.abs(this.item!.totalAmount!)
      this.item!.totalVat = _.isNil(this.item!.totalVat) ? null : Math.abs(this.item!.totalVat)
    }
  }

  afterSuccessDelete () {
    this.afterSuccessSave()
  }

  afterSuccessSave () {
    this.$store.dispatch('moneyMovement/refresh')
    this.$store.dispatch('moneyBox/refresh')
  }

  @Watch('item.type')
  onTypeChange (newType?: MoneyMovementType, oldType?: MoneyMovementType) {
    if (newType && oldType && newType !== oldType) {
      this.item!.invoice = null
    }
  }

  @Watch('item.accountingType')
  onFiscalChange () {
    if (this.item!.fiscal) {
      this.item!.taxableDate = moment(this.item!.date!)
    } else {
      this.item!.taxableDate = null
      this.item!.totalVat = null
    }
    if (this.subjectDisabled) {
      this.item!.subject = null
    }
    if (this.invoiceDisabled) {
      this.item!.invoice = null
    }
  }

  @Watch('item.invoice')
  onItemInvoiceChange (invoice: Invoice) {
    if (invoice && this.modelInitialized) {
      this.item!.accountingType = AccountingType.FISCAL
      this.item!.totalVat = invoice.totalVat
      this.item!.taxableDate = invoice.taxableDate

      if (this.item!.type === MoneyMovementType.INCOME) {
        this.item!.subject = invoice.subscriber
      } else {
        this.item!.subject = invoice.supplier
      }
    }
    if (invoice && _.isNil(this.item!.totalAmount)) {
      this.item!.setTotalAmount(invoice.unpaidAmount!)
    }
  }

  async created () {
    await this.$store.dispatch('company/getOwned')
  }
}
