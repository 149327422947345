

































import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Contact from '@/modules/contact/domain/contact'
import contactCreateModal from '@/modules/contact/components/contactCreateModal.vue'
import Vue from 'vue'

@Component({
  components: { contactCreateModal }
})
export default class PartnerAddressesBox extends Vue {
  selectedItem?: Contact | null = null
  showCreateModal: boolean = false

  @Prop({ type: Boolean, required: false, default: true }) mt?: boolean
  @Prop({ type: Contact, required: true }) contact!: Contact
  @Prop({ type: Boolean, default: true }) editable!: boolean

  updateShow () {
    this.selectedItem = this.contact
    this.showCreateModal = true
  }

  updatedHide () {
    this.showCreateModal = false
    this.selectedItem = null // need to be here to again set fresh copy of model in createModal
  }
}
