



































































































































































import _ from 'lodash'
import formModal from '@/modules/common/components/formModal.vue'
import CashBox from '@/modules/sale/domain/cashbox'
import Company from '@/modules/company/domain/company'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import Takings from '@/modules/sale/domain/takings'

@Component({
  components: { dateTimeField, checkboxField, formModal, numberField, selectField, staticField, textField }
})
export default class TakingsCreateModal extends mixins<createModal<Takings>>(createModal) {
  moduleName = 'takings'

  @Prop({ type: CompanyBranch, required: true }) companyBranch!: CompanyBranch

  @State('owned', { namespace: 'company' }) company?: Company
  @Getter('active', { namespace: 'cashBox' }) cashBoxesAll!: Array<CashBox>

  get cashBoxes (): Array<CashBox> {
    return _.filter(this.cashBoxesAll, cashBox => cashBox.companyBranch!.id === this.companyBranch.id)
  }

  get vatRates () {
    return this.item!.date ? _.unionBy(this.$store.getters['vatRate/validItems'](this.item!.date, this.company!.address!.country), 'rate') : []
  }

  async created () {
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('cashBox/getAll')
    await this.$store.dispatch('vatRate/getAll')
  }
}
