







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import subjectRecordsTable from '@/modules/record/components/subjectRecordsTable.vue'
import Vue from 'vue'

@Component({
  components: { subjectRecordsTable, page }
})
export default class SubjectRecordsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'contacts.label' }, { textKey: 'records.overview.label' }])
}
