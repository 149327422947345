






























import { API_ROOT, FILE_MAX_SIZE, FILE_SUPPORTED_EXTENSIONS } from '@/config'
import eventBus from '@/modules/common/services/eventBus'
import fileUpload from 'vue-upload-component'
import notificationService from '@/modules/common/services/notificationService'
import Document from '@/modules/document/domain/document'
import Component from 'vue-class-component'
import Vue from 'vue'
import { State } from 'vuex-class'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { fileUpload }
})
export default class DocumentUpload extends Vue {
  uploadVisible = false
  files = []
  actionUrl = API_ROOT + 'document'

  @State('accessToken', { namespace: 'auth' }) accessToken!: string
  @Prop({ type: Boolean, default: true }) showFiles!: boolean
  @Prop({ type: String, default: FILE_SUPPORTED_EXTENSIONS }) allowedTypes?: string
  @Prop({ type: Number, default: FILE_MAX_SIZE }) maxSize?: number

  get headers () {
    return { Authorization: 'Bearer ' + this.accessToken }
  }

  showUpload () {
    // send event about activating
    eventBus.$emit('file-upload-show')
    this.uploadVisible = true
  }

  upload () {
    (this.$refs.upload as any).active = true
  }

  stop () {
    (this.$refs.upload as any).active = false
  }

  cancel () {
    this.files.length = 0
    this.hideUpload()
  }

  hideUpload () {
    this.uploadVisible = false
  }

  inputFile (newFile: any, oldFile: any) {
    if (newFile && oldFile && !newFile.active && oldFile.active) {
      // Get response data
      if (newFile && newFile.error) {
        let arg = null
        switch (newFile.error) {
          case 'extension':
            arg = this.allowedTypes || FILE_SUPPORTED_EXTENSIONS
            break
          case 'size':
            arg = this.$options.filters!.fileSize(this.maxSize)
            break
        }
        notificationService.error('error.upload.' + newFile.error, [arg])

        this.files.length = 0
      } else if (oldFile.xhr) {
        //  Get the response status code
        this.$emit('document-uploaded', new Document(newFile.response))
        this.cancel()
      }
    }
  }

  created () {
    // hide if some file upload is activated
    eventBus.$on('file-upload-show', () => {
      this.hideUpload()
    })
  }
}
