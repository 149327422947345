import EntityService from '@/modules/common/services/entityService'
import moment from 'moment'
import orderService from '@/modules/order/services/orderService'
import OrderStatement from '@/modules/order/statement/domain/orderStatement'
import orderStatementResource from '@/modules/order/statement/services/orderStatementResource'
import { OrderState } from '@/modules/order/type/orderState'
import Order from '@/modules/order/domain/order'

class OrderStatementService extends EntityService<OrderStatement> {
  async newOrderStatement (subscriberId: number, currency: string, dateFrom?: moment.Moment | null, dateTo?: moment.Moment | null, enrichData?: any) {
    let orders = await orderService.findAll({
      'subscriber-id': subscriberId,
      'no-statement': true,
      state: OrderState.DELIVERED,
      currency: currency
    }) as Order[]
    if (enrichData) {
      orders.forEach(order => order.enrich(enrichData))
    }
    if (dateFrom) {
      orders = orders.filter((order: Order) => order.date!.isSameOrAfter(dateFrom))
    }
    if (dateTo) {
      orders = orders.filter((order: Order) => order.date!.isSameOrBefore(dateTo))
    }

    const data = { date: moment(), orders }
    return new OrderStatement(data)
  }
}

export default new OrderStatementService(orderStatementResource, OrderStatement)
