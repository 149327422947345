







import _ from 'lodash'
import Component from 'vue-class-component'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryForm from '@/modules/inventory/components/inventoryForm.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'

@Component({
  components: { inventoryForm, loading, page }
})
export default class InventoryEditPage extends Vue {
  inventory?: Inventory | null = null

  @Prop({ type: String, required: true }) inventoryId!: string

  async created () {
    this.inventory = _.cloneDeep(await this.$store.dispatch('inventory/select', Number(this.inventoryId)))
    await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.inventory!.stock!.id, 'active-only': true }))
    await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.inventory!.stock!.id }))
  }
}
