











































import Component, { mixins } from 'vue-class-component'
import BankTxImport from '@/modules/bank/transaction/import/domain/bankTxImport'
import box from '@/modules/common/components/box.vue'
import documentUpload from '@/modules/document/components/documentUpload.vue'
import Document from '@/modules/document/domain/document'
import documentField from '@/modules/common/components/form/documentField.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import notificationService from '@/modules/common/services/notificationService'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'

@Component({
  components: { box, documentUpload, documentField, loading, noRecords }
})
export default class BankTxImportPage extends mixins(entityTableMixin) {
  entityModuleName = 'bankTxImport'
  activeSupport = false

  documentUploaded (document: Document) {
    const bankTxImport = new BankTxImport({ document: document })
    this.$store.dispatch('bankTxImport/create', new EntityCreateParams(bankTxImport, true)).then((result) => {
      if (result) {
        notificationService.success('bank-tx-import.upload.success')
        this.$emit('close')
      }
    })
  }
}
