

























































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { OrderStatementFilter } from '@/modules/order/statement/domain/orderStatementFilter'
import { Prop, Watch } from 'vue-property-decorator'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { Getter, State } from 'vuex-class'
import EntityMap from '@/modules/common/domain/entityMap'
import Item from '@/modules/item/domain/Item'

@Component({
  components: { box, loading, noRecords }
})
export default class OrderStatementsTable extends mixins(entityTableMixin) {
  entityModuleName = 'orderStatement'
  itemsGetter = 'allEnriched'
  activeSupport = false
  // dataReady = false

  @Prop({ type: OrderStatementFilter, required: true }) filter!: OrderStatementFilter

  @Getter('idMap', { namespace: 'item' }) itemsMap!: EntityMap<Item>
  @Getter('partnersIdMap', { namespace: 'company' }) partnersMap!: EntityMap<Company>
  @State('owned', { namespace: 'company' }) company?: Company

  detail (id: number) {
    this.$router.push({ name: 'orderStatementDetail', params: { orderStatementId: id + '' } })
  }

  @Watch('filter.range')
  onFilterRangeChange () {
    this.fetch()
  }

  @Watch('filter.paymentTypeId')
  onFilterPaymentTypeIdChange () {
    this.fetch()
  }

  @Watch('filter.query')
  onFilterQueryChange (query?: string) {
    this.query = query || null
  }

  createFilter () {
    const dateFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null
    return _.pickBy({
      'date-from': dateFrom,
      'date-to': dateTo,
      'payment-type-id': this.filter!.paymentTypeId
    }, _.identity)
  }

  async fetch () {
    await this.$store.dispatch('item/getAll')
    await this.$store.dispatch('company/getAllPartners')
    await this.$store.dispatch('company/getOwned')
    await this.$store.dispatch('orderStatement/getAll', new EntityFetchParams(true, this.createFilter()))
    await this.$store.dispatch('orderStatement/enrichAll', {
      itemsMap: this.itemsMap,
      partnersMap: this.partnersMap,
      company: this.company
    })
  }
}
