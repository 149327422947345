























import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import Inventory from '@/modules/inventory/domain/inventory'
import inventoryDetailBox from '@/modules/inventory/components/inventoryDetailBox.vue'
import inventoryItemsBox from '@/modules/inventory/components/inventoryItemsBox.vue'
import inventoryMovementsSummaryBox from '@/modules/inventory/components/inventoryMovementsSummaryBox.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { inventoryDetailBox, inventoryItemsBox, inventoryMovementsSummaryBox, loading, page }
})
export default class InventoryPage extends Vue {
  breadCrumbData?: BreadCrumbData | null = null

  @Prop({ type: String, required: true }) inventoryId!: string

  @State('selected', { namespace: 'inventory' }) inventory!: Inventory

  async created () {
    await this.$store.dispatch('inventory/select', this.inventoryId)

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' },
      { textKey: 'stock-movements.label.2', routeName: 'stockMovements' },
      { text: this.inventory.formattedNumber! }], 'stockMovements')
  }
}
