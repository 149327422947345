

















import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import takingsOverviewFilterBox from '@/modules/sale/components/takingsOverviewFilterBox.vue'
import Vue from 'vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import _ from 'lodash'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { TakingsOverviewFilter } from '@/modules/sale/domain/takingsOverviewFilter'
import takingsOverviewSummaryBox from '@/modules/sale/components/takingsOverviewSummaryBox.vue'
import notificationService from '@/modules/common/services/notificationService'

@Component({
  components: { takingsOverviewSummaryBox, takingsOverviewFilterBox, page }
})
export default class TakingsOverviewPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'common.sale' }, { textKey: 'takings.overview.label' }])

  filter: TakingsOverviewFilter = new TakingsOverviewFilter()

  async onFilterChanged (filter: TakingsOverviewFilter) {
    this.filter = filter
    this.fetch(filter)
  }

  private fetch (filter: TakingsOverviewFilter) {
    if (this.filter.hasValidRange && this.filter.range!.to!.diff(this.filter.range!.from, 'days') > 365) {
      return notificationService.error('error.range.too.big')
    }
    this.$store.dispatch('takings/getAll', new EntityFetchParams(true, this.createFilter(filter)))
  }

  private createFilter (filter: TakingsOverviewFilter) {
    return _.pickBy({
      'company-branch-ids': filter.companyBranchIds,
      'date-from': FORMAT_SYSTEM_DATE(filter.range!.from),
      'date-to': FORMAT_SYSTEM_DATE(filter.range!.to),
      currency: filter.currency
    }, _.identity)
  }

  created () {
    this.$store.dispatch('takings/clearAll')
  }
}
