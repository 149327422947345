





















































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter, State } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'
import StockItem from '@/modules/stock/item/domain/stockItem'
import Item from '@/modules/item/domain/Item'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import ItemBatch from '@/modules/item/batch/domain/ItemBatch'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class StockMovementFilterModal extends mixins(validationMixin) {
  filterModel: StockMovementFilter | null = null
  show: boolean = false

  @Prop({ type: StockMovementFilter }) model!: StockMovementFilter
  @Prop({ type: Boolean, default: false }) showModal!: boolean

  @Getter('active', { namespace: 'stockItem' }) stockItems!: StockItem[]
  @Getter('validItems', { namespace: 'moneyMovementCategory' }) categories?: MoneyMovementCategory[]
  @Getter('active', { namespace: 'itemBatch' }) itemBatches?: ItemBatch[]
  @State('items', { namespace: 'item' }) items!: Item[]

  get selectedItem () {
    return this.filterModel!.itemId && _.find(this.items, { id: this.filterModel!.itemId })
  }

  get batchEvidenceEnabled () {
    return this.selectedItem ? this.selectedItem.batchEvidence : false
  }

  get availableItems () {
    const availableItemIds = _(this.stockItems!).map('item.id').value()
    return _.filter(this.items, item => availableItemIds.indexOf(item.id) >= 0)
  }

  get availableBatches () {
    if (this.filterModel && this.filterModel.itemId) {
      return _.filter(this.itemBatches, itemBatch => itemBatch!.item!.id === this.filterModel!.itemId)
    } else {
      return []
    }
  }

  onSubmit () {
    this.$emit('filter', this.filterModel)
    this.unprotect()
  }

  cancel () {
    this.$emit('cancel')
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('filterModel.itemEnabled')
  onFilterItemEnabledChange (newValue: boolean) {
    if (!newValue) {
      this.filterModel!.itemId = null
      this.filterModel!.itemBatch = null
    }
  }

  @Watch('filterModel.itemId')
  async onFilterItemChange (itemId?: number, oldItemId?: number) {
    if (itemId && this.batchEvidenceEnabled) {
      await this.$store.dispatch('itemBatch/getAll', new EntityFetchParams(true, { 'stock-id': this.filterModel!.stockId, 'item-id': itemId }))
      if (oldItemId && itemId !== oldItemId) {
        this.filterModel!.itemBatch = null
      }
    } else {
      await this.$store.dispatch('itemBatch/clearAll')
      this.filterModel!.itemBatch = null
    }
  }

  @Watch('model')
  onModelChange (newValue: any) {
    this.filterModel = _.cloneDeep(newValue)
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }
}
