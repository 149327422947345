

































import _ from 'lodash'
import moment from 'moment'
import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import Vue from 'vue'
import WorkRecord from '@/modules/work/record/domain/workRecord'
import { localeCompare } from '@/utils'

@Component({
  components: { noRecords, box, loading }
})
export default class WorkerBox extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: Object, required: true }) dateFrom!: moment.Moment
  @Prop({ type: Object, required: false }) dateTo?: moment.Moment
  @State('latestItems', { namespace: 'workRecord' }) latestRecords?: Array<WorkRecord>

  get items () {
    return _(this.latestRecords)
      .filter(record => record.dateFrom!.isSameOrAfter(this.dateFrom) && (!this.dateTo || record.dateFrom!.isBefore(this.dateTo)))
      .groupBy(workRecord => workRecord.workerActivity!.id)
      .values()
      .map((workRecordsPerActivity: Array<WorkRecord>) => ({
        label: workRecordsPerActivity[0]!.workerActivity!.activity!.label,
        duration: moment.duration(_.sumBy(workRecordsPerActivity, 'hours'), 'hour')
      }))
      .sort(localeCompare('label'))
      .value()
  }

  get totalDuration () {
    return _(this.items)
      .map('duration')
      .reduce((result: moment.Duration, item: moment.Duration) => {
        return result.add(item)
      }, moment.duration(0))
  }
}
