import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/codeListGetters'
import EntityState from '@/modules/common/store/entityState'
import RootState from '@/store/rootState'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import EntityService from '@/modules/common/services/entityService'
import resource from '@/modules/money/movement/category/services/moneyMovementCategoryResource'

const moneyMovementCategoryModule: Module<EntityState<MoneyMovementCategory>, RootState> = {
  namespaced: true,
  state: new EntityState<MoneyMovementCategory>(),
  mutations,
  getters,
  actions: actions(new EntityService<MoneyMovementCategory>(resource, MoneyMovementCategory), 'moneyMovementCategories')
}

export default moneyMovementCategoryModule
