









































import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class Page extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: Object, required: false }) breadCrumbData?: BreadCrumbData
  @Prop({ type: Boolean, default: true }) translateTitle!: boolean

  get detail () {
    return this.breadCrumbData && !_.isNil(this.breadCrumbData.closeRoute)
  }
}
