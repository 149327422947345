import financeSummaryResource from './financeSummaryResource'
import AmountSummary from '../domain/amountSummary'

class FinanceSummaryService {
  async getVatSummaries (currency: string) {
    const response = await financeSummaryResource.vat({ currency })
    if (response.ok) {
      return response.data.map((vatSummaryData: any) => new AmountSummary(vatSummaryData))
    } else {
      return null
    }
  }

  async getTaxBaseSummaries (currency: string) {
    const response = await financeSummaryResource.taxBase({ currency })
    if (response.ok) {
      return response.data.map((taxBaseSummaryData: any) => new AmountSummary(taxBaseSummaryData))
    } else {
      return null
    }
  }
}

export default new FinanceSummaryService()
