




















































































import _ from 'lodash'
import BankMoneyMovement from '@/modules/money/movement/domain/bankMoneyMovement'
import bankMoneyMovementService from '@/modules/money/movement/services/bankMoneyMovementService'
import Component, { mixins } from 'vue-class-component'
import formModal from '@/modules/common/components/formModal.vue'
import i18n from '@/i18n'
import Invoice from '@/modules/invoice/domain/invoice'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import MoneyMovementSplit from '@/modules/money/movement/domain/moneyMovementSplit'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import { State } from 'vuex-class'
import staticField from '@/modules/common/components/form/staticField.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { formModal, numberField, selectField, staticField, textField }
})
export default class BankMovementSpitModal extends mixins(submitProtectionMixin) {
  show: boolean = false
  split = new MoneyMovementSplit()

  @State('items', { namespace: 'invoice' }) invoiceItems!: Array<Invoice>
  @Prop({ type: Boolean, default: false }) showModal!: boolean
  @Prop(Object) model!: BankMoneyMovement

  get invoices () {
    if (!_.isNil(this.split.originalMovement) && !_.isNil(this.split.originalMovement.moneyBox)) {
      const currency = this.split.originalMovement.moneyBox.currency
      return _.filter(this.invoiceItems, (invoice: Invoice) => {
        const currencyMatch = invoice.currency === currency
        const subjectMatch = !this.split.originalMovement!.subject || this.split.originalMovement!.subject.id === invoice.subscriber!.id
        const typeMatch = (invoice.type === InvoiceType.INCOME && this.split.originalMovement!.type === MoneyMovementType.OUTCOME) || (invoice.type === InvoiceType.OUTCOME && this.split.originalMovement!.type === MoneyMovementType.INCOME)
        const minTotalAmount = invoice.unpaidAmount! > 0
        return currencyMatch && subjectMatch && typeMatch && minTotalAmount
      })
    }
  }

  get remainingAmount () {
    if (!_.isNil(this.split.originalMovement) && !_.isNil(this.split.originalMovement.totalAmount)) {
      const sum = _.round(_(this.split.newMovements).map('totalAmount').compact().map(parseFloat).sum(), 2)
      return Math.abs(this.split.originalMovement.totalAmount) - sum
    } else {
      return 0
    }
  }

  onSubmit () {
    if (this.remainingAmount !== 0) {
      notificationService.error('error.bank-money-movement.remainingAmount.not-zero')
      return this.unprotect()
    }

    this.$store.dispatch('bankMoneyMovement/split', this.split).then((result) => {
      if (result) {
        notificationService.success('money-movement.split.success')
        this.$emit('close')
      }
    }).finally(this.unprotect)
  }

  // don't offer invoices with lover unpaid amount than invoice amount or invoice twice
  possibleInvoices (movement: BankMoneyMovement) {
    const invoices2 = (_.map(this.split.newMovements, 'invoice') as Array<Invoice>)
    return _(this.invoices)
      .filter((invoice: Invoice) => invoice.unpaidAmount! >= movement.totalAmount!)
      .without(...invoices2)
      .value()
  }

  addMovement () {
    this.split.newMovements.push(bankMoneyMovementService.newMovement(this.split.originalMovement!))
  }

  deleteMovement (movement: BankMoneyMovement) {
    this.split.newMovements = _.without(this.split.newMovements, movement)
  }

  clearData () {
    this.split.originalMovement = null
    this.split.newMovements = []
    this.split.note = null
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  @Watch('model')
  onModelChange (model: BankMoneyMovement) {
    this.split.originalMovement = _.cloneDeep(model)
    this.split.note = i18n.message('money-movement.split.note')
    this.split.newMovements = [
      bankMoneyMovementService.newMovement(this.split.originalMovement),
      bankMoneyMovementService.newMovement(this.split.originalMovement)
    ]
  }

  @Watch('split.newMovements', { deep: true })
  onNewMovementsChange (newMovements: Array<BankMoneyMovement>, oldNewMovements: Array<BankMoneyMovement>) {
    if (newMovements.length === oldNewMovements.length) {
      newMovements.forEach((newMovement: BankMoneyMovement) => {
        if (newMovement.invoice && !_.isNil(newMovement.totalAmount) && newMovement.totalAmount <= 0) {
          newMovement.totalAmount = newMovement.invoice.unpaidAmount
        }
      })
    }
  }
}
