import Company from '../../company/domain/company'
import Subject from '@/modules/subject/domain/subject'

class SubjectService {
  getSubject (data: any): Subject | null {
    if (!data) {
      return null
    }
    switch (data['@type']) {
      case 'company':
        return new Company(data)
      default: {
        throw new Error('Unresolved subject type.')
      }
    }
  }
}

export default new SubjectService()
