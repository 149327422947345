








import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class StaticField extends Vue {
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String, required: false, default: '' }) labelClass!: string
  @Prop({ type: String, required: false, default: '' }) contentClass!: string
  @Prop({ type: Boolean, default: true }) showLabel!: boolean
}
