
































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import itemBatchesTable from '@/modules/item/batch/components/itemBatchesTable.vue'
import itemInfoBox from '@/modules/item/components/itemInfoBox.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import StockItem from '@/modules/stock/item/domain/stockItem'
import stockItemMovementsTable from '@/modules/stock/item/components/stockItemMovementsTable.vue'
import stockItemStatusBox from '@/modules/stock/item/components/stockItemStatusBox.vue'
import Vue from 'vue'

@Component({
  components: { loading, itemBatchesTable, itemInfoBox, page, stockItemMovementsTable, stockItemStatusBox }
})
export default class StockItemsPage extends Vue {
  breadCrumbData?: BreadCrumbData | null = null

  @Prop({ type: String, required: true }) itemId!: string

  @State('selected', { namespace: 'stockItem' }) stockItem!: StockItem

  async created () {
    await this.$store.dispatch('stockItem/select', Number(this.itemId))

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'stock-items.label', routeName: 'stockItems' }, { text: this.stockItem.item!.name! }], 'stockItems')
  }
}
