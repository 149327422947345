






















































import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import Item from '@/modules/item/domain/Item'
import ItemDispatch from '@/modules/item/domain/ItemDispatch'
import itemDispatchCreateModal from '@/modules/item/components/itemDispatchCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: { itemDispatchCreateModal, loading, noRecords }
})
export default class ItemDispatchTable extends mixins(entityTableMixin) {
  entityModuleName = 'itemDispatch'
  activeSupport = false

  @Prop({ type: Item, required: true }) item!: Item
  @State('items', { namespace: 'itemDispatch' }) itemDispatches?: ItemDispatch[]

  async created () {
    const parameters: any = { 'item-id': this.item.id }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    this.newItemTemplateDefault = () => new ItemDispatch({ item: this.item })
  }

  async beforeFetch () {
    await this.$store.dispatch('itemDispatch/clearAll')
  }
}
