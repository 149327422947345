import inventoryCreatePage from '@/modules/inventory/components/inventoryCreatePage.vue'
import inventoryEditPage from '@/modules/inventory/components/inventoryEditPage.vue'
import inventoryPage from '@/modules/inventory/components/inventoryPage.vue'

export default [
  {
    path: '/stock/movements/inventory/:inventoryId',
    component: inventoryPage,
    name: 'inventory',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/item/inventory/new/:stockId',
    component: inventoryCreatePage,
    name: 'inventoryNew',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    path: '/stock/movements/inventory/edit/:inventoryId',
    component: inventoryEditPage,
    name: 'inventoryEdit',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  }
]
