import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from './vatRateGetters'
import EntityState from '@/modules/common/store/entityState'
import RootState from '@/store/rootState'
import EntityService from '@/modules/common/services/entityService'
import resource from '@/modules/vatRate/services/vatRateResource'
import VatRate from '@/modules/vatRate/domain/vatRate'

const regionModule: Module<EntityState<VatRate>, RootState> = {
  namespaced: true,
  state: new EntityState<VatRate>(),
  mutations,
  getters,
  actions: actions(new EntityService<VatRate>(resource, VatRate), 'vatRate')
}

export default regionModule
