







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import workRecordsWorkerSummaryTable from '@/modules/work/record/components/workRecordsWorkerSummaryTable.vue'

@Component({
  components: { page, workRecordsWorkerSummaryTable }
})
export default class WorkRecordsWorkerSummaryPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'work-record.label' }, { textKey: 'work-record.summary.worker.label' }])
}
