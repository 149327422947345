


















































import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import Range from '@/modules/common/components/form/range'
import selectField from '@/modules/common/components/form/selectField.vue'
import Stock from '@/modules/stock/domain/stock'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'
import textField from '@/modules/common/components/form/textField.vue'
import { Watch } from 'vue-property-decorator'
import StockMovementFilterModal from '@/modules/stock/movement/components/stockMovementFilterModal.vue'

@Component({
  components: { StockMovementFilterModal, selectField, dateRangeField, textField, loading }
})
export default class StockMovementFilterBox extends mixins(bookmarkableComponent) {
  pageDataId = 'stockMovements'
  filter = new StockMovementFilter()
  showFilterModal = false
  filterModel: StockMovementFilter | null = null

  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]

  @Watch('filter.stockId')
  onFilterStockIdChanged (newId?: number, oldId?: number) {
    if (newId) {
      if (oldId && newId !== oldId) {
        this.filter.itemEnabled = false
        this.filter.itemId = null
        this.filter.itemBatch = null
      }
      this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'stock-id': this.filter.stockId }))
    } else {
      this.$store.dispatch('stockItem/clearAll')
    }
    this.filterChanged()
  }

  @Watch('filter.range')
  onFilterRangeChanged (rangeNew?: Range, rangeOld?: Range) {
    if (!rangeNew && rangeOld) {
      this.filterChanged()
    }
  }

  doShowFilterModal () {
    this.filterModel = _.cloneDeep(this.filter)
    this.showFilterModal = true
  }

  private filterChanged () {
    this.$emit('filter-changed', this.filter)
  }

  async defaultFilter () {
    this.filter.query = null
    this.filter.stockId = this.stocks!.length > 0 ? this.stocks![0].id! : null
    this.filter.range = new Range(moment().startOf('month').subtract(2, 'month'))
  }

  hideModals () {
    this.showFilterModal = false
  }

  doFilter (filter: StockMovementFilter) {
    _.assign(this.filter, filter)
    this.hideModals()
    this.filterChanged()
  }

  cancelFilter () {
    this.filter.itemEnabled = false
    this.filter.itemId = null
    this.filter.itemBatch = null
    this.hideModals()
    this.filterChanged()
  }

  async initData () {
    await this.$store.dispatch('item/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('stock/getAll')
  }
}
