






























import _ from 'lodash'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import Item from '@/modules/item/domain/Item'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import Recipe from '@/modules/recipe/domain/recipe'
import recipeCreateModal from '@/modules/recipe/components/recipeCreateModal.vue'
import Vue from 'vue'
import { Getter } from 'vuex-class'
import RecipeItemTable from '@/modules/recipe/components/recipeItemTable.vue'

@Component({
  components: { RecipeItemTable, booleanIcon, loading, recipeCreateModal }
})
export default class ItemRecipeBox extends Vue {
  showCreateModal: boolean = false
  selectedItem?: any | null = null
  newRecipe: Recipe | null = null

  @Prop({ type: Item, required: true }) item!: Item

  @Getter('all', { namespace: 'recipe' }) recipes!: Array<Recipe>

  get recipe () {
    const firstRecipe = _.first(this.recipes)
    return !firstRecipe ? this.newRecipe : firstRecipe as Recipe
  }

  editItem () {
    this.selectedItem = _.cloneDeep(this.recipe)
    this.showCreateModal = true
  }

  createHide () {
    this.selectedItem = null
    this.showCreateModal = false
  }

  async beforeCreate () {
    await this.$store.dispatch('recipe/clearAll')
  }

  async created () {
    await this.$store.dispatch('recipe/getAll', new EntityFetchParams(true, { 'item-id': this.item.id }))
    this.newRecipe = new Recipe({ item: this.item })
  }
}
