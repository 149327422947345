import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import orderResource from '@/modules/order/services/orderResource'
import Order from '@/modules/order/domain/order'

const entityService = new EntityService<Order>(orderResource, Order)
const orderModule: Module<EntityState<Order>, RootState> = {
  namespaced: true,
  state: new EntityState<Order>(),
  mutations,
  getters,
  actions: actions(entityService, 'order')
}

export default orderModule
