export class OrderStatementPrintFilter {
  statement: boolean
  invoices: boolean
  deliveryNotes: boolean

  constructor (statement: boolean, invoices: boolean, deliveryNotes: boolean) {
    this.statement = statement
    this.invoices = invoices
    this.deliveryNotes = deliveryNotes
  }
}
