










































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import Item from '@/modules/item/domain/Item'
import numberField from '@/modules/common/components/form/numberField.vue'
import RecipeItem from '@/modules/recipe/domain/recipeItem'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { formModal, checkboxField, numberField, selectField, textField }
})
export default class RecipeItemCreateModal extends mixins<createModal<RecipeItem>>(createModal) {
  moduleName = 'recipeItem'

  @Getter('activeForStock', { namespace: 'item' }) items!: Item[]

  get availableItems () {
    const usedItemIds = _.map(this.item!.recipe!.items, 'item.id')
    usedItemIds.push(this.item!.recipe!.item!.id)
    return _.filter(this.items, item => (usedItemIds.indexOf(item.id) < 0))
  }

  validationObject () {
    const validationObj = { required: true, max_value: 9999999999, min_value: 0 } as any
    const item = this.item!.item
    if (item) {
      if (item.scale === 0) {
        validationObj.numeric = true
        validationObj.min_value = 1
      } else if (item.scale! > 0) {
        validationObj.decimal = item.scale
        validationObj.min_value = _.round(1 / (10 ** item.scale!))
      }
    }
    return validationObj
  }

  async afterSuccessDelete () {
    await this.$store.dispatch('recipe/refresh')
  }

  async afterSuccessSave (item: any) {
    await this.$store.dispatch('recipe/refresh')
  }

  async created () {
    await this.$store.dispatch('item/getAll')
  }
}
