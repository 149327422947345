var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("translate")('payment-types.label'))+" ")]},proxy:true}])},[_c('code-list-table',{attrs:{"module-name":"paymentType","new-item-template":_vm.newPaymentType(),"loading-id":"paymentType"},scopedSlots:_vm._u([{key:"modal",fn:function(ref){
var showCreateModal = ref.showCreateModal;
var selectedItem = ref.selectedItem;
var createHide = ref.createHide;
return [_c('payment-type-create-modal',{attrs:{"show-modal":showCreateModal,"model":selectedItem},on:{"close":function($event){return createHide()}}})]}},{key:"table",fn:function(ref){
var items = ref.items;
var editItem = ref.editItem;
var positionUp = ref.positionUp;
var positionDown = ref.positionDown;
return [_c('div',{staticClass:"table-responsive"},[(items.length)?_c('table',{staticClass:"table no-padding table-hover table-striped table-sm"},[_c('thead',[_c('tr',{staticClass:"bg-gray"},[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm._f("translate")('common.name')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("translate")('order-statement.label.short')))]),_c('th')])]),_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:item.id,class:{inactive : !item.valid}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.label))]),_c('td',{staticClass:"text-center"},[_c('boolean-icon',{attrs:{"model":item.statementEnabled}})],1),_c('td',{staticClass:"text-right text-nowrap"},[_c('button',{staticClass:"btn btn-link btn-tool",on:{"click":function($event){$event.preventDefault();return editItem(item)}}},[_c('i',{staticClass:"fas fa-pencil-alt text-muted",attrs:{"title":_vm._f("translate")('common.edit')}})]),(item.valid)?_c('button',{staticClass:"btn btn-link btn-tool",on:{"click":function($event){$event.preventDefault();return positionUp(item)}}},[_c('i',{staticClass:"fas fa-arrow-up text-muted"})]):_vm._e(),(item.valid)?_c('button',{staticClass:"btn btn-link btn-tool",on:{"click":function($event){$event.preventDefault();return positionDown(item)}}},[_c('i',{staticClass:"fas fa-arrow-down text-muted"})]):_vm._e()])])]})],2)]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }