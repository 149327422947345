import Address from '@/modules/common/domain/address'
import BankAccount from '@/modules/bank/account/domain/bankAccount'
import ColorableCodeList from '@/modules/common/domain/colorableCodeList'
import { Entity } from '@/modules/common/domain/entity'

abstract class Subject extends Entity {
  address?: Address
  categories: Array<ColorableCodeList>
  bankAccounts: Array<BankAccount>
  active?: boolean
  '@type': string

  protected constructor (data: any, labelProp: string) {
    super(data, 'id', labelProp)
    this.address = data.address ? new Address(data.address) : undefined
    this.categories = data.categories ? data.categories.map((category: any) => new ColorableCodeList(category)) : []
    this.bankAccounts = data.bankAccounts ? data.bankAccounts.map((bankAccount: any) => new BankAccount(bankAccount)) : []
  }
}

export default Subject
