



























































































import Component, { mixins } from 'vue-class-component'
import formModal from '@/modules/common/components/formModal.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { invoiceSeriesFormats } from '@/modules/invoice/type/InvoiceSeriesFormat'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import createModal from '@/modules/common/components/createModal'
import years from '@/modules/common/values/years'

@Component({
  components: { checkboxField, formModal, numberField, selectField, staticField, textField }
})
export default class invoiceSeriesCreateModal extends mixins(createModal) {
  moduleName = 'invoiceSeries'
  years = years
  invoiceSeriesFormats = invoiceSeriesFormats
}
