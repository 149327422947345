































































import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import noRecords from '@/modules/common/components/noRecords.vue'
import loading from '@/modules/common/components/loading.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import subjectRecordCreateModal from '@/modules/record/components/subjectRecordCreateModal.vue'
import SubjectRecord from '@/modules/record/domain/subjectRecord'

@Component({
  components: { box, loading, noRecords, subjectRecordCreateModal }
})
export default class ContactTable extends mixins(entityTableMixin) {
  entityModuleName = 'contact'

  detail (id: bigint) {
    this.$router.push({ name: 'partnerDetail', params: { partnerId: id + '' } })
  }

  created () {
    this.newItemTemplateDefault = (data: any) => new SubjectRecord({ subject: data.subject, contact: data })
  }

  async beforeFetch () {
    await this.$store.dispatch('contact/clearAll')
  }
}
