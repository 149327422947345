import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import Stock from '@/modules/stock/domain/stock'

class StockItem extends Entity {
  stock: Stock | null
  item: Item | null
  price?: number | null
  amount?: number | null
  minAmount?: number | null
  active?: boolean | null

  constructor (data: any) {
    super(data)

    this.stock = data.stock == null ? null : new Stock(data.stock)
    this.item = data.item == null ? null : new Item(data.item)

    this.extendSearchString()
  }

  get totalPrice () {
    return _.round((this.price || 0) * (this.amount || 0), 2)
  }

  get underMinAmount () {
    return (this.amount || 0) < (this.minAmount || 0)
  }

  get underMinAmountCount () {
    return this.underMinAmount ? ((this.minAmount || 0) - (this.amount || 0)) : 0
  }

  private extendSearchString () {
    if (this.item) {
      this.searchString += ' ' + this.item.searchString
    }
    if (this.stock) {
      this.searchString += ' ' + this.stock.searchString
    }
    // concat string without whitespaces
    this.searchString += this.searchString!.replace(/\s/g, '')
  }
}

export default StockItem
