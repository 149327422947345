


































import box from '@/modules/common/components/box.vue'
import Component from 'vue-class-component'
import Invoice from '@/modules/invoice/domain/invoice'
import loading from '@/modules/common/components/loading.vue'
import { paymentMethods } from '@/modules/common/values'
import { State } from 'vuex-class'
import Vue from 'vue'
import NoRecords from '@/modules/common/components/noRecords.vue'

@Component({
  components: { NoRecords, loading, box }
})
export default class InvoiceDetailInfoBox extends Vue {
  paymentMethods = paymentMethods

  @State('selected', { namespace: 'invoice' }) invoice!: Invoice
}
