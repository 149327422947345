import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import vatCalculator from '@/modules/finance/services/vatCalculator'

export default class PriceListItem extends Entity {
  price?: number
  vatRate?: number | null
  currency?: string | null

  constructor (data: any) {
    super(data)
    this.vatRate = _.isUndefined(this.vatRate) ? null : this.vatRate
  }

  get totalPrice (): number {
    return vatCalculator.calculateTotalWithVat(this.price, 1, this.vatRate) || 0
  }

  get totalVat () {
    return vatCalculator.calculateVat(this.price, 1, this.vatRate)
  }
}
