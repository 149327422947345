





















































import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import { CurrencyPeriodFilter } from '@/modules/company/domain/currencyPeriodFilter'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import { Getter } from 'vuex-class'
import Invoice from '@/modules/invoice/domain/invoice'
import loading from '../../../common/components/loading.vue'
import noRecords from '../../../common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { noRecords, loading }
})
export default class PartnerInvoicesTable extends Vue {
  @Prop({ type: CurrencyPeriodFilter, required: true }) filter!: CurrencyPeriodFilter
  @Prop({ type: Object, required: true }) subject!: Company

  @Getter('all', { namespace: 'invoice' }) invoices!: Invoice[]

  @Watch('filter', { deep: true, immediate: true })
  onFilterChange () {
    this.fetch()
  }

  get meanDueDate () {
    return _(this.invoices).filter('paid').meanBy('paidDays')
  }

  detail (invoice: Invoice) {
    this.$router.push({ name: 'invoiceDetailOutcome', params: { id: invoice.id + '' } })
  }

  async onFilterChanged (filter: CurrencyPeriodFilter) {
    this.filter = filter
    await this.fetch()
  }

  async fetch () {
    await this.$store.dispatch('invoice/getAll', new EntityFetchParams(true, this.createFilter()))
  }

  createFilter () {
    const dateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null
    return _.pickBy({
      dateOfIssueFrom: dateFrom,
      dateOfIssueTo: dateTo,
      type: 'OUTCOME',
      currency: this.filter!.currency,
      'subscriber-id': this.subject.id
    }, _.identity)
  }

  async created () {
    await this.$store.dispatch('invoice/clearAll')
  }
}
