






















import Component from 'vue-class-component'
import IncomeStockMovement from '@/modules/stock/movement/domain/incomeStockMovement'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class IncomeStockMovementTransferBox extends Vue {
  @Prop({ type: IncomeStockMovement, required: true }) movement!: IncomeStockMovement
}
