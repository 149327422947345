import { Entity } from '@/modules/common/domain/entity'
import Item from '@/modules/item/domain/Item'
import vatCalculator from '@/modules/finance/services/vatCalculator'

export default class OrderItem extends Entity {
  item?: Item | null
  ordered?: number | null
  delivered?: number | null
  returned?: number | null
  itemPrice?: number | null
  vatRate?: number | null

  constructor (data: any) {
    super(data)
    this.item = data.item ? new Item(data.item) : null
  }

  get totalPrice () {
    return vatCalculator.calculateTotalWithVat(this.itemPrice, this.ordered, this.vatRate)
  }

  get totalWithoutVat () {
    return vatCalculator.calculateTotalWithoutVat(this.itemPrice, this.ordered, this.vatRate)
  }

  get totalDeliveredPrice () {
    return vatCalculator.calculateTotalWithVat(this.itemPrice, this.delivered, this.vatRate)
  }

  get totalDeliveredWithoutVat () {
    return vatCalculator.calculateTotalWithoutVat(this.itemPrice, this.delivered, this.vatRate)
  }

  get totalVat () {
    return vatCalculator.calculateVat(this.itemPrice, this.ordered, this.vatRate)
  }

  get totalDeliveredVat () {
    return vatCalculator.calculateVat(this.itemPrice, this.delivered, this.vatRate)
  }
}
