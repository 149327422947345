





















import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { ValidationProvider }
})
export default class CheckboxField extends Vue {
  innerValue: boolean = false

  @Prop({ type: Boolean, default: false }) value!: boolean
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String, required: true }) fieldId!: string
  @Prop({ type: String, default: '' }) validation?: string
  @Prop({ type: String }) cssClass?: string
  @Prop({ type: Boolean, default: false }) middle?: boolean
  @Prop({ type: Boolean, default: false }) reverse?: boolean
  @Prop({ type: Boolean, default: false }) toggle?: boolean
  @Prop({ type: Boolean, default: false }) disabled?: boolean

  @Watch('innerValue')
  onInnerValueChange (value: boolean) {
    this.$emit('input', this.reverse ? !value : value)
  }

  @Watch('value')
  onValueChange (value: boolean) {
    this.innerValue = this.reverse ? !value : value
  }

  get requiredVal () {
    return ((this.validation || '').indexOf('required') >= 0)
  }

  created () {
    this.innerValue = this.reverse ? !this.value : this.value
  }
}
