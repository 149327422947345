
























import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Order from '@/modules/order/domain/order'
import ordersDeliveryPrintDetailTable from '@/modules/order/components/print/ordersDeliveryPrintDetailTable.vue'
import Vue from 'vue'
import _ from 'lodash'
import { State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'

@Component({
  components: { ordersDeliveryPrintDetailTable }
})
export default class OrdersDeliveryPrintPaymentSummaryTable extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Array<Order>
  @State('items', { namespace: 'item' }) items!: Array<Item>

  ordersByItem: any | null = null

  @Watch('orders')
  onOrdersChanged (orders: Array<Order>) {
    this.ordersByItem = _(orders)
      .flatMap('items')
      .groupBy('item.id')
      .value()
  }

  get filteredItems () {
    return this.ordersByItem ? _.filter(this.items, item => this.ordersByItem[item.id!]) : []
  }

  totalOrdered (itemId: number) {
    return _.sumBy(this.ordersByItem[itemId], 'ordered')
  }

  created () {
    this.$store.dispatch('item/getAll')
  }
}
