




























import _ from 'lodash'
import CompanyBranch from '@/modules/company/domain/companyBranch'
import Component, { mixins } from 'vue-class-component'
import formModal from '@/modules/common/components/formModal.vue'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import { Getter } from 'vuex-class'
import notificationService from '@/modules/common/services/notificationService'
import { Prop, Watch } from 'vue-property-decorator'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import Stock from '@/modules/stock/domain/stock'
import validationMixin from '@/modules/common/components/form/validationMixin'

@Component({
  components: { formModal, selectField, staticField }
})
export default class MoneyMovementFilterModal extends mixins(validationMixin) {
  companyBranch: CompanyBranch | null = null
  show: boolean = false
  selectedStock: Stock | null = null

  @Prop(Object) model!: CompanyBranch
  @Prop({ type: Boolean, default: false }) showModal!: boolean
  @Getter('active', { namespace: 'stock' }) stocks?: Stock[]

  onSubmit () {
    let stockToUpdate
    if (this.selectedStock) {
      stockToUpdate = _.cloneDeep(this.selectedStock)
      stockToUpdate.defaultForDispatch = true
    } else {
      const currentExpeditionStore = this.currentExpeditionStock(this.companyBranch!)
      if (currentExpeditionStore) {
        stockToUpdate = _.cloneDeep(currentExpeditionStore as Stock)
        stockToUpdate.defaultForDispatch = false
      }
    }

    if (stockToUpdate) {
      this.$store.dispatch('stock/update', new EntityCreateParams(stockToUpdate)).then((result) => {
        if (result) {
          notificationService.success('stock.expedition.update.success')
          this.$emit('close')
        }
      }).finally(this.unprotect)
    } else {
      this.unprotect()
    }
  }

  private currentExpeditionStock (companyBranch: CompanyBranch) {
    return _.find(this.stocks, stock => stock.companyBranch!.id === companyBranch.id && stock.defaultForDispatch)
  }

  get filteredStocks () {
    return _.filter(this.stocks, stock => stock.companyBranch!.id === this.companyBranch!.id)
  }

  cancel () {
    this.$emit('cancel')
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('model')
  onModelChange (newValue: any) {
    this.companyBranch = _.cloneDeep(newValue)
    this.selectedStock = this.currentExpeditionStock(this.companyBranch!) as Stock
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  async created () {
    await this.$store.dispatch('stock/getAll')
  }
}
