







































































import _ from 'lodash'
import { AccountingType } from '@/modules/money/movement/type/accountingType'
import BankMoneyBox from '@/modules/money/box/domain/bankMoneyBox'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import CashMoneyMovement from '@/modules/money/movement/domain/cashMoneyMovement'
import cashMovementCreateModal from '@/modules/money/movement/components/cashMovementCreateModal.vue'
import codeListValueFilter from '@/modules/common/filters/codeListValueFilter'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import exportMixin from '@/modules/common/mixins/exportMixin'
import { Getter } from 'vuex-class'
import i18n from '@/i18n'
import moment from 'moment'
import MoneyBox from '@/modules/money/box/domain/moneyBox'
import moneyBoxBalance from './moneyBoxBalanceBox.vue'
import moneyBoxDelta from './moneyBoxDeltaBox.vue'
import MoneyMovement from '@/modules/money/movement/domain/moneyMovement'
import { MoneyMovementFilter } from '@/modules/money/movement/components/moneyMovementFilter'
import moneyMovementFilterModal from '@/modules/money/movement/components/moneyMovementFilterModal.vue'
import moneyMovementsTable from '@/modules/money/movement/components/moneyMovementsTable.vue'
import moneyMovementTypes from '@/modules/money/movement/type/moneyMovementTypes'
import page from '@/modules/common/components/page.vue'
import Range from '@/modules/common/components/form/range'
import selectField from '@/modules/common/components/form/selectField.vue'
import SortData from '@/modules/common/mixins/sortData'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    dateRangeField,
    cashMovementCreateModal,
    moneyBoxBalance,
    moneyBoxDelta,
    moneyMovementFilterModal,
    moneyMovementsTable,
    page,
    selectField
  }
})
export default class MoneyBoPage extends mixins(bookmarkableComponent, exportMixin) {
  breadCrumbData = new BreadCrumbData([{ textKey: 'finance.label' }, { textKey: 'money-boxes.label' }])
  showCashMovementCreateModal = false
  showFilterModal = false
  selectedMoneyMovement: CashMoneyMovement | null = null
  filterModel: MoneyMovementFilter | null = null
  filter = new MoneyMovementFilter()

  @Getter('active', { namespace: 'moneyBox' }) moneyBoxes!: Array<MoneyBox>
  @Getter('byFilter', { namespace: 'moneyMovement' })
  movementsByFilter!: (filterData: FilterData, sortData: SortData) => Array<MoneyMovement>

  @Watch('filter.moneyBoxId')
  onMoneyBoxIdChanged () {
    this.fetch()
  }

  @Watch('filter.range')
  onRangeChanged (rangeNew?: Range, rangeOld?: Range) {
    if (!rangeNew && rangeOld) {
      this.fetch()
    }
  }

  get selectedMoneyBox () {
    return this.moneyBoxes && this.filter.moneyBoxId ? _.find(this.moneyBoxes, { id: this.filter.moneyBoxId }) : null
  }

  get movements () {
    return this.movementsByFilter(this.filter, this.sortData)
  }

  createCashMoneyMovement () {
    if (this.filter.moneyBoxId) {
      const moneyBox = _.find(this.moneyBoxes, { id: this.filter.moneyBoxId })
      this.selectedMoneyMovement = new CashMoneyMovement({
        moneyBox: moneyBox,
        accountingType: AccountingType.NON_FISCAL,
        date: moment()
      })
      this.showCashMovementCreateModal = true
    }
  }

  doShowFilterModal () {
    this.filterModel = _.cloneDeep(this.filter)
    this.showFilterModal = true
  }

  hideModals () {
    this.showCashMovementCreateModal = false
    this.showFilterModal = false
  }

  doFilter (filter: MoneyMovementFilter) {
    _.assign(this.filter, filter)
    this.hideModals()
    this.fetch()
  }

  cancelFilter () {
    this.filter.typesEnabled = false
    this.filter.categoriesEnabled = false
    this.filter.accountingTypesEnabled = false
    this.filter.types = []
    this.filter.categories = []
    this.filter.accountingTypes = []
    this.hideModals()
    this.fetch()
  }

  isBankMoneybox (moneyboxId: number) {
    return moneyboxId && _.some(this.moneyBoxes, (moneyBox: MoneyBox) => moneyBox.id === moneyboxId && moneyBox instanceof BankMoneyBox)
  }

  defaultFilter () {
    this.sortData.by = 'date'
    this.sortData.asc = false
    this.filter.query = null
    this.filter.range = new Range(moment().subtract(1, 'month'))
    this.filter.typesEnabled = false
    this.filter.categoriesEnabled = false
    this.filter.accountingTypesEnabled = false
    this.filter.categories = []
  }

  async fetch () {
    await this.$store.dispatch('moneyMovement/clearAll')
    await this.$store.dispatch('moneyMovement/getAll', new EntityFetchParams(true, this.createFilter()))
  }

  createFilter () {
    const dateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null
    const dateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid()
      ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null
    return _.pickBy({
      dateFrom,
      dateTo,
      moneyBoxId: this.filter && this.filter.moneyBoxId ? this.filter.moneyBoxId : null,
      categories: this.filter.categoriesEnabled ? this.filter.categories : null,
      types: this.filter.typesEnabled ? this.filter.types : null,
      accountingTypes: this.filter.accountingTypesEnabled ? this.filter.accountingTypes : null
    }, _.identity)
  }

  private toExcel () {
    const data = [[i18n.message('common.date'),
      i18n.message('money-movement.type.label'),
      i18n.message('common.subject'),
      i18n.message('common.category'),
      i18n.message('common.note'),
      i18n.message('common.total'),
      i18n.message('common.vat.share')]]
    this.movements.forEach(movement => {
      data.push([movement.date!.toDate(),
        i18n.message(codeListValueFilter(movement.type!, moneyMovementTypes)!),
        movement.subject ? movement.subject.label : null,
        movement.category ? movement.category.label : null,
        movement.note,
        movement.totalAmount!,
        movement.totalVat! * -1] as Array<any>)
    })

    this.exportToExcel(data, 'money-movements.label')
  }

  async created () {
    await this.fetch()
    await this.$store.dispatch('moneyBox/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('company/getAllPartners', new EntityFetchParams(true))
    await this.$store.dispatch('moneyMovementCategory/getAll', new EntityFetchParams(true))
    await this.$store.dispatch('vatRate/getAll')
    await this.$store.dispatch('invoice/getAll', new EntityFetchParams(true, { state: 'OUTSTANDING' }))
  }

  destroyed () {
    this.$store.dispatch('moneyMovement/clearAll')
  }
}
