















import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import { OrderStatementFilter } from '@/modules/order/statement/domain/orderStatementFilter'
import orderStatementsFilterBox from '@/modules/order/statement/components/orderStatementsFilterBox.vue'
import orderStatementsTable from '@/modules/order/statement/components/orderStatementsTable.vue'
import Page from '@/modules/common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { orderStatementsTable, orderStatementsFilterBox, Page }
})
export default class OrderStatementsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'regular-sale.label' }, { textKey: 'order-statements.label.short' }])

  filter: OrderStatementFilter | null = null

  async onFilterChanged (filter: OrderStatementFilter) {
    this.filter = filter
  }
}
