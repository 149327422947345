



























































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import userCreateModal from '@/modules/user/components/userCreateModal.vue'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'

@Component({
  components: { loading, noRecords, userCreateModal }
})
export default class WorkingActivitiesTable extends mixins<entityTableMixin<WorkingActivity>>(entityTableMixin) {
  entityModuleName = 'workingActivity'

  activeNumber (workingActivity: WorkingActivity) {
    return _.filter(workingActivity.assignments, 'active').length
  }
}
