








































































import _ from 'lodash'
import auditableTooltipMixin from '@/modules/common/mixins/auditableTooltipMixin'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Component, { mixins } from 'vue-class-component'
import dateMonthField from '@/modules/common/components/dateMonthField.vue'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import FilterData from '@/modules/common/mixins/filterData'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Worker from '@/modules/worker/domain/worker'
import workRecordCreateModal from '@/modules/work/record/components/workRecordCreateModal.vue'
import WorkRecord from '@/modules/work/record/domain/workRecord'
import { State } from 'vuex-class'
import Timesheet from '@/modules/timesheet/domain/timesheet'

@Component({
  components: { dateMonthField, dateRangeField, loading, noRecords, workRecordCreateModal }
})
export default class TimeSheetBox extends mixins<entityTableMixin<WorkRecord>>(entityTableMixin, bookmarkableComponent, auditableTooltipMixin) {
  entityModuleName = 'workRecord'
  activeSupport = false
  filter: FilterData = {
    date: null
  }

  @Prop({ type: Worker, required: true }) worker!: Worker
  @State('items', { namespace: 'timesheet' }) timesheets!: Array<Timesheet>
  @Prop({ type: Boolean, default: true }) respectLock!: boolean

  @Watch('filter.date')
  filterChanged (newDate: moment.Moment, oldDate: moment.Moment) {
    // need to check type of value, because watcher from bookmarkableComponent has impact here
    if (oldDate && moment.isMoment(newDate)) {
      this.fetch()
    }
  }

  get locked () {
    if (!this.respectLock) {
      return false
    }
    const timesheet = _.head(this.timesheets)
    return timesheet && timesheet.locked
  }

  defaultFilter () {
    this.filter.date = moment().startOf('month')
  }

  createFilter () {
    return _.pickBy({
      dateFrom: FORMAT_SYSTEM_DATE(this.filter.date.startOf('month')),
      dateTo: FORMAT_SYSTEM_DATE(this.filter.date.endOf('month')),
      workerId: this.worker!.id
    }, _.identity)
  }

  async beforeFetch () {
    await this.$store.dispatch('workRecord/clearAll')
    const parameters = this.createFilter()
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    await this.$store.dispatch('timesheet/getAll', new EntityFetchParams(true, { date: parameters.dateFrom, workerId: this.worker!.id }))
  }

  async created () {
    await this.$store.dispatch('timesheet/clearAll')
    const activities = _.filter(this.worker.activities, 'active')
    this.newItemTemplateDefault = () => new WorkRecord({ dateFrom: moment().startOf('day'), workerActivity: activities.length === 1 ? activities[0] : null })
  }
}
