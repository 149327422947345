


































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import { Entity } from '@/modules/common/domain/entity'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { Getter } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { normalize } from '@/utils'
import { Prop, Watch } from 'vue-property-decorator'
import SortData from '@/modules/common/mixins/sortData'
import Stock from '@/modules/stock/domain/stock'
import StockItem from '@/modules/stock/item/domain/stockItem'
import the from '@/modules/common/components/the.vue'
import numberFilter from '@/modules/common/filters/numberFilter'
import FilterData from '@/modules/common/mixins/filterData'

@Component({
  components: { the, loading, noRecords }
})
export default class StockItemsUnderLimitTable extends mixins<entityTableMixin<StockItem>>(entityTableMixin) {
  entityModuleName = 'stockItem'
  activeSupport = false
  query: string | null = null
  sortData: SortData = new SortData('item.name', true)

  @Prop({ type: Object, required: true }) filter!: FilterData

  @Getter('active', { namespace: 'stock' }) allStocks!: Stock[]
  @Getter('all', { namespace: 'stockItem' }) stockItems!: StockItem[]

  @Watch('filter.stockIds', { deep: true })
  onFilterStockIdChange () {
    this.fetchActionParams.parameters = this.createFilter()
    this.fetch()
  }

  @Watch('filter.query')
  onFilterQueryChange (query: string) {
    this.query = query
  }

  get stocks () {
    if (this.filter.stockIds && this.filter.stockIds.length) {
      return _.filter(this.allStocks, (stock: Stock) => this.filter.stockIds.indexOf(stock.id!) >= 0)
    } else {
      return this.allStocks
    }
  }

  transformItems (items: Entity[]) {
    const filteredStocks = this.stocks!

    const regexp = new RegExp(this.query ? normalize(this.query) : '.*', 'g')

    const itemsByItem = _(items as StockItem[])
      .filter('underMinAmount')
      .filter(item => !!normalize(item.searchString).match(regexp))
      .groupBy('item.id')
      .values()
      .flatMap(group => ({
        item: _.first(group)!.item,
        stockItems: group,
        totalUnderMinAmount: _.round(_.sumBy(group, 'underMinAmountCount'), _.first(group)!.item!.scale),
        searchString: _.first(group)!.item!.searchString
      }))
      .value()

    itemsByItem.forEach((groupItem: any) => {
      filteredStocks.forEach(stock => {
        const stockItemOfStock = _.find(groupItem.stockItems, (stockItem: StockItem) => stockItem.stock!.id === stock.id)
        if (stockItemOfStock) {
          groupItem['stock-' + stock.id] = numberFilter(stockItemOfStock.underMinAmountCount, groupItem.item.precision)
        }
      })
    })

    return itemsByItem
  }

  createFilter () {
    return _.pickBy({
      'stock-ids': this.filter!.stockIds
    }, _.identity)
  }

  async created () {
    await this.$store.dispatch('stock/getAll')
    this.fetchActionParams = new EntityFetchParams(true, this.createFilter())
  }
}
