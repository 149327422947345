












import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import optionalValue from '@/modules/common/components/optionalValue.vue'
import { Prop } from 'vue-property-decorator'
import Worker from '@/modules/worker/domain/worker'
import Vue from 'vue'

@Component({
  components: { optionalValue, loading }
})
export default class WorkerBox extends Vue {
  @Prop({ type: Worker }) worker?: Worker
}
