
































































































import _ from 'lodash'
import Component from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import { Getter } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import InvoiceItem from '@/modules/invoice/domain/invoiceItem'
import { Moment } from 'moment'
import Order from '@/modules/order/domain/order'
import OrderItem from '@/modules/order/domain/orderItem'
import PriceList from '@/modules/pricelist/domain/priceList'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { box, numberField, selectField, staticField, textField }
})
export default class OrderItemForm extends Vue {
  @Prop({ type: Order, required: true }) order!: Order
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: OrderItem, required: true }) orderItem!: OrderItem
  @Prop({ type: Boolean, default: false }) deliveryMode!: boolean
  @Prop({ type: Boolean, default: true }) deleteEnabled!: boolean

  @Getter('active', { namespace: 'item' }) items!: Array<Item>
  @Getter('active', { namespace: 'priceList' }) priceLists!: Array<Item>
  @Getter('validForDate', { namespace: 'priceList' }) validForDate!: (date: Moment, itemId: number, companyId: number, currency: string) => PriceList | null
  @Getter('hasValidPriceList', { namespace: 'priceList' }) hasValidPriceList!: (date: Moment, itemId: number, companyId: number, currency: string) => boolean

  // support only items with valid price list
  get itemsWithPriceList () {
    const usedItemIds = _.map(this.order.items, 'item.id')

    return _(this.items)
      .filter((item: Item) => usedItemIds.indexOf(item.id) < 0) // filter out already added items
      .filter((item: Item) => this.hasValidPriceList(this.order.date!, item.id!, this.order.subscriber!.id!, this.order.currency!)) // filter out items without price list
      .value()
  }

  deleteItem (item: InvoiceItem) {
    this.$emit('delete', item)
  }

  @Watch('orderItem.item', { immediate: true })
  onItemChange (newItem?: Item, oldItem?: Item) {
    // clear price if item has been deleted
    if (!newItem) {
      this.orderItem.itemPrice = null
      // (!oldItem && !this.orderItem.id) => orderItem.Id need to be checked, because immediate
      // flag is active and price could be recomputed during first display in edit mode (if price list was updated)
    } else if ((!oldItem && !this.orderItem.id) || (oldItem && newItem.id !== oldItem.id)) {
      const priceList = this.validForDate(this.order.date!, newItem.id!, this.order.subscriber!.id!, this.order.currency!)
      // find price list
      if (priceList) {
        // select currency item
        const currencyPrice = _.find(priceList.items, { currency: this.order.currency })
        this.orderItem!.itemPrice = currencyPrice ? currencyPrice.price : null
        this.orderItem!.vatRate = currencyPrice ? currencyPrice.vatRate : null
      }
    }
  }

  /**
   * Validation object need to be created dynamic, based on scale settings of "Item" object.
   * @param item selected for OrderItem
   */
  validationObject (item?: Item, minNonZero = true) {
    const validationObj = { required: true, max_value: 9999999999 } as any
    if (!item) {
      return validationObj
    } else if (item.scale === 0) {
      validationObj.numeric = true
      validationObj.min_value = minNonZero ? 1 : 0
    } else if (item.scale! > 0) {
      validationObj.decimal = item.scale
      validationObj.min_value = minNonZero ? _.round(1 / (10 ** item.scale!), item.scale) : 0
    }
    return validationObj
  }
}
