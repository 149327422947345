














































import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import Inventory from '@/modules/inventory/domain/inventory'

@Component
export default class InventoryItemsBox extends Vue {
  @Prop({ type: Inventory, required: true }) inventory!: Inventory
}
