import { Entity } from '@/modules/common/domain/entity'
import { Moment } from 'moment'

class AmountSummary extends Entity {
  dateFrom?: Moment | null
  dateTo?: Moment | null
  amount?: number

  constructor (data: any) {
    super(data)

    this.dateFrom = this.convertDate(this.dateFrom)
    this.dateTo = this.convertDate(this.dateTo)
  }
}

export default AmountSummary
