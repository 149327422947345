import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'money-movement'

const customActions = {
  latest: { method: 'GET', url: API_ROOT + contextPath + '/latest' }
}

export default (<any>Vue).resource(API_ROOT + contextPath + '{/id}', {}, customActions)
