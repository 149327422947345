import EntityService from '@/modules/common/services/entityService'
import itemDispatchResource from '@/modules/item/service/itemDispatchResource'
import ItemDispatch from '@/modules/item/domain/ItemDispatch'

class ItemDispatchService extends EntityService<ItemDispatch> {
  constructor () {
    super(itemDispatchResource, ItemDispatch)
  }
}

export default new ItemDispatchService()
