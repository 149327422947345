







































import _ from 'lodash'
import Vue from 'vue'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import recordWidget from '@/modules/record/components/recordWidget.vue'
import Subject from '@/modules/subject/domain/subject'
import SubjectRecord from '@/modules/record/domain/subjectRecord'
import subjectRecordCreateModal from './subjectRecordCreateModal.vue'
import { State } from 'vuex-class'

@Component({
  components: { recordWidget, loading, subjectRecordCreateModal }
})
export default class SubjectRecordsTimeline extends Vue {
  selectedItem?: SubjectRecord | null = null
  showCreateModal: boolean = false
  @State('items', { namespace: 'subjectRecord' }) records!: Array<SubjectRecord>
  @Prop({ type: Object, required: true }) subject!: Subject

  createShow () {
    this.selectedItem = new SubjectRecord({ subject: this.subject })
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  get recordGroups () {
    return _(this.records || [])
      .groupBy(record => FORMAT_SYSTEM_DATE(record.date))
      .map(records => {
        return { date: records[0].date, records }
      })
      .orderBy('date', 'desc')
      .value()
  }

  beforeMount () {
    this.$store.dispatch('subjectRecord/getAll', new EntityFetchParams(true, { 'subject-id': this.subject.id }))
  }
}
