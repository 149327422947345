






























































































import _ from 'lodash'
import formModal from '@/modules/common/components/formModal.vue'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import moment from 'moment'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Worker from '@/modules/worker/domain/worker'
import { Getter, State } from 'vuex-class'
import WorkRecord from '@/modules/work/record/domain/workRecord'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { compareFunction } from '@/utils'

class TimeModel {
  from?: moment.Moment | null = null
  to?: moment.Moment | null = null
}

@Component({
  components: { dateTimeField, checkboxField, formModal, selectField, staticField, textField }
})
export default class WorkRecordCreateModal extends mixins<createModal<WorkRecord>>(createModal) {
  moduleName = 'workRecord'
  timeModel = new TimeModel()
  selectedWorker: Worker | null = null
  selectedDate: moment.Moment | null = null
  doFetchLatestAfterDelete = true

  @Prop({ type: Worker }) worker?: Worker
  @Prop({ type: Boolean, default: true }) workerEditable!: boolean
  @Getter('active', { namespace: 'worker' }) workers!: Array<Worker>
  @State('items', { namespace: 'worker' }) allWorkers!: Array<Worker>

  @Watch('selectedWorker')
  onWorkerChanged (newValue: Worker, oldValue: Worker) {
    // remove selected workerActivity if worker has change
    if (this.modelInitialized &&
      newValue &&
      oldValue &&
      newValue.id !== oldValue.id) {
      this.item!.workerActivity = null
    }
    if (this.modelInitialized && newValue && !this.item!.workerActivity && this.activities.length === 1) {
      this.item!.workerActivity = this.activities[0]
    }
  }

  get totalDuration () {
    if (this.timeModel.from && this.timeModel.to) {
      const toTime = moment(this.timeModel.to)
      // ensure computing correct duration with "dateTo" of nextDay
      if (this.timeModel.to.isSameOrBefore(this.timeModel.from)) {
        toTime.add(1, 'd')
      }
      return moment.duration(Math.abs(toTime.diff(this.timeModel.from)))
    } else {
      return moment.duration({ hours: 0, minutes: 0 })
    }
  }

  get activities () {
    if (this.selectedWorker) {
      if (this.workerEditable) {
        const value = _(this.allWorkers).filter({ id: this.selectedWorker.id }).flatMap('activities').filter(workerActivity => workerActivity.active && workerActivity.activity.active).value()
        return value.sort(compareFunction('label'))
      } else {
        return _.filter(this.selectedWorker.activities, workerActivity => workerActivity.active && workerActivity.activity!.active).sort(compareFunction('label'))
      }
    }
    return []
  }

  afterModelSet () {
    if (this.isEdit()) {
      this.timeModel.from = moment(this.item!.dateFrom!)
      // ensure same date as "from"
      this.timeModel.to = moment(this.item!.dateFrom!).set({
        hour: this.item!.dateTo!.get('hour'),
        minute: this.item!.dateTo!.get('minute'),
        second: this.item!.dateTo!.get('second'),
        millisecond: this.item!.dateTo!.get('millisecond')
      })
      this.selectedWorker = this.item!.workerActivity!.worker || null
    }
    if (this.item!.dateFrom) {
      this.selectedDate = this.item!.dateFrom
    }
    if (this.worker) {
      this.selectedWorker = this.worker
    }
  }

  beforeSave () {
    this.item!.dateFrom = moment(this.selectedDate!)
    this.item!.dateFrom.set({
      hour: this.timeModel.from!.get('hour'),
      minute: this.timeModel.from!.get('minute'),
      second: this.timeModel.from!.get('second'),
      millisecond: this.timeModel.from!.get('millisecond')
    })
    this.item!.dateTo = moment(this.item!.dateFrom).set({
      hour: this.timeModel.to!.get('hour'),
      minute: this.timeModel.to!.get('minute'),
      second: this.timeModel.to!.get('second'),
      millisecond: this.timeModel.to!.get('millisecond')
    })
    // ensure add 1 day if time "to" is before time "from"
    if (this.item!.dateTo.isSameOrBefore(this.item!.dateFrom)) {
      this.item!.dateTo = this.item!.dateTo.add(1, 'day')
    }
  }

  beforeClose () {
    this.selectedWorker = null
    this.selectedWorker = null
    this.timeModel.from = null
    this.timeModel.to = null
    this.selectedDate = null
  }

  async created () {
    if (this.workerEditable) {
      await this.$store.dispatch('worker/getAll', new EntityFetchParams(true))
    }
  }
}
