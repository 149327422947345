

































import Vue from 'vue'
import Component from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import contactCreateModal from '@/modules/contact/components/contactCreateModal.vue'
import Contact from '@/modules/contact/domain/contact'
import _ from 'lodash'
import loading from '@/modules/common/components/loading.vue'
import contactBox from '@/modules/contact/components/contactBox.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import Company from '@/modules/company/domain/company'
import NoRecords from '@/modules/common/components/noRecords.vue'

@Component({
  components: { NoRecords, box, contactBox, contactCreateModal, loading }
})
export default class PartnerContactsBox extends Vue {
  showInactivated = false
  selectedItem?: Contact | null = null
  showCreateModal: boolean = false

  @Prop({ type: Boolean, default: false }) collapsible!: boolean
  @Prop({ type: Boolean, default: false }) collapsed!: boolean
  @Prop({ type: Boolean, default: true }) editable!: boolean

  @State('selected', { namespace: 'company' }) partner?: Company

  get contacts () {
    if (this.showInactivated) {
      const all = this.$store.getters['contact/all']
      return _.orderBy(all, 'active', 'desc')
    } else {
      return this.$store.getters['contact/active']
    }
  }

  createShow () {
    this.selectedItem = new Contact({ subject: this.partner, active: true })
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }
}
