









import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import moment, { Moment } from 'moment'

@Component
export default class DateMonthField extends Vue {
  innerValue?: moment.Moment | null = null

  @Prop({ type: Object }) value!: Moment

  prevMonth () {
    if (this.innerValue) {
      this.innerValue = this.innerValue.subtract(1, 'month')
      this.$emit('input', moment(this.innerValue))
    }
  }

  nextMonth () {
    if (this.innerValue) {
      this.innerValue = this.innerValue.add(1, 'month')
      this.$emit('input', moment(this.innerValue))
    }
  }

  @Watch('value')
  onValueChange (value?: Moment) {
    this.innerValue = value ? moment(value) : null
  }

  created () {
    if (this.value) {
      this.innerValue = moment(this.value)
    }
  }
}
