




















































import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import OrderItemTemplate from '@/modules/order/domain/orderItemTemplate'
import orderItemTemplateCreateModal from '@/modules/order/components/orderItemTemplateCreateModal.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { loading, noRecords, orderItemTemplateCreateModal }
})
export default class CompanyOrderItemTemplateTable extends mixins(entityTableMixin) {
  entityModuleName = 'orderItemTemplate'
  activeSupport = false

  @Prop({ required: false }) companyId!: number

  async created () {
    const parameters: any = { 'company-id': this.companyId }
    this.fetchActionParams = new EntityFetchParams(true, parameters)
    this.newItemTemplateDefault = () => new OrderItemTemplate({ company: new Company({ id: this.companyId }) })
  }

  async beforeFetch () {
    await this.$store.dispatch('orderItemTemplate/clearAll')
  }
}
