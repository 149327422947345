




























import companyBoxPrint from '@/modules/invoice/components/print/companyBoxPrint.vue'
import Component from 'vue-class-component'
import documentField from '@/modules/common/components/form/documentField.vue'
import Invoice from '@/modules/invoice/domain/invoice'
import invoiceDetailBoxPrint from '@/modules/invoice/components/print/invoiceDetailBoxPrint.vue'
import { Prop } from 'vue-property-decorator'
import invoiceItemsTablePrint from '@/modules/invoice/components/print/invoiceItemsTablePrint.vue'
import invoiceVatSummaryPrint from '@/modules/invoice/components/print/invoiceVatSummaryPrint.vue'
import InvoiceHeaderPrint from '@/modules/invoice/components/print/invoiceHeaderPrint.vue'
import InvoiceSummaryBox from '@/modules/invoice/components/invoiceSummaryBox.vue'
import InvoiceSummaryPrint from '@/modules/invoice/components/print/invoiceSummaryPrint.vue'
import Vue from 'vue'

@Component({
  components: {
    InvoiceSummaryPrint,
    InvoiceSummaryBox,
    InvoiceHeaderPrint,
    companyBoxPrint,
    documentField,
    invoiceVatSummaryPrint,
    invoiceItemsTablePrint,
    invoiceDetailBoxPrint
  }
})
export default class InvoicePrint extends Vue {
  @Prop({ type: Invoice, required: true }) invoice!: Invoice
  @Prop({ type: Boolean, default: true }) updateTitle!: boolean
}
