





























































import _ from 'lodash'
import cashBoxCreateModal from '@/modules/sale/components/cashBoxCreateModal.vue'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { FORMAT_SYSTEM_DATE, normalize } from '@/utils'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'
import { Entity } from '@/modules/common/domain/entity'
import Vue from 'vue'
import { Getter } from 'vuex-class'
import StockMovement from '@/modules/stock/movement/domain/stockMovement'
import Inventory from '@/modules/inventory/domain/inventory'
import StockMovementDescriptionColumn from '@/modules/stock/movement/components/stockMovementDescriptionColumn.vue'

@Component({
  components: { StockMovementDescriptionColumn, cashBoxCreateModal, loading, noRecords }
})
export default class StockMovementsTable extends Vue {
  activeSupport = false
  fetched = false

  @Prop({ type: StockMovementFilter, required: true }) filter!: StockMovementFilter
  @Getter('all', { namespace: 'stockMovement' }) stockMovements!: Array<StockMovement>
  @Getter('all', { namespace: 'inventory' }) inventory!: Array<StockMovement>

  get items (): Array<Entity> {
    const items = _(this.stockMovements).concat(this.inventory)

    if (this.filter.query) {
      const regexp = new RegExp(normalize(this.filter.query), 'g')
      return items.filter(item => !!normalize(item.searchString).match(regexp)).sortBy('date').reverse().value()
    } else {
      return items.sortBy('date').reverse().value()
    }
  }

  @Watch('filter.stockId')
  @Watch('filter.itemId')
  @Watch('filter.itemBatch')
  @Watch('filter.range')
  onFilterStockIdChange () {
    this.fetch()
  }

  detail (item: Inventory | StockMovement) {
    const id = item.id + ''
    if (item instanceof Inventory) {
      this.$router.push({ name: 'inventory', params: { inventoryId: id } })
    } else if (item.income) {
      this.$router.push({ name: 'incomeStockMovement', params: { movementId: id } })
    } else {
      this.$router.push({ name: 'outcomeStockMovement', params: { movementId: id } })
    }
  }

  createFilter () {
    return _.pickBy({
      'stock-id': this.filter!.stockId,
      'date-from': this.filter.range && this.filter.range.from ? FORMAT_SYSTEM_DATE(this.filter.range!.from) : null,
      'date-to': this.filter.range && this.filter.range.to ? FORMAT_SYSTEM_DATE(this.filter.range!.to) : null,
      'item-id': this.filter.itemId,
      'item-batch-number': this.filter.itemBatch
    }, _.identity)
  }

  async fetch () {
    this.fetched = false
    const filter = this.createFilter()
    await this.$store.dispatch('stockMovement/clearAll')
    await this.$store.dispatch('inventory/clearAll')
    await this.$store.dispatch('stockMovement/getAll', new EntityFetchParams(true, filter))
    await this.$store.dispatch('inventory/getAll', new EntityFetchParams(true, filter))
    this.fetched = true
  }

  async beforeMount () {
    await this.fetch()
  }
}
