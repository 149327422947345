








































import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import Item from '@/modules/item/domain/Item'
import itemBatchesTable from '@/modules/item/batch/components/itemBatchesTable.vue'
import itemDispatchTable from '@/modules/item/components/itemDispatchTable.vue'
import itemInfoBox from '@/modules/item/components/itemInfoBox.vue'
import itemRecipeBox from '@/modules/item/components/itemRecipeBox.vue'
import itemStockInfoBox from '@/modules/item/components/itemStockInfoBox.vue'
import itemStockItemsTable from '@/modules/item/components/itemStockItemsTable.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '../../common/components/page.vue'
import priceListTables from '@/modules/pricelist/components/priceListTables.vue'
import PriceList from '@/modules/pricelist/domain/priceList'
import { Prop } from 'vue-property-decorator'
import StockItem from '@/modules/stock/item/domain/stockItem'
import Vue from 'vue'

@Component({
  components: { itemBatchesTable, itemStockInfoBox, itemDispatchTable, itemRecipeBox, itemInfoBox, itemStockItemsTable, loading, page, priceListTables }
})
export default class ItemDetailPage extends Vue {
  breadCrumbData: BreadCrumbData | null = null
  initialized = false
  noStock = false

  @Prop({ type: String, required: true }) itemId!: string
  @State('loadingData', { namespace: 'app' }) loading?: Array<string>
  @State('selected', { namespace: 'item' }) item!: Item
  @State('items', { namespace: 'priceList' }) priceLists!: PriceList[]
  @Getter('active', { namespace: 'stockItem' }) stockItems!: StockItem[]

  get showNoPriceList () {
    return this.initialized && this.item && this.item.forPurchase && this.priceLists.length === 0
  }

  setMissingStock () {
    if (this.item.forStock) {
      this.noStock = this.stockItems.length === 0
    }
  }

  async created () {
    await this.$store.dispatch('item/select', this.itemId)
    if (this.item.forStock) {
      await this.$store.dispatch('stockItem/getAll', new EntityFetchParams(true, { 'item-id': this.itemId }))
      this.setMissingStock()
    }
    this.initialized = true

    this.breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'items.label', routeName: 'itemSettings' }, { text: this.item.name! }], 'itemSettings')
  }
}
