import stockItemsPage from '@/modules/stock/item/components/stockItemsPage.vue'
import stockItemsOverviewPage from '@/modules/stock/item/components/stockItemsOverviewPage.vue'
import stockItemDetailPage from '@/modules/stock/item/components/stockItemDetailPage.vue'

export default [
  {
    name: 'stockItemsOverview',
    path: '/stock/item-overview',
    component: stockItemsOverviewPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    name: 'stockItems',
    path: '/stock/item',
    component: stockItemsPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  },
  {
    name: 'stockItem',
    path: '/stock/item/:itemId',
    component: stockItemDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresPermission: 'STOCK',
      pageGroup: 'stock'
    }
  }
]
