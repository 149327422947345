import _ from 'lodash'
import Company from '@/modules/company/domain/company'
import Invoice from '../domain/invoice'
import InvoiceItem from '../domain/invoiceItem'
import InvoiceSetting from '../../configuration/domain/invoiceSetting'
import InvoiceSeries from '@/modules/invoice/domain/invoiceSeries'
import { InvoiceType } from '@/modules/invoice/type/InvoiceType'
import moment from 'moment'
import orderStatementService from '@/modules/order/statement/services/orderStatementService'
import itemService from '@/modules/item/service/itemService'

export default {
  newOutcomeInvoice (invoiceSetting: InvoiceSetting, supplier: Company, invoiceSeries: Array<InvoiceSeries>) {
    const invoice = new Invoice({
      invoiceSeries: _(invoiceSeries).filter('defaultFlag').first(),
      supplier,
      type: InvoiceType.OUTCOME,
      dateOfIssue: moment(),
      taxableDate: moment(),
      items: [this.newInvoiceItem()]
    })
    if (invoiceSetting) {
      invoice.dueDate = moment().add(invoiceSetting.defaultDueDateOffset || 0, 'd')
      invoice.currency = invoiceSetting.defaultCurrency
      invoice.paymentMethod = invoiceSetting.defaultPaymentMethod
      invoice.bankAccount = invoiceSetting.defaultBankAccount
    }
    return invoice
  },
  async newOutcomeForOrderStatement (orderStatementId: string, invoiceSetting: InvoiceSetting, supplier: Company, invoiceSeries: Array<InvoiceSeries>) {
    const invoice = this.newOutcomeInvoice(invoiceSetting, supplier, invoiceSeries)
    const statement = await orderStatementService.find(orderStatementId)
    const itemsMap = _.keyBy(await itemService.findAll({}), 'id')
    if (statement) {
      invoice.subscriber = statement.subscriber
      invoice.reference = statement.formattedNumber || ''
      invoice.currency = statement.currency!
      invoice.orderStatement = statement
      if (!_.isNil(statement.subscriber!.saleSettings!.dueDateOffset)) {
        invoice.dueDate = moment().add(statement.subscriber!.saleSettings!.dueDateOffset, 'd')
      }
      invoice.items = []
      statement.itemSummaries.forEach(summaryItem => {
        invoice.items.push(new InvoiceItem({
          name: itemsMap[summaryItem.item.id!].description,
          price: summaryItem.price,
          unit: itemsMap[summaryItem.item.id!].unit,
          number: summaryItem.delivered,
          vatRate: summaryItem.vatRate
        }))
      })
    }
    return invoice
  },
  newIncomeInvoice (invoiceSetting: InvoiceSetting, subscriber: Company) {
    const invoice = new Invoice({
      type: InvoiceType.INCOME,
      subscriber,
      dateOfIssue: moment(),
      taxableDate: moment()
    })
    if (invoiceSetting) {
      invoice.dueDate = moment().add(invoiceSetting.defaultDueDateOffset || 0, 'd')
      invoice.currency = invoiceSetting.defaultCurrency
      invoice.paymentMethod = invoiceSetting.defaultPaymentMethod
    }
    return invoice
  },
  newInvoiceItem (vatRate?: number | null) {
    return new InvoiceItem({
      vatRate
    })
  }
}
