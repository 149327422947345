



























import Component from 'vue-class-component'
import Order from '@/modules/order/domain/order'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class OrderDetailBox extends Vue {
  @Prop({ type: Order, required: true }) order!: Order
  @Prop({ type: Boolean, default: false }) show!: boolean
}
