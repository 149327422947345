




















































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import Component, { mixins } from 'vue-class-component'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import Worker from '@/modules/worker/domain/worker'
import { Prop, Watch } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import WorkingActivity from '@/modules/workingactivity/domain/workingActivity'
import WorkerActivity from '@/modules/worker/domain/workerActrivity'
import Company from '@/modules/company/domain/company'

@Component({
  components: { box, checkboxField, numberField, textField, vForm }
})
export default class WorkingActivityForm extends mixins(validationMixin) {
  activityWorkers: Array<number> = []

  @Prop({ type: WorkingActivity, required: true }) activity!: WorkingActivity
  @Getter('active', { namespace: 'worker' }) workers!: Array<Worker>
  @State('owned', { namespace: 'company' }) company?: Company

  @Watch('activity')
  onActivityChanges () {
    this.extractActivityWorkers()
  }

  onSubmit () {
    const operation = 'workingActivity/' + (this.activity.id ? 'update' : 'create')
    this.$store.dispatch(operation, new EntityCreateParams(this.activity)).then((result: Worker) => {
      if (result) {
        notificationService.success('entity.edit.success')
        this.$router.push({ name: 'workingActivities' })
      }
    }).finally(this.unprotect)
  }

  async toggleWorker (worker: Worker) {
    const workerActivity = _.find(this.activity.assignments, workerActivity => workerActivity.worker!.id === worker.id)
    if (workerActivity && workerActivity.id) {
      workerActivity.active = !workerActivity.active
    } else if (workerActivity) {
      this.activity.assignments = _.filter(this.activity.assignments, workerActivity => workerActivity.worker!.id !== worker.id)
    } else {
      this.activity.assignments.push(new WorkerActivity({ activity: { id: this.activity.id }, worker: { id: worker.id }, price: 0, active: true }))
    }
    this.extractActivityWorkers()
  }

  assignment (worker: Worker) {
    return _.find(this.activity.assignments, workerActivity => workerActivity.worker!.id === worker.id)
  }

  hasAssignment (worker: Worker) {
    return this.activityWorkers.lastIndexOf(worker.id!) >= 0
  }

  private extractActivityWorkers () {
    if (this.activity) {
      this.activityWorkers = _(this.activity.assignments).filter('active').map(workerActivity => workerActivity.worker!.id!).value()
    }
  }

  mounted () {
    this.extractActivityWorkers()
  }
}
