



































































import _ from 'lodash'
import box from '@/modules/common/components/box.vue'
import booleanIcon from '@/modules/common/components/icon/booleanIcon.vue'
import Component, { mixins } from 'vue-class-component'
import companyPriceListCreateModal from '@/modules/company/components/companyPriceListCreateModal.vue'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import itemCreateModal from '@/modules/item/components/itemCreateModal.vue'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop } from 'vue-property-decorator'
import PriceList from '@/modules/pricelist/domain/priceList'
import priceListService from '@/modules/pricelist/service/priceListService'
import { Getter } from 'vuex-class'
import Currency from '@/modules/currency/domain/currency'
import PriceListItem from '@/modules/pricelist/domain/priceListItem'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'

@Component({
  components: { booleanIcon, box, companyPriceListCreateModal, itemCreateModal, loading, noRecords, selectField }
})
export default class ItemsTable extends mixins(entityTableMixin) {
  entityModuleName = 'priceList'
  activeSupport = false
  showAll = false

  @Prop({ required: false }) companyId!: number

  @Getter('validItems', { namespace: 'currency' }) currencyList!: Array<Currency>

  get priceListItems (): Array<any> {
    let priceLists: Array<PriceList>
    if (this.showAll) {
      priceLists = _.sortBy(this.items as Array<PriceList>, 'item.name')
    } else {
      priceLists = _((this.items as Array<PriceList>))
        .groupBy('item.id')
        .values()
        .map(priceLists => _.find(priceLists, priceList => priceList.validInDate(moment()))) // sorted by date from BE, so just take valid
        .sortBy('item.name')
        .value() as Array<PriceList>
    }
    return _.flatMap(priceLists, (priceList: PriceList) =>
      _.map(priceList.items as Array<PriceListItem>, (priceListItem: PriceListItem) => ({ priceList, priceListItem })))
  }

  created () {
    this.$store.dispatch('priceList/clearAll')
    this.$store.dispatch('currency/getAll')
    this.fetchActionParams = new EntityFetchParams(true, { 'include-global': false, 'company-id': this.companyId })
    this.newItemTemplateDefault = () => priceListService.newPriceList([], null, this.companyId)
  }
}
