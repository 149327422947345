
















































































































import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import i18n from '@/i18n'
import { localeCompare } from '@/utils'
import MoneyMovementCategory from '@/modules/money/movement/category/domain/moneyMovementCategory'
import { MoneyMovementFilter } from '@/modules/money/movement/components/moneyMovementFilter'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import radioField from '@/modules/common/components/form/radioField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import { MoneyMovementType } from '@/modules/money/movement/type/moneyMovementType'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, radioField, selectField, textField }
})
export default class MoneyMovementFilterModal extends mixins(validationMixin) {
  filterModel: MoneyMovementFilter | null = null
  show: boolean = false
  UNCATEGORIZED: MoneyMovementCategory = new MoneyMovementCategory({ id: -1, label: i18n.message('money-movement.category.UNCATEGORIZED') })

  @Prop({ type: MoneyMovementFilter }) model!: MoneyMovementFilter
  @Prop({ type: Boolean, default: false }) showModal!: boolean

  @Getter('validItems', { namespace: 'moneyMovementCategory' }) categories?: MoneyMovementCategory[]

  get categoryItems () {
    return _(this.categories)
      .filter((category: MoneyMovementCategory) => !this.filterModel || !this.filterModel.typesEnabled || this.filterModel.types!.indexOf(MoneyMovementType[category.type!]) >= 0)
      .sort(localeCompare('label'))
      .push(this.UNCATEGORIZED)
      .value()
  }

  onSubmit () {
    this.$emit('filter', this.filterModel)
    this.unprotect()
  }

  cancel () {
    this.$emit('cancel')
  }

  onClose () {
    this.$emit('close')
  }

  @Watch('model')
  onModelChange (newValue: any) {
    this.filterModel = _.cloneDeep(newValue)
  }

  @Watch('showModal')
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  async created () {
    await this.$store.dispatch('moneyMovementCategory/getAll')
  }
}
