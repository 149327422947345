
























import Vue from 'vue'
import Component from 'vue-class-component'
import box from '@/modules/common/components/box.vue'
import loading from '@/modules/common/components/loading.vue'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'

@Component({
  components: { box, loading }
})
export default class UserInfoBox extends Vue {
  @State('selected', { namespace: 'user' }) user!: User
}
