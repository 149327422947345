

















































import _ from 'lodash'
import Component from 'vue-class-component'
import i18n from '@/i18n'
import Item from '@/modules/item/domain/Item'
import Labeled from '@/modules/common/values/labeled'
import { localeCompare } from '@/utils'
import noRecords from '@/modules/common/components/noRecords.vue'
import Order from '@/modules/order/domain/order'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

class DataSource {
  value: string
  label: string
  groupFn: (order: Order) => Labeled

  constructor (value: string, label: string, groupFn: (order: Order) => Labeled) {
    this.value = value
    this.label = label
    this.groupFn = groupFn
  }
}

class SumItem {
  item: Item
  values: {
    [key: string]: number
  }

  constructor (item: Item) {
    this.item = item
    this.values = {}
  }

  get sum () {
    return _(this.values).values().sum()
  }
}

class SumItems {
  // eslint-disable-next-line no-undef
  [key: string]: SumItem
}

@Component({
  components: { selectField, noRecords }
})
export default class OrdersOverviewItemTable extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) orders!: Order[]

  dataSource: DataSource | null = null

  notDefined = {
    value: 0,
    label: i18n.message('common.not-defined')
  } as Labeled

  multiple = {
    value: -1,
    label: i18n.message('common.category.multiple')
  } as Labeled

  dataSources = [
    new DataSource('REGION', i18n.message('regions.label'), order => order.subscriberAddress!.region ? order.subscriberAddress!.region : this.notDefined),
    new DataSource('SUBJECT_CATEGORY', i18n.message('partner.category.label'), order => {
      if (_.isEmpty(order.subscriber!.categories)) {
        return this.notDefined
      } else if (_.size(order.subscriber!.categories) === 1) {
        return order.subscriber!.categories[0]
      } else {
        return this.multiple
      }
    })
  ]

  columns: Labeled[] = []
  items: Item[] = []
  sumItems: SumItems | null = null

  @Watch('orders')
  onOrdersChanged () {
    this.calculateSumItems()
  }

  get currency () {
    return this.orders.length ? this.orders[0].currency : null
  }

  private generateColumns () {
    this.columns = _(this.orders)
      .map(this.dataSource!.groupFn)
      .uniqBy('id')
      .sortBy('order')
      .value()
  }

  private calculateSumItems () {
    if (this.dataSource) {
      this.generateColumns()

      const sumItems = new SumItems()

      this.orders.forEach(order => {
        const groupObj = this.dataSource!.groupFn(order)
        order.items.forEach(orderItem => {
          const item = orderItem.item!
          if (!sumItems[item.id! + '']) {
            sumItems[item.id! + ''] = new SumItem(item)
          }
          const sumItem = sumItems[item.id!]
          const numberOfItems = order.delivered ? orderItem.delivered : orderItem.ordered
          sumItem.values[groupObj.value + ''] = (sumItem.values[groupObj.value + ''] || 0) + numberOfItems!
        })
      })
      this.items = _(sumItems).values().map('item').sort(localeCompare('name')).value()

      this.sumItems = sumItems
    }
  }

  created () {
    this.calculateSumItems()
  }
}
