import _ from 'lodash'
import AuditableEntity from '@/modules/common/domain/auditableEntity'
import { Entity } from '@/modules/common/domain/entity'
import { Moment } from 'moment'
import User from '@/modules/user/domain/user'
import Stock from '@/modules/stock/domain/stock'
import StockMovementItem from '@/modules/stock/movement/domain/stockMovementItem'
import Inventory from '@/modules/inventory/domain/inventory'

class StockMovement extends Entity implements AuditableEntity {
  stock?: Stock | null
  inventory: Inventory | null
  number?: number
  date?: Moment | null
  note?: string
  totalPrice?: number | null
  items: StockMovementItem[]
  createdBy?: User | null
  updatedBy?: User | null
  editable?: boolean
  '@type': string

  constructor (data: any) {
    super(data, 'id', 'formattedNumber', ['note'])

    this.stock = data.stock == null ? null : new Stock(data.stock)
    this.inventory = data.inventory == null ? null : new Inventory(data.inventory)
    this.date = this.convertDate(data.date)
    this.items = data.items ? data.items.map((item: StockMovementItem) => new StockMovementItem(item)) : []
    this.createdBy = data.createdBy == null ? null : new User(data.createdBy)
    this.updatedBy = data.updatedBy == null ? null : new User(data.updatedBy)
    this.totalPrice = _.isNil(data.totalPrice) ? null : data.totalPrice

    this.relations('items')
  }

  get income () {
    return this['@type'] === 'income'
  }

  get outcome () {
    return this['@type'] === 'outcome'
  }
}

export default StockMovement
