import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import moment, { Moment } from 'moment'
import Item from '@/modules/item/domain/Item'
import Company from '@/modules/company/domain/company'
import PriceListItem from '@/modules/pricelist/domain/priceListItem'
import i18n from '@/i18n'
import { FORMAT_DATE } from '@/utils'

export default class PriceList extends Entity {
  item?: Item | null
  company?: Company | null
  validFrom?: Moment | null
  items: Array<PriceListItem>

  constructor (data: any) {
    super(data, 'id')

    this.item = data.item == null ? null : new Item(data.item)
    this.company = data.company == null ? null : new Company(data.company)
    this.validFrom = this.convertDate(data.validFrom)
    this.items = data.items ? data.items.map((item: any) => new PriceListItem(item)) : []
    this.label = this.validFrom ? i18n.message('price-list.label', [FORMAT_DATE(this.validFrom)]) : ''
    this.relations('items')
  }

  validForCurrency (currency: string) {
    return _.some(this.items, { currency })
  }

  validInDate (date?: moment.Moment) {
    return !this.validFrom || this.validFrom.isSameOrBefore(date || moment())
  }
}
