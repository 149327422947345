import Range from '@/modules/common/components/form/range'

export class TakingsOverviewFilter {
  range: Range | null = null
  groupType: string | null = null
  companyBranchIds: number[] = []
  currency: string | null = null

  get hasValidRange () {
    return this.range && this.range.from && this.range.from.isValid() && this.range.to && this.range.to.isValid()
  }
}
