







import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import { StockMovementFilter } from '@/modules/stock/movement/domain/stockMovementFilter'
import Vue from 'vue'
import StockMovementFilterBox from '@/modules/stock/movement/components/stockMovementFilterBox.vue'
import StockMovementsTable from '@/modules/stock/movement/components/stockMovementsTable.vue'

@Component({
  components: { StockMovementsTable, StockMovementFilterBox, page }
})
export default class StockMovementsPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stocks.label' }, { textKey: 'stock-movements.label.2' }])

  filter: StockMovementFilter | null = null

  async onFilterChanged (filter: StockMovementFilter) {
    this.filter = filter
  }
}
