






import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import itemsTable from '@/modules/item/components/itemsTable.vue'

@Component({ components: { itemsTable, page } })
export default class ItemSettingPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'items.label' }])
}
